import * as tslib_1 from "tslib";
import { throwError as observableThrowError, Subject } from 'rxjs';
import { catchError, share } from 'rxjs/operators';
import { NgZone } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { environment } from '@myenv/environment';
import { enMessage } from '@shared/en-us-message';
import { isArray } from 'util';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@layouts/login/authentication.service';
import { BehaviorSubject } from 'rxjs';
var SharedService = /** @class */ (function () {
    function SharedService(http, handler, router, ngzone, authenticationService, route) {
        this.http = http;
        this.handler = handler;
        this.router = router;
        this.ngzone = ngzone;
        this.authenticationService = authenticationService;
        this.route = route;
        this.apiUrl = environment.apiUrl;
        this.ErrorMsg = new Subject();
        this.SuccessMsg = new Subject();
        this.WarningMsg = new Subject();
        this.InfoMsg = new Subject();
        this.CustomMsg = new Subject();
        this.ServerErrorMsg = new Subject();
        this.emitChangeNotify = new Subject();
        this.changeEmittedNotify$ = this.emitChangeNotify.asObservable();
        this.responseMessage = {};
        this.unoHeader = environment.unoHeader;
        this.activeSeeker = [];
        this.resolutionParam = ['.header', 'app-data-tab-header', 'footer'];
        this.primaryConfig = new BehaviorSubject(null);
        this.primaryConfigData = this.primaryConfig.asObservable();
        this.httpClient = new HttpClient(handler);
    }
    SharedService.prototype.setResponseMessage = function (data) {
        this.emitChangeNotify.next(data);
    };
    SharedService.prototype.getCurrentUser = function () {
        return JSON.parse(localStorage.getItem('currentUser'));
    };
    SharedService.prototype.updateUser = function (data) {
        return this.http.put(this.apiUrl + 'users/' + data.userId, data).pipe(catchError(this.handleError));
    };
    SharedService.prototype.getVehicleHeader = function () {
        return this.http.get(this.apiUrl + 'vehicles/header')
            .pipe(share()).pipe(catchError(this.handleError));
    };
    SharedService.prototype.getAllEvVehicles = function (page) {
        return this.http.get(this.apiUrl + 'devices?sortingKey=lastCommunication&direction=desc&key=generation&value=EBIKE&isAssociatedDevice=false&page=0' + page).pipe(catchError(this.handleError));
    };
    SharedService.prototype.getDurationBtwTwoEpoch = function (ts, te) {
        var delta = Math.abs(te - ts) / 1000;
        var days = Math.floor(delta / 86400);
        delta -= days * 86400;
        var hours = Math.floor(delta / 3600) % 24;
        delta -= hours * 3600;
        var minutes = Math.floor(delta / 60) % 60;
        delta -= minutes * 60;
        var durationInSecs = delta % 60;
        return [days, hours, minutes, durationInSecs];
    };
    // To get all Place
    SharedService.prototype.getAllPlace = function () {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        });
        return this.http.get(this.apiUrl + 'places', { headers: headers }).pipe(catchError(this.handleError));
    };
    // To get all Alerts
    SharedService.prototype.getAllAlerts = function () {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        });
        return this.http.get(this.apiUrl + 'alerts/type', { headers: headers }).pipe(catchError(this.handleError));
    };
    SharedService.prototype.getUsers = function () {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        });
        return this.http.get(this.apiUrl + 'users', { headers: headers }).pipe(catchError(this.handleError));
    };
    SharedService.prototype.getAllCampaign = function () {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        });
        return this.http.get(this.apiUrl + 'campaign', { headers: headers }).pipe(catchError(this.handleError));
    };
    SharedService.prototype.getAllDevices = function (selectedGroup) {
        var tempUrl = 'devices?sortingKey=lastCommunication&direction=desc';
        if (selectedGroup) {
            tempUrl += '&groups=' + selectedGroup;
        }
        tempUrl += "&attributes=deviceId,serialNumber,assetName,productSerialNumber,vin,lastCommunication,deviceStatus,architectureType,ecuMake,generation,simStatus,imei,firmwareVersion,configVersion,simCcid,lastKnownEvent,debugLogsUploadEnabled,lastKnownStatus";
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        });
        return this.http.get(this.apiUrl + tempUrl, { headers: headers }).pipe(catchError(this.handleError));
    };
    SharedService.prototype.getDashcam = function (deviceId, startTime, endTime) {
        return this.http.get(this.apiUrl + 'messages/device/' + deviceId + '?messageType=DashCam&page=0&attributes=latitude,longitude,eventTime,heading,vehicleSpeed,messageType,ingestionTimestamp,messageId,camSerialNumber,eventName,videoURL,camType&startTime=' + startTime + '&endTime=' + endTime + '&sort=ASC&limit=10')
            .pipe(catchError(this.handleError));
    };
    SharedService.prototype.getAllActiveDevices = function () {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        });
        return this.http.get(this.apiUrl + 'devices?sortingKey=lastCommunication&direction=desc&active=true', { headers: headers }).pipe(catchError(this.handleError));
    };
    SharedService.prototype.getAllCustomer = function () {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        });
        return this.http.get(this.apiUrl + 'customers/user', { headers: headers }).pipe(catchError(this.handleError));
    };
    SharedService.prototype.getRoles = function () {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        });
        return this.http.get(this.apiUrl + 'roles', { headers: headers }).pipe(catchError(this.handleError));
    };
    SharedService.prototype.getGroups = function () {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        });
        return this.http.get(this.apiUrl + 'groups/associated-vehicles', { headers: headers }).pipe(catchError(this.handleError));
    };
    SharedService.prototype.getVehiclesView = function () {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        });
        return this.httpClient.get(this.apiUrl + 'vehicles?sortingKey=lastCommunication&direction=desc', { headers: headers }).pipe(catchError(this.handleError));
    };
    SharedService.prototype.searchVehicles = function (searchValue) {
        return this.http.get(this.apiUrl + ("messages/latest/search?limit=3000&searchKey=assetName&searchValue=" + searchValue)).pipe(catchError(this.handleError));
    };
    SharedService.prototype.getAllVehicles = function (page) {
        return this.http.get(this.apiUrl + 'devices?sortingKey=lastCommunication&direction=desc&page=' + page).pipe(catchError(this.handleError));
    };
    SharedService.prototype.getAllfavourites = function (type) {
        return this.http.get(this.apiUrl + ("favourite?typeCode=" + type)).pipe(catchError(this.handleError));
    };
    SharedService.prototype.getFavDetails = function (name) {
        return this.http.get(this.apiUrl + ("favourite/" + name)).pipe(catchError(this.handleError));
    };
    SharedService.prototype.deletefavourite = function (name) {
        return this.http.delete(this.apiUrl + 'favourite/' + name).pipe(catchError(this.handleError));
    };
    SharedService.prototype.addfavourite = function (data) {
        return this.http.post(this.apiUrl + 'favourite', data).pipe(catchError(this.handleError));
    };
    SharedService.prototype.updateFav = function (name, payload) {
        return this.http.put(this.apiUrl + ("favourite/" + name), payload).pipe(catchError(this.handleError));
    };
    SharedService.prototype.getAllMessages = function () {
        return this.http.get(this.apiUrl + 'messages/types').pipe(catchError(this.handleError));
    };
    SharedService.prototype.onSearchVehicle = function (key, value, data, group, attributes) {
        var tempUrl = this.apiUrl + "devices?sortingKey=lastCommunication&direction=desc";
        if (value !== '' && value) {
            tempUrl += "&key=" + key + "&value=" + value;
        }
        if (data) {
            tempUrl += "&status=" + data;
        }
        if (group) {
            tempUrl += "&groups=" + group;
        }
        if (attributes) {
            tempUrl += "&attributes=" + attributes;
        }
        return this.http.get(tempUrl).pipe(catchError(this.handleError));
    };
    SharedService.prototype.onSearchDevice = function (key, value, page, data, group, active, attributes, isAssociatedDevice) {
        var tempUrl = this.apiUrl + "devices?sortingKey=lastCommunication&direction=desc";
        if (isAssociatedDevice == undefined) {
            isAssociatedDevice = false;
        }
        if (value !== '' && value) {
            tempUrl += "&key=" + key + "&value=" + value + "&isAssociatedDevice=" + isAssociatedDevice;
        }
        if (data) {
            tempUrl += "&status=" + data;
        }
        if (page || page == 0) {
            tempUrl += "&page=" + page;
        }
        if (group) {
            tempUrl += "&groups=" + group;
        }
        if (active) {
            tempUrl += "&active=" + active;
        }
        if (attributes) {
            tempUrl += "&attributes=" + attributes;
        }
        return this.http.get(tempUrl).pipe(catchError(this.handleError));
    };
    SharedService.prototype.onBenchTestDeviceSearch = function (key, value) {
        return this.http.get(this.apiUrl + "devices/bench/search?key=" + key + "&value=" + value).pipe(catchError(this.handleError));
    };
    SharedService.prototype.getCounts = function () {
        return this.http.get(this.apiUrl + "devices/bench/score").pipe(catchError(this.handleError));
    };
    SharedService.prototype.getDeviceHeader = function () {
        return this.http.get(this.apiUrl + 'devices/header')
            .pipe(share()).pipe(catchError(this.handleError));
    };
    SharedService.prototype.getVehicleEventDates = function (deviceId, startTime, endTime) {
        var offset = new Date().getTimezoneOffset() * 60 * 1000 * -1;
        return this.http.get(this.apiUrl + "analytics/device/" + deviceId + "/messages/newIndicator" +
            ("?startTime=" + startTime + "&endTime=" + endTime + "&offset=" + offset))
            .pipe(catchError(this.handleError));
    };
    SharedService.prototype.getDeviceEventDates = function (serialNumber, startTime, endTime) {
        return this.http.get(this.apiUrl + "analytics/serialNumber/" + serialNumber + "/messages/indicator" +
            ("?startTime=" + startTime + "&endTime=" + endTime))
            .pipe(catchError(this.handleError));
    };
    SharedService.prototype.setOBDHeight = function (getScreenWidth, getScreenHeight, isHavingEnoughSpace, params, pidViewTabPos) {
        var vh1 = 0;
        var timeSeeker = $('#tskr').height();
        this.resolutionParam.map(function (e) {
            var height = $(e).height();
            if (height) {
                vh1 += height;
            }
            else if (e === 'app-data-tab-header') {
                vh1 += 96;
            }
            // if (e === '#tskr' && height) { vh1 += 38; }
        });
        var p = $('#tripView').height(); // trip view
        var q = $('#pidView').height(); // pid section
        var r = $('#messageView').height(); // message view
        vh1 = this.convertPxToVH(vh1);
        vh1 = vh1 + 1;
        var overallHeight = vh1;
        var accordionSection = this.convertPxToVH((p >= 0 ? (p + q) : (q + r)));
        var timeSeekerHeightInVh = this.convertPxToVH(timeSeeker);
        var equation = (100 - (vh1 + timeSeekerHeightInVh));
        // right side container
        var a = (getScreenHeight <= 650) ? (equation * 0.5) : (getScreenHeight > 650 && getScreenHeight <= 800) ? (equation * 0.45) : (getScreenHeight > 800 && getScreenHeight <= 950) ? (equation * 0.40) : (equation * 0.35);
        $('#gmap').height((equation - (a + 1)) + 'vh');
        $('#drawerContent').height((a - 2.5) + 'vh');
        $('#rightSctn').height((equation - 6) + 'vh');
        var leftSctnHeightInVh = 100 - (vh1 + 1);
        $('#leftsctn').height((leftSctnHeightInVh + 'vh'));
        var leftSection = $('#leftsctn').height();
        var firstActiveSection = $('.left-sec')[0].style.display === 'block' ? $($('.left-sec')[0]) : $($('.left-sec')[1]);
        var firstActiveContainer = firstActiveSection.find('#tripsContainer');
        var messageActiveContainer = firstActiveSection.find('#messageContainer');
        var col = $('#leftCol' + params);
        var dataViewSection = $($('.left-sec')[2]); // pids section
        var tripTable = $('#trips');
        var tripInfo = $('#trip_info').height();
        if (!isHavingEnoughSpace) {
            dataViewSection.height(q);
            if (params === 3) { // pid view
                // col.height(100 - (accordionSection + overallHeight + 2.5) + 'vh');
            }
            else {
                var height = (leftSctnHeightInVh - (this.convertPxToVH(tripInfo) + accordionSection + 2.7));
                // firstActiveSection.css('max-height', 'none');
                // col.css('max-height', (leftSctnHeightInVh - this.convertPxToVH(tripInfo + r)) + 'vh');
                // col.css('max-height', (height - this.convertPxToVH(p + tripInfo)) + 'vh');
                // firstActiveSection.css('max-height', (height + 'vh'));
                firstActiveContainer.css('max-height', height + 'vh');
                messageActiveContainer.css('max-height', height + 'vh');
            }
        }
        else if (isArray(params)) {
            console.info('Intervend Enough space');
            if (params[0] === 'trip_expand') {
                var table_header = $(firstActiveSection).find('datatable-header').height();
                var height = (leftSctnHeightInVh - (this.convertPxToVH(p)));
                firstActiveSection.css('height', 'auto');
                // firstActiveSection.css('max-height', '50%');
                // console.info(firstActiveSection.height() - (p + tripInfo));
                // $('#leftCol1').css('max-height', (firstActiveSection.height() - (p + tripInfo)));
                // col.css('max-height', (height - this.convertPxToVH(p + tripInfo)) + 'vh');
                // firstActiveSection.css('max-height', (leftSctnHeightInVh - (accordionSection + 2)) + 'vh');
                // $(firstActiveSection).find('datatable-body').css('max-height', firstActiveSection.height() - (p + trip_view + table_header + 12));
            }
            else if (params[0] === 'trip_collapse') {
                $(firstActiveSection).height(p);
            }
            else if (params[0] === 'msg_expand') {
                var msg_view = $('#msg_info').height();
                firstActiveSection.css('height', 'auto');
                // firstActiveSection.css('max-height', '50%');
                // $('#leftCol3').height(firstActiveSection.height() - (r + msg_view));
            }
            else if (params[0] === 'msg_collapse') {
                $($('.left-sec')[1]).height(r);
            }
            if (params[1] === 'data_expand') {
                var height = leftSection - (firstActiveSection.height());
                // $('#drawerContainer').height(height - q);
                dataViewSection.height(height);
            }
            else if (params[1] === 'data_collapse') {
                dataViewSection.height(q);
            }
        }
        // const col = $('#leftCol' + (view + 1));
        // if (getScreenHeight < slab) {
        // if (view === 1) { // pid view
        //   col.height(100 - (leftCol + overallHeight + 2.5) + 'vh');
        // } else {
        //   col.css('max-height', (100 - (leftCol + overallHeight + (view === 2 ? 0 : 2.5))) + 'vh');
        // }
        // } else {
        //   const dataViewSection = $($('.left-sec')[2]); // pids section
        //   if (view[0] === 'trip_expand') {
        //     const trip_view = $('#trip_info').height();
        //     const table_header = $(firstActiveSection).find('datatable-header').height();
        //     // firstActiveSection.css('max-height', (leftSctnHeightInVh/2) + 'vh');
        //     firstActiveSection.css('height', 'auto');
        //     firstActiveSection.css('max-height', '50%');
        //     $(firstActiveSection).find('datatable-body').css('max-height', firstActiveSection.height() - (p + trip_view + table_header + 12));
        //   } else if (view[0] === 'trip_collapse') {
        //     $(firstActiveSection).height(p);
        //   } else if (view[0] === 'msg_expand') {
        //     const msg_view = $('#msg_info').height();
        //     firstActiveSection.css('height', 'auto');
        //     firstActiveSection.css('max-height', '50%');
        //     $('#leftCol3').height(firstActiveSection.height() - (r + msg_view));
        //   } else if (view[0] === 'msg_collapse') {
        //     $($('.left-sec')[1]).height(r);
        //   }
        //   if (view[1] === 'data_expand') {
        //     const height = leftSection - (firstActiveSection.height());
        //     dataViewSection.height(height);
        //   } else if (view[1] === 'data_collapse') {
        //     dataViewSection.height(q);
        //   }
        // }
        if ((!isHavingEnoughSpace && params === 3) || isHavingEnoughSpace) {
            this.set_OBD_PID_Height(pidViewTabPos);
        }
    };
    SharedService.prototype.set_OBD_PID_Height = function (pidViewTabPos) {
        var pidSection = $('#pidView').height(); // pid section
        var leftSection = $('#leftsctn').height();
        var firstActiveSection = $('.left-sec')[0].style.display === 'block' ? $($('.left-sec')[0]) : $($('.left-sec')[1]);
        var navHeight = $('.nav.nav-tabs.detailed-tabs').height();
        if (pidViewTabPos === 0) {
            var searchBarHeight = $('.input-group.input-group-lg.searchVeh.liveSearch').height();
            var tableHeader = $('#pidTable').find('datatable-header').height();
            var height = leftSection - (firstActiveSection.height() + pidSection + navHeight + searchBarHeight + (tableHeader ? tableHeader : 46) + 10);
            $('#pidTable > div > .datatable-body').css('height', height);
        }
        else if (pidViewTabPos === 1) {
            var height = leftSection - (firstActiveSection.height() + pidSection + navHeight);
            $('#gps').css('height', height);
        }
        else {
            var camRow = $('.cambtn').height();
            var height = leftSection - (firstActiveSection.height() + navHeight + camRow);
            $('#cam').height(height);
        }
    };
    SharedService.prototype.convertPxToVH = function (pixels) {
        return pixels * (100 / document.documentElement.clientHeight);
    };
    // getDevicesView(): Observable<any> {
    //
    //   return this.http.get(this.apiUrl + 'devices')
    //     .pipe(share()).pipe(
    //     catchError(this.handleError));
    // }
    SharedService.prototype.getModules = function () {
        return this.http.get(this.apiUrl + 'modules')
            .pipe(share()).pipe(catchError(this.handleError));
    };
    SharedService.prototype.getRoleAccessModule = function (roleId) {
        return this.http.get(this.apiUrl + 'roles/' + roleId)
            .pipe(share()).pipe(catchError(this.handleError));
    };
    SharedService.prototype.getUserRoleId = function (tokenId) {
        return this.http.get(this.apiUrl + 'tokens/' + tokenId)
            .pipe(share()).pipe(catchError(this.handleError));
    };
    SharedService.prototype.setAccessRole = function (accessRoleModules, permissionModules) {
        localStorage.setItem('accessRoleModules', JSON.stringify(accessRoleModules));
        localStorage.setItem('permissionModules', JSON.stringify(permissionModules));
        // localStorage.setItem('resPermissionModule', JSON.stringify(permissionModules));
    };
    SharedService.prototype.getReports = function () {
        return this.http.get(this.apiUrl + 'reports')
            .pipe(share()).pipe(catchError(this.handleError));
    };
    SharedService.prototype.getALReports = function () {
        return this.http.get(this.apiUrl + 'reports')
            .pipe(share()).pipe(catchError(this.handleError));
    };
    SharedService.prototype.uploadProfilePicture = function (data, userId) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        /*return this.http.post(this.apiUrl + 'users/' + userId + '/profile/picture', data, { headers })
          .pipe(share()).pipe(
            catchError(this.handleError));*/
        return this.httpClient.post(this.apiUrl + 'users/' + userId + '/profile/picture', data, { headers: headers })
            .pipe(catchError(this.handleError));
    };
    SharedService.prototype.handleError = function (error) {
        return observableThrowError(error.error || error || 'Server error');
    };
    SharedService.prototype.impersonate = function (data) {
        var _headers = new HttpHeaders();
        var token = this.getHeaders();
        var headers = _headers.append('Authorization', 'Bearer ' + token).append('Content-Type', 'application/json');
        return this.http.post(this.apiUrl + 'impersonate/user/' + data + '/login', data).pipe(catchError(this.handleError));
    };
    SharedService.prototype.getHeaders = function () {
        return localStorage.getItem('token');
    };
    SharedService.prototype.getActivity = function (id, page, limit, filter) {
        var tempUrl = "activity/device/deviceId/" + id + "?page=" + page + "&limit=" + limit;
        if (filter)
            tempUrl += "&filter=" + filter;
        return this.http.get(this.apiUrl + tempUrl).pipe(catchError(this.handleError));
    };
    SharedService.prototype.updatePreference = function (data) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.httpClient.put(this.apiUrl + 'users/preferences/update', data, { headers: headers }).pipe(catchError(this.handleError));
    };
    // To get the vehicle count
    SharedService.prototype.getVehicleCodeCount = function (deviceId, startTime, endTime) {
        return this.http.get(this.apiUrl + 'analytics/dtc/device/' + deviceId + '/count?startTime=' + startTime + '&endTime=' + endTime);
    };
    SharedService.prototype.getErrorMsg = function (error, type) {
        var errorCodes = [400, 403, 404, 500];
        if (error.headers) {
            error = error.error;
        }
        if (error.status == 401) {
            if (localStorage.getItem('token')) {
                var token = localStorage.getItem('token');
                localStorage.clear();
                sessionStorage.clear();
                this.responseMessage = { success: false, message: enMessage.error401 };
                this.setResponseMessage(this.responseMessage);
                this.authenticationService.logout(token).subscribe(function (res) {
                });
            }
        }
        if (error.errorCode) {
            var errorCode = error.errorCode;
            var errorMessage = enMessage[errorCode];
            if (!errorMessage) {
                this.responseMessage = { success: false, message: enMessage.internalServerError };
                // this.setResponseMessage(this.responseMessage);
            }
            else {
                this.responseMessage = { success: false, message: errorMessage, type: type, error: error };
                this.setResponseMessage(this.responseMessage);
            }
        }
        else if (error.type && error.type === 'error') {
            // this.responseMessage = { success: 'noInternet', message: enMessage.noInternet };
            // this.setResponseMessage(this.responseMessage);
        }
        else {
            this.responseMessage = { success: false, message: enMessage.internalServerError };
            // this.setResponseMessage(this.responseMessage);
        }
    };
    SharedService.prototype.setDefaultUrl = function (permissionModules) {
        var _this = this;
        var defaultUrl = '/';
        var returnModule = this.route.snapshot.queryParams['returnModule'];
        if (permissionModules.includes(returnModule)) {
            if (this.route.snapshot.queryParams['returnUrl'].includes('location-history')) {
                defaultUrl = '/live';
            }
            else if (this.route.snapshot.queryParams['returnUrl'].includes('devices')) {
                defaultUrl = '/devices';
            }
            else if (this.route.snapshot.queryParams['returnUrl'].includes('campaign-detail')) {
                defaultUrl = '/ota';
            }
            else {
                defaultUrl = this.route.snapshot.queryParams['returnUrl'];
            }
        }
        else {
            if (permissionModules.includes('SUPPORT')) {
                defaultUrl = '/support';
                localStorage.setItem('defaultUrl', defaultUrl);
            }
            // else if (this.unoHeader && permissionModules.includes('DASHBOARD')) {
            //   defaultUrl = '/dashboard/default';
            //   localStorage.setItem('defaultUrl', defaultUrl);
            // }
            else {
                if (isArray(permissionModules)) {
                    // let defaultUrl;
                    if (permissionModules.includes('AZUGA-DASHBOARD')) {
                        defaultUrl = '/dashboard/azuga';
                        localStorage.setItem('defaultUrl', defaultUrl);
                    }
                    else if (permissionModules.includes('LIVE')) {
                        defaultUrl = '/live';
                        localStorage.setItem('defaultUrl', defaultUrl);
                    }
                    else if (permissionModules.includes('DASHBOARD')) {
                        defaultUrl = '/dashboard/default';
                        localStorage.setItem('defaultUrl', defaultUrl);
                    }
                    else if (permissionModules.includes('TRIPS')) {
                        defaultUrl = '/trips';
                        localStorage.setItem('defaultUrl', defaultUrl);
                    }
                    else if (permissionModules.includes('DATA')) {
                        defaultUrl = '/data';
                        localStorage.setItem('defaultUrl', defaultUrl);
                    }
                    else if (permissionModules.includes('REPORT')) {
                        defaultUrl = '/reports/system/live-status';
                        localStorage.setItem('defaultUrl', defaultUrl);
                    }
                    else if (permissionModules.includes('SCORE')) {
                        defaultUrl = '/score';
                        localStorage.setItem('defaultUrl', defaultUrl);
                    }
                    else if (permissionModules.includes('OTA')) {
                        defaultUrl = '/ota';
                        localStorage.setItem('defaultUrl', defaultUrl);
                    }
                    else if (permissionModules.includes('BENCH-TEST')) {
                        defaultUrl = '/bench-test';
                        localStorage.setItem('defaultUrl', defaultUrl);
                    }
                    else if (permissionModules.includes('DESTINATION')) {
                        defaultUrl = '/destinations';
                        localStorage.setItem('defaultUrl', defaultUrl);
                    }
                    else if (permissionModules.includes('CUSTOMER')) {
                        defaultUrl = '/customer';
                        localStorage.setItem('defaultUrl', defaultUrl);
                    }
                    else if (permissionModules.includes('TOOLS')) {
                        defaultUrl = '/tools';
                        localStorage.setItem('defaultUrl', defaultUrl);
                    }
                    else if (permissionModules.includes('ORDER')) {
                        defaultUrl = '/orders/pending';
                        localStorage.setItem('defaultUrl', defaultUrl);
                    }
                    else if (permissionModules.includes('CUSTOMER')) {
                        defaultUrl = '/customers';
                        localStorage.setItem('defaultUrl', defaultUrl);
                    }
                    else if (permissionModules.includes('API')) {
                        defaultUrl = '/developer';
                        localStorage.setItem('defaultUrl', defaultUrl);
                    }
                    else if (permissionModules.includes('SUPPORT-OTA')) {
                        defaultUrl = '/support-ota';
                        localStorage.setItem('defaultUrl', defaultUrl);
                    }
                    else if (permissionModules.includes('SUPPORT')) {
                        defaultUrl = '/support';
                        localStorage.setItem('defaultUrl', defaultUrl);
                    }
                    else if (permissionModules.includes('CONFIGURATION')) {
                        defaultUrl = '/configurations';
                        localStorage.setItem('defaultUrl', defaultUrl);
                    }
                    else if (permissionModules.includes('ADMIN')) {
                        if (permissionModules.includes('USER')) {
                            defaultUrl = '/admin/users';
                            localStorage.setItem('defaultUrl', defaultUrl);
                        }
                        // else if (permissionModules.includes('GROUP')) {
                        //   defaultUrl = '/admin/groups';
                        //   localStorage.setItem('defaultUrl', defaultUrl);
                        // }
                        else if (permissionModules.includes('ROLE')) {
                            defaultUrl = '/admin/roles';
                            localStorage.setItem('defaultUrl', defaultUrl);
                        }
                        // else if (permissionModules.includes('DEVICE')) {
                        //   defaultUrl = '/admin/device';
                        //   localStorage.setItem('defaultUrl', defaultUrl);
                        // }
                        else if (permissionModules.includes('ALERT')) {
                            defaultUrl = '/admin/alerts';
                            localStorage.setItem('defaultUrl', defaultUrl);
                        }
                        else if (permissionModules.includes('PLACE')) {
                            defaultUrl = '/admin/place';
                            localStorage.setItem('defaultUrl', defaultUrl);
                        }
                        else if (permissionModules.includes('SETTING')) {
                            defaultUrl = '/admin/whitelabel';
                            localStorage.setItem('defaultUrl', defaultUrl);
                        }
                        else if (permissionModules.includes('HEADER')) {
                            defaultUrl = '/admin/headers';
                            localStorage.setItem('defaultUrl', defaultUrl);
                        }
                        else if (permissionModules.includes('CACHE-ATTRIBUTES')) {
                            defaultUrl = '/admin/cache-attributes';
                            localStorage.setItem('defaultUrl', defaultUrl);
                        }
                        else if (permissionModules.includes('RULE')) {
                            defaultUrl = '/admin/rules';
                            localStorage.setItem('defaultUrl', defaultUrl);
                        }
                        else if (permissionModules.includes('AUDIT-TRIAL')) {
                            defaultUrl = '/admin/audit-trial';
                            localStorage.setItem('defaultUrl', defaultUrl);
                        }
                        else if (permissionModules.includes('DESTINATION')) {
                            defaultUrl = '/admin/destination';
                            localStorage.setItem('defaultUrl', defaultUrl);
                        }
                    }
                    else {
                        // let defaultUrl = '/';
                        localStorage.setItem('defaultUrl', defaultUrl);
                        this.logout();
                    }
                }
            }
        }
        if (defaultUrl != undefined) {
            this.ngzone.run(function () {
                _this.router.navigate([defaultUrl]);
            });
        }
    };
    /**
     * This will format the json input into reable format
     *
     * @param json : String
     * @returns
     */
    SharedService.prototype.syntaxHighlight = function (jsonText) {
        var json = JSON.stringify(jsonText, undefined, 4);
        json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
        return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
            var cls = 'number';
            if (/^"/.test(match)) {
                if (/:$/.test(match)) {
                    cls = 'key';
                }
                else {
                    cls = 'string';
                }
            }
            else if (/true|false/.test(match)) {
                cls = 'boolean';
            }
            else if (/null/.test(match)) {
                cls = 'null';
            }
            return '<span class="' + cls + '">' + match + '</span>';
        });
    };
    SharedService.prototype.logout = function () {
        var _this = this;
        var token = localStorage.getItem('token');
        localStorage.clear();
        sessionStorage.clear();
        if (token) {
            this.authenticationService.logout(token).subscribe(function (res) {
            });
        }
        this.ngzone.run(function () {
            _this.router.navigate(['/login']);
        });
    };
    //to get lastlocation of the device using this appi 
    SharedService.prototype.getVehicleDetail = function (deviceId) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.get(this.apiUrl + ("messages/device/" + deviceId + "/snapped"), { headers: headers }).pipe(catchError(this.handleError));
    };
    SharedService.prototype.goToHistory = function (params, device) {
        var _this = this;
        if (device) {
            localStorage.setItem('defaultselectvehicle', (device.length ? JSON.stringify(device[0]) : '{}'));
            // localStorage.setItem('defaultselectvehicle', JSON.stringify(value));
        }
        this.router.navigateByUrl('/*', { skipLocationChange: true }).then(function () {
            _this.router.navigate(['/trips'], params);
        });
    };
    SharedService.prototype.setdefaultselectvehicle = function (vehicleinfo) {
        // console.log("@@@@ set defaultselectvehicle",vehicleinfo);
        localStorage.setItem('defaultselectvehicle', JSON.stringify(vehicleinfo));
    };
    SharedService.prototype.getdefaultselectvehicle = function () {
        return JSON.parse(localStorage.getItem('defaultselectvehicle'));
    };
    // get title & subtitle
    SharedService.prototype.getTitle = function () {
        var get = JSON.parse(localStorage.getItem('title'));
        return get;
    };
    SharedService.prototype.setTitle = function (p1, p2) {
        localStorage.setItem('title', JSON.stringify({ title: p1, sub: p2 }));
    };
    SharedService.prototype.convertKPHtoMPH = function (value) {
        return Math.floor(value * 0.621371);
    };
    // To get the RecentdeviceData
    SharedService.prototype.getRecentdeviceData = function (deviceId) {
        return this.http.get(this.apiUrl + 'trips/device/' + deviceId + '/recent').pipe(catchError(this.handleError));
    };
    SharedService.prototype.getRecentdeviceDataAlternateAPI = function (deviceId) {
        return this.http.get(this.apiUrl + 'messages/device/' + deviceId + '/latest/occurrence').pipe(catchError(this.handleError));
    };
    SharedService.prototype.dateToTimeAgo = function (p1) {
        var date = new Date(p1);
        var now = new Date(Date.now());
        var difftime = now.getTime() - date.getTime();
        var diffDate = new Date(difftime - 5.5 * 60 * 60 * 1000);
        var _a = tslib_1.__read([
            diffDate.getSeconds(),
            diffDate.getMinutes(),
            diffDate.getHours(),
            diffDate.getDate() - 1,
            diffDate.getMonth(),
        ], 5), sec = _a[0], min = _a[1], hr = _a[2], day = _a[3], month = _a[4];
        var f = function (property, end) { return property + " " + end + (property > 1 ? "s" : "") + " ago"; };
        return month >= 1
            ? f(month, "month")
            : day >= 1
                ? f(day, "day")
                : hr >= 1
                    ? f(hr, "hr")
                    : min >= 1
                        ? f(min, "min")
                        : day >= 1
                            ? f(sec, "sec")
                            : "";
    };
    SharedService.prototype.getSeekerData = function (deviceId, ts, te, offset) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.get(this.apiUrl + ("messages/messagefilter/device/" + deviceId + "?startTime=" + ts + "&endTime=" + te + "&seeker=60&timeFormat=24&offsetInSeconds=" + offset), { headers: headers }).pipe(catchError(this.handleError));
    };
    SharedService.prototype.checkSeeker = function (val) {
        if (this.activeSeeker.includes(val.ts)) {
            var index = this.activeSeeker.indexOf(val.ts);
            this.activeSeeker.splice(index, 1);
            return true; // return true in consideration that the seeker has been deselected
        }
        else if (this.activeSeeker.length < 1 || this.activeSeeker.length === 1) {
            this.activeSeeker = [];
            this.activeSeeker.push(val.ts);
            return false; // return false in consideration that the seeker has been selected
        }
    };
    SharedService.prototype.checkSeekerActive = function (val) {
        if (this.activeSeeker.includes(val)) {
            return true;
        }
        return false;
    };
    SharedService.prototype.goToConfiguration = function (data) {
        var _this = this;
        this.router.navigateByUrl('/*', { skipLocationChange: true }).then(function () {
            _this.router.navigate(['/Configuration'], { state: { data: data } });
        });
    };
    SharedService.prototype.updatePrimaryConfig = function (data) {
        console.log('Emitting new data in next():', data); // For debugging
        this.primaryConfig.next(data); // Emit new data
    };
    return SharedService;
}());
export { SharedService };
