import { OnInit, EventEmitter, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { SharedService } from '../shared.service';
import { environment } from '@myenv/environment';
// const now = new Date();
var DateTimeCalendarComponent = /** @class */ (function () {
    function DateTimeCalendarComponent(calendar, sharedService) {
        this.calendar = calendar;
        this.sharedService = sharedService;
        this.timeChangeEvent = new EventEmitter();
        this.hideLinks = [];
        this.timeSelection = true;
        // variable created for message view in DataHub Visualization
        // as it supports only last 24hrs
        this.messageView = false;
        this.dateFormat = environment.noSecondsTimeFormat;
        this.showPicker = false;
        this.displayText = '';
        this.displayDates = {
            start: null,
            end: null
        };
        this.invalidDateTime = false;
        this.errorText = '';
        this.customSelected = {
            startDate: null,
            endDate: null,
            startTime: { hour: 0, minute: 0 },
            endTime: { hour: 23, minute: 59 }
        };
        this.fromtime = { hour: 0, minute: 0 };
        this.totime = { hour: 23, minute: 59 };
        this.date = new Date();
        this.today = {
            year: this.date.getFullYear(),
            month: this.date.getMonth() + 1,
            day: this.date.getDate()
        };
        this.meridian = true;
        // eventDates: any = [];
        // tripDates: any = [];
        this.eventClass = {};
        this.navigating = false;
        this.disableNavigation = false;
        this.subscribedReqs = [];
        this.showLoadingIcon = true;
        this.loading = {
            start: null,
            buff: [],
            end: null,
            dateRanges: {}
        };
        this.fromDate = calendar.getToday();
    }
    DateTimeCalendarComponent.prototype.ngOnInit = function () {
        if (this.selectPreset) {
            if (this.selectPreset === 'eventTime') {
                this.displayText = 'Event Time';
                this.displayDates = this.displayEventTime;
            }
            else {
                this.onTimeChange(this.selectPreset);
            }
        }
        else {
            this.onTimeChange('month');
        }
    };
    DateTimeCalendarComponent.prototype.ngOnChanges = function (changes) {
        // setting value for hiding overlay
        if (changes.clickOutside && changes.clickOutside.previousValue !== undefined) {
            if (changes.clickOutside.previousValue !== changes.clickOutside.currentValue) {
                this.closePicker();
            }
        }
        // setting display date
        if (changes.displayEventTime && changes.displayEventTime.previousValue !== undefined) {
            if (changes.displayEventTime.currentValue !== null &&
                (changes.displayEventTime.previousValue !== changes.displayEventTime.currentValue)) {
                this.displayDates = this.displayEventTime;
                this.displayText = 'Event Time';
            }
        }
        // setting vehicle on change
        if (changes.vehicle && changes.vehicle.previousValue !== undefined) {
            if (changes.vehicle.previousValue !== changes.vehicle.currentValue) {
                this.getVehicleEventDates(true);
            }
        }
        // for vehicle selected onload of component
        else if (changes.vehicle && this.singlySelectedVehicle) {
            if (changes.vehicle.currentValue) {
                this.getVehicleEventDates(true);
            }
        }
        // setting device on change
        if (changes.device && changes.device.previousValue !== undefined) {
            if (changes.device.previousValue !== changes.device.currentValue) {
                this.getVehicleEventDates(true);
            }
        }
    };
    DateTimeCalendarComponent.prototype.ngOnDestroy = function () {
        this.hideLinks = [];
        this.vehicle = null;
        this.singlySelectedVehicle = false;
        this.device = null;
        this.resetLoadingIcon();
    };
    DateTimeCalendarComponent.prototype.getVehicleEventDates = function (reset) {
        var _this = this;
        var loadingRange = new Date();
        if (reset) {
            this.resetLoadingIcon();
            this.loading.end = new Date().getTime();
        }
        else {
            loadingRange = new Date(this.loading.start);
            this.loading.end = this.loading.buff[0];
        }
        this.loading.start = loadingRange.setDate(loadingRange.getDate() - 60);
        this.loading.start = new Date(this.loading.start).getDate() >= 2 ? new Date(new Date(new Date(this.loading.start).setDate(1)).setHours(0, 0, 0, 0)).getTime() : new Date(new Date(this.loading.start).setHours(0, 0, 0, 0)).getTime();
        var start = new Date(this.loading.start);
        start.setDate(start.getDate() - 1);
        var Difference_In_Time = this.loading.end - this.loading.start;
        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        var date1 = new Date(this.loading.start);
        for (var i = 0; i < Difference_In_Days; i++) {
            var stringDate = this.getStringDate(date1);
            this.loading.dateRanges[stringDate] = 'loading';
            date1.setDate(date1.getDate() + 1);
        }
        for (var i = 1; i <= 6; i++) {
            var reqStart = start.setDate(start.getDate() + 1);
            var reqEnd = i === 6 ? this.loading.end : start.setDate(start.getDate() + 10) + 86399999;
            var req = this.sharedService.getVehicleEventDates(this.vehicle, reqStart, reqEnd).subscribe({
                next: function (res) {
                    // this.eventDates = res.noTrips;
                    // this.tripDates = res.trips;
                    _this.assignEventClass(res);
                    _this.hideLoadingIcon(new Date(res.startTime), new Date(res.endTime));
                },
                error: function (error) {
                    _this.sharedService.getErrorMsg(error);
                }
            });
            this.subscribedReqs.push(req);
        }
    };
    DateTimeCalendarComponent.prototype.getDeviceEventDates = function () {
        var _this = this;
        var d = new Date();
        var end = Date.parse(d.toUTCString());
        d.setMonth(d.getMonth() - 12);
        var start = Date.parse(new Date(d.getFullYear(), d.getMonth(), 1).toUTCString());
        this.sharedService.getDeviceEventDates(this.device, start, end).subscribe({
            next: function (res) {
                // this.eventDates = res.noTrips;
                // this.tripDates = res.trips;
                _this.assignEventClass(res);
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    DateTimeCalendarComponent.prototype.assignEventClass = function (res) {
        var _this = this;
        // this.eventClass = {};
        if (res.noTrips.length) {
            res.noTrips.map(function (item) {
                var i = new Date(item);
                var attr = _this.getStringDate(i);
                _this.eventClass[attr] = 'messageEvent';
            });
        }
        if (res.trips.length) {
            res.trips.map(function (item) {
                var i = new Date(item);
                var attr = _this.getStringDate(i);
                _this.eventClass[attr] = 'tripEvent';
            });
        }
    };
    // hide loading icon as we got response from api
    DateTimeCalendarComponent.prototype.hideLoadingIcon = function (s, e) {
        // To calculate the time difference of two dates
        var Difference_In_Time = new Date(new Date(e).setHours(23, 59, 59)).getTime() - new Date(new Date(s).setHours(0, 0, 0, 0)).getTime();
        // To calculate the no. of days between two dates
        var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
        for (var i = 0; i < Difference_In_Days; i++) {
            var stringDate = this.getStringDate(s);
            this.loading.dateRanges[stringDate] = 'loaded';
            s.setDate(s.getDate() + 1);
        }
    };
    DateTimeCalendarComponent.prototype.resetLoadingIcon = function () {
        this.eventClass = {};
        this.loading.dateRanges = {};
        this.loading.start = null;
        this.loading.end = null;
        this.subscribedReqs.map(function (e) {
            e.unsubscribe();
        });
    };
    DateTimeCalendarComponent.prototype.getRangeMinMax = function (date) {
        var attr = date.year + "-" + date.month + "-" + date.day;
        return this.loading.dateRanges[attr] === 'loading' ? true : false;
    };
    DateTimeCalendarComponent.prototype.getStringDate = function (i) {
        return i.getFullYear() + "-" + (i.getMonth() + 1) + "-" + i.getDate();
    };
    DateTimeCalendarComponent.prototype.togglePicker = function () {
        this.showPicker = !this.showPicker;
    };
    DateTimeCalendarComponent.prototype.closePicker = function () {
        if (this.showPicker) {
            if (this.displayText === 'Custom') {
                this.fromDate = this.customSelected.startDate;
                this.toDate = this.customSelected.endDate;
                this.fromtime = this.customSelected.startTime;
                this.totime = this.customSelected.endTime;
            }
            else {
                this.fromDate = this.calendar.getToday();
                this.toDate = null;
                this.fromtime = { hour: 0, minute: 0 };
                this.totime = { hour: 23, minute: 59 };
            }
            this.showPicker = false;
        }
    };
    DateTimeCalendarComponent.prototype.onTimeChange = function (e) {
        if (this.hideLinks.includes(e)) {
            return;
        }
        var start = null;
        var end = null;
        var t = new Date();
        if (e === 'today') {
            this.displayText = 'Today';
            start = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 0, 0, 0).toUTCString());
            end = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59).toUTCString());
        }
        else if (e === 'week') {
            this.displayText = 'This Week';
            var first = t.getDate() - t.getDay() + 1;
            var today = new Date();
            var firstday = new Date(today.setDate(first));
            start = Date.parse(new Date(firstday.getFullYear(), firstday.getMonth(), firstday.getDate(), 0, 0, 0).toUTCString());
            end = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59).toUTCString());
        }
        else if (e === 'month') {
            this.displayText = 'This Month';
            start = Date.parse(new Date(t.getFullYear(), t.getMonth(), 1, 0, 0, 0).toUTCString());
            end = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59).toUTCString());
        }
        else if (e === 'todaysofar') {
            this.displayText = 'Today so Far';
            start = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 0, 0, 0).toUTCString());
            end = Date.parse(t.toUTCString());
        }
        else if (e === 'weektodate') {
            this.displayText = 'Week to Date';
            var first = t.getDate() - t.getDay() + 1;
            var today = new Date();
            var firstday = new Date(today.setDate(first));
            start = Date.parse(new Date(firstday.getFullYear(), firstday.getMonth(), firstday.getDate(), 0, 0, 0).toUTCString());
            end = Date.parse(t.toUTCString());
        }
        else if (e === 'monthtodate') {
            this.displayText = 'Month to Date';
            start = Date.parse(new Date(t.getFullYear(), t.getMonth(), 1, 0, 0, 0).toUTCString());
            end = Date.parse(t.toUTCString());
        }
        else if (e === '15mins') {
            this.displayText = 'Last 15 Minutes';
            start = Date.parse(t.toUTCString()) - (15 * 60 * 1000);
            end = Date.parse(t.toUTCString());
        }
        else if (e === '30mins') {
            this.displayText = 'Last 30 Minutes';
            start = Date.parse(t.toUTCString()) - (30 * 60 * 1000);
            end = Date.parse(t.toUTCString());
        }
        else if (e === '1hour') {
            this.displayText = 'Last 1 Hour';
            start = Date.parse(t.toUTCString()) - (60 * 60 * 1000);
            end = Date.parse(t.toUTCString());
        }
        else if (e === '4hours') {
            this.displayText = 'Last 4 Hours';
            start = Date.parse(t.toUTCString()) - (60 * 4 * 60 * 1000);
            end = Date.parse(t.toUTCString());
        }
        else if (e === '12hours') {
            this.displayText = 'Last 12 Hours';
            start = Date.parse(t.toUTCString()) - (60 * 12 * 60 * 1000);
            end = Date.parse(t.toUTCString());
        }
        else if (e === '24hours') {
            this.displayText = 'Last 24 Hours';
            start = Date.parse(t.toUTCString()) - (60 * 24 * 60 * 1000);
            end = Date.parse(t.toUTCString());
        }
        else if (e === '7days') {
            this.displayText = 'Last 7 Days';
            t.setDate(t.getDate() - 7);
            start = Date.parse(t.toUTCString());
            end = Date.parse(new Date().toUTCString());
        }
        else if (e === '30days') {
            this.displayText = 'Last 30 Days';
            t.setDate(t.getDate() - 30);
            start = Date.parse(t.toUTCString());
            end = Date.parse(new Date().toUTCString());
        }
        else if (e === '90days') {
            this.displayText = 'Last 90 Days';
            t.setDate(t.getDate() - 90);
            start = Date.parse(t.toUTCString());
            end = Date.parse(new Date().toUTCString());
        }
        else if (e === '180days') {
            this.displayText = 'Last 180 Days';
            t.setDate(t.getDate() - 180);
            start = Date.parse(t.toUTCString());
            end = Date.parse(new Date().toUTCString());
        }
        else if (e === '365days') {
            this.displayText = 'Last 365 Days';
            t.setDate(t.getDate() - 365);
            start = Date.parse(t.toUTCString());
            end = Date.parse(new Date().toUTCString());
        }
        this.timeChangeEvent.emit({ startTime: start, endTime: end, e: e });
        this.displayDates = { start: start, end: end };
        this.showPicker = false;
        this.fromDate = this.calendar.getToday();
        this.toDate = null;
        this.navigating = false;
        this.disableNavigation = true;
    };
    DateTimeCalendarComponent.prototype.setCustomDate = function () {
        var start = null;
        var end = null;
        if (this.checkValidity()) {
            if (this.fromDate != null) {
                if (this.toDate != null) {
                    start = Date.parse(new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day, this.fromtime.hour, this.fromtime.minute, 0).toUTCString());
                    end = Date.parse(new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day, this.totime.hour, this.totime.minute, 59).toUTCString());
                }
                else {
                    start = Date.parse(new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day, this.fromtime.hour, this.fromtime.minute, 0).toUTCString());
                    end = Date.parse(new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day, this.totime.hour, this.totime.minute, 59).toUTCString());
                }
            }
            this.displayText = 'Custom';
            this.displayDates = { start: start, end: end };
            this.customSelected.startDate = this.fromDate;
            this.customSelected.endDate = this.toDate;
            this.customSelected.startTime = this.fromtime;
            this.customSelected.endTime = this.totime;
            this.timeChangeEvent.emit({ startTime: start, endTime: end });
            this.showPicker = false;
            this.navigating = false;
            this.disableNavigation = end >= Date.parse(new Date().toUTCString()) ? true : false;
        }
    };
    DateTimeCalendarComponent.prototype.navigate = function (dir) {
        this.navigating = true;
        // this.displayDates.start += 60000;
        this.displayDates.end += 60000;
        var diff = (this.displayDates.end - this.displayDates.start);
        if (dir === 'left') {
            this.disableNavigation = false;
            this.displayDates.end = this.displayDates.start - 60000;
            this.displayDates.start -= (diff);
            this.timeChangeEvent.emit({ startTime: this.displayDates.start, endTime: this.displayDates.end });
        }
        else if (dir === 'right') {
            var start = this.displayDates.end + 60000;
            var end = this.displayDates.end + diff;
            if (end >= Date.parse(new Date().toUTCString())) {
                this.disableNavigation = true;
            }
            else {
                this.disableNavigation = false;
                this.displayDates.start = start;
                this.displayDates.end = end;
                this.timeChangeEvent.emit({ startTime: start, endTime: end });
            }
        }
        // Set date and time in calendar
        if (this.displayText === 'Custom') { }
    };
    DateTimeCalendarComponent.prototype.checkValidity = function () {
        // Validation to time < from time for same day
        if (this.toDate === null && !this.validateHours) {
            if (this.totime.hour < this.fromtime.hour ||
                (this.totime.hour === this.fromtime.hour && this.totime.minute < this.fromtime.minute)) {
                this.invalidDateTime = true;
                this.errorText = 'End time cannot be after Start time.';
                return false;
            }
            else {
                this.invalidDateTime = false;
                return true;
            }
        }
        else if (this.toDate === null && this.validateHours) {
            var hours = this.validateHours * 60 * 60 * 1000;
            var start = Date.parse(new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day, this.fromtime.hour, this.fromtime.minute, 0).toUTCString());
            var end = Date.parse(new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day, this.totime.hour, this.totime.minute, 0).toUTCString());
            var diff = end - start;
            if (diff <= hours) {
                this.invalidDateTime = false;
                return true;
            }
            else {
                this.invalidDateTime = true;
                this.errorText = "Please select maximum " + this.validateHours + " Hours.";
                return false;
            }
        }
        else {
            // Validation for custom hours between two dates
            if (this.validateHours) {
                var hours = this.validateHours * 60 * 60 * 1000;
                var start = Date.parse(new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day, this.fromtime.hour, this.fromtime.minute, 0).toUTCString());
                var end = Date.parse(new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day, this.totime.hour, this.totime.minute, 0).toUTCString());
                var diff = end - start;
                if (diff <= hours) {
                    this.invalidDateTime = false;
                    return true;
                }
                else {
                    this.invalidDateTime = true;
                    this.errorText = "Please select maximum " + this.validateHours + " Hours.";
                    return false;
                }
            }
            else {
                this.invalidDateTime = false;
                return true;
            }
        }
    };
    DateTimeCalendarComponent.prototype.navigationOnMonth = function (p1) {
        var currentMonth = new Date(p1.next.year, p1.next.month, 1).getTime();
        if (currentMonth <= this.loading.start) {
            this.loading.buff.unshift(currentMonth);
            this.getVehicleEventDates(false);
        }
    };
    // isWeekend(date: NgbDateStruct) {
    //   const d = new Date(date.year, date.month - 1, date.day);
    //   return d.getDay() === 0 || d.getDay() === 6;
    // }
    // isDisabled(date: NgbDateStruct, current: { month: number }) {
    //   return date.month !== current.month;
    // }
    // hasMessages(date: NgbDateStruct) {
    //   return this.dateHasMessages(date);
    // }
    // hasTrips(date: NgbDateStruct) {
    //   return this.dateHasTrips(date);
    // }
    // showTasks(date: NgbDateStruct) {
    //   if (this.dateHasTask(date)) {
    //     // TODO show popup
    //     alert(date);
    //   }
    // }
    DateTimeCalendarComponent.prototype.getEventClass = function (date) {
        var attr = date.year + "-" + date.month + "-" + date.day;
        if (this.eventClass[attr]) {
            return this.eventClass[attr];
        }
        else {
            return 'noEvent';
        }
    };
    // dateHasMessages(date: NgbDateStruct): boolean {
    //   for (let i = 0; i <= this.eventDates.length; i++) {
    //     const td = new Date(this.eventDates[i]);
    //     const day: number = td.getDate();
    //     const month: number = td.getMonth() + 1;
    //     const year: number = td.getFullYear();
    //     if (day === date.day && month === date.month && year === date.year) {
    //       return true;
    //     }
    //   }
    // }
    // dateHasTrips(date: NgbDateStruct): boolean {
    //   for (let i = 0; i <= this.tripDates.length; i++) {
    //     const td = new Date(this.tripDates[i]);
    //     const day: number = td.getDate();
    //     const month: number = td.getMonth() + 1;
    //     const year: number = td.getFullYear();
    //     if (day === date.day && month === date.month && year === date.year) {
    //       return true;
    //     }
    //   }
    // }
    DateTimeCalendarComponent.prototype.onDateSelection = function (date) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
            this.maxRange = this.setMaxRange(date);
        }
        else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
            if (date.before(this.maxRange)) {
                this.toDate = date;
            }
            else {
                this.toDate = this.maxRange;
            }
        }
        else {
            this.fromDate = date;
            this.maxRange = this.setMaxRange(date);
            if (this.autoselectEndDate && !(this.maxRange.equals(this.today) || this.maxRange.after(this.today))) {
                this.toDate = this.maxRange;
            }
            else {
                this.toDate = null;
            }
        }
    };
    DateTimeCalendarComponent.prototype.setMaxRange = function (date) {
        if (this.maxRangeDays) {
            return this.calendar.getNext(date, 'd', this.maxRangeDays);
        }
        else {
            return this.calendar.getNext(date, 'd', 30);
        }
    };
    DateTimeCalendarComponent.prototype.isHovered = function (date) {
        if (date.before(this.today) && date.before(this.maxRange)) {
            return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
        }
    };
    DateTimeCalendarComponent.prototype.isRange = function (date) {
        return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
    };
    DateTimeCalendarComponent.prototype.isInside = function (date) {
        return date.after(this.fromDate) && date.before(this.toDate);
    };
    return DateTimeCalendarComponent;
}());
export { DateTimeCalendarComponent };
