import { merge as observableMerge, of, fromEvent } from 'rxjs';
// import {Observable} from 'rxjs/Rx';
import { map } from 'rxjs/operators';
import { OnInit, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@myenv/environment';
import { SharedService } from '@shared/shared.service';
import { enMessage } from '@shared/en-us-message';
import { AuthenticationService } from "@layouts/login/authentication.service";
var AppComponent = /** @class */ (function () {
    // tslint:disable-next-line:max-line-length
    function AppComponent(_document, sharedService, translate, route, router, authenticationService, ngzone) {
        var _this = this;
        this._document = _document;
        this.sharedService = sharedService;
        this.translate = translate;
        this.route = route;
        this.router = router;
        this.authenticationService = authenticationService;
        this.ngzone = ngzone;
        this.cmImageUrl = environment.cmImageUrl;
        this.title = 'BitBrew';
        this.currentUser = {};
        this.responseMessage = {};
        this.imgSrc = this.cmImageUrl + 'assets/img/load.svg';
        this._document.getElementById('appFavicon').setAttribute('href', this.cmImageUrl + 'assets/img/load.svg');
        translate.addLangs(['en', 'fr', 'it', 'zh-hans']);
        translate.setDefaultLang('en');
        var language = navigator.language || navigator.userLanguage || window.navigator.language;
        translate.use(language ? language : 'en');
        this.isConnected = observableMerge(of(navigator.onLine), fromEvent(window, 'online').pipe(map(function () { return true; })), fromEvent(window, 'offline').pipe(map(function () { return false; })));
        this.isConnected.subscribe(function (conn) {
            if (conn === false) {
                var errorCode = 'noInternet';
                var errorMessage = enMessage[errorCode];
                _this.responseMessage = { success: 'noInternet', message: errorMessage };
                _this.sharedService.setResponseMessage(_this.responseMessage);
            }
            if (conn === true) {
                var errorCode = 'internetIsBack';
                var errorMessage = enMessage[errorCode];
                _this.responseMessage = { success: 'internetIsBack', message: errorMessage };
                _this.sharedService.setResponseMessage(_this.responseMessage);
            }
        });
    }
    AppComponent.prototype.ngOnInit = function () {
        this.loginComponent();
        this.adminComponent();
        this.ordersComponent();
        this.reportsComponent();
        this.inactivityTime();
    };
    AppComponent.prototype.change = function () {
        this.value = Math.round(Math.random() * 1000000) / 100;
    };
    AppComponent.prototype.loginComponent = function () {
        if (window.location.pathname == '/login') {
            var defaultUrl = localStorage.getItem('defaultUrl');
            if (localStorage.getItem('token')) {
                this.router.navigate([defaultUrl]);
            }
            else {
                this.router.navigate(['login']);
            }
        }
    };
    AppComponent.prototype.adminComponent = function () {
        if (window.location.pathname == '/admin' || window.location.pathname == '/admin/') {
            var defaultAdminUrl = localStorage.getItem('defaultAdminUrl');
            if (localStorage.getItem('token')) {
                this.router.navigate([defaultAdminUrl]);
            }
            else {
                this.router.navigate(['login']);
            }
        }
    };
    AppComponent.prototype.reportsComponent = function () {
        if (window.location.pathname == '/reports' || window.location.pathname == '/reports/') {
            if (localStorage.getItem('token')) {
                this.router.navigate(['/reports/system/live-status']);
            }
            else {
                this.router.navigate(['login']);
            }
        }
    };
    AppComponent.prototype.ordersComponent = function () {
        if (window.location.pathname == '/orders' || window.location.pathname == '/orders/') {
            var defaultOrdersUrl = localStorage.getItem('defaultOrdersUrl');
            if (localStorage.getItem('token')) {
                this.router.navigate([defaultOrdersUrl]);
            }
            else {
                this.router.navigate(['login']);
            }
        }
    };
    AppComponent.prototype.inactivityTime = function () {
        var time;
        window.onload = resetTimer;
        // DOM Events
        window.onkeypress = resetTimer;
        window.onload = resetTimer;
        window.onmousemove = resetTimer;
        window.onmousedown = resetTimer; // touchscreen presses
        window.ontouchstart = resetTimer;
        window.onclick = resetTimer; // touchpad clicks
        window.onkeypress = resetTimer;
        window.addEventListener('scroll', resetTimer, true);
        var global = this;
        function logout() {
            var token = localStorage.getItem('token');
            localStorage.clear();
            sessionStorage.clear();
            if (token) {
                global.authenticationService.logout(token).subscribe(function (res) {
                });
            }
            global.ngzone.run(function () {
                global.router.navigate(['/login']);
            });
        }
        function resetTimer() {
            clearTimeout(time);
            time = setTimeout(logout, 60 * 60 * 8 * 1000);
        }
    };
    ;
    return AppComponent;
}());
export { AppComponent };
