import { OnInit, NgZone, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@layouts/login/authentication.service';
import { SharedService } from '@myproject/shared/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@myenv/environment';
import { CookieService } from 'angular2-cookie/core';
import { enMessage } from '@shared/en-us-message';
import { DashboardService } from '@components/dashboard/dashboard.service';
import { configMessage } from '@shared/config-message';
var HeaderComponent = /** @class */ (function () {
    function HeaderComponent(_cookieService, translate, authenticationService, ngzone, router, dashboardService, sharedService) {
        var _this = this;
        this._cookieService = _cookieService;
        this.translate = translate;
        this.authenticationService = authenticationService;
        this.ngzone = ngzone;
        this.router = router;
        this.dashboardService = dashboardService;
        this.sharedService = sharedService;
        // localStorage = localStorage;
        // public tenantId = environment.tenantId;
        this.unoHeader = environment.unoHeader;
        this.cmImageUrl = environment.cmImageUrl;
        this.currentUser = {};
        this.defaultReportUrl = localStorage;
        this.window = window;
        this.deviceId = '';
        this.url = '';
        this.accessRoleModules = {};
        this.permissionModules = [];
        this.notifications = [];
        this.dashboards = [];
        this.searchModel = {};
        this.responseMessage = {};
        this.impersonate = false;
        this.isPermission = false;
        this.unreadCount = 0;
        this.headerItems = [];
        this.outerHeader = [];
        this.dropdownHeader = [];
        this.randomNumber = Math.random();
        this.subTabHeader = false;
        this.menu_title = '';
        this.menu_subtitle = '';
        this.edgeimg = "../../../../assets/img/Edge_icon.svg";
        this.edgeToggle = false;
        this.profilePicRandomNumber = Math.random();
        authenticationService.changeEmitteds$.subscribe(function (data) {
            if (data === null) {
                _this.router.navigate(['/login']);
            }
            else if (data !== null) {
                _this.currentUser = data;
                _this.getRoleAccessModule();
            }
        });
    }
    HeaderComponent.prototype.ngOnInit = function () {
        this.url = this.router.url;
        this.currentUser = this.authenticationService.getCurrentUser();
        if (this.currentUser !== null) {
            this.getRoleAccessModule();
        }
        // edge icon change on reload only
        if (this.url.includes('devices')) {
            this.edgeToggle = true;
            this.edgeimg = '../../../../assets/img/Edge_icon_green.svg';
        }
        this.setMenuTitle();
        var data = localStorage.getItem('currentUser');
        var x = JSON.parse(data);
        this.userType = x.userType;
        this.accountKey = x.accountKey;
        if (this._cookieService.get('user')) {
            this.impersonate = true;
        }
        var token = this.authenticationService.getHeaders();
        this.authenticationService.setHeaders(token);
        this.localImpersonate = localStorage.getItem('impersonate');
        this.getUserNotification();
        this.getUnreadNotificationCount();
    };
    HeaderComponent.prototype.getRoleAccessModule = function () {
        this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
        this.getDefaultAdminUrl();
        this.getDefaultSupportAdminUrl();
        this.getDefaultOrdersUrl();
        this.setHeaderItems();
    };
    // on page reload only
    HeaderComponent.prototype.setMenuTitle = function () {
        var routeParam = (this.url).split('/');
        var url = this.router.url;
        var menu_title = '';
        var menu_subtitle = '';
        if (routeParam.includes('admin') && (routeParam.includes('tags') || routeParam.includes('device') || routeParam.includes('groups'))) {
            menu_title = 'Edge';
            menu_subtitle = routeParam[2];
            this.subTabHeader = false;
            this.sharedService.setTitle(menu_title, menu_subtitle);
        }
        else if (routeParam.includes('admin')) {
            menu_title = 'Admin';
            menu_subtitle = routeParam[2] === 'developer' ? 'Api Credentials' : routeParam[2];
            this.subTabHeader = false;
            this.sharedService.setTitle(menu_title, menu_subtitle);
        }
        else if (url.includes('data') || url.includes('data-simulator') || url.includes('destinations') || url.includes('trips') || url.includes('location-history') || url.includes('location-history?searchBy=&value=')) {
            menu_title = 'Data';
            this.subTabHeader = true;
            menu_subtitle = (routeParam[2] === 'data-schemes' ? 'Data Schemes' : routeParam[1] === 'destinations' ? 'Destinations'
                : routeParam[1] === 'data' ? 'Messages' : routeParam[1] === 'data-simulator' ? 'Data Simulator' : routeParam[1]);
            this.sharedService.setTitle(menu_title, menu_subtitle);
        }
        else if (routeParam.includes('reports')) {
            menu_title = 'Reports';
            menu_subtitle = 'General';
            this.subTabHeader = false;
            this.sharedService.setTitle(menu_title, menu_subtitle);
        }
        else {
            this.subTabHeader = false;
            this.sharedService.setTitle('', '');
        }
    };
    HeaderComponent.prototype.changeEdgeIcon = function () {
        if (this.edgeToggle) {
            this.edgeToggle = false;
            this.edgeimg = '../../../../assets/img/Edge_icon.svg';
        }
        else {
            this.edgeToggle = true;
            this.edgeimg = '../../../../assets/img/Edge_icon_green.svg';
        }
    };
    // on click of nav bar button
    HeaderComponent.prototype.subNavHeader = function (p1) {
        if (p1 === 'DATAHUB') {
            this.subTabHeader = true;
        }
        else if (p1 === 'DASHBOARD') {
            this.router.navigate(['/dashboard/azuga']);
        }
        else {
            this.subTabHeader = false;
        }
        // if (p1 !== 'DEVICE') {
        //   this.edgeToggle = false;
        //   this.edgeimg = '../../../../assets/img/Edge_icon.svg';
        // }
        // if (p1 === 'HISTORY') {
        //   const userPref = JSON.parse(localStorage.getItem('preferences'));
        //   if (userPref.liveHistory.toggle && userPref.liveHistory.toggle.tripsView) {
        //     this.router.navigate(['/trips'], {
        //       queryParams: {
        //         'liveSearch': '',
        //         'liveKey': ''
        //       }
        //     });
        //   } else {
        //     this.router.navigate(['/location-history']);
        //   }
        // }
    };
    HeaderComponent.prototype.setHeaderItems = function () {
        var _this = this;
        var header = [];
        header = this.unoHeader ? configMessage.unoHeaderItems : configMessage.headerItems;
        var userPref = JSON.parse(localStorage.getItem('preferences'));
        var dashboardAdded = false;
        var dataHubAdded = false;
        var connectionAdded = false;
        header.map(function (item) {
            if (_this.permissionModules.includes(item) && (item !== 'LIVE' && item !== 'TRIPS' && item !== 'DATA' && item !== 'HISTORY' && item !== 'DATASIMULATOR' && item !== 'DESTINATION') && (item !== 'DEVICE' && item !== 'GROUP' && item !== 'TAGS') && (item !== 'DASHBOARD' && item !== 'SYSTEM-DASHBOARD' && item !== 'EVENTS-DASHBOARD' && item !== 'FLEET-DASHBOARD' && item !== 'INSURANCE-DASHBOARD' && item !== 'EV-DASHBOARD' && item !== 'AZUGA-DASHBOARD' && item !== 'OTA')) {
                var obj = {};
                obj.name = item === 'HISTORY' ? 'History' : "menu." + item;
                obj.permission = item;
                obj.icon = configMessage.headerIcons[item];
                if (item === 'ADMIN') {
                    obj.route = localStorage.getItem('defaultAdminUrl');
                }
                else if (item === 'SUPPORT-ADMIN') {
                    obj.route = localStorage.getItem('defaultSupportAdminUrl');
                }
                else if (item === 'ORDER') {
                    obj.route = localStorage.getItem('defaultOrdersUrl');
                }
                else if (item === 'REPORT') {
                    obj.route = localStorage.getItem('lastReportUrl');
                }
                else if (item === 'HISTORY') {
                    // obj.route = (userPref.liveHistory.toggle && userPref.liveHistory.toggle.tripsView) ? '/trips' : '/location-history';
                    obj.route = '/trips';
                }
                else {
                    obj.route = configMessage.route[item];
                }
                _this.headerItems.push(obj);
            }
            if (_this.permissionModules.includes(item) && !dashboardAdded && (item === 'LIVE' || item === 'DASHBOARD' || item === 'SYSTEM-DASHBOARD' || item === 'EVENTS-DASHBOARD' || item === 'FLEET-DASHBOARD' || item === 'INSURANCE-DASHBOARD' || item === 'EV-DASHBOARD' || item === 'AZUGA-DASHBOARD')) {
                var obj = {};
                obj.permission = 'DASHBOARD';
                obj.route = item === 'LIVE' ? '/live' : item === 'DASHBOARD' ? '/dashboard/default' : item === 'SYSTEM-DASHBOARD' ? '/dashboard/system-dashboard' : item === 'EVENTS-DASHBOARD' ? '/dashboard/events-dashboard' : item === 'FLEET-DASHBOARD' ? '/dashboard/fleet-report' : item === 'INSURANCE-DASHBOARD' ? '/dashboard/insurance-report' : item === 'AZUGA-DASHBOARD' ? '/dashboard/azuga' : '/dashboard/ev';
                obj.name = 'Dashboard';
                obj.icon = configMessage.headerIcons['DASHBOARD'];
                dashboardAdded = true;
                _this.headerItems.push(obj);
            }
            else if (_this.permissionModules.includes(item) && !connectionAdded && (item === 'DEVICE' || item === 'GROUP' || item === 'TAGS' || item === 'OTA')) {
                var obj = {};
                obj.permission = 'CONNECTIONS';
                obj.route = item === 'DEVICE' ? '/devices' : item === 'GROUP' ? '/devices/groups' : item === 'TAGS' ? '/devices/tags' : '/ota';
                obj.icon = '../../../../assets/img/Edge_icon.svg';
                obj.name = 'Connections';
                obj.icon = configMessage.headerIcons['CONNECTIONS'];
                connectionAdded = true;
                _this.headerItems.push(obj);
            }
            else if (_this.permissionModules.includes(item) && !dataHubAdded && (item === 'TRIPS' || item === 'DATA' || item === 'DATASIMULATOR' || item === 'DESTINATION')) {
                var obj = {};
                obj.permission = 'DATAHUB';
                obj.route = item === 'TRIPS' ? '/trips' : item === 'DATA' ? '/data/messages' : item === 'DATASIMULATOR' ? '/data-simulator' : '/destinations';
                obj.name = 'Data Hub';
                obj.icon = configMessage.headerIcons['DATAHUB'];
                dataHubAdded = true;
                _this.headerItems.push(obj);
            }
        });
        if (this.headerItems.length >= 5) {
            for (var i = 0; i < this.headerItems.length; i++) {
                if (i >= 8) {
                    this.dropdownHeader.push(this.headerItems[i]);
                }
                else {
                    this.outerHeader.push(this.headerItems[i]);
                }
            }
        }
        else {
            this.outerHeader = this.headerItems;
        }
        if (this.outerHeader.length > 0) {
            this.outerHeader = this.sortHeaders(this.outerHeader);
        }
    };
    HeaderComponent.prototype.sortHeaders = function (data) {
        var flag = false;
        var sortedArra = [];
        var storedObj;
        data.forEach(function (ele) {
            if (ele.permission === "OTA") {
                storedObj = ele;
                flag = true;
                sortedArra.push(storedObj);
            }
            else if (ele.permission === "DEVICE") {
                sortedArra.push(ele);
                sortedArra.push(storedObj);
                flag = false;
            }
            else {
                sortedArra.push(ele);
            }
        });
        return sortedArra;
    };
    HeaderComponent.prototype.logout = function () {
        var _this = this;
        var token = localStorage.getItem('token');
        // let baseToken = atob(token);
        localStorage.clear();
        sessionStorage.clear();
        this.authenticationService.logout(token).subscribe(function (res) {
        });
        this.ngzone.run(function () {
            _this.router.navigate(['/login']);
        });
    };
    HeaderComponent.prototype.redirectTo = function () {
        this.subTabHeader = false;
        var defaultUrl = localStorage.getItem('defaultUrl');
        this.router.navigate([defaultUrl]);
    };
    HeaderComponent.prototype.menuClick = function () {
        this.menuClose.nativeElement.click();
    };
    HeaderComponent.prototype.dashboardClick = function (data) {
        this.menuClose.nativeElement.click();
        localStorage.setItem('currentDashboard', JSON.stringify(data));
        this.router.navigate(['/dashboard'], {
            queryParams: {
                id: data.dashboardId
            }
        });
    };
    HeaderComponent.prototype.getDashboards = function () {
        var _this = this;
        if (localStorage.getItem('dashboards')) {
            this.dashboards = JSON.parse(localStorage.getItem('dashboards'));
        }
        else {
            this.dashboardService.getDashboards().subscribe(function (res) {
                _this.dashboards = res;
                localStorage.setItem('dashboards', JSON.stringify(res));
            }, function (error) {
                _this.sharedService.getErrorMsg(error);
            });
        }
    };
    HeaderComponent.prototype.tokenScenario = function (res) {
        var _this = this;
        this.authenticationService.getLoggedUser().subscribe(function (reponseInFo) {
            if (res.status === 404) {
                var errorCode = res.errorCode;
                var errorMessage = enMessage[errorCode];
                _this.responseMessage = { success: false, message: errorMessage };
                _this.sharedService.setResponseMessage(_this.responseMessage);
            }
            else {
                _this.authenticationService.setLoggedInUser(reponseInFo);
                _this.permissionModules = reponseInFo.modules.map(function (data) { return data.name; });
                _this.sharedService.setAccessRole(reponseInFo.modules, _this.permissionModules);
                _this.sharedService.setDefaultUrl(_this.permissionModules);
                if (_this.permissionModules.includes('REPORT')) {
                    localStorage.setItem('lastReportUrl', '/reports/system/live-status');
                }
                window.location.reload();
            }
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    HeaderComponent.prototype.stopImpersonate = function () {
        var _this = this;
        this.authenticationService.stopImpersonate().subscribe(function (res) {
            if (res.accessToken) {
                localStorage.clear();
                var base64Token = res.accessToken;
                _this.authenticationService.setHeaders(base64Token);
                _this._cookieService.remove('user');
                _this.impersonate = false;
                _this.tokenScenario(res);
                localStorage.setItem('impersonate', 'false');
            }
            else if (res.status === 404) {
                var errorCode = res.errorCode;
                var errorMessage = enMessage[errorCode];
                _this.responseMessage = { success: false, message: errorMessage };
                _this.sharedService.setResponseMessage(_this.responseMessage);
            }
        }, function (error) {
            if (error.status === 404) {
                _this.sharedService.getErrorMsg(error);
            }
            else {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    HeaderComponent.prototype.getDefaultOrdersUrl = function () {
        if (this.permissionModules.includes('ORDER')) {
            this.defaultOrdersUrl = '/orders/pending';
            localStorage.setItem('defaultOrdersUrl', this.defaultOrdersUrl);
        }
    };
    HeaderComponent.prototype.getDefaultAdminUrl = function () {
        this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
        if (this.permissionModules.includes('ADMIN')) {
            if (this.permissionModules.includes('USER')) {
                this.defaultAdminUrl = '/admin/users';
                localStorage.setItem('defaultAdminUrl', this.defaultAdminUrl);
            }
            else if (this.permissionModules.includes('ROLE')) {
                this.defaultAdminUrl = '/admin/roles';
                localStorage.setItem('defaultAdminUrl', this.defaultAdminUrl);
            }
            else if (this.permissionModules.includes('PLACE')) {
                this.defaultAdminUrl = '/admin/place';
                localStorage.setItem('defaultAdminUrl', this.defaultAdminUrl);
            }
            else if (this.permissionModules.includes('ALERT')) {
                this.defaultAdminUrl = '/admin/alerts';
                localStorage.setItem('defaultAdminUrl', this.defaultAdminUrl);
            }
            else if (this.permissionModules.includes('HEADER')) {
                this.defaultAdminUrl = '/admin/headers';
                localStorage.setItem('defaultAdminUrl', this.defaultAdminUrl);
            }
            else if (this.permissionModules.includes('SETTING')) {
                this.defaultAdminUrl = '/admin/whitelabel';
                localStorage.setItem('defaultAdminUrl', this.defaultAdminUrl);
            }
            else if (this.permissionModules.includes('DEVELOPER')) {
                this.defaultAdminUrl = '/admin/developer';
                localStorage.setItem('defaultAdminUrl', this.defaultAdminUrl);
            }
            else if (this.permissionModules.includes('DESTINATION')) {
                this.defaultAdminUrl = '/admin/destination';
                localStorage.setItem('defaultAdminUrl', this.defaultAdminUrl);
            }
            else if (this.permissionModules.includes('RULE')) {
                this.defaultAdminUrl = '/admin/rules';
                localStorage.setItem('defaultAdminUrl', this.defaultAdminUrl);
            }
            else if (this.permissionModules.includes('AUDIT-TRIAL')) {
                this.defaultAdminUrl = '/admin/audit-trial';
                localStorage.setItem('defaultAdminUrl', this.defaultAdminUrl);
            }
            else if (this.permissionModules.includes('CACHE-ATTRIBUTES')) {
                this.defaultAdminUrl = '/admin/cache-attributes';
                localStorage.setItem('defaultAdminUrl', this.defaultAdminUrl);
            }
        }
    };
    HeaderComponent.prototype.getDefaultSupportAdminUrl = function () {
        this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
        if (this.permissionModules.includes('SUPPORT-ADMIN')) {
            if (this.permissionModules.includes('USER')) {
                this.defaultSupportAdminUrl = '/support-admin/users';
                localStorage.setItem('defaultSupportAdminUrl', this.defaultSupportAdminUrl);
            }
            else if (this.permissionModules.includes('ROLE')) {
                this.defaultSupportAdminUrl = '/support-admin/roles';
                localStorage.setItem('defaultSupportAdminUrl', this.defaultSupportAdminUrl);
            }
        }
    };
    HeaderComponent.prototype.getUserNotification = function () {
        var _this = this;
        this.authenticationService.getUserNotification().subscribe(function (res) {
            _this.notifications = res;
            if (res) {
                _this.alertMessage = true;
            }
        }, function (error) {
            _this.alertMessage = false;
            _this.sharedService.getErrorMsg(error);
        });
    };
    HeaderComponent.prototype.getUnreadNotificationCount = function () {
        var _this = this;
        this.authenticationService.getUnreadNotificationCount().subscribe(function (res) {
            _this.unreadCount = res.count;
            localStorage.setItem('unreadNotification', JSON.stringify(res));
        });
    };
    HeaderComponent.prototype.readNotifications = function () {
        this.unreadCount = 0;
    };
    HeaderComponent.prototype.closeUserNotification = function (userNotificationId) {
        var _this = this;
        this.authenticationService.closeUserNotification(userNotificationId).subscribe(function (res) {
            _this.alertMessage = false;
        }, function (error) {
            _this.alertMessage = false;
            _this.sharedService.getErrorMsg(error);
        });
    };
    return HeaderComponent;
}());
export { HeaderComponent };
