import * as tslib_1 from "tslib";
import { OnInit, NgZone, ElementRef, ChangeDetectorRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '@myproject/shared/shared.service';
import { environment } from '@myenv/environment';
import { LiveService } from '../live.service';
import { configMessage } from '@shared/config-message';
import * as FileSaver from 'file-saver';
import { DataService } from '@components/data/data.service';
import { GraphicReportsService } from '@myproject/components/graphic-reports/graphic-reports.service';
import { DatePipe } from '@angular/common';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import * as moment from 'moment';
import { ObdPidViewComponent } from '@myproject/shared/obd-pid-view/obd-pid-view.component';
var TripsComponent = /** @class */ (function () {
    function TripsComponent(title, sharedService, route, router, liveService, dataService, ngzone, reportsService, cdr, eleRef) {
        this.title = title;
        this.sharedService = sharedService;
        this.route = route;
        this.router = router;
        this.liveService = liveService;
        this.dataService = dataService;
        this.ngzone = ngzone;
        this.reportsService = reportsService;
        this.cdr = cdr;
        this.eleRef = eleRef;
        this.moduleName = 'TRIPS';
        this.subTitle = 'Trips';
        // for distance travelled
        this.distanceMetrics = environment.distanceMetrics;
        this.distanceTravelled = 0;
        // Markers
        this.harsh_braking_color = '../../../../assets/img/trips/harsh-braking-color.png';
        this.harsh_braking_gray = '../../../../assets/img/trips/harsh-braking-gray.png';
        this.high_speed_color = '../../../../assets/img/trips/high_speed_color.png';
        this.high_speed_gray = '../../../../assets/img/trips/high_speed_gray.png';
        this.rapid_acceleration_color = '../../../../assets/img/trips/rapid_acceleration_color.png';
        this.rapid_acceleration_gray = '../../../../assets/img/trips/rapid_acceleration_gray.png';
        // for permissions
        this.permissionModules = [];
        this.tripAuthorities = [];
        this.infoWindow = [];
        this.markers = [];
        this.pageLoading = true;
        this.isLoading = false;
        this.dateFormat = environment.isoDateTimeFormat;
        this.msgDateFormat = environment.smallDateFormat;
        this.selecteddeviceId = null;
        this.selectedDevice = {};
        this.querySelectVehicle = null;
        this.vehicles = [];
        this.trips = [];
        this.selected = [];
        this.showEvents = false;
        this.disableNav = true;
        this.sort = 'desc';
        this.page = 0;
        this.selectedTrip = {};
        this.harshBraking = [];
        this.overSpeeding = [];
        this.harshAccelaration = [];
        this.hideCalendar = false;
        this.querySelectDate = false;
        this.selectedvehicleinfo = {};
        // devicestartTime: any;
        // deviceendTime: any;
        // for reports dropdown
        this.reportsMenu = [];
        this.reports = [];
        this.customLoader = false;
        this.staticReports = configMessage.staticReports;
        this.AL_SystemReport = environment.AL_SystemReport;
        // for places filter
        this.filterplace = false;
        this.obd = {
            ts: null,
            te: null
        };
        this.obdLocation = 'tripMode';
        this.latencyData = {
            gsmStrength: '',
            eventTime: '',
            ingestionTimestamp: '',
            messageType: '',
            latitude: '',
            longitude: ''
        };
        this.selectedTrips = true;
        // selectedNetwork: boolean = false
        // selectedStartDate: any;
        // selectedEnddate: any;
        this.noDevices = true;
        this.updateChrt = 0;
        this.pageInfo = {
            offset: 0,
            count: 0,
            limit: 6,
            pageSize: 0,
            lastPage: 0,
        };
        // currentView = 0;
        this.graphContainerParams = null;
        this.actionDateChange = 0;
        // getScreenWidth: any;
        // getScreenHeight: any;
        // variable for map message view section start
        this.messageTypeArray = [];
        this.pageVehicleHistory = 0;
        this.dottedEvents = {
            // checked: false,
            tripChecked: false,
            messageChecked: false
        };
        this.initialDataLoad = true;
        this.timeSeeker = {
            selected: false,
            ts: null,
            te: null,
            selectedVal: [],
            len: 0
        };
        this.selectedMessages = [];
        this.messageMarkers = {};
        this.isDrawerOpen = false;
        this.viewPage = false;
        this.show = true;
        this.openDatePopup = false;
        this.livehistorySearchheader = [];
        this.rows = [];
        this.historyRows = [];
        this.temp = [];
        this.enablePageVehicleHistory = true;
        this.liveHistoryAttribute = configMessage.liveHistoryAttribute;
        this.csvLiveHistPrefKeyArray = [];
        this.csvLiveHistPrefHeaderArray = [];
        this.endPacket = false;
        this.toggleEffect = false;
        this.loadingIndicator = true;
        this.polyLineData = [];
        this.currentPageCount = {
            start: 0,
            end: 0
        };
        this.multipleCircles = [];
        this.resSizeenv = environment.resSize;
        this.resSize = this.resSizeenv;
        this.prevResponse = 0;
        this.markerscluster = [];
        this.historyRowsEmpty = false;
        this.usingEventTime = false;
        this.selectedFilter = '';
        this.liveSearch = '';
        this.liveKey = '';
        this.selectedVehicle = {};
        this.totalDistanceTravelled = 0;
        // message JSON popup
        this.messageJsonLoader = false;
        // message type side drawer
        this.messagesLoading = false;
        this.messages = [];
        this.places = [];
        this.line = [];
        this.searchModel = {};
        this.messageTypeColor = configMessage.messageTypeColor;
        this.tripViewToggle = true;
        this.networkViewToggle = true;
        this.accordion = {
            isHavingEnoughSpace: false,
            trip_view: true,
            isTripManualCollapse: false,
            msg_view: false,
            isMsgManualCollapse: false,
            pid_view: false,
            isPidManualCollapse: false,
        };
    }
    TripsComponent.prototype.ngOnInit = function () {
        window["trip"] = this;
        this.title.setTitle('Trips' + environment.title_text);
        this.permissionModules = localStorage.getItem('permissionModules');
        var modules = JSON.parse(localStorage.getItem('accessRoleModules'));
        var tripRoleModule = modules.find(function (module) { return module.name === 'TRIPS'; });
        this.tripAuthorities = tripRoleModule && tripRoleModule.authorities ? tripRoleModule.authorities : [];
        // this.liveSelectedVehicle = JSON.parse(localStorage.getItem('liveSelectedVehicle'));
        var queryParams = this.route.snapshot.queryParams;
        if (queryParams.distance)
            this.distanceTravelled = queryParams.distance;
        this.mapInit();
        var localData = JSON.parse(localStorage.getItem("preferences"));
        // try {
        //   this.dottedEvents.tripChecked = localData["liveHistory"]["toggle"]["networkLatency"];
        //   this.dottedEvents.messageChecked = localData["liveHistory"]["toggle"]["networkLatency"];
        // } catch (e) {
        //   console.log(e);
        // }
        // this.getScreenWidth = window.innerWidth;
        // this.getScreenHeight = window.innerHeight;
        // this.setOBDHeight();
        // localData["liveHistory"]["toggle"]["tripsView"] = trips;
    };
    TripsComponent.prototype.onWindowResize = function () {
        var firstActiveSection = $('.left-sec')[0].style.display === 'block' ? $($('.left-sec')[0]) : $($('.left-sec')[1]);
        var activeViewHeight = firstActiveSection.height();
        var leftSection = $('#leftsctn').height();
        if (!this.accordion.isPidManualCollapse && (activeViewHeight > 100 || this.accordion.msg_view || this.accordion.trip_view)) {
            var checkCanViewDataView = (leftSection - activeViewHeight);
            this.accordion.isHavingEnoughSpace = checkCanViewDataView >= configMessage.minHeightReqDataView;
            this.accordion.pid_view = this.accordion.isHavingEnoughSpace;
        }
        var params = (this.accordion.isHavingEnoughSpace ? (this.tripViewToggle ? (this.accordion.trip_view ? ['trip_expand', this.accordion.pid_view ? 'data_expand' : 'data_collapse'] : ['trip_collapse', this.accordion.pid_view ? 'data_expand' : 'data_collapse']) : (this.accordion.msg_view ? ['msg_expand', this.accordion.pid_view ? 'data_expand' : 'data_collapse'] : ['msg_collapse', this.accordion.pid_view ? 'data_expand' : 'data_collapse'])) : (this.accordion.trip_view ? 1 : this.accordion.msg_view ? 2 : 3));
        this.sharedService.setOBDHeight(window.innerWidth, window.innerHeight, this.accordion.isHavingEnoughSpace, params, this.graphContainerParams.tabPos ? this.graphContainerParams.tabPos : 0);
    };
    TripsComponent.prototype.controlAccordionActions = function (p1) {
        if (this.accordion.isHavingEnoughSpace) {
            if (p1 === 'trip') {
                this.accordion.trip_view = !this.accordion.trip_view;
                this.accordion.isTripManualCollapse = !this.accordion.trip_view;
            }
            else if (p1 === 'msg') {
                this.accordion.msg_view = !this.accordion.msg_view;
                this.accordion.isMsgManualCollapse = !this.accordion.msg_view;
            }
            else {
                this.accordion.pid_view = !this.accordion.pid_view;
                this.accordion.isPidManualCollapse = !this.accordion.pid_view;
                this.sharedService.set_OBD_PID_Height(this.graphContainerParams.tabPos);
            }
        }
        else {
            // when no space available either any one of the tabs shoule be shown to the user
            if (p1 === 'trip') {
                this.accordion.msg_view = false;
                if (this.accordion.trip_view) {
                    this.accordion.trip_view = false;
                    this.accordion.pid_view = true;
                }
                else {
                    this.accordion.trip_view = true;
                    this.accordion.pid_view = false;
                }
            }
            else if (p1 === 'msg') {
                this.accordion.trip_view = false;
                if (this.accordion.msg_view) {
                    this.accordion.msg_view = false;
                    this.accordion.pid_view = true;
                }
                else {
                    this.accordion.msg_view = true;
                    this.accordion.pid_view = false;
                }
            }
            else if (p1 === 'pidView') {
                if (this.accordion.pid_view) {
                    // when pid view is already active and on second click
                    if (this.tripViewToggle) {
                        this.accordion.trip_view = true;
                    }
                    else {
                        this.accordion.msg_view = true;
                    }
                    this.accordion.pid_view = false;
                }
                else {
                    // when pid view is not active active and on click
                    this.accordion.trip_view = false;
                    this.accordion.msg_view = false;
                    this.accordion.pid_view = true;
                }
            }
            this.sharedService.set_OBD_PID_Height(this.graphContainerParams.tabPos);
        }
    };
    TripsComponent.prototype.onTimeChange = function (e) {
        this.resetTimeSeeker();
        this.actionDateChange += 1;
        var queryParams = this.route.snapshot.queryParams;
        if (!e && queryParams.startDate && queryParams.endDate) {
            this.pickerStart = queryParams.startDate;
            this.pickerEnd = queryParams.endDate;
            this.querySelectDate = true;
            // delete params
            var params = tslib_1.__assign({}, queryParams);
            delete params.startDate;
            delete params.endDate;
            this.router.navigate([], { queryParams: params });
            // this.location.replaceState('trips');
        }
        else if (this.tripViewToggle) {
            this.pickerStart = e.startTime;
            this.pickerEnd = e.endTime;
            this.querySelectDate = false;
        }
        if (this.selecteddeviceId && this.tripViewToggle) {
            this.getTrips(this.sort);
        }
        else if (this.selecteddeviceId && !this.tripViewToggle) {
            // this.obd.ts = this.pickerStart;
            // this.obd.te = this.pickerEnd;
            this.initMapMessageView();
        }
    };
    TripsComponent.prototype.changeVehicle = function (e) {
        this.resetTimeSeeker();
        this.resetAccordionParams();
        this.pickerStart = null;
        this.pickerEnd = null;
        this.obd.ts = null;
        this.obd.te = null;
        this.mapInit();
        var queryParams = this.route.snapshot.queryParams;
        if (queryParams.id && queryParams.startDate && queryParams.endDate) {
            this.selecteddeviceId = this.route.snapshot.queryParams.id;
            this.pickerStart = parseInt(queryParams.startDate, 10);
            this.pickerEnd = parseInt(queryParams.endDate, 10);
            this.obd.ts = this.pickerStart;
            this.obd.te = this.pickerEnd;
            this.getQueryVehicle(this.route.snapshot.queryParams.name);
            // delete params
            // const params = { ...queryParams };
            // delete params.id;
            // delete params.name;
            // this.router.navigate([], { queryParams: params });
            // this.location.replaceState('trips');
        }
        else {
            this.selecteddeviceId = e ? e.deviceId : null;
            this.selectedDevice = e;
            this.querySelectVehicle = null;
            var params = tslib_1.__assign({}, queryParams);
            delete params.id;
            delete params.name;
            delete params.endDate;
            delete params.startDate;
            this.router.navigate([], { queryParams: params });
            this.getDeviceDetail();
        }
        if (e === null || e === undefined) {
            this.graphContainerParams = {
                noDevices: true
            };
        }
    };
    TripsComponent.prototype.getQueryVehicle = function (name) {
        var _this_1 = this;
        this.sharedService.onSearchVehicle('assetName', name).subscribe(function (res) {
            if (res.length) {
                _this_1.selectedDevice = res[0];
                _this_1.selecteddeviceId = res[0].deviceId;
                _this_1.querySelectVehicle = res[0];
                _this_1.initiateData();
                _this_1.sharedService.getVehicleDetail(_this_1.selecteddeviceId).subscribe(function (res) {
                    _this_1.selectedvehicleinfo = res;
                    if (res && res.hasOwnProperty('groupName')) {
                        _this_1.selectedDevice['groupName'] = res.groupName;
                    }
                    //this.mapInit(); // changeVehicle //commented to fix NEX-3856
                }, function (error) {
                    _this_1.sharedService.getErrorMsg(error);
                });
            }
            else {
                _this_1.selectedDevice = {};
                _this_1.querySelectVehicle = null;
            }
        }, function (error) {
            _this_1.sharedService.getErrorMsg(error);
        });
    };
    TripsComponent.prototype.getDeviceDetail = function () {
        var _this_1 = this;
        this.sharedService.getRecentdeviceData(this.selecteddeviceId).subscribe(function (res) {
            if (res.teTime || res.tsTime) {
                if (res.teTime) {
                    _this_1.pickerStart = res.teTime - (60 * 24 * 60 * 1000);
                    _this_1.pickerEnd = res.teTime;
                }
                else if (!res.teTime && res.tsTime) {
                    _this_1.pickerStart = res.tsTime - (60 * 24 * 60 * 1000);
                    _this_1.pickerEnd = res.tsTime;
                }
                else {
                    var start = null;
                    var end = null;
                    var t = new Date();
                    start = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 0, 0, 0).toUTCString());
                    end = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59).toUTCString());
                    _this_1.pickerStart = start;
                    _this_1.pickerEnd = end;
                }
                if (_this_1.pickerStart && _this_1.pickerEnd) {
                    _this_1.deviceStartTime = _this_1.pickerStart;
                    _this_1.deviceEndTime = _this_1.pickerEnd;
                    _this_1.initiateData();
                    _this_1.sharedService.getVehicleDetail(_this_1.selecteddeviceId).subscribe(function (res) {
                        _this_1.selectedvehicleinfo = res;
                        if (res && res.hasOwnProperty('groupName')) {
                            _this_1.selectedDevice['groupName'] = res.groupName;
                        }
                        //this.mapInit(); // changeVehicle //commented to fix NEX-3856
                    }, function (error) {
                        _this_1.sharedService.getErrorMsg(error);
                    });
                }
            }
            else {
                _this_1.sharedService.getRecentdeviceDataAlternateAPI(_this_1.selecteddeviceId).subscribe(function (res) {
                    if (res.eventTimeEpoch) {
                        _this_1.pickerStart = res.eventTimeEpoch - (60 * 24 * 60 * 1000);
                        _this_1.pickerEnd = res.eventTimeEpoch;
                    }
                    else {
                        var start = null;
                        var end = null;
                        var t = new Date();
                        start = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 0, 0, 0).toUTCString());
                        end = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59).toUTCString());
                        _this_1.pickerStart = start;
                        _this_1.pickerEnd = end;
                    }
                    if (_this_1.pickerStart && _this_1.pickerEnd) {
                        _this_1.deviceStartTime = _this_1.pickerStart;
                        _this_1.deviceEndTime = _this_1.pickerEnd;
                        _this_1.initiateData();
                        _this_1.sharedService.getVehicleDetail(_this_1.selecteddeviceId).subscribe(function (res) {
                            _this_1.selectedvehicleinfo = res;
                            if (res && res.hasOwnProperty('groupName')) {
                                _this_1.selectedDevice['groupName'] = res.groupName;
                            }
                            //this.mapInit(); // changeVehicle //commented to fix NEX-3856
                        }, function (error) {
                            _this_1.sharedService.getErrorMsg(error);
                        });
                    }
                });
            }
        });
    };
    TripsComponent.prototype.initiateData = function () {
        if (this.tripViewToggle) {
            this.getTrips(this.sort); // changeVehicle
        }
        else {
            this.getAllData();
        }
    };
    TripsComponent.prototype.getTrips = function (sort) {
        var _this_1 = this;
        if (this.selecteddeviceId !== null) {
            // this.obd.ts = null;
            // this.obd.te = null;
            // this.checkDateValidity();
            this.liveService.getTrips(this.selecteddeviceId, this.pickerEnd, this.pickerStart, sort).subscribe(function (res) {
                if (res.length == 0) {
                    _this_1.selectedTrip = {};
                    _this_1.trips = [];
                    _this_1.showEvents = false;
                    _this_1.map.setZoom(4);
                    _this_1.clearMarkers();
                    if (_this_1.flightPath != undefined) {
                        _this_1.removePolyline();
                    }
                    _this_1.obd.ts = _this_1.pickerStart;
                    _this_1.obd.te = _this_1.pickerEnd;
                }
                else {
                    _this_1.trips = res;
                    _this_1.trips.map(function (item, index) {
                        item['index'] = index;
                        if (item.hasOwnProperty('tsTime') && item.hasOwnProperty('teTime')) {
                            item['tripDuration'] = _this_1.getDuration(item.tsTime, item.teTime);
                        }
                        else {
                            item['tripDuration'] = '';
                        }
                    });
                    _this_1.selected = [_this_1.trips[0]];
                    if (_this_1.selected[0].hasOwnProperty('tsTime')) {
                        _this_1.obd.ts = _this_1.selected[0].tsTime;
                        // this.pickerEnd = this.selected[0].teTime;
                        // this.pickerStart = this.pickerEnd - (1000 * 60 * 60 * 24);
                    }
                    else if (_this_1.selected[0].hasOwnProperty('teTime')) {
                        _this_1.obd.te = _this_1.selected[0].teTime;
                        //this.pickerEnd = this.selected[0].teTime;
                    }
                    _this_1.clearMarkers();
                    _this_1.selectTrip({ selected: _this_1.selected });
                    _this_1.isLoading = false;
                }
                _this_1.pageLoading = false;
            }, function (error) {
                _this_1.pageLoading = false;
                _this_1.sharedService.getErrorMsg(error);
            });
        }
        else {
            this.pageLoading = false;
        }
    };
    TripsComponent.prototype.getDuration = function (ts, te) {
        var durationOfTrip = '';
        var _a = tslib_1.__read(this.sharedService.getDurationBtwTwoEpoch(ts, te), 4), days = _a[0], hours = _a[1], minutes = _a[2], seconds = _a[3];
        if (ts && te) {
            if (hours < 10) {
                durationOfTrip += '0' + (hours).toString() + ':';
            }
            else {
                durationOfTrip += (hours).toString() + ':';
            }
            if (minutes < 10) {
                durationOfTrip += '0' + (minutes).toString() + ':';
            }
            else {
                durationOfTrip += (minutes).toString() + ':';
            }
            if (seconds < 10) {
                durationOfTrip += '0' + (seconds).toString();
            }
            else {
                durationOfTrip += (seconds).toString();
            }
            return durationOfTrip;
        }
        return '';
    };
    TripsComponent.prototype.selectTrip = function (e) {
        this.selectedTrip = e.selected[0];
        var trip = e.selected[0];
        if (trip && Object.keys(trip).length) {
            var teTime = 0;
            if (trip.hardAcclCounts || trip.hardBreakingCounts || trip.highSpeedEventCounts) {
                this.showEvents = true;
            }
            else {
                this.showEvents = false;
            }
            if (!trip.teTime) {
                if (this.sort === 'asc') {
                    if (trip.index === (this.trips.length - 1)) {
                        // teTime = Date.parse(new Date().toUTCString());
                        teTime = this.pickerEnd;
                    }
                    else {
                        teTime = this.trips[trip.index + 1].tsTime;
                    }
                }
                else if (this.sort === 'desc') {
                    if (trip.index === 0) {
                        teTime = this.pickerEnd;
                    }
                    else {
                        teTime = this.trips[trip.index - 1].tsTime;
                    }
                }
                else {
                    teTime = this.pickerEnd;
                }
                this.obd.te = teTime;
            }
            else {
                teTime = trip.teTime;
                this.obd.te = teTime;
            }
            this.obd.ts = trip.tsTime;
            this.tripStart = trip.tsTime;
            this.tripEnd = teTime;
            if (this.dottedEvents.tripChecked) {
                this.switchToDots(this.selecteddeviceId, trip.tsTime, teTime);
            }
            else {
                this.getPolylineData(trip.tsTime, teTime);
            }
        }
    };
    TripsComponent.prototype.sortTrips = function (e) {
        if (this.trips.length) {
            this.sort = e.newValue;
            this.getTrips(this.sort);
        }
    };
    // to get all Reports
    TripsComponent.prototype.getallReports = function () {
        var _this_1 = this;
        if (localStorage.getItem('reports')) {
            this.reportsMenu = [];
            var reports = JSON.parse(localStorage.getItem('reports'));
            reports.map(function (obj) {
                if (!obj.hidden) {
                    _this_1.reportsMenu.push(obj);
                }
                // this.vehicleDetail = {};
            });
            this.reports = this.reportsMenu;
        }
        else {
            this.customLoader = true;
            this.sharedService.getReports().subscribe(function (res) {
                res.map(function (obj) {
                    if (!obj.hidden) {
                        _this_1.reportsMenu.push(obj);
                    }
                    // this.vehicleDetail = {};
                });
                _this_1.staticReports = _this_1.staticReports.filter(function (item) {
                    if (item.reportType === 'system' && item.url.includes('trigger-report') && !_this_1.AL_SystemReport)
                        return false;
                    if (item.reportType === 'device' && item.url.includes('trip-summary') && !_this_1.tripAuthorities.includes('READ'))
                        return false;
                    if (item.reportType === 'billing' && !_this_1.permissionModules.includes('BILLING'))
                        return false;
                    return true;
                });
                _this_1.reportsMenu = tslib_1.__spread(_this_1.reportsMenu, _this_1.staticReports);
                localStorage.setItem('reports', JSON.stringify(_this_1.reportsMenu));
                _this_1.customLoader = false;
                _this_1.reports = _this_1.reportsMenu;
            }, function (error) {
                _this_1.customLoader = false;
                _this_1.sharedService.getErrorMsg(error);
            });
        }
    };
    TripsComponent.prototype.filterReport = function (event) {
        var inputVal = event.target.value.toLowerCase();
        var val = inputVal.trim();
        var temp = this.reportsMenu.filter(function (d) {
            return d.name.toLowerCase().indexOf(val) !== -1 || !val;
        });
        this.reports = temp;
    };
    TripsComponent.prototype.redirectToReports = function (report) {
        var vehicle = this.selectedDevice;
        try {
            localStorage.setItem('currentReport', JSON.stringify(report));
            localStorage.setItem('reportsVehicle', JSON.stringify(vehicle));
            if (report.type === 'VISUALIZATION') {
                this.router.navigate(['/reports/device/visualization']);
            }
            else if (report.downloadable && report.type !== 'AL_PLANT_MODE') {
                this.router.navigate(['/reports/device/custom']);
            }
            else if (report.type === 'AL_PLANT_MODE') {
                this.router.navigate(['/reports/device/alPlantMode']);
            }
            else if (['vehicle', 'device', 'system', 'billing'].includes(report.reportType) || report.name === 'Trip Summary') {
                this.router.navigate([report.url]);
            }
            else {
                this.router.navigate(['/reports/device/messages']);
            }
        }
        catch (e) {
            console.log(e.message);
        }
    };
    // To download trips data as a csv file
    TripsComponent.prototype.downloadTripCSV = function () {
        var _this_1 = this;
        this.liveService.downloadTripSummary(this.selecteddeviceId, this.pickerStart, this.pickerEnd).subscribe(function (res) {
            var contentDisposition = res.headers.get('Content-Disposition');
            var filename;
            if (contentDisposition == null) {
                var date = new Date();
                filename = 'Trip_Summary_' + date + '.csv';
            }
            else {
                filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            }
            //Converting ArrayBuffer to String
            var arrbuf = new Uint8Array(res.body);
            //Converting the charcodes to string and replacing the string to Distance Travelled (KMS) 
            var text = (String.fromCharCode.apply(String, tslib_1.__spread(arrbuf))).replace("Distance Travelled,", "Distance Travelled (Kms),");
            // Converting the string to ArrayBuffer
            var uint8array = new TextEncoder().encode(text);
            var blob = new Blob([uint8array], {
                type: 'text/csv;charset=utf-8'
            });
            FileSaver.saveAs(blob, filename);
        }, function (error) {
            _this_1.sharedService.getErrorMsg(error);
        });
    };
    // togglePlacesFilter() {
    //   if (this.filterplace) {
    //     // this.page = 0;
    //     // this.pageInfo.offset = 0;
    //     this.clearFilter();
    //   } else {
    //     this.filterPlaces(this.startTime, this.endTime);
    //   }
    // }
    // navigateDates(dir) {
    //   if (dir === 'left') {
    //     this.startDt = new Date(this.startDt.setDate(this.startDt.getDate() - 1));
    //     this.disableNav = false;
    //   } else if (dir === 'right') {
    //     this.startDt = new Date(this.startDt.setDate(this.startDt.getDate() + 1));
    //   }
    //   this.getTrips('asc');
    // }
    // checkDateValidity() {
    //   if (
    //     this.startDt.getDate() === this.maxDate.getDate() &&
    //     this.startDt.getMonth() === this.maxDate.getMonth() &&
    //     this.startDt.getFullYear() === this.maxDate.getFullYear()
    //   ) {
    //     this.disableNav = true;
    //   } else {
    //     this.disableNav = false;
    //   }
    // }
    // Initialize map
    TripsComponent.prototype.mapInit = function () {
        var mapOptionObject = {
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoom: 4,
            minZoom: 3,
            maxZoom: 21,
            mapTypeControl: true,
            mapTypeControlOptions: {
                // style : google.maps.MapTypeControlStyle.TOP_RIGHT,
                position: google.maps.ControlPosition.TOP_RIGHT
            },
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_CENTER
            },
            scaleControl: true,
            streetViewControl: true,
            streetViewControlOptions: {
                position: google.maps.ControlPosition.RIGHT_CENTER
            },
            fullscreenControl: false,
            center: {
                lat: this.selectedvehicleinfo.latitude ? this.selectedvehicleinfo.latitude : environment.lat,
                lng: this.selectedvehicleinfo.longitude ? this.selectedvehicleinfo.longitude : environment.lng
            },
            clickableIcons: false
        };
        this.map = new google.maps.Map(this.gmapElement.nativeElement, mapOptionObject);
    };
    // Receive Polyline data from API
    TripsComponent.prototype.getPolylineData = function (startTime, endTime) {
        var _this_1 = this;
        this.liveService.getTripsPolylineData(this.selecteddeviceId, startTime, endTime).subscribe(function (res) {
            // this.length = res.length;
            _this_1.tripsData = res;
            if (res.length > 0) {
                _this_1.clearMarkers();
                if (_this_1.flightPath != undefined) {
                    _this_1.removePolyline();
                }
                _this_1.addPolyline(res);
                var imgStart = '../../assets/img/map-marker-start.png';
                var imgEnd = '../../assets/img/map-marker-end-red.png';
                for (var i = 0; i < res.length; i++) {
                    if (res[i].messageType == "TRIP_END") {
                        _this_1.addMarker(res[i].latitude, res[i].longitude, imgEnd);
                    }
                    if (res[i].messageType == "TRIP_START") {
                        _this_1.addMarker(res[i].latitude, res[i].longitude, imgStart);
                    }
                }
                if (_this_1.showEvents) {
                    _this_1.plotEvents(res);
                }
            }
            else {
                _this_1.map.setZoom(4);
            }
        }, function (error) {
            _this_1.sharedService.getErrorMsg(error);
            _this_1.map.setZoom(4);
            _this_1.clearMarkers();
            _this_1.removePolyline();
        });
    };
    TripsComponent.prototype.addPolyline = function (data) {
        var flightPlanCoordinates = [];
        var bounds = new google.maps.LatLngBounds();
        data.forEach(function (item) {
            var latLngObj = {};
            if (item.latitude && item.longitude) {
                latLngObj['lat'] = item.latitude;
                latLngObj['lng'] = item.longitude;
                flightPlanCoordinates.push(latLngObj);
                // to extends the bounds
                bounds.extend(new google.maps.LatLng(item.latitude, item.longitude));
            }
        });
        this.flightPath = new google.maps.Polyline({
            path: flightPlanCoordinates,
            geodesic: true,
            strokeColor: '#891d56',
            strokeOpacity: 0.8,
            strokeWeight: 3
        });
        this.flightPath.setMap(this.map);
        this.map.fitBounds(bounds);
    };
    TripsComponent.prototype.removePolyline = function () {
        if (this.flightPath != null) {
            this.flightPath.setMap(null);
        }
    };
    TripsComponent.prototype.addMarker = function (lat, long, img, obj) {
        var latlng = new google.maps.LatLng(lat, long);
        var marker = new google.maps.Marker({
            position: latlng,
            map: this.map,
            // label: eventDate,
            icon: img
        });
        this.setAnimation(marker);
        if (obj) {
            this.setInfoWindow(obj, marker);
        }
        this.markers.push(marker);
    };
    // Set Animation
    TripsComponent.prototype.setAnimation = function (marker) {
        marker.setAnimation(google.maps.Animation.BOUNCE);
        setTimeout(function () { marker.setAnimation(null); }, 750);
    };
    TripsComponent.prototype.clearMarkers = function () {
        for (var i = 0; i < this.markers.length; i++) {
            this.markers[i].setMap(null);
        }
        this.markers = [];
    };
    TripsComponent.prototype.plotEvents = function (data) {
        var _this_1 = this;
        this.harshAccelaration = [];
        this.harshBraking = [];
        this.overSpeeding = [];
        data.map(function (obj) {
            if (obj.messageType === 'HARD_BRAKING') {
                _this_1.addMarker(obj.latitude, obj.longitude, _this_1.harsh_braking_color, obj);
            }
            else if (obj.messageType === 'OVER_SPEEDING') {
                _this_1.addMarker(obj.latitude, obj.longitude, _this_1.high_speed_color, obj);
            }
            else if (obj.messageType === 'HARD_ACCELERATION') {
                _this_1.addMarker(obj.latitude, obj.longitude, _this_1.rapid_acceleration_color, obj);
            }
        });
    };
    // Set info Window for each marker
    TripsComponent.prototype.setInfoWindow = function (obj, marker) {
        var global = this;
        if (obj.messageType === 'HARD_BRAKING') {
            this.harshBraking.push(marker);
            var brakeContent;
            if (this.accordion.trip_view) {
                brakeContent = "\n        <div id=\"brakeInfoWindow\">\n          <h5 style=\"border-bottom: 2px solid #c6425b\">Hard Braking</h5>\n          <p><span class=\"font-weight-bold\">Initial Speed: </span><span>" + this.sharedService.convertKPHtoMPH(obj.initialSpeed) + " mph</span></p>\n          <p><span class=\"font-weight-bold\">Final Speed: </span><span>" + this.sharedService.convertKPHtoMPH(obj.finalSpeed) + " mph</span></p>\n          <p><span class=\"font-weight-bold\">Max Braking: </span><span>" + this.sharedService.convertKPHtoMPH(obj.maxBraking) + " mph</span></p>\n       </div>";
            }
            else {
                brakeContent = "\n        <div id=\"brakeInfoWindow\">\n          <h5 style=\"border-bottom: 2px solid #c6425b\">Hard Braking</h5>\n          <p><span class=\"font-weight-bold\">Initial Speed: </span><span>" + this.sharedService.convertKPHtoMPH(obj.initialSpeed) + " mph</span></p>\n          <p><span class=\"font-weight-bold\">Final Speed: </span><span>" + this.sharedService.convertKPHtoMPH(obj.finalSpeed) + " mph</span></p>\n          <p><span class=\"font-weight-bold\">Max Braking: </span><span>" + this.sharedService.convertKPHtoMPH(obj.maxBraking) + " mph</span></p>\n          <p><span class=\"font-weight-bold\">Event Time: </span><span>" + (obj.eventTime ? obj.eventTime : '--') + "</span></p>\n          <p><span class=\"font-weight-bold\">Ingestion Time: </span><span>" + (obj.ingestionTimestamp ? obj.ingestionTimestamp : '--') + "</span></p>\n        </div>";
            }
            var infoWindow = new google.maps.InfoWindow({
                content: brakeContent
            });
            global.infoWindow.push(infoWindow);
        }
        else if (obj.messageType === 'OVER_SPEEDING') {
            this.overSpeeding.push(marker);
            var speedContent;
            if (this.accordion.trip_view) {
                speedContent = "\n        <div id=\"brakeInfoWindow\">\n          <h5 style=\"border-bottom: 2px solid #5ec5e2\">Speeding</h5>\n          <p><span class=\"font-weight-bold\">Max Speed: </span><span>" + this.sharedService.convertKPHtoMPH(obj.maxSpeed) + " mph</span></p>\n          <p><span class=\"font-weight-bold\">Average Speed: </span><span>" + this.sharedService.convertKPHtoMPH(obj.avgSpeed) + " mph</span></p>\n       </div>";
            }
            else {
                speedContent = "\n        <div id=\"brakeInfoWindow\">\n          <h5 style=\"border-bottom: 2px solid #5ec5e2\">Speeding</h5>\n          <p><span class=\"font-weight-bold\">Max Speed: </span><span>" + this.sharedService.convertKPHtoMPH(obj.maxSpeed) + " mph</span></p>\n          <p><span class=\"font-weight-bold\">Average Speed: </span><span>" + this.sharedService.convertKPHtoMPH(obj.avgSpeed) + " mph</span></p>\n          <p><span class=\"font-weight-bold\">Event Time: </span><span>" + (obj.eventTime ? obj.eventTime : '--') + "</span></p>\n          <p><span class=\"font-weight-bold\">Ingestion Time: </span><span>" + (obj.ingestionTimestamp ? obj.ingestionTimestamp : '--') + "</span></p>\n        </div>";
            }
            var infoWindow = new google.maps.InfoWindow({
                content: speedContent
            });
            global.infoWindow.push(infoWindow);
        }
        else if (obj.messageType === 'HARD_ACCELERATION') {
            this.harshAccelaration.push(marker);
            var acclContent;
            if (this.accordion.trip_view) {
                acclContent = "\n        <div id=\"brakeInfoWindow\">\n          <h5 style=\"border-bottom: 2px solid #f6ab1f\">Hard Acceleration</h5>\n          <p><span class=\"font-weight-bold\">Initial Speed: </span><span>" + this.sharedService.convertKPHtoMPH(obj.initialSpeed) + " mph</span></p>\n          <p><span class=\"font-weight-bold\">Final Speed: </span><span>" + this.sharedService.convertKPHtoMPH(obj.finalSpeed) + " mph</span></p>\n          <p><span class=\"font-weight-bold\">Max Acceleration: </span><span>" + this.sharedService.convertKPHtoMPH(obj.maxAcceleration) + " mph</span></p>\n        </div>";
            }
            else {
                acclContent = "\n        <div id=\"brakeInfoWindow\">\n          <h5 style=\"border-bottom: 2px solid #f6ab1f\">Hard Acceleration</h5>\n          <p><span class=\"font-weight-bold\">Initial Speed: </span><span>" + this.sharedService.convertKPHtoMPH(obj.initialSpeed) + " mph</span></p>\n          <p><span class=\"font-weight-bold\">Final Speed: </span><span>" + this.sharedService.convertKPHtoMPH(obj.finalSpeed) + " mph</span></p>\n          <p><span class=\"font-weight-bold\">Max Acceleration: </span><span>" + this.sharedService.convertKPHtoMPH(obj.maxAcceleration) + " mph</span></p>\n          <p><span class=\"font-weight-bold\">Event Time: </span><span>" + (obj.eventTime ? obj.eventTime : '--') + "</span></p>\n          <p><span class=\"font-weight-bold\">Ingestion Time: </span><span>" + (obj.ingestionTimestamp ? obj.ingestionTimestamp : '--') + "</span></p>\n        </div>";
            }
            var infoWindow = new google.maps.InfoWindow({
                content: acclContent
            });
            global.infoWindow.push(infoWindow);
        }
        var _this = this;
        google.maps.event.addListener(marker, 'mouseover', function () {
            if (global.infoWindow.length) {
                global.infoWindow.map(function (item) { item.close(); });
            }
            infoWindow.open(global.map, marker);
        });
        google.maps.event.addListener(marker, 'mouseout', function () {
            if (_this.dottedEvents.tripChecked) {
                infoWindow.close();
            }
        });
        google.maps.event.addListener(marker, 'mouseout', function () {
            if (!_this.dottedEvents.tripChecked) {
                infoWindow.close();
            }
        });
        google.maps.event.addListener(marker, 'click', function () {
            infoWindow.close();
        });
        google.maps.event.addListener(global.map, 'click', function () {
            infoWindow.close();
        });
    };
    TripsComponent.prototype.goBackToLocationHistory = function () {
        this.updateToggle(this.dottedEvents.tripChecked, false);
        localStorage.setItem('liveSelectedVehicle', JSON.stringify(this.selectedDevice));
        this.router.navigate(['location-history'], {
            queryParams: {
                searchBy: '',
                value: '',
            }
        });
    };
    TripsComponent.prototype.backToLive = function () {
        this.router.navigate(['/live']);
    };
    TripsComponent.prototype.showReportsModal = function () {
        this.getallReports();
        document.getElementById('goToReportsBtn').click();
    };
    TripsComponent.prototype.updateToggle = function (network, trips) {
        var localData = JSON.parse(localStorage.getItem("preferences"));
        localData["liveHistory"]["toggle"] = {};
        localData["liveHistory"]["toggle"]["networkLatency"] = network;
        localData["liveHistory"]["toggle"]["tripsView"] = trips;
        localStorage.setItem("preferences", JSON.stringify(localData));
        this.sharedService.updatePreference({ "liveHistory": { "toggle": localData["liveHistory"]["toggle"] } }).subscribe(function (data) {
            console.log("data", data);
        });
    };
    TripsComponent.prototype.networkToggle = function (p1) {
        // let localData = JSON.parse(localStorage.getItem("preferences"));
        // localData["liveHistory"]["toggle"]={};
        // localData["liveHistory"]["toggle"]["networkLatency"] = !this.selectedNetwork;
        // localData["liveHistory"]["toggle"]["tripsView"] = true;
        // this.sharedService.updatePreference({ "liveHistory": {"toggle":localData["liveHistory"]["toggle"]}}).subscribe(data=>{
        //   console.log("data", data);
        // })
        this.mapInit();
        this.updateToggle(!this.dottedEvents.tripChecked, true);
        if (p1) {
            this.dottedEvents.tripChecked = p1;
            this.switchToDots(this.selecteddeviceId, this.obd.ts, this.obd.te);
        }
        else {
            this.dottedEvents.tripChecked = p1;
            this.clearMarkers();
            this.removePolyline();
            // for (var i = 0; i < this.flightPath.length; i++) {
            //   this.flightPath[i].setMap(null);
            // }
            this.selectTrip({ selected: [this.selectedTrip] });
        }
    };
    TripsComponent.prototype.switchToDots = function (deviceId, startTime, endTime) {
        var _this_1 = this;
        // this.selecteddeviceId, this.startTime, this.endTime
        // if (!deviceId){
        //   deviceId = this.selecteddeviceId;
        //   startTime = this.startTime;
        //   endTime = this.endTime;
        // }
        // this.selectedStartDate = startTime;
        // this.selectedEnddate = endTime;
        this.liveService.getPolylineData(deviceId, startTime, endTime).subscribe(function (data) {
            _this_1.liveService.getTripsPolylineData(_this_1.selecteddeviceId, startTime, endTime).subscribe(function (res) {
                if (_this_1.markers) {
                    _this_1.clearMarkers();
                }
                _this_1.removePolyline();
                var locationData = data.locations;
                var graceArr = [];
                var uniqueChars = [];
                locationData.forEach(function (c) {
                    if (!uniqueChars.includes(c)) {
                        uniqueChars.push(c);
                    }
                });
                uniqueChars.forEach(function (ele, index) {
                    var tempObj = uniqueChars[index];
                    res.forEach(function (resEle) {
                        if (ele.eventTime === resEle.eventTime && ele.latitude === resEle.latitude && ele.longitude === resEle.longitude && resEle.messageType != "GPS_MESSAGE") {
                            Object.assign(tempObj, resEle);
                        }
                    });
                    graceArr.push(tempObj);
                });
                locationData = graceArr;
                _this_1.tripsData = locationData.filter(function (item) {
                    if (item.ingestionTimestamp) {
                        var eventTime = Date.parse(item.eventTime);
                        var ingestionTimestamp = Date.parse(item.ingestionTimestamp);
                        var timeDiff = ingestionTimestamp - eventTime;
                        var result = Math.floor((timeDiff / 1000) / 60);
                        if (result <= 3) {
                            var iconUrl = {
                                url: '../../assets/img/location-history-icons/green_circle.png',
                                labelOrigin: { x: 50, y: -15 },
                                scaledSize: new google.maps.Size(10, 10)
                            };
                        }
                        else if (result > 3 && result <= 10) {
                            var iconUrl = {
                                url: '../../assets/img/location-history-icons/orange_circle.png',
                                labelOrigin: { x: 50, y: -15 },
                                scaledSize: new google.maps.Size(10, 10)
                            };
                        }
                        else if (result > 10) {
                            var iconUrl = {
                                url: '../../assets/img/location-history-icons/red_circle.png',
                                labelOrigin: { x: 50, y: -15 },
                                scaledSize: new google.maps.Size(10, 10)
                            };
                        }
                        if (item.messageType === 'HARD_BRAKING') {
                            _this_1.addMarker(item.latitude, item.longitude, _this_1.harsh_braking_color, item);
                        }
                        else if (item.messageType === 'OVER_SPEEDING') {
                            _this_1.addMarker(item.latitude, item.longitude, _this_1.high_speed_color, item);
                        }
                        else if (item.messageType === 'HARD_ACCELERATION') {
                            _this_1.addMarker(item.latitude, item.longitude, _this_1.rapid_acceleration_color, item);
                        }
                        item.iconUrl = iconUrl;
                        return item;
                    }
                });
                _this_1.tripsData.map(function (item) {
                    _this_1.addDots(item);
                });
                _this_1.setDotsBounds();
            });
        });
    };
    // switchToDots() {
    //      if (this.markers) {
    //       this.clearMarkers();
    //     }
    //     this.removePolyline();
    //   this.tripsData = this.tripsData.map(item => {
    //          let eventTime = Date.parse(item.eventTime);
    //         // let ingestionTimestamp = Date.parse(item.ingestionTimestamp);
    //         let timeDiff = eventTime;
    //         let result = Math.floor((timeDiff / 1000) / 60);
    //         if (result <= 3) {
    //           var iconUrl = {
    //             url: '../../assets/img/location-history-icons/green_circle.png',
    //             labelOrigin: { x: 50, y: -15 },
    //             scaledSize: new google.maps.Size(10, 10)
    //           }
    //         } else if (result > 3 && result <= 10) {
    //           var iconUrl = {
    //             url: '../../assets/img/location-history-icons/orange_circle.png',
    //             labelOrigin: { x: 50, y: -15 },
    //             scaledSize: new google.maps.Size(10, 10)
    //           }
    //         } else if (result > 10) {
    //           var iconUrl = {
    //             url: '../../assets/img/location-history-icons/red_circle.png',
    //             labelOrigin: { x: 50, y: -15 },
    //             scaledSize: new google.maps.Size(10, 10)
    //           }
    //         }
    //         item.iconUrl = iconUrl;
    //         return item;
    //     })
    //     this.tripsData.map(item => {
    //       this.addDots(item);
    //     })
    //     this.setDotsBounds();
    // }
    TripsComponent.prototype.checkInfoWindow = function (data) {
        return (data.ingestionTimestamp && this.accordion.trip_view) || (data.ingestionTimestamp && this.accordion.msg_view) ||
            this.dottedEvents.tripChecked || !this.dottedEvents.tripChecked;
    };
    TripsComponent.prototype.addDots = function (data) {
        var bounds = new google.maps.LatLngBounds();
        var latlng = new google.maps.LatLng(data.latitude, data.longitude);
        var marker = new google.maps.Marker({
            position: latlng,
            map: this.map,
            icon: data.iconUrl
        });
        this.markers.push(marker);
        var global = this;
        google.maps.event.addListener(marker, 'mouseover', function () {
            global.latencyData = data;
            this.infowindow = new google.maps.InfoWindow();
            this.infowindow.setContent(data.ingestionTimestamp && global.infowindowData.nativeElement);
            this.infowindow.open(global.map, marker);
        });
        google.maps.event.addListener(marker, 'mouseout', function () {
            this.infowindow.close();
        });
        // if(this.tripsData.length <= 1) {
        //   bounds.extend(latlng);
        //   this.map.fitBounds(bounds);
        // } else {
        //   this.map.setZoom(5);
        //   this.map.setCenter({ lat: environment.lat, lng: environment.lng });
        // }
    };
    TripsComponent.prototype.setDotsBounds = function () {
        var bounds = new google.maps.LatLngBounds();
        for (var u in this.tripsData) {
            if (u) {
                var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(this.tripsData[u].latitude, this.tripsData[u].longitude),
                });
                bounds.extend(marker.getPosition());
            }
        }
        this.map.fitBounds(bounds);
    };
    TripsComponent.prototype.onPIDClick = function (e) {
        this.graphContainerParams = e;
    };
    TripsComponent.prototype.updatePointerEvents = function (e) {
        this.obdPidView.disablePointerEvents = e;
    };
    // map message view section
    TripsComponent.prototype.initMapMessageView = function () {
        if (this.selectedDevice.architectureType)
            this.getMessageType(this.selectedDevice.architectureType);
        if ((this.querySelectVehicle && this.querySelectVehicle.lastCommunication) || (this.selectedDevice)) {
            this.selectedDevice.eventTime = this.querySelectVehicle && this.querySelectVehicle.hasOwnProperty('lastCommunication') ? this.querySelectVehicle.lastCommunication : this.selectedDevice.lastCommunication;
            if (!this.tripViewToggle) {
                var endTime = Date.parse(this.selectedDevice.lastCommunication);
                var startTime = endTime - (1000 * 60 * 60 * 24);
                this.selectedDevice.startTime = startTime;
                this.selectedDevice.endTime = endTime;
                this.pickerStart = startTime;
                this.pickerEnd = endTime;
            }
            this.usingEventTime = true;
            this.getAllData();
        }
        else {
            this.historyRows = 0;
            this.historyRowsEmpty = true;
            this.locationTotalCount.count = 0;
            this.alerts = [];
            this.locationVehicles = 0;
            this.totalDistanceTravelled = 0;
            var endTime = Date.parse(new Date().toUTCString());
            var startTime = endTime - (1000 * 60 * 60 * 4);
            this.selectedDevice.startTime = startTime;
            this.selectedDevice.endTime = endTime;
            // this.mapInit();
        }
    };
    TripsComponent.prototype.getMessageType = function (architectureType) {
        var _this_1 = this;
        this.dataService.getMessageType(architectureType).subscribe({
            next: function (res) {
                _this_1.messageTypeArray = res;
                // this.searchModel.messageType = this.messageTypeArray[0].messageType;
            },
            error: function (error) {
                _this_1.sharedService.getErrorMsg(error);
            }
        });
    };
    TripsComponent.prototype.getAllData = function () {
        this.page = 0;
        this.pageVehicleHistory = 0;
        this.pageInfo.offset = 0;
        this.totalDistanceTravelled = 0;
        this.getPermissionsLiveHistory();
        // this.getAlerts(this.startTime, this.endTime);
        var start = this.pickerStart;
        var end = this.pickerEnd;
        this.obd.ts = this.pickerStart;
        this.obd.te = this.pickerEnd;
        if (this.selected.length && Object.keys(this.selected[0]).length && this.tripViewToggle) {
            this.obd.ts = this.pickerStart;
            this.obd.te = this.pickerEnd;
        }
        else {
            this.obd.ts = this.pickerStart;
            this.obd.te = this.pickerEnd;
        }
        if (this.markers.length) {
            this.clearMarkers();
        }
        if (this.dottedEvents.tripChecked) {
            this.plotDottedLine();
        }
        else {
            this.switchToDots(this.selecteddeviceId, this.obd.ts, this.obd.te);
        }
        // this.plotDottedLine();
        if (this.filterplace) {
            this.filterPlaces(start, end);
        }
        else {
            if (this.timeSeeker.selected) {
                this.getSingleVehicleView(this.selecteddeviceId, this.timeSeeker.ts, this.timeSeeker.te);
                this.getTestCount(this.timeSeeker.ts, this.timeSeeker.te);
            }
            else {
                this.getSingleVehicleView(this.selecteddeviceId, this.pickerStart, this.pickerEnd);
                this.getTestCount(this.pickerStart, this.pickerEnd);
            }
        }
        this.selectedMessages = [];
        this.clearAllMessageMarkers();
        if (this.isDrawerOpen)
            this.getAllMessagesWithCount();
    };
    // To get preferences and permissions
    TripsComponent.prototype.getPermissionsLiveHistory = function () {
        this.liveHistoryKey = [];
        if (localStorage['liveHistoryPrefrence']) {
            var liveHistoryPrefrence = JSON.parse(localStorage.getItem('liveHistoryPrefrence'));
            this.livehistorySearchheader = liveHistoryPrefrence;
            this.liveHistoryKey = liveHistoryPrefrence.map(function (data) {
                return data.key;
            });
            if (this.liveHistoryKey.length) {
                this.liveHistorykeyString = this.liveHistoryKey.toString().concat(',').concat(this.liveHistoryAttribute);
            }
            else {
                this.liveHistorykeyString = this.liveHistoryAttribute;
            }
        }
        else {
            this.liveHistorykeyString = this.liveHistoryAttribute;
            this.livehistorySearchheader = '';
        }
    };
    /*Polyline and marker creation method starts*/
    TripsComponent.prototype.getmapPolyline = function (startTime, endTime) {
        var _this_1 = this;
        var req = null;
        if (this.timeSeeker.selected) {
            req = this.liveService.getPolylineData(this.selecteddeviceId, this.timeSeeker.ts, this.timeSeeker.te);
        }
        else {
            req = this.liveService.getPolylineData(this.selecteddeviceId, startTime, endTime);
        }
        req.subscribe(function (res) {
            if (_this_1.markers.length) {
                _this_1.clearMarkers();
            }
            if (res.events.length > 0) {
                _this_1.plotEvents(res.events);
            }
            _this_1.locationVehicles = res;
            _this_1.totalDistanceTravelled = res.distanceTravelled;
            if (_this_1.selectedDevice && _this_1.selectedDevice.generation === 'V2X') {
                if (res.locations.length > 0) {
                    var markerUrl = '../../assets/img/direction/v2x.png';
                    if (res.locations[0] && res.locations[0].publishedBy === 'OBU Simulation') {
                        markerUrl = '../../assets/img/marker_red.png';
                    }
                    var iconUrl = {
                        url: markerUrl,
                        labelOrigin: { x: 50, y: -15 }
                    };
                    res.locations[0].iconUrl = iconUrl;
                    _this_1.addMessageMarker(res.locations[0]);
                    _this_1.addBounds(res.locations);
                }
                else {
                    _this_1.map.setZoom(4);
                }
            }
            else {
                if (res.locations.length > 0) {
                    if (res.locations[res.locations.length - 1]) {
                        var iconUrl_1 = {
                            url: '../../assets/img/marker_start.png',
                            labelOrigin: { x: 50, y: -15 }
                        };
                        res.locations[res.locations.length - 1].iconUrl = iconUrl_1;
                        _this_1.addMessageMarker(res.locations[res.locations.length - 1]);
                    }
                    if (res.locations[0]) {
                        var heading = res.locations[0].heading;
                        var packet = res.locations[0];
                        var icon = '';
                        if (packet.messageType) {
                            if (packet.messageType === 'COMMUNICATION_LOST') {
                                icon = '../../../../assets/img/direction/warning/';
                            }
                            else if (packet.messageType === 'TRIP_END' || packet.messageType === 'IGNITION_OFF') {
                                icon = '../../../../assets/img/direction/red/';
                            }
                            else if (!packet.endPacket && packet.messageType !== 'TRIP_END' && packet.messageType !== 'IGNITION_OFF') {
                                icon = '../../../../assets/img/direction/green/';
                            }
                            else {
                                icon = '../../../../assets/img/direction/gray/';
                            }
                        }
                        else {
                            icon = '../../../../assets/img/direction/default/';
                        }
                        var iconName = icon + "stop.png";
                        if (heading || heading == '0') {
                            if (heading > 0 && heading <= 30) {
                                iconName = icon + "30.png";
                            }
                            else if (heading > 30 && heading <= 60) {
                                iconName = icon + "60.png";
                            }
                            else if (heading > 60 && heading <= 90) {
                                iconName = icon + "90.png";
                            }
                            else if (heading > 90 && heading <= 120) {
                                iconName = icon + "120.png";
                            }
                            else if (heading > 120 && heading <= 150) {
                                iconName = icon + "150.png";
                            }
                            else if (heading > 150 && heading <= 180) {
                                iconName = icon + "180.png";
                            }
                            else if (heading > 180 && heading <= 210) {
                                iconName = icon + "210.png";
                            }
                            else if (heading > 210 && heading <= 240) {
                                iconName = icon + "240.png";
                            }
                            else if (heading > 240 && heading <= 270) {
                                iconName = icon + "270.png";
                            }
                            else if (heading > 270 && heading <= 300) {
                                iconName = icon + "300.png";
                            }
                            else if (heading > 300 && heading <= 330) {
                                iconName = icon + "330.png";
                            }
                            else if (heading > 330 && heading <= 360 || heading == '0') {
                                iconName = icon + "0.png";
                            }
                        }
                        var iconUrl_2 = {
                            url: iconName,
                            labelOrigin: { x: 50, y: -15 }
                        };
                        res.locations[0].iconUrl = iconUrl_2;
                        _this_1.addMessageMarker(res.locations[0]);
                    }
                    _this_1.addPolyline(res.locations);
                }
                else {
                    _this_1.map.setZoom(4);
                }
            }
        }, function (error) {
            _this_1.sharedService.getErrorMsg(error);
        });
    };
    TripsComponent.prototype.addBounds = function (data) {
        var bounds = new google.maps.LatLngBounds();
        data.forEach(function (item) {
            if (item.latitude && item.longitude) {
                // to extends the bounds
                bounds.extend(new google.maps.LatLng(item.latitude, item.longitude));
            }
        });
        this.map.fitBounds(bounds);
    };
    TripsComponent.prototype.filterPlaces = function (startTime, endTime) {
        var _this_1 = this;
        this.loadingIndicator = true;
        this.filterplace = true;
        this.selectedFilter = 'placeFilter';
        this.liveService.getfilteredPlaces(this.selecteddeviceId, this.pageVehicleHistory, this.liveHistorykeyString, startTime, endTime, this.resSize).subscribe(function (res) {
            _this_1.ngzone.run(function () {
                if (res.length > 0) {
                    if (_this_1.pageVehicleHistory == 0) {
                        _this_1.historyRows = [];
                    }
                    _this_1.historyRowsEmpty = false;
                    _this_1.historyRows = tslib_1.__spread(_this_1.historyRows, res);
                    _this_1.selectedDevice.trips = _this_1.historyRows;
                    _this_1.selectedDevice.trips = _this_1.selectedDevice.trips.map(function (data) {
                        if (data.vehicleSpeed && !Number.isInteger(data.vehicleSpeed)) {
                            var newvehicleSpeed = data.vehicleSpeed;
                            data.vehicleSpeed = parseFloat(newvehicleSpeed).toFixed(1);
                        }
                        var messageValue = configMessage.messageType[data.eventType];
                        if (!messageValue) {
                            messageValue = data.eventType;
                        }
                        data.activity = messageValue + " (" + data.placeName + ")";
                        var global = _this_1;
                        if (global.places.length) {
                            global.places.forEach(function (placeData) {
                                if (data.eventType == 'IN' && data.placeId == placeData.placeId) {
                                    data.place = placeData;
                                }
                            });
                            if (data.place) {
                                global.plotCircle(data.place);
                            }
                        }
                        return data;
                    });
                    // To set page Count
                    _this_1.setPageInfo();
                    _this_1.getPlcesFilterCount(startTime, endTime);
                    _this_1.pageCount = res.length;
                    _this_1.loadingIndicator = false;
                }
                else {
                    _this_1.currentPageCount = {
                        start: 0,
                        end: 0
                    };
                    _this_1.historyRowsEmpty = true;
                    _this_1.historyRows = [];
                    _this_1.mapInit();
                    _this_1.getPlcesFilterCount(startTime, endTime);
                    _this_1.mapInit();
                }
            });
        }, function (error) {
            _this_1.sharedService.getErrorMsg(error);
        });
    };
    TripsComponent.prototype.getPlcesFilterCount = function (startTime, endTime) {
        var _this_1 = this;
        this.isLoading = true;
        this.liveService.getPlcesFilterCount(this.selecteddeviceId, startTime, endTime, this.pageVehicleHistory, this.resSize).subscribe(function (res) {
            _this_1.locationTotalCount = res;
        }, function (error) {
            _this_1.sharedService.getErrorMsg(error);
        });
    };
    TripsComponent.prototype.setPageInfo = function () {
        this.pageInfo.count = this.historyRows.length;
        var pagecount = this.pageInfo.count / this.pageInfo.limit;
        this.pageInfo.lastPage = Math.floor(pagecount);
        var decimal = pagecount - this.pageInfo.lastPage;
        if (!Number.isInteger(decimal)) {
            this.pageInfo.lastPage = this.pageInfo.lastPage + 1;
        }
        if (this.pageVehicleHistory == 0) {
            if (this.historyRows.length) {
                this.currentPageCount.start = (this.pageInfo.offset * this.pageInfo.pageSize) + 1;
                if (this.historyRows.length <= this.pageInfo.limit) {
                    this.currentPageCount.end = this.pageInfo.count;
                }
                else {
                    this.currentPageCount.end = (this.pageInfo.offset * this.pageInfo.pageSize) + this.pageInfo.limit;
                }
            }
        }
    };
    TripsComponent.prototype.plotCircle = function (place) {
        var location = { lat: place.circle.centre[0], lng: place.circle.centre[1] };
        this.cityCircle = new google.maps.Circle({
            strokeColor: place.color,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: place.color,
            fillOpacity: 0.35,
            map: this.map,
            center: location,
            radius: place.circle.radius,
            editable: false
        });
        var latlng = new google.maps.LatLng(location.lat, location.lng);
        var bounds = new google.maps.LatLngBounds();
        bounds.extend(latlng);
        this.multipleCircles.push(this.cityCircle);
        // this.map.fitBounds(this.cityCircle.getBounds());
    };
    // To get data for specific vehicle
    TripsComponent.prototype.getSingleVehicleView = function (deviceId, startTime, endTime, messageType) {
        try {
            this.enablePageVehicleHistory = true;
            this.loadingIndicator = true;
            this.viewPage = true;
            this.getPermissionsLiveHistory();
            if (this.pageVehicleHistory == 0) {
                this.historyRows = [];
            }
            if (deviceId) {
                if (this.timeSeeker.selected) {
                    this.getMessages(this.selecteddeviceId, this.timeSeeker.ts, this.timeSeeker.te);
                }
                else {
                    this.getMessages(deviceId, startTime, endTime, messageType);
                }
            }
            else {
                this.messagesReset();
            }
        }
        catch (e) {
            console.log(e.message);
        }
    };
    // To add a marker in the map
    TripsComponent.prototype.addMessageMarker = function (data) {
        var localDate = new Date(data.eventTime);
        var datePipe = new DatePipe('en-US');
        var eventDate = datePipe.transform(data.eventTime, 'MMM d, h:mm:ss a');
        var latlng = new google.maps.LatLng(data.latitude, data.longitude);
        var marker = new google.maps.Marker({
            position: latlng,
            map: this.map,
            label: eventDate,
            icon: data.iconUrl.url
        });
        this.markers.push(marker);
    };
    TripsComponent.prototype.plotDottedLine = function () {
        var _this_1 = this;
        var req = null;
        if (this.timeSeeker.selected) {
            req = this.liveService.getPolylineData(this.selecteddeviceId, this.timeSeeker.ts, this.timeSeeker.te);
        }
        else {
            req = this.liveService.getPolylineData(this.selecteddeviceId, this.pickerStart, this.pickerEnd);
        }
        req.subscribe(function (res) {
            // this.locationVehicles = res;
            if (_this_1.markers) {
                _this_1.clearMarkers();
            }
            if (_this_1.line.length) {
                _this_1.clearPolyline();
            }
            _this_1.removePolyline();
            _this_1.totalDistanceTravelled = res.distanceTravelled;
            if (res.locations.length > 0) {
                _this_1.locationVehicles = res.locations.filter(function (item) {
                    if (item.ingestionTimestamp) {
                        var eventTime = Date.parse(item.eventTime);
                        var ingestionTimestamp = Date.parse(item.ingestionTimestamp);
                        var timeDiff = ingestionTimestamp - eventTime;
                        // let ms = 1000*Math.round(timeDiff/1000); // round to nearest second
                        // let d = new Date(ms);
                        // let result = d.getUTCMinutes();
                        var result = Math.floor((timeDiff / 1000) / 60);
                        if (result <= 3) {
                            var iconUrl = {
                                url: '../../assets/img/location-history-icons/green_circle.png',
                                labelOrigin: { x: 50, y: -15 },
                                scaledSize: new google.maps.Size(10, 10)
                            };
                        }
                        else if (result > 3 && result <= 10) {
                            var iconUrl = {
                                url: '../../assets/img/location-history-icons/orange_circle.png',
                                labelOrigin: { x: 50, y: -15 },
                                scaledSize: new google.maps.Size(10, 10)
                            };
                        }
                        else if (result > 10) {
                            var iconUrl = {
                                url: '../../assets/img/location-history-icons/red_circle.png',
                                labelOrigin: { x: 50, y: -15 },
                                scaledSize: new google.maps.Size(10, 10)
                            };
                        }
                        item.iconUrl = iconUrl;
                        return item;
                    }
                });
                _this_1.locationVehicles.map(function (item) {
                    _this_1.addDots(item);
                });
                _this_1.setDotsBounds();
            }
            else {
                _this_1.map.setZoom(4);
            }
        }, function (error) {
            _this_1.sharedService.getErrorMsg(error);
        });
    };
    TripsComponent.prototype.getTestCount = function (startTime, endTime) {
        var _this_1 = this;
        // if(!this.searchModel.messageType) {
        if (this.selecteddeviceId) {
            this.isLoading = true;
            this.liveService.getTestCount(this.selecteddeviceId, startTime, endTime, this.pageVehicleHistory, this.resSize, this.searchModel.messageType).subscribe(function (res) {
                _this_1.locationTotalCount = res;
            }, function (error) {
                _this_1.sharedService.getErrorMsg(error);
            });
        }
        // }
    };
    TripsComponent.prototype.clearAllMessageMarkers = function () {
        for (var k in this.messageMarkers) {
            if (this.messageMarkers[k] && this.messageMarkers[k].length) {
                this.clearMessageMarkers(k);
            }
        }
        this.messageMarkers = [];
    };
    TripsComponent.prototype.clearPolyline = function () {
        for (var i = 0; i < this.line.length; i++) {
            this.line[i].setMap(null);
        }
        this.line = [];
    };
    TripsComponent.prototype.getAllMessagesWithCount = function () {
        var _this_1 = this;
        this.messagesLoading = true;
        var startTime = new Date(this.pickerStart);
        var endTime = new Date(this.pickerEnd);
        var start = startTime.getFullYear() + "-" + (startTime.getMonth() + 1) + "-" + startTime.getDate();
        var end = endTime.getFullYear() + "-" + (endTime.getMonth() + 1) + "-" + endTime.getDate();
        this.reportsService.getVehicleMessageData(this.selecteddeviceId, start, end).subscribe({
            next: function (res) {
                // find all the distinct messages
                if (res.length) {
                    var list_1 = [];
                    res.forEach(function (item) {
                        for (var k in item) {
                            if (k !== 'date' && k !== 'NO_DATA') {
                                var obj = { message: '', color: '', count: 0 };
                                if (item[k]) {
                                    obj.message = k;
                                    obj.count = item[k];
                                    if (_this_1.messageTypeColor[k])
                                        obj.color = _this_1.messageTypeColor[k].colorOpaque;
                                    else
                                        obj.color = _this_1.messageTypeColor['UNKNOWN'].colorOpaque;
                                    list_1.push(obj);
                                }
                            }
                        }
                    });
                    _this_1.messages = list_1;
                }
                else {
                    _this_1.messages = [];
                    _this_1.clearAllMessageMarkers();
                }
                _this_1.messagesLoading = false;
            },
            error: function (error) {
                _this_1.messagesLoading = false;
                _this_1.sharedService.getErrorMsg(error);
            }
        });
    };
    TripsComponent.prototype.clearMessageMarkers = function (messageType) {
        for (var i = 0; i < this.messageMarkers[messageType].length; i++) {
            this.messageMarkers[messageType][i].setMap(null);
        }
        this.messageMarkers = [];
    };
    TripsComponent.prototype.getMessages = function (deviceId, startTime, endTime, messageType) {
        var _this_1 = this;
        this.liveService.getSingleVehicleMessage(deviceId, this.pageVehicleHistory, this.liveHistorykeyString, startTime, endTime, this.resSize, this.searchModel.messageType).subscribe(function (res) {
            _this_1.ngzone.run(function () {
                if (res.length > 0) {
                    _this_1.historyRowsEmpty = false;
                    // if (this.timeSeeker.selected) {
                    //   this.historyRows = res;
                    // } else {
                    //   this.historyRows = [...this.historyRows, ...res];
                    // }
                    if (_this_1.pageVehicleHistory === 0) {
                        _this_1.historyRows = res;
                    }
                    else {
                        _this_1.historyRows = tslib_1.__spread(_this_1.historyRows, res);
                    }
                    _this_1.selectedDevice.trips = _this_1.historyRows;
                    _this_1.selectedDevice.trips = _this_1.selectedDevice.trips.map(function (data) {
                        if (data.vehicleSpeed && !Number.isInteger(data.vehicleSpeed)) {
                            var newvehicleSpeed = data.vehicleSpeed;
                            data.vehicleSpeed = parseFloat(newvehicleSpeed).toFixed(1);
                        }
                        var messageValue = configMessage.messageType[data.messageType];
                        if (!messageValue) {
                            messageValue = data.messageType;
                        }
                        data.activity = messageValue;
                        return data;
                    });
                    // To set page Count
                    _this_1.setPageInfo();
                    _this_1.prevResponse = res.length;
                    _this_1.pageCount = res.length;
                    _this_1.loadingIndicator = false;
                }
                else {
                    _this_1.messagesReset();
                }
                // if(this.searchModel.messageType) {
                //   this.locationTotalCount.count = 0;
                // }
            });
        }, function (error) {
            _this_1.sharedService.getErrorMsg(error);
        });
    };
    TripsComponent.prototype.messagesReset = function () {
        if (!this.pageVehicleHistory) {
            this.historyRowsEmpty = true;
            this.currentPageCount = {
                start: 0,
                end: 0
            };
        }
        this.pageCount = 0;
        this.historyRowsEmpty = true;
        this.historyRows = [];
        this.prevResponse = 0;
    };
    TripsComponent.prototype.pageCallback = function (value) {
        this.pageInfo.offset = value.offset;
        this.currentPageCount.start = (value.offset * value.pageSize) + 1;
        var lastPage = Math.floor(this.historyRows.length / this.pageInfo.limit);
        if (lastPage == value.offset) {
            this.currentPageCount.end = value.count;
        }
        else {
            this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
        }
        // if (this.pageCount == 100) {
        if (this.pageInfo.lastPage == (this.pageInfo.offset + 1)) {
            if (this.prevResponse == 100) {
                this.pageVehicleHistory = ++this.pageVehicleHistory;
                // if (this.usingEventTime) {
                //   this.getSingleVehicleView(this.vehicle.deviceId, this.vehicle.startTime, this.vehicle.endTime);
                // } else {
                if (this.timeSeeker.selected) {
                    this.getSingleVehicleView(this.selecteddeviceId, this.timeSeeker.ts, this.timeSeeker.te);
                }
                else {
                    this.getSingleVehicleView(this.selecteddeviceId, this.pickerStart, this.pickerEnd);
                }
                // }
            }
        }
        // }
    };
    TripsComponent.prototype.switchMapForLatency = function (p1) {
        this.dottedEvents.tripChecked = p1;
        this.mapInit();
        this.updateToggle(!this.dottedEvents.tripChecked, true);
        if (p1) {
            this.plotDottedLine();
        }
        else {
            this.switchToDots(this.selecteddeviceId, this.obd.ts, this.obd.te);
        }
    };
    TripsComponent.prototype.switchMap = function (p1) {
        this.mapInit();
        this.dottedEvents.tripChecked = p1;
        this.updateToggle(this.dottedEvents.tripChecked, false);
        if (this.dottedEvents.tripChecked) {
            this.plotDottedLine();
        }
        else {
            this.getmapPolyline(this.pickerStart, this.pickerEnd);
        }
    };
    TripsComponent.prototype.resetTimeSeeker = function () {
        this.timeSeeker = {
            selected: false,
            ts: null,
            te: null,
            selectedVal: [],
            len: 0
        };
    };
    TripsComponent.prototype.resetAccordionParams = function () {
        this.tripViewToggle = true;
        this.accordion.isHavingEnoughSpace = false;
        this.accordion.trip_view = true;
        this.accordion.msg_view = false;
        this.accordion.pid_view = false;
        this.accordion.isMsgManualCollapse = false;
        this.accordion.isPidManualCollapse = false;
        this.accordion.isTripManualCollapse = false;
    };
    TripsComponent.prototype.onTimeSelection = function (val) {
        this.pageVehicleHistory = 0;
        this.pageInfo.offset = 0;
        if (this.timeSeeker.selectedVal.includes(val.ts)) {
            var index = this.timeSeeker.selectedVal.indexOf(val.ts);
            this.timeSeeker.selectedVal.splice(index, 1);
            this.timeSeeker.selected = false;
            this.obd.ts = this.pickerStart;
            this.obd.te = this.pickerEnd;
            this.getMessages(this.selecteddeviceId, this.pickerStart, this.pickerEnd, this.searchModel.messageType);
            this.getTestCount(this.pickerStart, this.pickerEnd);
        }
        else if (this.timeSeeker.selectedVal.length < 1 || this.timeSeeker.selectedVal.length === 1) {
            this.timeSeeker.selectedVal = [];
            this.timeSeeker.selected = true;
            this.timeSeeker.ts = val.ts;
            this.timeSeeker.te = val.te;
            this.obd.ts = val.ts;
            this.obd.te = val.te;
            this.getMessages(this.selecteddeviceId, val.ts, val.te, this.searchModel.messageType);
            this.getTestCount(val.ts, val.te);
            this.timeSeeker.selectedVal.push(val.ts);
        }
        this.mapInit();
        this.getAllData();
    };
    TripsComponent.prototype.tripViewSwitch = function (p1) {
        this.resetTimeSeeker();
        this.tripViewToggle = p1;
        if (p1) {
            this.accordion.msg_view = false;
            this.accordion.trip_view = true;
        }
        else {
            this.accordion.trip_view = false;
            this.accordion.msg_view = true;
            this.obd.ts = this.pickerStart;
            this.obd.te = this.pickerEnd;
        }
        this.initiateData();
    };
    TripsComponent.prototype.getFilteredMessage = function () {
        this.page = 0;
        this.pageVehicleHistory = 0;
        this.pageInfo.offset = 0;
        if (!this.filterplace) {
            // this.getSingleVehicleView(this.vehicle.deviceId, this.startTime, this.endTime, this.searchModel.messageType);
            if (this.timeSeeker.selected) {
                this.getSingleVehicleView(this.selecteddeviceId, this.timeSeeker.ts, this.timeSeeker.te);
                this.getTestCount(this.timeSeeker.ts, this.timeSeeker.te);
            }
            else {
                this.getSingleVehicleView(this.selecteddeviceId, this.pickerStart, this.pickerEnd);
                this.getTestCount(this.pickerStart, this.pickerEnd);
            }
        }
        else {
            this.filterPlaces(this.pickerStart, this.pickerEnd);
        }
    };
    TripsComponent.prototype.getMessageJSON = function (messageId) {
        var _this_1 = this;
        this.messageJsonLoader = true;
        this.dataService.getMessage(messageId).subscribe(function (res) {
            _this_1.messageJsonLoader = false;
            _this_1.messageJsonRaw = JSON.stringify(res, null, 4);
            _this_1.messageJsonFormattedString = _this_1.sharedService.syntaxHighlight([res]);
        }, function (error) {
            _this_1.messageJsonLoader = false;
            _this_1.sharedService.getErrorMsg(error);
        });
    };
    TripsComponent.prototype.downloadMessageJSON = function () {
        var blob = new Blob([this.messageJsonRaw], {
            type: 'text/json;charset=utf-8'
        });
        FileSaver.saveAs(blob, 'message.json');
    };
    TripsComponent.prototype.togglePlacesFilter = function () {
        this.totalDistanceTravelled = 0;
        if (this.filterplace) {
            this.page = 0;
            this.pageInfo.offset = 0;
            this.clearFilter();
        }
        else {
            // if (this.usingEventTime) {
            //   this.filterPlaces(this.vehicle.startTime, this.vehicle.endTime);
            // } else {
            this.filterPlaces(this.pickerStart, this.pickerEnd);
            // }
        }
    };
    TripsComponent.prototype.clearFilter = function () {
        this.selectedFilter = '';
        this.filterplace = false;
        var start = null;
        var end = null;
        // if (this.usingEventTime) {
        //   start = this.vehicle.startTime;
        //   end = this.vehicle.endTime;
        // } else {
        start = this.pickerStart;
        end = this.pickerEnd;
        // }
        this.pageVehicleHistory = 0;
        // this.getSingleVehicleView(this.vehicle.deviceId, start, end);
        if (this.timeSeeker.selected) {
            this.getSingleVehicleView(this.selecteddeviceId, this.timeSeeker.ts, this.timeSeeker.te);
            this.getTestCount(this.timeSeeker.ts, this.timeSeeker.te);
        }
        else {
            this.getSingleVehicleView(this.selecteddeviceId, this.pickerStart, this.pickerEnd);
            this.getTestCount(this.pickerStart, this.pickerEnd);
        }
        if (this.dottedEvents.checked) {
            this.plotDottedLine();
        }
        else {
            this.getmapPolyline(this.pickerStart, this.pickerEnd);
        }
        if (this.multipleCircles.length) {
            this.multipleCircles.forEach(function (item) {
                item.setMap(null);
            });
        }
    };
    TripsComponent.prototype.downloadViewPage = function () {
        var _this_1 = this;
        this.getHistoryCsvHeaderData();
        var data = this.historyRows.map(function (vehicle) {
            return _this_1.csvLiveHistPrefKeyArray.map(function (csvHeader) {
                if (csvHeader === 'eventTime') {
                    vehicle[csvHeader] = moment(vehicle[csvHeader]).format('DD MMM YYYY, hh:mm a');
                }
                return vehicle[csvHeader] || '';
            });
        });
        var options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: false,
            showTitle: false,
            useBom: false,
            headers: this.csvLiveHistPrefHeaderArray
        };
        var date = new Date();
        var filename = this.selectedDevice.assetName + '' + date + '';
        new Angular5Csv(data, filename, options);
    };
    // To download vehicle data as a csv file
    TripsComponent.prototype.getHistoryCsvHeaderData = function () {
        var _this_1 = this;
        // Getting the liveHistoryPrefrence and the default list of liveAttribute and creating a CSV Header Array after removing the duplicates
        // const defaultLiveHistoryArray = this.liveHistoryAttribute.split(',');
        var liveHistoryPreference = JSON.parse(localStorage.getItem('liveHistoryPrefrence'));
        if (localStorage['liveHistoryPrefrence']) {
            var liveHistoryPrefKeyArray = liveHistoryPreference.map(function (data) { return data.key; });
            this.csvLiveHistPrefKeyArray = Array.from(new Set(tslib_1.__spread(['eventTime'], liveHistoryPrefKeyArray)));
            // Creating our User Preferred Header Array with the display names to be shown on the downloaded file
            this.livehistorySearchheader.map(function (obj) {
                _this_1.csvLiveHistPrefKeyArray.map(function (keys) {
                    if (keys === obj.key) {
                        var i = _this_1.csvLiveHistPrefKeyArray.indexOf(keys);
                        _this_1.csvLiveHistPrefHeaderArray[i] = obj.displayName;
                    }
                });
            });
        }
        else {
            this.csvLiveHistPrefKeyArray = ['eventTime'];
        }
        this.csvLiveHistPrefHeaderArray[0] = 'Time';
    };
    return TripsComponent;
}());
export { TripsComponent };
