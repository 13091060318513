import { Routes, RouterModule, CanActivate, PreloadAllModules } from '@angular/router';
import { AuthGuard } from '@myproject/auth.guard';
import { LoginComponent } from '@layouts/login/login.component';
import { ModuleWithProviders } from '@angular/compiler/src/core';
import { LayoutComponent } from '@components/layout/layout.component';
import { LiveComponent } from '@components/live/live.component';
import { LocationHistoryComponent } from '@components/live/location-history/location-history.component';
import { ForgotPasswordComponent } from '@layouts/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from '@layouts/reset-password/reset-password.component';
import { CustomPreloadingStrategy } from '@myproject/lazy-loadding.preloading';
import { PageNotFoundComponent } from '@shared/errors/page-not-found.component';
import { PermissionGuard } from '@myproject/perimission.guard';
// import { DestinationsComponent } from '@components/destinations/destinations.component';
import { PodsStatusComponent } from '@components/pods-status/pods-status.component';
import { DataComponent } from '@components/data/data.component';
import { ContactUsComponent } from '@layouts/contact-us/contact-us.component';
import { FaqComponent } from '@layouts/faq/faq.component';
import { AlDashboardComponent } from './components/al-dashboard/al-dashboard.component';
import { LiveTrackingComponent } from '@components/live-tracking/live-tracking.component';
import { TripsComponent } from './components/live/trips/trips.component';
import { ScoreComponent } from '@components/graphic-reports/score/score.component';
import { MarketplaceComponent } from '@components/admin/marketplace/marketplace.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'tracking',
    component: LiveTrackingComponent
  },
  {
    path: 'logout',
    component: LoginComponent
  },
  {
    path: 'refresh',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password/:id',
    component: ResetPasswordComponent
  },
  {
    path: '', redirectTo: 'live', pathMatch: 'full'
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'contact-us',
        component: ContactUsComponent
      },
      {
        path: 'faq',
        component: FaqComponent
      },
      {
        path: 'live',
        component: LiveComponent,
        data: { moduleName: 'LIVE' },
        canActivate: [PermissionGuard]
      }, {
        path: 'pods',
        component: PodsStatusComponent,
        data: { moduleName: 'PODS-STATUS' },
        canActivate: [PermissionGuard]
      },
      // {
      //   path: 'location-history',
      //   component: LocationHistoryComponent,
      //   data: { moduleName: 'TRIPS' },
      //   canActivate: [PermissionGuard]
      // },
      {
        path: 'trips',
        component: TripsComponent,
        data: { moduleName: 'TRIPS' },
        canActivate: [PermissionGuard]
      },
      {
        path: 'devices',
        loadChildren: () => import('@components/device/device.module').then(m => m.DeviceModule),
        // data: { moduleName: 'DEVICE' },
        // canActivate: [PermissionGuard]
      },
      {
        path: 'admin',
        loadChildren: () => import('@components/admin/admin.module').then(m => m.AdminModule),
        canActivate: [PermissionGuard],
        data: { moduleName: 'ADMIN' }
      },
      {
        path: 'marketplace',
        component: MarketplaceComponent,
        // canActivate: [PermissionGuard],
        data: {moduleName: 'MARKETPLACE'}
      },
      {
        path: 'support-admin',
        loadChildren: () => import('@components/support-admin/support-admin.module').then(m => m.SupportAdminModule),
        canActivate: [PermissionGuard],
        data: { moduleName: 'SUPPORT-ADMIN' }
      },
      {
        path: 'reports',
        loadChildren: () => import('@components/graphic-reports/graphic-reports.module').then(m => m.GraphicReportsModule),
        data: { moduleName: 'REPORT' },
        canActivate: [PermissionGuard]
      },
      {
        path: 'billing-reports',
        loadChildren: () => import('@components/support-ota/support-ota.module').then(m => m.SupportOtaModule),
        data: { moduleName: 'BILLING-REPORTS' },
        canActivate: [PermissionGuard]
      },
      {
        path: 'ota',
        loadChildren: () => import('@components/ota/ota.module').then(m => m.OtaModule),
        data: { moduleName: 'OTA' },
        canActivate: [PermissionGuard]
      },
      {
        path: 'Configuration',
        loadChildren: () => import('@components/configuration-ui/configuration.module').then(m => m.ConfigurationModule),
        data: { moduleName: 'OTA' },
        canActivate: [PermissionGuard]
      },
      {
        path: 'support-ota',
        loadChildren: () => import('@components/support-ota/support-ota.module').then(m => m.SupportOtaModule),
        data: { moduleName: 'SUPPORT-OTA' },
        canActivate: [PermissionGuard]
      },
      {
        path: 'support',
        loadChildren: () => import('@components/support/support.module').then(m => m.SupportModule),
        data: { moduleName: 'SUPPORT' },
        canActivate: [PermissionGuard]
      },
      {
        path: 'destinations',
        loadChildren: () => import('@myproject/components/destination/destination.module').then(m => m.DestinationModule),
        canActivate: [PermissionGuard],
        data: { moduleName: 'DESTINATION' }
      },
      {
        path: 'score',
        component: ScoreComponent,
        data: {moduleName: 'SCORE'},
        canActivate: [PermissionGuard]
      },
      {
        path: 'tools',
        loadChildren: () => import('@components/tools/tools.module').then(m => m.ToolsModule),
        data: { moduleName: 'TOOLS' },
        canActivate: [PermissionGuard]
      },
      {
        path: 'configurations',
        loadChildren: () => import('@components/configurations/configurations.module').then(m => m.ConfigurationsModule),
        data: { moduleName: 'CONFIGURATION' },
        canActivate: [PermissionGuard]
      },
      // {
      //   path: 'trips',
      //   loadChildren: () => import('@components/trips-new/trips-new.module').then(m => m.TripsNewModule),
      //   data: { moduleName: 'TRIPS' },
      //   canActivate: [PermissionGuard]
      // },
      {
        path: 'orders',
        loadChildren: () => import('@components/orders/orders.module').then(m => m.OrdersModule),
        canActivate: [PermissionGuard],
        data: { moduleName: 'ORDER' }
      },
      {
        path: 'dashboard',
        loadChildren: () => import('@components/dashboard/dashboard.module').then(m => m.DashboardModule)
        // data: { moduleName: 'DASHBOARD' },
        // canActivate: [PermissionGuard]
      },
      // {
      //   path: 'system-dashboard',
      //   loadChildren: () => import('@components/support-dashboard/support-dashboard.module').then(m => m.SupportDashboardModule),
      //   data: { moduleName: 'SUPPORT-DASHBOARD' },
      //   canActivate: [PermissionGuard]
      // },
      // {
      //   path: 'al-dashboard',
      //   component: AlDashboardComponent,
      //   data: { moduleName: 'AL-DASHBOARD' },
      //   canActivate: [PermissionGuard]
      // },
      {
        path: 'data/:tab',
        component: DataComponent,
        data: { moduleName: 'DATA' },
        canActivate: [PermissionGuard]
      },
      {
        path : 'data-simulator',
        loadChildren: () => import('@myproject/components/data-simulator/data-simulator.module').then(m => m.DataSimulatorModule),
        canActivate: [PermissionGuard],
        data: {moduleName: 'DATASIMULATOR'}
      },
      // {
      //   path: 'score',
      //   component: ScoreComponent,
      //   data: { moduleName: 'SCORE' },
      //   canActivate: [PermissionGuard]
      // },
      {
        path: 'pods-status',
        component: PodsStatusComponent,
      },
      {
        path: 'quarantine-users',
        loadChildren: () => import('@components/quarantine-users/quarantine-users.module').then(m => m.QuarantineUsersModule),
        data: { moduleName: 'TRACK' },
        canActivate: [PermissionGuard],
      },
      {
        path: 'profile',
        loadChildren: () => import('@layouts/profile/profile.module').then(m => m.ProfileModule),
        data: { moduleName: 'PROFILE' }
      }
    ]
  },
  { path: '**', redirectTo: '404' },
  { path: '404', component: PageNotFoundComponent }

];


export const AppRoutingModule: ModuleWithProviders = RouterModule.forRoot(routes, {
  preloadingStrategy: CustomPreloadingStrategy
}
);

