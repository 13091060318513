import * as tslib_1 from "tslib";
import { OnInit, EventEmitter } from '@angular/core';
import { SharedService } from '@shared/shared.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '@myenv/environment';
import { configMessage } from '@shared/config-message';
var ReportsSideMenuComponent = /** @class */ (function () {
    function ReportsSideMenuComponent(sharedService, route, title, router) {
        this.sharedService = sharedService;
        this.route = route;
        this.title = title;
        this.router = router;
        this.staticReports = configMessage.staticReports;
        this.selectReport = new EventEmitter();
        this.AL_SystemReport = environment.AL_SystemReport;
        this.permissionModules = [];
        this.tripAuthorities = [];
        this.userAuthorities = [];
        this.searchModel = {};
        this.reportsMenu = [];
        this.columns = [];
        this.newReportsMenu = {};
        this.allReports = [];
        this.noReports = false;
        this.noData = false;
        this.noSearch = false;
        this.temp = [];
        this.currentReport = {};
        this.report = {};
        this.reportsOverlay = true;
        this.columMenu = [];
        this.visualizationMode = false;
    }
    ReportsSideMenuComponent.prototype.ngOnInit = function () {
        // for seperate visualizations category
        this.visualizationMode = (this.router.url.includes('/device/visualization')) ? true : false;
        // Getting reports list
        this.getallReports();
        // Getting values from localStorage
        this.localReport = JSON.parse(localStorage.getItem('currentReport'));
        this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
        this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
        // Getting TRIPS authorities
        var tripRoleModule = this.modules.find(function (module) { return module.name === 'TRIPS'; });
        this.tripAuthorities = tripRoleModule && tripRoleModule.authorities ? tripRoleModule.authorities : [];
        // Getting USER authorities
        var userRoleModule = this.modules.find(function (module) { return module.name === 'USER'; });
        this.userAuthorities = userRoleModule && userRoleModule.authorities ? userRoleModule.authorities : [];
        window["search"] = this;
    };
    // To get all reports
    ReportsSideMenuComponent.prototype.getallReports = function () {
        var _this = this;
        if (localStorage.getItem('reports')) {
            this.newReportsMenu = {};
            var reports = JSON.parse(localStorage.getItem('reports'));
            // var newArr = reports.slice(0, -14);
            this.reportsMenu = reports;
            this.categorizeReports(reports);
            this.setReport();
            // console.log('rep from store:', reports.length);
        }
        else {
            this.sharedService.getReports().subscribe(function (res) {
                if (res.length > 0) {
                    _this.staticReports = _this.staticReports.filter(function (item) {
                        if (item.reportType === 'system' && item.url.includes('trigger-report') && !_this.AL_SystemReport)
                            return false;
                        if (item.reportType === 'device' && item.url.includes('trip-summary') && !_this.tripAuthorities.includes('READ'))
                            return false;
                        if (item.reportType === 'billing' && !_this.permissionModules.includes('BILLING'))
                            return false;
                        if (!_this.AL_SystemReport && (item.name === 'C-Triggers' || item.name === 'S-Triggers'))
                            return false;
                        return true;
                    });
                    _this.reportsMenu = tslib_1.__spread(res, _this.staticReports);
                    _this.columns = res;
                    _this.categorizeReports(res);
                    localStorage.setItem('reports', JSON.stringify(_this.reportsMenu));
                    _this.setReport();
                    // console.log('rep from API:', this.reportsMenu.length);
                }
                else {
                    localStorage.setItem('reports', JSON.stringify([]));
                    _this.noReports = true;
                }
            }, function (error) {
                _this.sharedService.getErrorMsg(error);
            });
        }
    };
    ReportsSideMenuComponent.prototype.categorizeReports = function (reports) {
        var _this = this;
        // seggregate reports not for static reports
        reports = reports.filter(function (item) {
            if (['system', 'user', 'device'].includes(item.reportType))
                return false;
            return true;
        });
        // categorize reports received from API other than static reports
        if (!this.visualizationMode) {
            this.newReportsMenu.messages = [];
            reports.map(function (item) {
                if (item.type !== 'VISUALIZATION' &&
                    !item.downloadable &&
                    item.type !== 'MULTI_DEVICE_RECENT_MESSAGE_DOWNLOAD'
                    && item.type !== 'AL_PLANT_MODE') {
                    item.url = '/reports/device/messages';
                    item.reportType = 'messages';
                    _this.newReportsMenu.messages.push(item);
                }
            });
            // console.log('msg rep', this.newReportsMenu.messages.length);
            this.newReportsMenu.custom = [];
            reports.map(function (item) {
                if (item.downloadable && item.type !== 'MULTI_DEVICE_RECENT_MESSAGE_DOWNLOAD' && item.type !== 'AL_PLANT_MODE') {
                    item.url = '/reports/device/custom';
                    item.reportType = 'custom';
                    _this.newReportsMenu.custom.push(item);
                }
            });
            // console.log('custom rep', this.newReportsMenu.custom.length);
            this.newReportsMenu.multiVehicleDownload = [];
            reports.map(function (item) {
                if (item.type === 'MULTI_DEVICE_RECENT_MESSAGE_DOWNLOAD') {
                    item.url = '/reports/device/multiVehicleDownload';
                    item.reportType = 'multiVehicleDownload';
                    _this.newReportsMenu.multiVehicleDownload.push(item);
                }
            });
            // console.log('multi rep', this.newReportsMenu.multiVehicleDownload.length);
            this.newReportsMenu.alPlantMode = [];
            reports.map(function (item) {
                if (item.type === 'AL_PLANT_MODE') {
                    item.url = '/reports/device/alPlantMode';
                    item.reportType = 'alPlantMode';
                    _this.newReportsMenu.alPlantMode.push(item);
                }
            });
            // console.log('al rep', this.newReportsMenu.alPlantMode.length);
        }
        else {
            this.newReportsMenu.visualization = [];
            reports.map(function (item) {
                if (item.type === 'VISUALIZATION') {
                    item.url = '/reports/device/visualization';
                    item.reportType = 'visualization';
                    _this.newReportsMenu.visualization.push(item);
                }
            });
            // console.log('vis rep', this.newReportsMenu.visualization.length);
        }
    };
    ReportsSideMenuComponent.prototype.setReport = function () {
        this.temp = this.reportsMenu;
        var currentReport;
        var report = this.route.snapshot.queryParams.report;
        if (report) {
            this.reportsMenu.forEach(function (item) {
                if (item.reportId === report) {
                    currentReport = item;
                }
            });
            this.columns = this.reportsMenu;
            localStorage.setItem('currentReport', JSON.stringify(currentReport));
            this.report.reportId = currentReport.reportId;
            this.currentReport = currentReport;
        }
        else if (JSON.parse(localStorage.getItem('currentReport'))) {
            var localReport = JSON.parse(localStorage.getItem('currentReport'));
            this.currentReport = localReport;
            this.report.reportId = localReport.reportId;
        }
        else {
            localStorage.setItem('currentReport', JSON.stringify({}));
        }
        if (!this.currentReport.name) {
            this.reportsOverlay = true;
        }
        else {
            this.reportsOverlay = false;
        }
        sessionStorage.setItem('reportName', this.currentReport.name);
        this.columMenu = this.currentReport.columns;
    };
    ReportsSideMenuComponent.prototype.messageReport = function (report, reportUrl) {
        this.searchModel.columnVal = '';
        this.onColumnSearch();
        localStorage.setItem('currentReport', JSON.stringify(report));
        this.router.navigate([reportUrl]);
        this.selectReport.emit(report);
        if (!this.visualizationMode)
            localStorage.setItem('lastReportUrl', reportUrl);
        var localReport = JSON.parse(localStorage.getItem('currentReport'));
        this.currentReport = localReport;
    };
    ReportsSideMenuComponent.prototype.setReportUrl = function (reportUrl, staticReportName) {
        this.searchModel.columnVal = '';
        this.onColumnSearch();
        if (!this.visualizationMode)
            localStorage.setItem('lastReportUrl', reportUrl);
        var report = {};
        if (staticReportName === 'messageCount')
            report = { name: 'Messages', reportType: 'vehicle', url: '/reports/device/message-count' };
        if (staticReportName === 'packetHistory')
            report = { name: 'Packet History', reportType: 'vehicle', url: '/reports/device/packet-history' };
        if (staticReportName === 'tripSummary')
            report = { name: 'Trip Summary', reportType: 'vehicle', url: '/reports/device/trip-summary' };
        localStorage.setItem('currentReport', JSON.stringify(report));
    };
    ReportsSideMenuComponent.prototype.onColumnSearch = function () {
        var _this = this;
        this.columns = this.reportsMenu;
        var inputVal = this.searchModel.columnVal.trim();
        var val = inputVal.toLowerCase();
        if (val === '') {
            this.allReports = [];
            this.noSearch = false;
            this.noData = false;
            return;
        }
        else {
            this.noSearch = true;
            var reports = this.newReportsMenu;
            var deviceReport = [];
            if (!this.visualizationMode) {
                deviceReport = [
                    { name: 'Messages', reportType: 'vehicle', url: '/reports/device/message-count' },
                    { name: 'Packet History', reportType: 'vehicle', url: '/reports/device/packet-history' },
                ];
                if (reports && reports.messages)
                    deviceReport = tslib_1.__spread(deviceReport, reports.messages);
                if (reports && reports.custom)
                    deviceReport = tslib_1.__spread(deviceReport, reports.custom);
                if (reports && reports.multiVehicleDownload)
                    deviceReport = tslib_1.__spread(deviceReport, reports.multiVehicleDownload);
                if (reports && reports.alPlantMode)
                    deviceReport = tslib_1.__spread(deviceReport, reports.alPlantMode);
                if (this.tripAuthorities.includes('READ')) {
                    deviceReport.push({ name: 'Trip Summary', reportType: 'vehicle', url: '/reports/device/trip-summary' });
                }
            }
            else if (this.visualizationMode) {
                if (reports && reports.visualization)
                    deviceReport = tslib_1.__spread(deviceReport, reports.visualization);
            }
            var userReport = [
                { name: 'Log-in Activity', reportType: 'user', url: '/reports/user/in-out-activity' }
            ];
            var systemReport = [
                { name: 'Message Published', reportType: 'system', url: '/reports/system/message-published' },
                { name: 'Live Status', reportType: 'system', url: '/reports/system/live-status' },
                { name: 'Provisioning', reportType: 'system', url: '/reports/system/provisioning' },
                { name: 'Software Version', reportType: 'system', url: '/reports/system/version-drilldown' },
                { name: 'Not Communicating', reportType: 'system', url: '/reports/system/not-communicating' },
                { name: 'Communicating', reportType: 'system', url: '/reports/system/communicating' },
                { name: 'Device Timeline', reportType: 'system', url: '/reports/system/device-timeline' },
                { name: 'Bitbrew Usage', reportType: 'system', url: '/reports/system/usage' },
                { name: 'Billing', reportType: 'system', url: '/reports/system/billing-report' },
                { name: 'Discovery', reportType: 'system', url: '/reports/system/discovery' },
                { name: 'Diagnostics', reportType: 'system', url: '/reports/system/diagnostics' },
                { name: 'Disonnected', reportType: 'system', url: '/reports/system/disconnected' }
            ];
            if (this.AL_SystemReport) {
                systemReport.push({ name: 'C-Triggers', reportType: 'system', url: '/reports/system/c-trigger-report' }, { name: 'S-Triggers', reportType: 'system', url: '/reports/system/s-trigger-report' });
            }
            // const billingReport = [];
            // if (this.permissionModules.includes('BILLING')) {
            //   billingReport.push(
            //     { name: 'Billing', reportType: 'billing', url: '/reports/billing/provision-billing' }
            //   );
            // }
            this.allReports = tslib_1.__spread(deviceReport, userReport, systemReport);
            if (this.allReports.length) {
                var temp = this.allReports.filter(function (item, index) {
                    if (item.name.toLowerCase().includes(val)) {
                        return _this.allReports[index];
                    }
                });
                if (!temp.length) {
                    this.noData = true;
                }
                else {
                    this.noData = false;
                }
                this.allReports = temp;
                this.deviceReport = 0;
                this.userReport = 0;
                this.messagesReport = 0;
                this.alPlantMode = 0;
                this.customReport = 0;
                this.visualizationReport = 0;
                this.systemReport = 0;
                this.vehicleReport = 0;
                this.multiVehicleReport = 0;
                this.billingReport = 0;
                this.allReports.map(function (item) {
                    if (item.reportType === 'device') {
                        _this.deviceReport++;
                    }
                    if (item.reportType === 'messages') {
                        _this.messagesReport++;
                    }
                    if (item.reportType === 'custom') {
                        _this.customReport++;
                    }
                    if (item.type === 'VISUALIZATION') {
                        _this.visualizationReport++;
                    }
                    if (item.reportType === 'vehicle') {
                        _this.vehicleReport++;
                    }
                    if (item.reportType === 'MULTI_DEVICE_RECENT_MESSAGE_DOWNLOAD') {
                        _this.multiVehicleReport++;
                    }
                    if (item.reportType === 'user') {
                        _this.userReport++;
                    }
                    if (item.reportType === 'system') {
                        _this.systemReport++;
                    }
                    if (item.reportType === 'alPlantMode') {
                        _this.alPlantMode++;
                    }
                    if (item.reportType === 'billing') {
                        _this.billingReport++;
                    }
                });
            }
        }
    };
    return ReportsSideMenuComponent;
}());
export { ReportsSideMenuComponent };
