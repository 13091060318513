<div class="ng-select-row relative">
  <i class="fa fa-microchip pr-1 ng-select-icon"></i>
  <ng-select
    #select
    class=" btn btn-custom p-0 text-left ajustar"
    bindLabel="assetName"
    placeholder="Select Device"
    typeToSearchText="Please enter 1 or more characters"
    [multiple]="isMulti"
    [closeOnSelect]="closeOnSelect"
    [items]="vehicles"
    [addTag]="false"
    [multiple]="false"
    [hideSelected]="false"
    [trackByFn]="trackByFn"
    [minTermLength]="0"
    [loading]="vehicleLoading"
    [clearable]="clearable"
    [typeahead]="vehicleInput$"
    [(ngModel)]="selectedVehicles"
    (change)="selectVehicle()"
    (open)="loadVehicles()"
    (close)="select.blur(); select.focus(); vehicleLoading=false"
    >
    <ng-template ng-option-tmp let-item="item" let-index="index">
      <div class="row no-gutters align-items-center">
        <div class="col-1">
          <i class="fa fa-microchip"></i>
        </div>
        <div class="col-11">
          <span>{{item.assetName}}&nbsp;</span>
          <span [class]="'badge badge-' + getLastKnownStatus(item.lastKnownStatus)">
            {{getLastKnownStatus(item.lastKnownStatus)}}
          </span>
          <div>
            <small *ngIf="item.lastCommunication">
              {{item.lastCommunication | date : dateFormat}} ({{item.lastCommunicationString}})
            </small>
            <!-- <span *ngIf="!item.lastCommunication" class="badge badge-secondary">Not Connected</span> -->
          </div>
          <div *ngIf="item.simStatus">
            <span>
              <small>Sim Status: </small>
              <small class="badge badge-primary" [tooltip]="'Sim Status'" placement="top">{{item.simStatus}}</small>
            </span>
          </div>
        </div>
      </div>
    </ng-template>
  </ng-select>
</div>
