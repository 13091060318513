import { NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { SharedService } from '@shared/shared.service';
var PermissionGuard = /** @class */ (function () {
    function PermissionGuard(sharedService, router, ngzone) {
        this.sharedService = sharedService;
        this.router = router;
        this.ngzone = ngzone;
    }
    PermissionGuard.prototype.canActivate = function (route, state) {
        var _this = this;
        var permissionModules = [];
        permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
        var modulename = route.data.moduleName;
        localStorage.setItem('currentModule', modulename);
        return this.checkPermission(modulename, permissionModules).then(function (res) {
            if (res) {
                return true;
            }
            else {
                _this.sharedService.setDefaultUrl(permissionModules);
                return false;
            }
        });
    };
    PermissionGuard.prototype.checkPermission = function (modulename, permissionModules) {
        if (permissionModules) {
            var checkmodule = permissionModules.includes(modulename);
            return Promise.resolve(checkmodule);
        }
        else {
            this.router.navigate(['login']);
        }
    };
    return PermissionGuard;
}());
export { PermissionGuard };
