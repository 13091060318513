<div class="sidebar-object">
  <div class="reportScrollSideMenu">
    <ul class="list-recent reportsSidemenu">
      <li class="clearfix ripple side-menu-list-item"
        *ngFor="let data of sideData; let i=index;"
        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}" >
        <a class="pb-2 pt-2" routerLink="{{data.route}}" style="cursor: pointer; display: block;">
          <span class="sideMenuIconBox">
            <!-- <img src="{{cmImageUrl}}{{data.img}}" class="img-responsive side-menu-icon"> -->
            <i class="fa fa-{{data.img}} side-menu-icon"></i>
          </span>
          <span class="side-menu-text">{{data.name | translate}}</span>
        </a>
      </li>
    </ul>
  </div>
</div>
