<div class="row no-gutters">
  <div class="col-md-8 table-title text-left">
    <h6>{{displayTitle}}</h6>
  </div>
  <div class="table-button col-md-4 button-container mb-2 text-right">
    <!-- <div class="btn-group">
      <button
        class="btn btn-icon mr-2"
        tooltip="Configure Columns"
        placement="top"
        (click)="togglePopup()"
        [disabled]="!devicesList.length"
        >
        <i class="fa fa-columns" aria-hidden="true"></i>
        <span class="mob-access-text d-none">&nbsp;Columns</span>
      </button>
    </div> -->
    <!-- Columns dropdown -->
    <div class="selectColumnsDropdown text-left" *ngIf="columnPopup">
      <!-- <div class="title">
        <h5>Columns</h5>
      </div> -->
      <div class="column-search">
        <input
          type="text" name="userTypeVal"
          required autofocus
          [(ngModel)]="searchModel.columnVal"
          #columnVal="ngModel"
          (keyup)="onColumnSearch()"
          placeholder="Search"
        />
        <span
          class="cross-icon"
          *ngIf="searchModel.columnVal"
          (click)="columns = headerItems; searchModel.columnVal=''"
          >
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
        <span><i class="fa fa-search img-responsive search-icon" aria-hidden="true"></i></span>
      </div>
      <div *ngIf="columns.length" class="content">
        <div *ngIf="!this.searchModel.columnVal" class="item">
          <label class="box-container">Select All
            <input type="checkbox" [checked]="selectCount===headerItems.length" (change)="selectAllItems($event)">
            <span class="checkmark"></span>
          </label>
        </div>
        <div class="item"
          *ngFor="let header of columns; let i=index;"
          [class.disabled]="deviceStaticHeader.includes(header.name)"
          >
          <label class="box-container">{{header.displayName}}
            <input
              type="checkbox" [checked]="header.checked"
              (change)="selectItems($event, header, i)"
              [disabled]="deviceStaticHeader.includes(header.name)">
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
      <div *ngIf="!columns.length" class="content">
        <div class="item text-center bold">
          No Data Found
        </div>
      </div>
      <div class="actions text-right">
        <button
          class="btn btn-outline-secondary small-screen-btn mr-1"
          (click)="cancelSelection()"
          >
          Cancel
        </button>
        <button
          class="btn btn-primary small-screen-btn"
          (click)="updatePreference()"
          >
          Apply
        </button>
      </div>
    </div>
    <button
      class="btn btn-icon download"
      tooltip="Download"
      placement="top"
      (click)="downloadFile()"
      [disabled]="!devicesList.length"
      >
      <i class="fa fa-cloud-download" aria-hidden="true"></i>
      <span class="mob-access-text d-none">&nbsp;Download</span>
    </button>
  </div>
</div>
<div>
  <div class="page-loading" *ngIf="pageLoading">
    <img src="../../../assets/img/darby_loader_small.gif"/>
  </div>
  <div *ngIf="!devicesList.length && !pageLoading" class="text-center mb-3 mt-2">
    <img width="170" src="../../../assets/img/info_nodata_256.png"/>
    <h4 class="mt-3">No data to show</h4>
    <p>To see data, check your date selection.
      <!--<a class="pointer link-color" data-toggle="modal" (click)="toggleButton()"-->
         <!--data-target="#addNewRole_modal" >create role</a>.-->
    </p>
  </div>
  <ngx-datatable *ngIf="devicesList.length"
    id="tableau" #dataTable
    class="bootstrap alertTable reportsTable wrapcell"
    [rows]="devicesList"
    [columnMode]="'force'"
    [headerHeight]="50"
    [footerHeight]="50"
    [rowHeight]="'auto'"
    [summaryRow]="false"
    [scrollbarH]="true"
    [reorderable]="true"
    [limit]="pageInfo.limit"
    [offset]="pageInfo.offset"
    [count]="pageInfo.count"
    (page)="pageCallback($event)"
    >
    <ngx-datatable-column [resizeable]="false" [sortable]="false" name="#" [width]="50">
      <ng-template let-rowIndex="rowIndex" ngx-datatable-cell-template>
        {{rowIndex+1}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Serial Number" prop="serialNumber">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.serialNumber}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="IMEI" prop="imei">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.imei}}
      </ng-template>
    </ngx-datatable-column>
    <!-- <ngx-datatable-column name="ICCID" prop="simCcid">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.simCcid}}
      </ng-template>
    </ngx-datatable-column> -->

    <ngx-datatable-column name="Group" prop="groupName">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.groupName}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Firmware Version" prop="firmwareVersion">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.firmwareVersion}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Config Version" prop="configVersion">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.configVersion}}
      </ng-template>
    </ngx-datatable-column>
    <ngx-datatable-column name="Last Communication" prop="lastCommunication">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.lastCommunication}}
      </ng-template>
    </ngx-datatable-column>
    <!-- <ngx-datatable-column name="Model" prop="model">
      <ng-template let-row="row" ngx-datatable-cell-template>
        {{row.model}}
      </ng-template>
    </ngx-datatable-column> -->
    <!-- <ngx-datatable-column name="Status" [sortable]="false">
      <ng-template let-row="row" ngx-datatable-cell-template>
        <span [class]="'class-' + deviceStatusClass[row.deviceStatus]">
          {{deviceStatus[row.deviceStatus]}}
        </span>
      </ng-template>
    </ngx-datatable-column> -->

    <ng-container *ngFor="let dh of customHeaders">
      <ngx-datatable-column [resizeable]="false" prop="{{dh.name}}" name="{{dh.displayName}}" [sortable]="true">
        <ng-template let-row="row" ngx-datatable-cell-template>
          <span *ngIf="dateAttributes.includes(dh.name)">{{row[dh.name] | date : dateFormat}}</span>
          <span *ngIf="!dateAttributes.includes(dh.name)">{{row[dh.name]}}</span>
        </ng-template>
      </ngx-datatable-column>
    </ng-container>
  </ngx-datatable>
</div>
<!-- <div class="total-count pl-3" *ngIf="devicesCount && devicesCount.count">
  Showing
  <span contenteditable="false" (input)="currentPageCount.start = $event.target.innerText">
    {{currentPageCount.start}}
  </span>
  -
  <span contenteditable="false" (input)="currentPageCount.end = $event.target.innerText">
    {{currentPageCount.end}}
  </span>
  of {{(devicesCount.count)}} devices.
</div> -->
