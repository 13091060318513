import { throwError as observableThrowError, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '@myenv/environment';
var AuthenticationService = /** @class */ (function () {
    function AuthenticationService(http, router, handler) {
        this.http = http;
        this.router = router;
        this.handler = handler;
        this.apiUrl = environment.apiUrl;
        this.emitChangeSourceUser = new Subject();
        this.changeEmitteds$ = this.emitChangeSourceUser.asObservable();
        this.httpClient = new HttpClient(handler);
    }
    AuthenticationService.prototype.login = function (data) {
        return this.http.post(this.apiUrl + 'tokens', data).pipe(catchError(this.handleError));
    };
    AuthenticationService.prototype.getMfaToken = function (token, payload) {
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.post(this.apiUrl + 'mfa/tokens', payload, { headers: headers })
            .pipe(catchError(this.handleError));
    };
    AuthenticationService.prototype.resendVerficationCode = function (token) {
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.post(this.apiUrl + 'mfa/tokens/resend', {}, { headers: headers })
            .pipe(catchError(this.handleError));
    };
    AuthenticationService.prototype.getUserInfo = function (userId) {
        var _headers = new HttpHeaders();
        var token = this.getHeaders();
        var headers = _headers.append('Authorization', 'Bearer ' + token).append('Content-Type', 'application/json');
        // return this.http.get(this.apiUrl + 'users/loggedin', {headers}).pipe(
        return this.http.get(this.apiUrl + 'users/' + userId, { headers: headers }).pipe(catchError(this.handleError));
    };
    AuthenticationService.prototype.impersonate = function (data) {
        return this.http.post(this.apiUrl + 'impersonate/user/' + data + '/login', data).pipe(catchError(this.handleError));
    };
    AuthenticationService.prototype.stopImpersonate = function () {
        return this.http.post(this.apiUrl + 'impersonate/logout', {}).pipe(catchError(this.handleError));
    };
    AuthenticationService.prototype.getLoggedUser = function () {
        return this.http.get(this.apiUrl + 'users/loggedin').pipe(catchError(this.handleError));
    };
    AuthenticationService.prototype.getUserNotification = function () {
        var _headers = new HttpHeaders();
        var token = this.getHeaders();
        var headers = _headers.append('Authorization', 'Bearer ' + token).append('Content-Type', 'application/json');
        return this.http.get(this.apiUrl + 'notifications/user', { headers: headers }).pipe(catchError(this.handleError));
    };
    AuthenticationService.prototype.getUnreadNotificationCount = function () {
        var _headers = new HttpHeaders();
        var token = this.getHeaders();
        var headers = _headers.append('Authorization', 'Bearer ' + token).append('Content-Type', 'application/json');
        return this.http.get(this.apiUrl + 'activity/users/unread', { headers: headers }).pipe(catchError(this.handleError));
    };
    AuthenticationService.prototype.getAllUserNotifications = function () {
        var _headers = new HttpHeaders();
        var token = this.getHeaders();
        var headers = _headers.append('Authorization', 'Bearer ' + token).append('Content-Type', 'application/json');
        return this.http.get(this.apiUrl + 'activity/users', { headers: headers }).pipe(catchError(this.handleError));
    };
    AuthenticationService.prototype.readAllUserNotifications = function () {
        var _headers = new HttpHeaders();
        var token = this.getHeaders();
        var headers = _headers.append('Authorization', 'Bearer ' + token).append('Content-Type', 'application/json');
        return this.http.post(this.apiUrl + 'activity/users/read', { headers: headers }).pipe(catchError(this.handleError));
    };
    AuthenticationService.prototype.closeUserNotification = function (userNotificationId) {
        return this.http.patch(this.apiUrl + "notifications/user/" + userNotificationId + "?display=true", {}).pipe(catchError(this.handleError));
    };
    AuthenticationService.prototype.setHeaders = function (token) {
        return localStorage.setItem('token', token);
    };
    AuthenticationService.prototype.getHeaders = function () {
        return localStorage.getItem('token');
    };
    AuthenticationService.prototype.checkCredentials = function () {
        if (localStorage.getItem('currentUser') === null) {
            return this.router.navigate(['Login']);
        }
    };
    AuthenticationService.prototype.getCurrentUser = function () {
        return JSON.parse(localStorage.getItem('currentUser'));
    };
    AuthenticationService.prototype.onClickRester = function (data) {
        return this.http.post(this.apiUrl + 'admin', data).pipe(catchError(this.handleError));
    };
    AuthenticationService.prototype.setLoggedInUser = function (data) {
        localStorage.setItem('currentUser', JSON.stringify(data));
        this.emitChangeSourceUser.next(data);
    };
    // livePrefrences(live) {
    //   // localStorage.setItem('livePrefrence', JSON.stringify(live));
    //   localStorage.setItem('livePrefrence', JSON.stringify([]));
    //   this.emitChangeSourceUser.next(live);
    // }
    AuthenticationService.prototype.userPreferences = function () {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
        });
        return this.http.get(this.apiUrl + 'users/preferences', { headers: headers }).pipe(catchError(this.handleError));
    };
    AuthenticationService.prototype.liveHistoryPrefrences = function (liveHistory) {
        localStorage.setItem('liveHistoryPrefrence', JSON.stringify(liveHistory));
        this.emitChangeSourceUser.next(liveHistory);
    };
    AuthenticationService.prototype.ForgotPassword = function (data) {
        var body = new URLSearchParams();
        body.set('userName', data.userName);
        var headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        });
        return this.httpClient.post(this.apiUrl + 'password/reset', body.toString(), { headers: headers }).pipe(catchError(this.handleError));
    };
    AuthenticationService.prototype.ResetPassword = function (data, resetToken) {
        var body = new URLSearchParams();
        body.set('newPassword', data.newPassword);
        var headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded'
        });
        return this.httpClient.put(this.apiUrl + 'password/reset/' + resetToken, body.toString(), { headers: headers }).pipe(catchError(this.handleError));
    };
    AuthenticationService.prototype.updatePassword = function (data) {
        return this.http.put(this.apiUrl + 'users/password', data).pipe(catchError(this.handleError));
    };
    AuthenticationService.prototype.logout = function (token) {
        localStorage.removeItem('currentUser');
        localStorage.clear();
        sessionStorage.clear();
        this.emitChangeSourceUser.next(null);
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
        });
        return this.http.delete(this.apiUrl + 'tokens/logout', { headers: headers }).pipe(catchError(this.handleError));
    };
    AuthenticationService.prototype.handleError = function (error) {
        return observableThrowError(error.error || error.json() || error || 'Server error');
    };
    return AuthenticationService;
}());
export { AuthenticationService };
