import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedComponent } from '@shared/shared.component';
import { ScriptService } from '@shared/script.service';
import { SharedService } from '@shared/shared.service';
import { KeyValuePipe } from '@shared/key-value.pipe';
import { FilterPipe } from '@shared/filter.pipe';
import { ColumnFilterPipe } from '@shared/column-filter.pipe';
import { NotifyMessageComponent } from '@shared/notify-message/notify-message.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TrimValueAccessorModule } from 'ng-trim-value-accessor';
import { SideMenuComponent } from '@shared/side-menu/side-menu.component';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { PermissionGuard } from '@myproject/perimission.guard';
import { AuthGuard } from '@myproject/auth.guard';
import { NgSelectModule } from '@ng-select/ng-select';
import { MapServiceComponent } from '@shared/map-service/map-service.component';
import { BarGraphComponent } from './bar-graph/bar-graph.component';
import { LineGraphComponent } from './line-graph/line-graph.component';
import { ScatterChartComponent } from './scatter-chart/scatter-chart.component';
import { CalendarComponent } from './calendar/calendar.component';
import { InlineEditComponent } from './inline-edit/inline-edit.component';
import { MyRenderer } from '@shared/inline-edit/inline-edit.service';
import { InlineEditSelectComponent } from './inline-edit-select/inline-edit-select.component';
import { StackedBarGraphComponent } from './stacked-bar-graph/stacked-bar-graph.component';
import { DateTimeCalendarComponent } from './date-time-calendar/date-time-calendar.component';
import { NgbDatepickerModule, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchSelectVehicleComponent } from './search-select-vehicle/search-select-vehicle.component';
import { SearchSelectDeviceComponent } from './search-select-device/search-select-device.component';
import { ConfirmDeleteModalComponent } from './confirm-delete-modal/confirm-delete-modal.component';
import { FileSizePipe } from './custom-pipes/file-size.pipe';
import { TooltipModule } from 'ng2-tooltip-directive';
import { SecondsToHmsPipe } from '@myproject/shared/seconds-to-hms.pipe';
import { EllipsesPipe } from '@myproject/shared/ellipses/ellipses.pipe';
import { CalendarGraphComponent } from './calendar-graph/calendar-graph.component';
import { OtaFileUploadModalComponent } from './ota-file-upload-modal/ota-file-upload-modal.component';
import { DeviceActivityComponent } from './campaign-device-activity/device-activity.component';
import { DistanceMetricsPipe } from '@myproject/shared/distance-metrics.pipe';
import { DataTabHeaderComponent } from './data-tab-header/data-tab-header.component';
import { ObdpidComponent } from './obdpid/obdpid.component';
import { TimeSeekerComponent } from './time-seeker/time-seeker.component';
import { DashboardHeaderComponent } from '@components/dashboard/dashboard-header/dashboard-header.component';
import { ObdPidViewComponent } from './obd-pid-view/obd-pid-view.component';
// import { ObdPidViewDirective } from './obd-pid-view/obd-pid-view.directive';
import { BillingReportComponent } from '../components/graphic-reports/system-reports/billing-report/billing-report.component';
import { ReportsHeaderComponent } from '../components/graphic-reports/reports-header/reports-header.component';
import { ReportsSideMenuComponent } from '../components/graphic-reports/reports-side-menu/reports-side-menu.component';
import { ReplayCustomCalendarComponent } from './replay-custom-calendar/replay-custom-calendar.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    TrimValueAccessorModule,
    RouterModule,
    NgxDatatableModule,
    NgSelectModule,
    FormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgbDatepickerModule,
    NgbTimepickerModule,
    TooltipModule
  ],
  exports : [
    SharedComponent,
    NotifyMessageComponent,
    KeyValuePipe,
    FilterPipe,
    ColumnFilterPipe,
    TranslateModule,
    SideMenuComponent,
    MapServiceComponent,
    BarGraphComponent,
    LineGraphComponent,
    ScatterChartComponent,
    CalendarComponent,
    InlineEditComponent,
    InlineEditSelectComponent,
    StackedBarGraphComponent,
    DateTimeCalendarComponent,
    SearchSelectVehicleComponent,
    SearchSelectDeviceComponent,
    ConfirmDeleteModalComponent,
    FileSizePipe,
    SecondsToHmsPipe,
    EllipsesPipe,
    CalendarGraphComponent,
    OtaFileUploadModalComponent,
    DeviceActivityComponent,
    DistanceMetricsPipe,
    DataTabHeaderComponent,
    ObdpidComponent,
    TimeSeekerComponent,
    DashboardHeaderComponent,
    ObdPidViewComponent,
    // ObdPidViewDirective
    BillingReportComponent,
    ReportsHeaderComponent,
    ReportsSideMenuComponent,
    ReplayCustomCalendarComponent

  ],
  declarations: [
    SharedComponent,
    NotifyMessageComponent,
    KeyValuePipe,
    FilterPipe,
    ColumnFilterPipe,
    SideMenuComponent,
    MapServiceComponent,
    BarGraphComponent,
    LineGraphComponent,
    ScatterChartComponent,
    CalendarComponent,
    InlineEditComponent,
    InlineEditSelectComponent,
    StackedBarGraphComponent,
    DateTimeCalendarComponent,
    SearchSelectVehicleComponent,
    SearchSelectDeviceComponent,
    ConfirmDeleteModalComponent,
    FileSizePipe,
    SecondsToHmsPipe,
    EllipsesPipe,
    CalendarGraphComponent,
    OtaFileUploadModalComponent,
    DeviceActivityComponent,
    DistanceMetricsPipe,
    DataTabHeaderComponent,
    ObdpidComponent,
    TimeSeekerComponent,
    DashboardHeaderComponent,
    ObdPidViewComponent,
    // ObdPidViewDirective
    BillingReportComponent,
    ReportsHeaderComponent,
    ReportsSideMenuComponent,
    ReplayCustomCalendarComponent
  ],
  providers : [ScriptService, SharedService, PermissionGuard, AuthGuard, MyRenderer]
})
export class SharedModule { }
