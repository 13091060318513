<app-notify-message></app-notify-message>
<app-reports-header></app-reports-header>
<div class="row no-gutters">
  <div class="col-md-2 col-sm-0 p-0">
    <app-reports-side-menu></app-reports-side-menu>
  </div>
  <div class="reports-body col-md-10 col-sm-12 p-0">
    <div class="row no-gutters align-items-center action-row px-2">
      <div class="col-md-5">
        <h4 class="report-heading page-heading"><i class="fa fa-bar-chart"></i> Provisioning</h4>
      </div>
      <div class="col-md-7 text-right">
        <div class="calendar d-inline-block ml-2" (clickOutside)="hideCalendar=!hideCalendar;">
          <app-date-time-calendar
            [clickOutside]="hideCalendar"
            [selectPreset]="'30days'"
            [timeSelection]="false"
            [hideLinks]="['today', '15mins', '30mins', '1hour', '4hours', '12hours', '90days', '180days', '365days']"
            (timeChangeEvent)="onTimeChange($event)"
            >
          </app-date-time-calendar>
          <button
            class="btn btn-icon ml-2 refresh"
            (click)="refresh()"
            tooltip="Refresh"
            placement="top"
            [disabled]="isLoading">
            <i class="fa fa-refresh" aria-hidden="true"></i>
            <span class="mob-access-text d-none">&nbsp;Refresh</span>
          </button>
        </div>
      </div>
    </div>
    <div class="row no-gutters pt-1">
      <div class="col-12 col-md-5 p-2">
        <div class="report-item-container clickable animated fadeIn">
          <div class="count-container" (click)="getPreferredDevices('installed')">
            <div class="count">{{installed}}</div>
            <div class="text">Installed&nbsp;
              <i class="fa fa-info-circle tooltip-event relative">
                <span class="tooltip-event-name">Shows the number of devices added to the account.</span>
              </i>
            </div>
          </div>
          <div class="graph-container">
            <canvas id="barChartInstalled" height="100" role="img"></canvas>
          </div>
        </div>
        <div class="report-item-container clickable animated fadeIn mt-2">
          <div class="count-container" (click)="getPreferredDevices('removed')">
            <div class="count">{{removed}}</div>
            <div class="text">Removed&nbsp;
              <i class="fa fa-info-circle tooltip-event relative">
                <span class="tooltip-event-name">Shows the number of devices removed from the account.</span>
              </i>
            </div>
          </div>
          <div class="graph-container">
            <canvas id="barChartRemoved" height="100" role="img"></canvas>
          </div>
        </div>
        <div class="report-item-container clickable animated fadeIn mt-2">
          <div class="count-container" (click)="getPreferredDevices('total')">
            <div class="count">{{total}}</div>
            <div class="text">Total Devices&nbsp;
              <i class="fa fa-info-circle tooltip-event relative">
                <span class="tooltip-event-name">Shows the total number of devices added to the account.</span>
              </i>
            </div>
          </div>
          <div class="graph-container">
            <canvas id="lineChartTotal" height="100" role="img"></canvas>
          </div>
        </div>
      </div>
      <div class="col-md-7 col-12 p-2">
        <div class="report-item-container animated fadeIn height100">
          <h6 style="text-transform: capitalize;">Installed and removed</h6>
          <canvas id="stackedBarGraph" height="170" role="img"></canvas>
        </div>
      </div>
    </div>
    <div class="row no-gutters">
      <div class="col-12 p-2">
        <div class="report-item-container animated fadeIn">
          <app-reports-table
            [devicesList]="showDevices"
            [displayTitle]="text"
            [changeOffset]="resetOffset"
            [blockCall]="blockCall"
            (pageChangeEvent)="onPageChange($event)"
            (downloadEvent)="downloadFile($event)"
            >
          </app-reports-table>
        </div>
      </div>
    </div>
  </div>
</div>
