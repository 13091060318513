import { throwError as observableThrowError, Observable, Subject } from 'rxjs';

import { catchError, share } from 'rxjs/operators';
import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';

import { environment } from '@myenv/environment';

import { enMessage } from '@shared/en-us-message';
import { isArray } from 'util';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '@layouts/login/authentication.service';
declare var $: any;
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class SharedService {
  public apiUrl = environment.apiUrl;
  public ErrorMsg: Subject<any> = new Subject<any>();
  public SuccessMsg: Subject<any> = new Subject<any>();
  public WarningMsg: Subject<any> = new Subject<any>();
  public InfoMsg: Subject<any> = new Subject<any>();
  public CustomMsg: Subject<any> = new Subject<any>();
  public ServerErrorMsg: Subject<any> = new Subject<any>();
  // public gotPermissions: Subject<any> = new Subject<JSON>();
  private httpClient: HttpClient;
  private emitChangeNotify = new Subject<any>();
  changeEmittedNotify$ = this.emitChangeNotify.asObservable();
  responseMessage: any = {};
  unoHeader = environment.unoHeader;
  returnUrl: string;
  public activeSeeker = [];
  resolutionParam = ['.header', 'app-data-tab-header', 'footer'];
  private primaryConfig: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public primaryConfigData = this.primaryConfig.asObservable();

  constructor(

    private http: HttpClient,
    private handler: HttpBackend,
    private router: Router,
    private ngzone: NgZone,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute,
  ) {
    this.httpClient = new HttpClient(handler);
  }

  setResponseMessage(data) {
    this.emitChangeNotify.next(data);
  }

  getCurrentUser(): Observable<any> {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  updateUser(data): Observable<any> {
    return this.http.put(this.apiUrl + 'users/' + data.userId, data).pipe(
      catchError(this.handleError));
  }

  getVehicleHeader(): Observable<any> {
    return this.http.get(this.apiUrl + 'vehicles/header')
      .pipe(share()).pipe(
        catchError(this.handleError));
  }


  getAllEvVehicles(page): Observable<any> {
    return this.http.get(this.apiUrl + 'devices?sortingKey=lastCommunication&direction=desc&key=generation&value=EBIKE&isAssociatedDevice=false&page=0' + page).pipe(
      catchError(this.handleError));
  }

  getDurationBtwTwoEpoch(ts, te) {
    let delta = Math.abs(te - ts) / 1000;
    const days = Math.floor(delta / 86400);
    delta -= days * 86400;
    const hours = Math.floor(delta / 3600) % 24;
    delta -= hours * 3600;
    const minutes = Math.floor(delta / 60) % 60;
    delta -= minutes * 60;
    const durationInSecs = delta % 60;
    return [days, hours, minutes, durationInSecs];
  }

  // To get all Place
  getAllPlace(): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
      // 'CUSTOM_X_LCM': 'true'
    });
    return this.http.get(this.apiUrl + 'places', { headers }).pipe(
      catchError(this.handleError));
  }

  // To get all Alerts
  getAllAlerts(): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
      // 'CUSTOM_X_LCM': 'true'
    });
    return this.http.get(this.apiUrl + 'alerts/type', { headers }).pipe(
      catchError(this.handleError));
  }

  getUsers(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
      // 'CUSTOM_X_LCM': 'true'
    });
    return this.http.get(this.apiUrl + 'users', { headers }).pipe(
      catchError(this.handleError));
  }

  getAllCampaign(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
      // 'CUSTOM_X_LCM': 'true'
    });

    return this.http.get(this.apiUrl + 'campaign', { headers }).pipe(
      catchError(this.handleError));
  }

  getAllDevices(selectedGroup?): Observable<any> {
    let tempUrl = 'devices?sortingKey=lastCommunication&direction=desc';
    if (selectedGroup) {
      tempUrl += '&groups=' + selectedGroup;
    }
    tempUrl += `&attributes=deviceId,serialNumber,assetName,productSerialNumber,vin,lastCommunication,deviceStatus,architectureType,ecuMake,generation,simStatus,imei,firmwareVersion,configVersion,simCcid,lastKnownEvent,debugLogsUploadEnabled,lastKnownStatus`;
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
      // 'CUSTOM_X_LCM': 'true'
    });

    return this.http.get(this.apiUrl + tempUrl, { headers }).pipe(
      catchError(this.handleError));
  }

  getDashcam(deviceId, startTime, endTime): Observable<any> {
    return this.http.get(this.apiUrl + 'messages/device/' + deviceId + '?messageType=DashCam&page=0&attributes=latitude,longitude,eventTime,heading,vehicleSpeed,messageType,ingestionTimestamp,messageId,camSerialNumber,eventName,videoURL,camType&startTime=' + startTime + '&endTime=' + endTime + '&sort=ASC&limit=10')
      .pipe(catchError(this.handleError));
  }

  getAllActiveDevices(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
      // 'CUSTOM_X_LCM': 'true'
    });

    return this.http.get(this.apiUrl + 'devices?sortingKey=lastCommunication&direction=desc&active=true', { headers }).pipe(
      catchError(this.handleError));
  }

  getAllCustomer(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
    });

    return this.http.get(this.apiUrl + 'customers/user', { headers }).pipe(
      catchError(this.handleError));
  }

  getRoles(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
      // 'CUSTOM_X_LCM': 'true'
    });
    return this.http.get(this.apiUrl + 'roles', { headers }).pipe(
      catchError(this.handleError));
  }

  getGroups(): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
      // 'CUSTOM_X_LCM': 'true'
    });
    return this.http.get(this.apiUrl + 'groups/associated-vehicles', { headers }).pipe(
      catchError(this.handleError));
  }

  getVehiclesView(): Observable<any> {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
    });
    return this.httpClient.get(this.apiUrl + 'vehicles?sortingKey=lastCommunication&direction=desc', { headers }).pipe(
      catchError(this.handleError));
  }

  searchVehicles(searchValue): Observable<any> {
    return this.http.get(this.apiUrl + `messages/latest/search?limit=3000&searchKey=assetName&searchValue=${searchValue}`).pipe(
      catchError(this.handleError));
  }

  getAllVehicles(page): Observable<any> {
    return this.http.get(this.apiUrl + 'devices?sortingKey=lastCommunication&direction=desc&page=' + page).pipe(
      catchError(this.handleError));
  }

  getAllfavourites(type: number): Observable<any> {
    return this.http.get(this.apiUrl + `favourite?typeCode=${type}`).pipe(
      catchError(this.handleError));
  }

  getFavDetails(name): Observable<any> {
    return this.http.get(this.apiUrl + `favourite/${name}`).pipe(
      catchError(this.handleError));
  }

  deletefavourite(name): Observable<any> {
    return this.http.delete(this.apiUrl + 'favourite/' + name).pipe(
      catchError(this.handleError));
  }

  addfavourite(data) {
    return this.http.post(this.apiUrl + 'favourite', data).pipe(
      catchError(this.handleError));
  }

  updateFav(name, payload): Observable<any> {
    return this.http.put(this.apiUrl + `favourite/${name}`, payload).pipe(
      catchError(this.handleError));
  }

  getAllMessages(): Observable<any> {
    return this.http.get(this.apiUrl + 'messages/types').pipe(
      catchError(this.handleError));
  }

  onSearchVehicle(key, value, data?, group?, attributes?): Observable<any> {
    let tempUrl = `${this.apiUrl}devices?sortingKey=lastCommunication&direction=desc`;
    if (value !== '' && value) {
      tempUrl += `&key=${key}&value=${value}`;
    }
    if (data) {
      tempUrl += `&status=${data}`;
    }
    if (group) {
      tempUrl += `&groups=${group}`;
    }
    if (attributes) {
      tempUrl += `&attributes=${attributes}`;
    }
    return this.http.get(tempUrl).pipe(catchError(this.handleError));
  }

  onSearchDevice(key, value, page?, data?, group?, active?, attributes?, isAssociatedDevice?): Observable<any> {
    let tempUrl = `${this.apiUrl}devices?sortingKey=lastCommunication&direction=desc`;
    if (isAssociatedDevice == undefined) {
      isAssociatedDevice = false;
    }
    if (value !== '' && value) {
      tempUrl += `&key=${key}&value=${value}&isAssociatedDevice=${isAssociatedDevice}`;
    }
    if (data) {
      tempUrl += `&status=${data}`;
    }
    if (page || page == 0) {
      tempUrl += `&page=${page}`;
    }
    if (group) {
      tempUrl += `&groups=${group}`;
    }
    if (active) {
      tempUrl += `&active=${active}`;
    }
    if (attributes) {
      tempUrl += `&attributes=${attributes}`;
    }
    return this.http.get(tempUrl).pipe(catchError(this.handleError));
  }

  onBenchTestDeviceSearch(key, value): Observable<any> {
    return this.http.get(`${this.apiUrl}devices/bench/search?key=${key}&value=${value}`).pipe(
      catchError(this.handleError));
  }

  getCounts(): Observable<any> {
    return this.http.get(`${this.apiUrl}devices/bench/score`).pipe(
      catchError(this.handleError));
  }


  getDeviceHeader(): Observable<any> {
    return this.http.get(this.apiUrl + 'devices/header')
      .pipe(share()).pipe(
        catchError(this.handleError));
  }

  getVehicleEventDates(deviceId, startTime, endTime): Observable<any> {
    let offset = new Date().getTimezoneOffset() * 60 * 1000 * -1;
    return this.http.get(
      `${this.apiUrl}analytics/device/${deviceId}/messages/newIndicator` +
      `?startTime=${startTime}&endTime=${endTime}&offset=${offset}`
    )
      .pipe(catchError(this.handleError));
  }

  getDeviceEventDates(serialNumber, startTime, endTime): Observable<any> {
    return this.http.get(
      `${this.apiUrl}analytics/serialNumber/${serialNumber}/messages/indicator` +
      `?startTime=${startTime}&endTime=${endTime}`
    )
      .pipe(catchError(this.handleError));
  }

  setOBDHeight(getScreenWidth, getScreenHeight, isHavingEnoughSpace, params, pidViewTabPos) {
    let vh1 = 0;
    const timeSeeker = $('#tskr').height();
    this.resolutionParam.map((e) => {
      const height = $(e).height();
      if (height) { vh1 += height; }
      else if (e === 'app-data-tab-header') { vh1 += 96; }
      // if (e === '#tskr' && height) { vh1 += 38; }
    });
    const p = $('#tripView').height(); // trip view
    const q = $('#pidView').height(); // pid section
    const r = $('#messageView').height(); // message view
    vh1 = this.convertPxToVH(vh1);
    vh1 = vh1 + 1;
    const overallHeight = vh1;
    const accordionSection = this.convertPxToVH((p >= 0 ? (p + q) : (q + r)));
    const timeSeekerHeightInVh = this.convertPxToVH(timeSeeker);
    const equation = (100 - (vh1 + timeSeekerHeightInVh));
    // right side container
    const a = (getScreenHeight <= 650) ? (equation * 0.5) : (getScreenHeight > 650 && getScreenHeight <= 800) ? (equation * 0.45) : (getScreenHeight > 800 && getScreenHeight <= 950) ? (equation * 0.40) : (equation * 0.35);
    $('#gmap').height((equation - (a + 1)) + 'vh');
    $('#drawerContent').height((a - 2.5) + 'vh');
    $('#rightSctn').height((equation - 6) + 'vh');

    const leftSctnHeightInVh = 100 - (vh1 + 1);
    $('#leftsctn').height((leftSctnHeightInVh + 'vh'));
    const leftSection = $('#leftsctn').height();
    const firstActiveSection = $('.left-sec')[0].style.display === 'block' ? $($('.left-sec')[0]) : $($('.left-sec')[1]);
    const firstActiveContainer = firstActiveSection.find('#tripsContainer');
    const messageActiveContainer = firstActiveSection.find('#messageContainer');
    const col = $('#leftCol' + params);
    const dataViewSection = $($('.left-sec')[2]); // pids section
    const tripTable = $('#trips');
    const tripInfo = $('#trip_info').height();
    if (!isHavingEnoughSpace) {
      dataViewSection.height(q);
      if (params === 3) { // pid view
        // col.height(100 - (accordionSection + overallHeight + 2.5) + 'vh');
      } else {
        const height = (leftSctnHeightInVh - (this.convertPxToVH(tripInfo) + accordionSection + 2.7));
        // firstActiveSection.css('max-height', 'none');
        // col.css('max-height', (leftSctnHeightInVh - this.convertPxToVH(tripInfo + r)) + 'vh');
        // col.css('max-height', (height - this.convertPxToVH(p + tripInfo)) + 'vh');
        // firstActiveSection.css('max-height', (height + 'vh'));
        firstActiveContainer.css('max-height', height + 'vh');
        messageActiveContainer.css('max-height', height + 'vh');
      }
    } else if (isArray(params)) {
      console.info('Intervend Enough space');
      if (params[0] === 'trip_expand') {
        const table_header = $(firstActiveSection).find('datatable-header').height();
        const height = (leftSctnHeightInVh - (this.convertPxToVH(p)));
        firstActiveSection.css('height', 'auto');
        // firstActiveSection.css('max-height', '50%');
        // console.info(firstActiveSection.height() - (p + tripInfo));
        // $('#leftCol1').css('max-height', (firstActiveSection.height() - (p + tripInfo)));
        // col.css('max-height', (height - this.convertPxToVH(p + tripInfo)) + 'vh');
        // firstActiveSection.css('max-height', (leftSctnHeightInVh - (accordionSection + 2)) + 'vh');
        // $(firstActiveSection).find('datatable-body').css('max-height', firstActiveSection.height() - (p + trip_view + table_header + 12));
      } else if (params[0] === 'trip_collapse') {
        $(firstActiveSection).height(p);
      } else if (params[0] === 'msg_expand') {
        const msg_view = $('#msg_info').height();
        firstActiveSection.css('height', 'auto');
        // firstActiveSection.css('max-height', '50%');
        // $('#leftCol3').height(firstActiveSection.height() - (r + msg_view));
      } else if (params[0] === 'msg_collapse') {
        $($('.left-sec')[1]).height(r);
      }
      if (params[1] === 'data_expand') {
        const height = leftSection - (firstActiveSection.height());
        // $('#drawerContainer').height(height - q);
        dataViewSection.height(height);
      } else if (params[1] === 'data_collapse') {
        dataViewSection.height(q);
      }
    }
    // const col = $('#leftCol' + (view + 1));
    // if (getScreenHeight < slab) {
    // if (view === 1) { // pid view
    //   col.height(100 - (leftCol + overallHeight + 2.5) + 'vh');
    // } else {
    //   col.css('max-height', (100 - (leftCol + overallHeight + (view === 2 ? 0 : 2.5))) + 'vh');
    // }
    // } else {
    //   const dataViewSection = $($('.left-sec')[2]); // pids section
    //   if (view[0] === 'trip_expand') {
    //     const trip_view = $('#trip_info').height();
    //     const table_header = $(firstActiveSection).find('datatable-header').height();
    //     // firstActiveSection.css('max-height', (leftSctnHeightInVh/2) + 'vh');
    //     firstActiveSection.css('height', 'auto');
    //     firstActiveSection.css('max-height', '50%');
    //     $(firstActiveSection).find('datatable-body').css('max-height', firstActiveSection.height() - (p + trip_view + table_header + 12));
    //   } else if (view[0] === 'trip_collapse') {
    //     $(firstActiveSection).height(p);
    //   } else if (view[0] === 'msg_expand') {
    //     const msg_view = $('#msg_info').height();
    //     firstActiveSection.css('height', 'auto');
    //     firstActiveSection.css('max-height', '50%');
    //     $('#leftCol3').height(firstActiveSection.height() - (r + msg_view));
    //   } else if (view[0] === 'msg_collapse') {
    //     $($('.left-sec')[1]).height(r);
    //   }
    //   if (view[1] === 'data_expand') {
    //     const height = leftSection - (firstActiveSection.height());
    //     dataViewSection.height(height);
    //   } else if (view[1] === 'data_collapse') {
    //     dataViewSection.height(q);
    //   }
    // }

    if ((!isHavingEnoughSpace && params === 3) || isHavingEnoughSpace) {
      this.set_OBD_PID_Height(pidViewTabPos);
    }
  }

  set_OBD_PID_Height(pidViewTabPos) {
      const pidSection = $('#pidView').height(); // pid section
      const leftSection = $('#leftsctn').height();
      const firstActiveSection = $('.left-sec')[0].style.display === 'block' ? $($('.left-sec')[0]) : $($('.left-sec')[1]);
      const navHeight = $('.nav.nav-tabs.detailed-tabs').height();
      if (pidViewTabPos === 0) {
        const searchBarHeight = $('.input-group.input-group-lg.searchVeh.liveSearch').height();
        const tableHeader = $('#pidTable').find('datatable-header').height();
        const height = leftSection - (firstActiveSection.height() + pidSection + navHeight + searchBarHeight + (tableHeader ? tableHeader : 46) + 10);
        $('#pidTable > div > .datatable-body').css('height', height);
      } else if (pidViewTabPos === 1) {
        const height = leftSection - (firstActiveSection.height() + pidSection + navHeight);
        $('#gps').css('height', height);
      } else {
        const camRow = $('.cambtn').height();
        const height = leftSection - (firstActiveSection.height() + navHeight + camRow);
        $('#cam').height(height);
      }
  }

  convertPxToVH(pixels) {
    return pixels * (100 / document.documentElement.clientHeight);
  }

  // getDevicesView(): Observable<any> {
  //
  //   return this.http.get(this.apiUrl + 'devices')
  //     .pipe(share()).pipe(
  //     catchError(this.handleError));
  // }

  getModules(): Observable<any> {
    return this.http.get(this.apiUrl + 'modules')
      .pipe(share()).pipe(
        catchError(this.handleError));
  }

  getRoleAccessModule(roleId): Observable<any> {
    return this.http.get(this.apiUrl + 'roles/' + roleId)
      .pipe(share()).pipe(
        catchError(this.handleError));
  }

  getUserRoleId(tokenId): Observable<any> {
    return this.http.get(this.apiUrl + 'tokens/' + tokenId)
      .pipe(share()).pipe(
        catchError(this.handleError));
  }

  setAccessRole(accessRoleModules, permissionModules) {
    localStorage.setItem('accessRoleModules', JSON.stringify(accessRoleModules));
    localStorage.setItem('permissionModules', JSON.stringify(permissionModules));
    // localStorage.setItem('resPermissionModule', JSON.stringify(permissionModules));
  }

  getReports(): Observable<any> {
    return this.http.get(this.apiUrl + 'reports')
      .pipe(share()).pipe(
        catchError(this.handleError));
  }

  getALReports(): Observable<any> {
    return this.http.get(this.apiUrl + 'reports')
      .pipe(share()).pipe(
        catchError(this.handleError));
  }

  uploadProfilePicture(data, userId) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    /*return this.http.post(this.apiUrl + 'users/' + userId + '/profile/picture', data, { headers })
      .pipe(share()).pipe(
        catchError(this.handleError));*/
    return this.httpClient.post(this.apiUrl + 'users/' + userId + '/profile/picture', data, {headers})
      .pipe(catchError(this.handleError));
  }

  handleError(error: any | any) {
    return observableThrowError(error.error || error || 'Server error');
  }

  impersonate(data) {
    const _headers = new HttpHeaders();
    let token = this.getHeaders();
    const headers = _headers.append('Authorization', 'Bearer ' + token).append('Content-Type', 'application/json');
    return this.http.post(this.apiUrl + 'impersonate/user/' + data + '/login', data).pipe(
      catchError(this.handleError));
  }

  getHeaders() {
    return localStorage.getItem('token');
  }

  getActivity(id, page, limit, filter?) {
    let tempUrl = `activity/device/deviceId/${id}?page=${page}&limit=${limit}`;
    if (filter) tempUrl += `&filter=${filter}`;
    return this.http.get(this.apiUrl + tempUrl).pipe(catchError(this.handleError));
  }

  updatePreference(data) {
    let token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    return this.httpClient.put(this.apiUrl + 'users/preferences/update', data, { headers }).pipe(
      catchError(this.handleError));
  }

  // To get the vehicle count
  getVehicleCodeCount(deviceId, startTime, endTime) {
    return this.http.get(this.apiUrl + 'analytics/dtc/device/' + deviceId + '/count?startTime=' + startTime + '&endTime=' + endTime);
  }

  getErrorMsg(error, type?) {
    const errorCodes = [400, 403, 404, 500];
    if (error.headers) {
      error = error.error;
    }
    if (error.status == 401) {
      if (localStorage.getItem('token')) {
        let token = localStorage.getItem('token');
        localStorage.clear();
        sessionStorage.clear();
        this.responseMessage = { success: false, message: enMessage.error401 };
        this.setResponseMessage(this.responseMessage);
        this.authenticationService.logout(token).subscribe((res) => {
        });
      }
    }
    if (error.errorCode) {
      const errorCode = error.errorCode;
      const errorMessage = enMessage[errorCode];
      if (!errorMessage) {
        this.responseMessage = { success: false, message: enMessage.internalServerError };
        // this.setResponseMessage(this.responseMessage);
      } else {
        this.responseMessage = { success: false, message: errorMessage, type: type, error: error };
        this.setResponseMessage(this.responseMessage);
      }
    } else if (error.type && error.type === 'error') {
      // this.responseMessage = { success: 'noInternet', message: enMessage.noInternet };
      // this.setResponseMessage(this.responseMessage);
    } else {
      this.responseMessage = { success: false, message: enMessage.internalServerError };
      // this.setResponseMessage(this.responseMessage);
    }
  }

  setDefaultUrl(permissionModules) {
    let defaultUrl = '/';
    let returnModule = this.route.snapshot.queryParams['returnModule'];
    if (permissionModules.includes(returnModule)) {
      if (this.route.snapshot.queryParams['returnUrl'].includes('location-history')) {
        defaultUrl = '/live';
      } else if (this.route.snapshot.queryParams['returnUrl'].includes('devices')) {
        defaultUrl = '/devices';
      } else if (this.route.snapshot.queryParams['returnUrl'].includes('campaign-detail')) {
        defaultUrl = '/ota';
      } else {
        defaultUrl = this.route.snapshot.queryParams['returnUrl'];
      }
    } else {
      if (permissionModules.includes('SUPPORT')) {
        defaultUrl = '/support';
        localStorage.setItem('defaultUrl', defaultUrl);
      }
      // else if (this.unoHeader && permissionModules.includes('DASHBOARD')) {
      //   defaultUrl = '/dashboard/default';
      //   localStorage.setItem('defaultUrl', defaultUrl);
      // }
      else {
        if (isArray(permissionModules)) {
          // let defaultUrl;
          if (permissionModules.includes('AZUGA-DASHBOARD')) {
            defaultUrl = '/dashboard/azuga';
            localStorage.setItem('defaultUrl', defaultUrl);
          } else if (permissionModules.includes('LIVE')) {
            defaultUrl = '/live';
            localStorage.setItem('defaultUrl', defaultUrl);
          } else if (permissionModules.includes('DASHBOARD')) {
            defaultUrl = '/dashboard/default';
            localStorage.setItem('defaultUrl', defaultUrl);
          } else if (permissionModules.includes('TRIPS')) {
            defaultUrl = '/trips';
            localStorage.setItem('defaultUrl', defaultUrl);
          } else if (permissionModules.includes('DATA')) {
            defaultUrl = '/data';
            localStorage.setItem('defaultUrl', defaultUrl);
          } else if (permissionModules.includes('REPORT')) {
            defaultUrl = '/reports/system/live-status';
            localStorage.setItem('defaultUrl', defaultUrl);
          } else if (permissionModules.includes('SCORE')) {
            defaultUrl = '/score';
            localStorage.setItem('defaultUrl', defaultUrl);
          } else if (permissionModules.includes('OTA')) {
            defaultUrl = '/ota';
            localStorage.setItem('defaultUrl', defaultUrl);
          } else if (permissionModules.includes('BENCH-TEST')) {
            defaultUrl = '/bench-test';
            localStorage.setItem('defaultUrl', defaultUrl);
          } else if (permissionModules.includes('DESTINATION')) {
            defaultUrl = '/destinations';
            localStorage.setItem('defaultUrl', defaultUrl);
          } else if (permissionModules.includes('CUSTOMER')) {
            defaultUrl = '/customer';
            localStorage.setItem('defaultUrl', defaultUrl);
          } else if (permissionModules.includes('TOOLS')) {
            defaultUrl = '/tools';
            localStorage.setItem('defaultUrl', defaultUrl);
          } else if (permissionModules.includes('ORDER')) {
            defaultUrl = '/orders/pending';
            localStorage.setItem('defaultUrl', defaultUrl);
          } else if (permissionModules.includes('CUSTOMER')) {
            defaultUrl = '/customers';
            localStorage.setItem('defaultUrl', defaultUrl);
          } else if (permissionModules.includes('API')) {
            defaultUrl = '/developer';
            localStorage.setItem('defaultUrl', defaultUrl);
          } else if (permissionModules.includes('SUPPORT-OTA')) {
            defaultUrl = '/support-ota';
            localStorage.setItem('defaultUrl', defaultUrl);
          } else if (permissionModules.includes('SUPPORT')) {
            defaultUrl = '/support';
            localStorage.setItem('defaultUrl', defaultUrl);
          } else if (permissionModules.includes('CONFIGURATION')) {
            defaultUrl = '/configurations';
            localStorage.setItem('defaultUrl', defaultUrl);
          } else if (permissionModules.includes('ADMIN')) {
            if (permissionModules.includes('USER')) {
              defaultUrl = '/admin/users';
              localStorage.setItem('defaultUrl', defaultUrl);
            }
            // else if (permissionModules.includes('GROUP')) {
            //   defaultUrl = '/admin/groups';
            //   localStorage.setItem('defaultUrl', defaultUrl);
            // }
            else if (permissionModules.includes('ROLE')) {
              defaultUrl = '/admin/roles';
              localStorage.setItem('defaultUrl', defaultUrl);
            }
            // else if (permissionModules.includes('DEVICE')) {
            //   defaultUrl = '/admin/device';
            //   localStorage.setItem('defaultUrl', defaultUrl);
            // }
            else if (permissionModules.includes('ALERT')) {
              defaultUrl = '/admin/alerts';
              localStorage.setItem('defaultUrl', defaultUrl);
            } else if (permissionModules.includes('PLACE')) {
              defaultUrl = '/admin/place';
              localStorage.setItem('defaultUrl', defaultUrl);
            } else if (permissionModules.includes('SETTING')) {
              defaultUrl = '/admin/whitelabel';
              localStorage.setItem('defaultUrl', defaultUrl);
            } else if (permissionModules.includes('HEADER')) {
              defaultUrl = '/admin/headers';
              localStorage.setItem('defaultUrl', defaultUrl);
            } else if (permissionModules.includes('CACHE-ATTRIBUTES')) {
              defaultUrl = '/admin/cache-attributes';
              localStorage.setItem('defaultUrl', defaultUrl);
            } else if (permissionModules.includes('RULE')) {
              defaultUrl = '/admin/rules';
              localStorage.setItem('defaultUrl', defaultUrl);
            } else if (permissionModules.includes('AUDIT-TRIAL')) {
              defaultUrl = '/admin/audit-trial';
              localStorage.setItem('defaultUrl', defaultUrl);
            } else if (permissionModules.includes('DESTINATION')) {
              defaultUrl = '/admin/destination';
              localStorage.setItem('defaultUrl', defaultUrl);
            }
          } else {
            // let defaultUrl = '/';
            localStorage.setItem('defaultUrl', defaultUrl);
            this.logout();
          }
        }
      }
    }

    if (defaultUrl != undefined) {
      this.ngzone.run(() => {
        this.router.navigate([defaultUrl]);
      });
    }
  }

  /**
   * This will format the json input into reable format
   *
   * @param json : String
   * @returns
   */

  syntaxHighlight(jsonText) {
    let json = JSON.stringify(jsonText, undefined, 4);
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
      let cls = 'number';
      if (/^"/.test(match)) {
        if (/:$/.test(match)) {
          cls = 'key';
        } else {
          cls = 'string';
        }
      } else if (/true|false/.test(match)) {
        cls = 'boolean';
      } else if (/null/.test(match)) {
        cls = 'null';
      }
      return '<span class="' + cls + '">' + match + '</span>';
    });
  }

  logout() {
    let token = localStorage.getItem('token');
    localStorage.clear();
    sessionStorage.clear();
    if (token) {
      this.authenticationService.logout(token).subscribe((res) => {
      });
    }
    this.ngzone.run(() => {
      this.router.navigate(['/login']);
    });
  }

  //to get lastlocation of the device using this appi 
  getVehicleDetail(deviceId): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl + `messages/device/${deviceId}/snapped`, { headers }).pipe(
      catchError(this.handleError));
  }

  goToHistory(params, device?) {
    if (device) {
      localStorage.setItem('defaultselectvehicle', (device.length ? JSON.stringify(device[0]) : '{}'));
      // localStorage.setItem('defaultselectvehicle', JSON.stringify(value));
    }
    this.router.navigateByUrl('/*', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/trips'], params);
    });
  }

  public setdefaultselectvehicle(vehicleinfo) {
    // console.log("@@@@ set defaultselectvehicle",vehicleinfo);
    localStorage.setItem('defaultselectvehicle', JSON.stringify(vehicleinfo));
  }

  public getdefaultselectvehicle() {
    return JSON.parse(localStorage.getItem('defaultselectvehicle'));
  }

  // get title & subtitle
  getTitle() {
    const get = JSON.parse(localStorage.getItem('title'));
    return get;
  }

  setTitle(p1, p2) {
    localStorage.setItem('title', JSON.stringify({ title: p1, sub: p2 }));
  }

  public convertKPHtoMPH(value) {
    return Math.floor(value * 0.621371);
  }

  // To get the RecentdeviceData
  getRecentdeviceData(deviceId) {
    return this.http.get(this.apiUrl + 'trips/device/' + deviceId + '/recent').pipe(
      catchError(this.handleError));
  }
  getRecentdeviceDataAlternateAPI(deviceId) {
    return this.http.get(this.apiUrl + 'messages/device/' + deviceId + '/latest/occurrence').pipe(
      catchError(this.handleError));
  }
  dateToTimeAgo(p1: Date): string {
    const date = new Date(p1);
    const now = new Date(Date.now());
    const difftime = now.getTime() - date.getTime();
    const diffDate = new Date(difftime - 5.5 * 60 * 60 * 1000);
    const [sec, min, hr, day, month] = [
      diffDate.getSeconds(),
      diffDate.getMinutes(),
      diffDate.getHours(),
      diffDate.getDate() - 1,
      diffDate.getMonth(),
    ];
    const f = (property, end) => { return `${property} ${end}${property > 1 ? "s" : ""} ago`; };
    return month >= 1
      ? f(month, "month")
      : day >= 1
        ? f(day, "day")
        : hr >= 1
          ? f(hr, "hr")
          : min >= 1
            ? f(min, "min")
            : day >= 1
              ? f(sec, "sec")
              : "";
  }

  getSeekerData(deviceId, ts, te, offset): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    return this.http.get(this.apiUrl + `messages/messagefilter/device/${deviceId}?startTime=${ts}&endTime=${te}&seeker=60&timeFormat=24&offsetInSeconds=${offset}`, { headers }).pipe(
      catchError(this.handleError));
  }

  checkSeeker(val) {
    if (this.activeSeeker.includes(val.ts)) {
      const index = this.activeSeeker.indexOf(val.ts);
      this.activeSeeker.splice(index, 1);
      return true; // return true in consideration that the seeker has been deselected
    } else if (this.activeSeeker.length < 1 || this.activeSeeker.length === 1) {
      this.activeSeeker = [];
      this.activeSeeker.push(val.ts);
      return false; // return false in consideration that the seeker has been selected
    }
  }

  checkSeekerActive(val) {
    if (this.activeSeeker.includes(val)) {
      return true;
    }
    return false;
  }

  goToConfiguration(data: any) {
    this.router.navigateByUrl('/*', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/Configuration'], { state: { data: data } });
    });
  }

  updatePrimaryConfig(data: any) {
    console.log('Emitting new data in next():', data);  // For debugging
    this.primaryConfig.next(data);  // Emit new data
  }
}
