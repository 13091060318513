import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { } from 'googlemaps';
import { LiveService } from "@components/live/live.service";
import { environment } from '@myenv/environment';
import { ActivatedRoute } from '@angular/router';
import { SharedService } from "@shared/shared.service";

@Component({
  selector: 'app-live-tracking',
  templateUrl: './live-tracking.component.html',
  styleUrls: ['./live-tracking.component.scss']
})
export class LiveTrackingComponent implements OnInit, AfterViewInit {

  @ViewChild('sessionExpired', { static: false }) sessionExpired: ElementRef;
  @ViewChild('gmap', { static: false }) gmapElement: ElementRef;
  lat = environment.lat;
  lng = environment.lng;
  zoom = 4;
  currentIndex: Number;
  isSnazzyInfoWindowOpened = false;
  page = 0;
  isLoading = false;
  loadingIndicator = true;
  live: any;
  date = new Date();
  timeout = null;
  pageCount: any;

  // NGX Datatable Variables
  toggleEffect = false;
  play = false;
  timer = false;

  // Google Maps Variables
  map: google.maps.Map;
  markers = [];
  deviceDetail: any;
  tracking = false;
  trackedVehicle: any = {};
  subtractTime = 15;
  vehicleLocations: any = [];
  trackingTime = 10000;
  latestCoOrds = [];
  flightPath: any;
  trackingKey: any;
  trackingId: any;
  event: any = {};
  flightPlan = [];
  places: any = [];
  place = false;
  sessionExpiredPopup = false;
  // Groups Filter Variables
  groups: any = [];
  selectedVehicle: any = {};
  public cmImageUrl = environment.cmImageUrl;
  accountKey = "";
  randomNumber = Math.random();

  constructor(
    private liveService: LiveService,
    private route: ActivatedRoute,
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.trackingKey = this.route.snapshot.queryParams.key;
    this.getTrackingToken();
  }

  ngAfterViewInit() {
    this.mapInit();
  }

  getTrackingToken() {
    this.liveService.getTrackingToken(this.trackingKey).subscribe(async res => {
      this.deviceDetail = res;
      this.accountKey = res.accountKey;
      await this.keepTracking();
      const global = this;
      if(!this.sessionExpiredPopup) {
        this.trackingId = setInterval(() => {
          global.keepTracking();
        }, global.trackingTime);
      }
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }


  clearMarkers() {
    this.setMapOnAll(null);
  }

  setMapOnAll(map) {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(map);
    }
  }

  keepTracking() {
    const global = this;
    global.liveService.keepTrackingVehicle(this.deviceDetail).subscribe(async res => {
      if (res.deviceId && res.length != 0 ) {
        global.vehicleLocations = [...global.vehicleLocations, ...res];
        global.latestCoOrds[0] = global.vehicleLocations[global.vehicleLocations.length - 2];
        global.latestCoOrds[1] = global.vehicleLocations[global.vehicleLocations.length - 1];
        global.trackedVehicle.latitude = res.latitude;
        global.trackedVehicle.longitude = res.longitude;
        await global.plotVehicle(global.latestCoOrds);
        await global.clearMarkers();
        await global.addMarkerIcon(res);
        await global.addMarker(res);
      }
    }, error => {
      global.sessionExpiredPopup = true;
      global.sessionExpired.nativeElement.click();
      this.sharedService.getErrorMsg(error);
      if(this.sessionExpiredPopup) {
        clearInterval(this.trackingId);
      }
    });
  }

  addMarkerIcon(data) {
    let iconName = '';
    if (data.messageType) {
      if (data.messageType === 'COMMUNICATION_LOST') {
        iconName = '../../../assets/img/direction/warning/';
      } else if (data.messageType === 'TRIP_END' || data.messageType === 'IGNITION_OFF') {
        iconName = '../../../assets/img/direction/red/';
      } else if (!data.endPacket && data.messageType !== 'TRIP_END' && data.messageType !== 'IGNITION_OFF') {
        iconName = '../../../assets/img/direction/green/';
      } else {
        iconName = '../../../assets/img/direction/gray/';
      }
    } else {
      iconName = '../../../../assets/img/direction/default/';
    }
    this.setIcon(data, iconName);
  }

  setIcon(data, icon) {
    const heading = data.heading;
    const publishedBy = data.publishedBy;
    let iconName = `${icon}stop.png`;
    if (heading || heading == '0') {
      if (heading > 0 && heading <= 30) {
        iconName = `${icon}30.png`;
      } else if (heading > 30 && heading <= 60) {
        iconName = `${icon}60.png`;
      } else if (heading > 60 && heading <= 90) {
        iconName = `${icon}90.png`;
      } else if (heading > 90 && heading <= 120) {
        iconName = `${icon}120.png`;
      } else if (heading > 120 && heading <= 150) {
        iconName = `${icon}150.png`;
      } else if (heading > 150 && heading <= 180) {
        iconName = `${icon}180.png`;
      } else if (heading > 180 && heading <= 210) {
        iconName = `${icon}210.png`;
      } else if (heading > 210 && heading <= 240) {
        iconName = `${icon}240.png`;
      } else if (heading > 240 && heading <= 270) {
        iconName = `${icon}270.png`;
      } else if (heading > 270 && heading <= 300) {
        iconName = `${icon}300.png`;
      } else if (heading > 300 && heading <= 330) {
        iconName = `${icon}330.png`;
      } else if (heading > 330 && heading <= 360 || heading == '0') {
        iconName = `${icon}0.png`;
      }
    }
    if (publishedBy && publishedBy === 'RSU Simulation') {
      iconName = '../../assets/img/direction/v2x.png';
    } else if (publishedBy && publishedBy === 'OBU Simulation') {
      iconName = '../../assets/img/marker_red.png';
    } else if (publishedBy && publishedBy === 'EBIKE Simulation') {
      iconName = '../../assets/img/direction/ebike.png';
    }
    const iconUrl = {
      url: iconName,
      labelOrigin: { x: 50, y: -15 }
    };
    data.iconUrl = iconUrl;
    return data;
  }

  addMarker(data) {
    if (this.tracking) {
      data.assetName = '';
      var image = {
        url: data.iconUrl.url,
        size: new google.maps.Size(71, 71),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(14, 13),
      };
    }
    var latlng = new google.maps.LatLng(data.latitude, data.longitude);
    var marker = new google.maps.Marker({
      position: latlng,
      map: this.map,
      label: data.assetName,
      icon: image
    });
    marker['eventTime'] = data.eventTime;
    marker['deviceId'] = data.deviceId;
    var bounds = new google.maps.LatLngBounds();
    bounds.extend(latlng);
    this.map.fitBounds(bounds);
    this.markers.push(marker);
  }

  async resetTracking() {
    await clearInterval(this.trackingId);
    this.tracking = false;
    // this.getVehicleInfoWindowView(this.trackedVehicle);
    await this.removePolyline();
    await this.clearMarkers();
    this.trackedVehicle = {};
    this.vehicleLocations = [];
    this.latestCoOrds = [];
    // this.addCluster(this.liveVehicles);
  }

  plotVehicle(data) {
    const flightPlanCoordinates = [];
    const bounds = new google.maps.LatLngBounds();

    data.forEach(item => {
      const latLngObj = {};
      if (item !== undefined && item.latitude !== undefined && item.longitude !== undefined) {
        latLngObj['lat'] = item.latitude;
        latLngObj['lng'] = item.longitude;
        flightPlanCoordinates.push(latLngObj);
        // to extends the bounds
        bounds.extend(new google.maps.LatLng(item.latitude, item.longitude));
      }
    });

    this.flightPath = new google.maps.Polyline({
      path: flightPlanCoordinates,
      geodesic: true,
      strokeColor: '#891d56',
      strokeOpacity: 0.8,
      strokeWeight: 3
    });

    this.flightPath.setMap(this.map);
    this.flightPlan.push(this.flightPath);
  }

  removePolyline() {
    this.flightPlan.map(line => {
      line.setMap(null);
    });
  }

  // END OF VEHICLE TRACKING

  // GOOGLE MAPS START
  mapInit() {
    var mapOptionObject = {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoom: 4,
      minZoom: 4,
      maxZoom: 17,
      mapTypeControl: true,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.TOP_RIGHT
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
      },
      scaleControl: true,
      streetViewControl: true,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
      },
      fullscreenControl: false,
      center: {
        lat: environment.lat,
        lng: environment.lng
      },
      clickableIcons: false
    };
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapOptionObject);
  }

}
