import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import Chart from 'chart.js';
import { GraphicReportsService } from '@components/graphic-reports/graphic-reports.service';
import { SharedService } from '@myproject/shared/shared.service';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import { Title } from '@angular/platform-browser';
import { environment } from '@myenv/environment';
var ProvisioningReportComponent = /** @class */ (function () {
    function ProvisioningReportComponent(reportsService, title, sharedService) {
        this.reportsService = reportsService;
        this.title = title;
        this.sharedService = sharedService;
        this.page = 0;
        this.isLoading = false;
        this.total = 0;
        this.installed = 0;
        this.removed = 0;
        this.installedDevices = [];
        this.removedDevices = [];
        this.totalDevices = [];
        this.showDevices = [];
        this.text = '';
        this.resetOffset = false;
        this.hideCalendar = false;
        this.blockCall = false;
        this.highestCount = 0;
    }
    ProvisioningReportComponent.prototype.ngOnInit = function () {
        this.title.setTitle('Provisioning' + environment.title_text);
        this.getTotalDevicesCount();
    };
    ProvisioningReportComponent.prototype.onTimeChange = function (e) {
        // this.total = 0;
        this.installed = 0;
        this.removed = 0;
        this.page = 0;
        this.highestCount = 0;
        this.startTime = e.startTime;
        this.endTime = e.endTime;
        this.resetOffset = !this.resetOffset;
        this.getProvisioningData(e.startTime, e.endTime);
    };
    ProvisioningReportComponent.prototype.refresh = function () {
        this.installed = 0;
        this.removed = 0;
        this.page = 0;
        this.highestCount = 0;
        this.resetOffset = !this.resetOffset;
        this.getProvisioningData(this.startTime, this.endTime);
    };
    ProvisioningReportComponent.prototype.getTotalDevicesCount = function () {
        var _this = this;
        this.reportsService.getTotalDevicesCount().subscribe({
            next: function (res) {
                if (res.count) {
                    _this.total = res.count;
                }
                else {
                    _this.total = 0;
                }
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    ProvisioningReportComponent.prototype.getProvisioningData = function (startTime, endTime) {
        var _this = this;
        this.isLoading = true;
        this.reportsService.getProvisioningData(startTime, endTime).subscribe({
            next: function (res) {
                if (res.length) {
                    var labels_1 = [];
                    var total_1 = [];
                    var installed_1 = [];
                    var removed_1 = [];
                    // this.total = res[0].total;
                    res.reverse();
                    res.map(function (item) {
                        var datePipe = new DatePipe('en-US');
                        var eventDate = datePipe.transform(item.day, 'dd MMM');
                        labels_1.push(eventDate);
                        total_1.push(item.total);
                        installed_1.push(item.added);
                        removed_1.push(item.removed);
                        _this.installed += item.added;
                        _this.removed += item.removed;
                        if (item.added > _this.highestCount) {
                            _this.highestCount = item.added;
                        }
                        if (item.removed > _this.highestCount) {
                            _this.highestCount = item.removed;
                        }
                    });
                    if (_this.installed !== 0) {
                        _this.getInstalledDevices(startTime, endTime, _this.page);
                    }
                    else {
                        _this.installedDevices = [];
                        _this.showDevices = [];
                        _this.text = 'Installed Devices';
                    }
                    _this.drawLineGraph(labels_1, total_1);
                    _this.drawBarGraph(labels_1, installed_1, 'barChartInstalled', 'rgba(54, 162, 235)', 'Installed');
                    _this.drawBarGraph(labels_1, removed_1, 'barChartRemoved', 'rgba(255, 99, 132)', 'Removed');
                    _this.drawStackedBarGraph(labels_1, installed_1, removed_1);
                }
                else {
                    // this.total = 0;
                    _this.installed = 0;
                    _this.removed = 0;
                    _this.installedDevices = [];
                    _this.removedDevices = [];
                    _this.totalDevices = [];
                    _this.showDevices = [];
                    _this.text = 'Installed Devices';
                    _this.drawLineGraph([], []);
                    _this.drawBarGraph([], [], 'barChartInstalled', 'rgba(54, 162, 235)', 'Installed');
                    _this.drawBarGraph([], [], 'barChartRemoved', 'rgba(255, 99, 132)', 'Removed');
                    _this.drawStackedBarGraph([], [], []);
                }
                _this.isLoading = false;
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    ProvisioningReportComponent.prototype.getInstalledDevices = function (startTime, endTime, page) {
        var _this = this;
        this.reportsService.getInstalledDevices(startTime, endTime, page).subscribe({
            next: function (res) {
                if (!res.length) {
                    _this.blockCall = true;
                }
                if (page === 0) {
                    _this.installedDevices = res;
                }
                else {
                    _this.installedDevices = tslib_1.__spread(_this.installedDevices, res);
                }
                _this.text = 'Installed Devices';
                _this.showDevices = _this.installedDevices;
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    ProvisioningReportComponent.prototype.getRemovedDevices = function (startTime, endTime, page) {
        var _this = this;
        this.reportsService.getRemovedDevices(startTime, endTime, page).subscribe({
            next: function (res) {
                if (!res.length) {
                    _this.blockCall = true;
                }
                if (page === 0) {
                    _this.removedDevices = res;
                }
                else {
                    _this.removedDevices = tslib_1.__spread(_this.removedDevices, res);
                }
                _this.text = 'Removed Devices';
                _this.showDevices = _this.removedDevices;
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    ProvisioningReportComponent.prototype.getTotalDevices = function (startTime, endTime, page) {
        var _this = this;
        this.reportsService.getTotalDevices(startTime, endTime, page).subscribe({
            next: function (res) {
                if (!res.length) {
                    _this.blockCall = true;
                }
                if (page === 0) {
                    _this.totalDevices = res;
                }
                else {
                    _this.totalDevices = tslib_1.__spread(_this.totalDevices, res);
                }
                _this.text = 'Total Devices';
                _this.showDevices = _this.totalDevices;
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    ProvisioningReportComponent.prototype.downloadInstalledDevices = function (attributes) {
        var _this = this;
        this.reportsService.downloadInstalledDevices(this.startTime, this.endTime, attributes).subscribe({
            next: function (res) {
                _this.saveFile(res);
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    ProvisioningReportComponent.prototype.downloadRemovedDevices = function (attributes) {
        var _this = this;
        this.reportsService.downloadRemovedDevices(this.startTime, this.endTime, attributes).subscribe({
            next: function (res) {
                _this.saveFile(res);
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    ProvisioningReportComponent.prototype.downloadTotalDevices = function (attributes) {
        var _this = this;
        this.reportsService.downloadTotalDevices(this.startTime, this.endTime, attributes).subscribe({
            next: function (res) {
                _this.saveFile(res);
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    ProvisioningReportComponent.prototype.getPreferredDevices = function (e) {
        this.page = 0;
        this.resetOffset = !this.resetOffset;
        this.blockCall = false;
        if (e === 'installed') {
            if (this.installed !== 0) {
                this.getInstalledDevices(this.startTime, this.endTime, this.page);
            }
        }
        else if (e === 'removed') {
            if (this.removed !== 0) {
                this.getRemovedDevices(this.startTime, this.endTime, this.page);
            }
        }
        else if (e === 'total') {
            if (this.total !== 0) {
                this.getTotalDevices(this.startTime, this.endTime, this.page);
            }
        }
    };
    ProvisioningReportComponent.prototype.downloadFile = function (attributes) {
        if (this.text === 'Installed Devices') {
            this.downloadInstalledDevices(attributes);
        }
        else if (this.text === 'Removed Devices') {
            this.downloadRemovedDevices(attributes);
        }
        else if (this.text === 'Total Devices') {
            this.downloadTotalDevices(attributes);
        }
    };
    ProvisioningReportComponent.prototype.onPageChange = function (page) {
        this.page = page;
        if (this.text === 'Installed Devices') {
            this.getInstalledDevices(this.startTime, this.endTime, page);
        }
        else if (this.text === 'Removed Devices') {
            this.getRemovedDevices(this.startTime, this.endTime, page);
        }
        else if (this.text === 'Total Devices') {
            this.getTotalDevices(this.startTime, this.endTime, page);
        }
    };
    ProvisioningReportComponent.prototype.saveFile = function (res) {
        var contentDisposition = res.headers.get('Content-Disposition');
        var filename;
        if (contentDisposition == null) {
            var date = new Date();
            filename = 'Device_' + date + '.zip';
        }
        else {
            filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        }
        var blob = new Blob([res.body], {
            type: 'application/zip'
        });
        FileSaver.saveAs(blob, filename);
    };
    ProvisioningReportComponent.prototype.drawLineGraph = function (labels, data) {
        if (this.lineChart) {
            this.lineChart.destroy();
        }
        var ctx = document.getElementById('lineChartTotal');
        this.lineChart = new Chart(ctx, {
            type: 'line',
            responsive: true,
            data: {
                labels: labels,
                datasets: [{
                        label: 'Total',
                        data: data,
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        fill: true,
                        borderWidth: 2,
                        pointRadius: 1
                    }
                ]
            },
            options: {
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                            display: false,
                            ticks: {
                                beginAtZero: true
                            }
                        }],
                    yAxes: [{
                            display: false,
                        }]
                }
            }
        });
    };
    ProvisioningReportComponent.prototype.drawBarGraph = function (labels, data, container, color, chartLabel) {
        if (container === 'barChartInstalled' && this.barChart1) {
            this.barChart1.destroy();
        }
        else if (container === 'barChartRemoved' && this.barChart2) {
            this.barChart2.destroy();
        }
        var ctx = document.getElementById(container);
        var myChart = new Chart(ctx, {
            type: 'bar',
            responsive: true,
            data: {
                labels: labels,
                datasets: [{
                        label: chartLabel,
                        data: data,
                        backgroundColor: color
                    }]
            },
            options: {
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                            display: false,
                            ticks: {
                                beginAtZero: true
                            }
                        }],
                    yAxes: [{
                            display: false,
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                }
            }
        });
        if (container === 'barChartInstalled') {
            this.barChart1 = myChart;
        }
        else {
            this.barChart2 = myChart;
        }
    };
    ProvisioningReportComponent.prototype.drawStackedBarGraph = function (labels, data1, data2) {
        if (this.stackedChart) {
            this.stackedChart.destroy();
        }
        var data = [{
                label: 'Installed',
                data: data1,
                backgroundColor: 'rgba(54, 162, 235)'
            }, {
                label: 'Removed',
                data: data2,
                backgroundColor: 'rgba(255, 99, 132)'
            }];
        var ctx = document.getElementById('stackedBarGraph');
        this.stackedChart = new Chart(ctx, {
            type: 'bar',
            responsive: true,
            data: {
                labels: labels,
                datasets: data
            },
            options: {
                legend: {
                    position: 'bottom'
                },
                scales: {
                    xAxes: [{
                            display: true,
                            stacked: true
                        }],
                    yAxes: [{
                            display: true,
                            stacked: true,
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 10,
                                stepSize: this.highestCount > 9 ? null : 1
                            }
                        }]
                }
            }
        });
    };
    return ProvisioningReportComponent;
}());
export { ProvisioningReportComponent };
