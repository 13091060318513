import { PermissionGuard } from './../../perimission.guard';
import { Routes } from '@angular/router';
import { ProvisioningReportComponent } from './device-reports/provisioning-report/provisioning-report.component';
import { VersionDrilldownReportComponent } from './device-reports/version-drilldown-report/version-drilldown-report.component';
import { NotCommunicatingReportComponent } from './device-reports/not-communicating-report/not-communicating-report.component';
import { MessageReportComponent } from '@components/graphic-reports/message-report/message-report.component';
import { CustomReportComponent } from '@components/graphic-reports/custom-report/custom-report.component';
import { MetricsReportComponent } from './vehicle-reports/metrics-report/metrics-report.component';
import { LogInOutReportComponent } from './user-reports/log-in-out-report/log-in-out-report.component';
import { TripSummaryReportComponent } from './vehicle-reports/trip-summary-report/trip-summary-report.component';
import { ProcessingTimeReportComponent } from './system-reports/processing-time-report/processing-time-report.component';
import { DeviceViewReportComponent } from '@components/graphic-reports/device-reports/device-view-report/device-view-report.component';
import { VehicleViewReportComponent } from '@components/graphic-reports/vehicle-reports/vehicle-view-report/vehicle-view-report.component';
import { CommunicatingReportComponent } from '@components/graphic-reports/device-reports/communicating-report/communicating-report.component';
import { LiveStatusReportComponent } from './system-reports/live-status-report/live-status-report.component';
import { MultiVehicleDownloadReportComponent } from '@components/graphic-reports/multi-vehicle-download-report/multi-vehicle-download-report.component';
import { AlPlantModeReportComponent } from '@components/graphic-reports/al-plant-mode-report/al-plant-mode-report.component';
import { CommunicatingCalendarReportComponent } from './system-reports/communicating-calendar-report/communicating-calendar-report.component';
import { CTriggerReportComponent } from './c-trigger-report/c-trigger-report.component';
import { STriggerReportComponent } from './s-trigger-report/s-trigger-report.component';
import { environment } from '@myenv/environment';
import { AlReportGuard } from '@myproject/al-report.guard';
// import { ProvisionBillingComponent } from './billing-reports/provision-billing/provision-billing.component';
// import { GeneralComponent } from './general/general.component';
import { DeepViewComponent } from './deep-view/deep-view.component';
import { PacketHistoryReportComponent } from './packet-history-report/packet-history-report.component';
import { UsageComponent } from './usage/usage.component';
import { BillingReportComponent } from './system-reports/billing-report/billing-report.component';
import { DiscoveryReportComponent } from './system-reports/discovery-report/discovery-report.component';
import { DiagnosticsReportComponent } from './system-reports/diagnostics-report/diagnostics-report.component';
import { DisconnectedReportComponent } from './device-reports/disconnected-report/disconnected-report.component';
var AL_SystemReport = environment.AL_SystemReport;
var ɵ0 = { moduleName: 'REPORT' }, ɵ1 = { moduleName: 'REPORT' }, ɵ2 = { moduleName: 'REPORT' }, ɵ3 = { moduleName: 'REPORT' }, ɵ4 = { moduleName: 'DEEP-VIEW' }, ɵ5 = { moduleName: 'REPORT' }, ɵ6 = { moduleName: 'REPORT' }, ɵ7 = { moduleName: 'REPORT' }, ɵ8 = { moduleName: 'REPORT' }, ɵ9 = { moduleName: 'REPORT' }, ɵ10 = { moduleName: 'REPORT' }, ɵ11 = { moduleName: 'REPORT' }, ɵ12 = { moduleName: 'REPORT' }, ɵ13 = { moduleName: 'REPORT' }, ɵ14 = { moduleName: 'REPORT' }, ɵ15 = { moduleName: 'REPORT' }, ɵ16 = { moduleName: 'REPORT' }, ɵ17 = { moduleName: 'REPORT' }, ɵ18 = { moduleName: 'REPORT' }, ɵ19 = { moduleName: 'REPORT' }, ɵ20 = { moduleName: 'REPORT' }, ɵ21 = { moduleName: 'REPORT' }, ɵ22 = { moduleName: 'REPORT' }, ɵ23 = { moduleName: "REPORT" }, ɵ24 = { moduleName: 'REPORT' }, ɵ25 = { moduleName: 'REPORT' }, ɵ26 = { moduleName: 'REPORT' };
var routes = [
    {
        path: 'system/not-communicating', component: NotCommunicatingReportComponent,
        data: ɵ0,
        canActivate: [PermissionGuard]
    },
    {
        path: 'system/communicating', component: CommunicatingReportComponent,
        data: ɵ1,
        canActivate: [PermissionGuard]
    },
    {
        path: 'system/provisioning', component: ProvisioningReportComponent,
        data: ɵ2,
        canActivate: [PermissionGuard]
    },
    {
        path: '', component: MetricsReportComponent,
        data: ɵ3,
        canActivate: [PermissionGuard]
    },
    // {
    //   path: 'general', component: GeneralComponent,
    //   data: { moduleName: 'REPORT' },
    //   canActivate: [PermissionGuard]
    // },
    {
        path: 'deep-view', component: DeepViewComponent,
        data: ɵ4,
        canActivate: [PermissionGuard]
    },
    // {
    //   path: 'score', component: ScoreComponent,
    //   data: { moduleName: 'SCORE' },
    //   canActivate: [PermissionGuard]
    // },
    // {
    //   path: 'system/not-communicating', component: NotCommunicatingReportComponent,
    //   data: { moduleName: 'REPORT' },
    //   canActivate: [PermissionGuard]
    // },
    // {
    //   path: 'system/communicating', component: CommunicatingReportComponent,
    //   data: { moduleName: 'REPORT' },
    //   canActivate: [PermissionGuard]
    // },
    // {
    //   path: 'system/provisioning', component: ProvisioningReportComponent,
    //   data: { moduleName: 'REPORT' },
    //   canActivate: [PermissionGuard]
    // },
    {
        path: 'system/version-drilldown', component: VersionDrilldownReportComponent,
        data: ɵ5,
        canActivate: [PermissionGuard]
    },
    {
        path: 'device/device-report', component: DeviceViewReportComponent,
        data: ɵ6,
        canActivate: [PermissionGuard]
    },
    {
        path: 'device/message-count', component: MetricsReportComponent,
        data: ɵ7,
        canActivate: [PermissionGuard]
    },
    {
        path: 'device/vehicle-report', component: VehicleViewReportComponent,
        data: ɵ8,
        canActivate: [PermissionGuard]
    },
    {
        path: 'device/packet-history', component: PacketHistoryReportComponent,
        data: ɵ9,
        canActivate: [PermissionGuard]
    },
    {
        path: 'device/trip-summary', component: TripSummaryReportComponent,
        data: ɵ10,
        canActivate: [PermissionGuard]
    },
    // {
    //   path: 'device/vehicle-report', component: VehicleViewReportComponent,
    //   data: { moduleName: 'REPORT' },
    //   canActivate: [PermissionGuard]
    // },
    {
        path: 'device/trip-summary', component: TripSummaryReportComponent,
        data: ɵ11,
        canActivate: [PermissionGuard]
    },
    {
        path: 'device/messages',
        component: MessageReportComponent,
        data: ɵ12,
        canActivate: [PermissionGuard]
    },
    // {
    //   path: 'device/visualization',
    //   component: VisualizationReportComponent,
    //   data: { moduleName: 'REPORT' },
    //   canActivate: [PermissionGuard]
    // },
    {
        path: 'device/custom',
        component: CustomReportComponent,
        data: ɵ13,
        canActivate: [PermissionGuard]
    },
    {
        path: 'device/multiVehicleDownload',
        component: MultiVehicleDownloadReportComponent,
        data: ɵ14,
        canActivate: [PermissionGuard]
    },
    {
        path: 'device/alPlantMode',
        component: AlPlantModeReportComponent,
        data: ɵ15,
        canActivate: [PermissionGuard]
    },
    {
        path: 'user/in-out-activity', component: LogInOutReportComponent,
        data: ɵ16,
        canActivate: [PermissionGuard]
    },
    // {
    //   path: 'system/message-published', component: DestinationReportComponent,
    //   data: { moduleName: 'REPORT' },
    //   canActivate: [PermissionGuard]
    // },
    {
        path: 'system/processing-time', component: ProcessingTimeReportComponent,
        data: ɵ17,
        canActivate: [PermissionGuard]
    },
    {
        path: 'system/live-status', component: LiveStatusReportComponent,
        data: ɵ18,
        canActivate: [PermissionGuard]
    },
    {
        path: 'system/billing-report', component: BillingReportComponent,
        data: ɵ19,
        canActivate: [PermissionGuard]
    },
    // {
    //   path: 'system/usage', component: UsageReportComponent,
    //   data: { moduleName: 'REPORT' },
    //   canActivate: [PermissionGuard]
    // },
    {
        path: 'system/device-timeline', component: CommunicatingCalendarReportComponent,
        data: ɵ20,
        canActivate: [PermissionGuard]
    },
    {
        path: 'system/c-trigger-report', component: CTriggerReportComponent,
        data: ɵ21,
        canActivate: [PermissionGuard, AlReportGuard]
    },
    {
        path: 'system/s-trigger-report', component: STriggerReportComponent,
        data: ɵ22,
        canActivate: [PermissionGuard, AlReportGuard]
    },
    {
        path: "system/usage",
        component: UsageComponent,
        data: ɵ23,
    },
    // {
    //   path: 'billing/provision-billing', component: ProvisionBillingComponent,
    //   data: { moduleName: 'REPORT' },
    //   canActivate: [PermissionGuard]
    // },
    {
        path: 'system/discovery', component: DiscoveryReportComponent,
        data: ɵ24,
        canActivate: [PermissionGuard]
    },
    {
        path: 'system/diagnostics', component: DiagnosticsReportComponent,
        data: ɵ25,
        canActivate: [PermissionGuard]
    },
    {
        path: 'system/disconnected', component: DisconnectedReportComponent,
        data: ɵ26,
        canActivate: [PermissionGuard]
    },
];
var GraphicReportsRoutingModule = /** @class */ (function () {
    function GraphicReportsRoutingModule() {
    }
    return GraphicReportsRoutingModule;
}());
export { GraphicReportsRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26 };
