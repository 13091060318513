import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@myenv/environment';
import { throwError as observableThrowError, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GraphicReportsService {
  public apiUrl = environment.apiUrl;
  d = new Date();
  offsetInMinutes = this.d.getTimezoneOffset() * -1;
  timeZone = `&dateFormat=${environment.isoDateTimeFormat}&offsetInMinutes=${this.offsetInMinutes}`;

  constructor(
    private http: HttpClient
  ) { }

  // To get single report
  getSingleReportByReportId(reportId, deviceId, startTime, endTime, page): Observable<any> {
    return this.http.get(
      this.apiUrl + 'reports/' + reportId + '/generate?startTime=' + startTime + '&endTime=' + endTime +
      '&page=' + page + '&deviceId=' + deviceId
    ).pipe(catchError(this.handleError));
  }

  // To get single report
  getDeviceView(reportId, startTime, endTime, page, serialNumber?): Observable<any> {
    let tempURL = this.apiUrl + `reports/vin-change/${reportId}/generate?startTime=${startTime}&endTime=${endTime}&page=${page}`;
    if (serialNumber) {
      tempURL += '&serialNumber=' + serialNumber;
    }
    return this.http.get(tempURL).pipe(catchError(this.handleError));
  }

  // To get Corelation chart data
  getCorelationChartData(reportId, deviceId, startTime, endTime, data): Observable<any> {
    const httpOptions = {
      'responseType': 'arraybuffer' as 'json'
    };
    return this.http.post(
      this.apiUrl + `reports/${reportId}/dataScience/download` +
      `?startTime=${startTime}&endTime=${endTime}&deviceId=${deviceId}`, data,
      { responseType: 'blob' }).pipe(catchError(this.handleError));
  }

  // To get chart data
  getChartData(reportId, deviceId, startTime, endTime, page, limit, data): Observable<any> {
    return this.http.get(
      this.apiUrl + `messages/device/${deviceId}` +
      `?startTime=${startTime}&endTime=${endTime}&page=${page}&limit=${limit}&attributes=${data},eventTime`
    ).pipe(catchError(this.handleError));
  }

  // To download reports as CSV file
  downloadReportCSV(reportId, deviceId, startTime, endTime): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.http.post(
      this.apiUrl + 'reports/' + reportId + '/download/token?startTime=' + startTime + '&endTime=' + endTime + '&deviceId=' + deviceId,
      { headers }).pipe(catchError(this.handleError));
  }

  downloadVPacketReportCSV(reportId): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.http.post(
      this.apiUrl + 'reports/' + reportId + '/vpacket/download/token',
      { headers }).pipe(catchError(this.handleError));
  }

  downloadalPlantModeReport(reportId): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.http.post(this.apiUrl + `reports/${reportId}/alPlantMode/download/token`, { headers }).pipe(
      catchError(this.handleError));
  }

  // To download reports as CSV file
  multiDownloadVehicle(reportId, deviceId, startTime, endTime): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    return this.http.post(
      this.apiUrl + 'reports/' + reportId + '/download/token?startTime=' + startTime + '&endTime=' + endTime + '&deviceId=' + deviceId,
      { headers }).pipe(catchError(this.handleError));
  }

  // To download reports as CSV file
  downloadDeviceReportCSV(reportId, startTime, endTime, serialNumber?): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token
    });
    let tempURL = this.apiUrl + 'reports/vin-change/' + reportId + '/download/token?startTime=' + startTime + '&endTime=' + endTime;
    if (serialNumber) {
      tempURL += '&serialNumber=' + serialNumber;
    }
    return this.http.post(tempURL, headers).pipe(
      catchError(this.handleError));
  }
  // get attribute API for vehicle
  getAttribute(data): Observable<any> {
    return this.http.get(this.apiUrl + 'reports/attribute/' + data).pipe(
      catchError(this.handleError));
  }

  // to get total message count
  getMessageCount(reportId, deviceId, startTime, endTime): Observable<any> {
    return this.http.get(
      this.apiUrl + 'reports/' + reportId + '/count?startTime=' + startTime + '&endTime=' + endTime + '&deviceId=' + deviceId
    ).pipe(catchError(this.handleError));
  }

  // to get total message count
  getDeviceMeessageCount(reportId, startTime, endTime, serialNumber?): Observable<any> {
    let tempURL = this.apiUrl + 'reports/vin-change/' + reportId + '/count?startTime=' + startTime + '&endTime=' + endTime;
    if (serialNumber) {
      tempURL += '&serialNumber=' + serialNumber;
    }
    return this.http.get(tempURL).pipe(
      catchError(this.handleError));
  }

  // All api for provisioning report
  getProvisioningData(startTime, endTime): Observable<any> {
    return this.http.get(this.apiUrl + `analytics/provisioning/trend?startTime=${startTime}&endTime=${endTime}`)
      .pipe(catchError(this.handleError));
  }

  getInstalledDevices(startTime, endTime, page): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `devices/installed?startTime=${startTime}&endTime=${endTime}` +
      `&page=${page}&limit=100`
    )
      .pipe(catchError(this.handleError));
  }

  getRemovedDevices(startTime, endTime, page): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `devices/removed?startTime=${startTime}&endTime=${endTime}` +
      `&page=${page}&limit=100`
    )
      .pipe(catchError(this.handleError));
  }

  getTotalDevices(startTime, endTime, page): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `devices?startTime=${startTime}&endTime=${endTime}` +
      `&page=${page}&limit=100`
    )
      .pipe(catchError(this.handleError));
  }

  getTotalDevicesCount(): Observable<any> {
    return this.http.get(this.apiUrl + `devices/loggedin/count`)
      .pipe(catchError(this.handleError));
  }

  downloadInstalledDevices(startTime, endTime, attributes): Observable<any> {
    return this.http.get(
      this.apiUrl + `devices/installed/download` +
      `?startTime=${startTime}&endTime=${endTime}&attributes=${attributes}` +
      `${this.timeZone}`,
      { responseType: 'arraybuffer', observe: 'response' }
    )
      .pipe(catchError(this.handleError));
  }

  // To download reports as CSV file
  downloadHeatMapCSV(startTime, endTime): Observable<any> {
    return this.http.get(
      this.apiUrl + 'analytics/heat-map-report/download' +
      `?startDate=${startTime}&endDate=${endTime}`,
      { responseType: 'arraybuffer', observe: 'response' }
    ).pipe(catchError(this.handleError));
  }

  downloadRemovedDevices(startTime, endTime, attributes): Observable<any> {
    return this.http.get(
      this.apiUrl + `devices/removed/download` +
      `?startTime=${startTime}&endTime=${endTime}&attributes=${attributes}` +
      `${this.timeZone}`,
      { responseType: 'arraybuffer', observe: 'response' }
    )
      .pipe(catchError(this.handleError));
  }

  downloadTotalDevices(startTime, endTime, attributes): Observable<any> {
    return this.http.get(
      this.apiUrl + `devices/download` +
      `?startTime=${startTime}&endTime=${endTime}&attributes=${attributes}` +
      `${this.timeZone}`,
      { responseType: 'arraybuffer', observe: 'response' }
    )
      .pipe(catchError(this.handleError));
  }

  // All api for version drilldown
  getFirmwareCount(startTime, endTime): Observable<any> {
    return this.http.get(this.apiUrl + `devices/firmware/count?startTime=${startTime}&endTime=${endTime}`)
      .pipe(catchError(this.handleError));
  }

  getConfigCount(startTime, endTime, firmware): Observable<any> {
    return this.http.get(this.apiUrl + `devices/config/count?firmwareVersion=${firmware}&startTime=${startTime}&endTime=${endTime}`)
      .pipe(catchError(this.handleError));
  }

  getFwConfigTrendData(startTime, endTime, firmware, config): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `analytics/fw/config/count` +
      `?configVersion=${config}&firmwareVersion=${firmware}&startTime=${startTime}&endTime=${endTime}`
    )
      .pipe(catchError(this.handleError));
  }

  getFwConfigDevices(startTime, endTime, firmware, config, page): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `devices/firmware/config` +
      `?configVersion=${config}&firmwareVersion=${firmware}&startTime=${startTime}&endTime=${endTime}` +
      `&page=${page}&limit=100`
    )
      .pipe(catchError(this.handleError));
  }

  downloadFwConfigDevices(startTime, endTime, firmware, config, attributes): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `devices/firmware/config/download` +
      `?configVersion=${config}&firmwareVersion=${firmware}&startTime=${startTime}&endTime=${endTime}` +
      `&attributes=${attributes}` +
      `${this.timeZone}`,
      { responseType: 'arraybuffer', observe: 'response' }
    )
      .pipe(catchError(this.handleError));
  }

  // All api for not-communicating/ communicating report
  getAllNotCommCount(startTime, endTime, type): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `devices/not-communicating/count` +
      `?startTime=${startTime}&endTime=${endTime}&type=${type}`
    )
      .pipe(catchError(this.handleError));
  }

  getAllCommCount(startTime, endTime, type): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `devices/communicating/count` +
      `?startTime=${startTime}&endTime=${endTime}&type=${type}`
    )
      .pipe(catchError(this.handleError));
  }

  getNotCommTrendData(startTime, endTime, type, value): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `analytics/devices/not-communicating/trend` +
      `?startTime=${startTime}&endTime=${endTime}&type=${type}&value=${value}`
    )
      .pipe(catchError(this.handleError));
  }

  getCommTrendData(startTime, endTime, type, value): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `analytics/devices/communicating/trend` +
      `?startTime=${startTime}&endTime=${endTime}&type=${type}&value=${value}`
    )
      .pipe(catchError(this.handleError));
  }

  getAllNotCommDevices(startTime, endTime, groupId, page): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `analytics/devices/not-communicating/data` +
      `?startTime=${startTime}&endTime=${endTime}&groupName=${groupId}&page=${page}&limit=100`
    )
      .pipe(catchError(this.handleError));
  }

  getAllCommDevices(startTime, endTime, groupId, page): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `analytics/devices/communicating/data` +
      `?startTime=${startTime}&endTime=${endTime}&groupName=${groupId}&page=${page}&limit=100`
    )
      .pipe(catchError(this.handleError));
  }

  downloadAllNotCommDevices(startTime, endTime, attributes): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `devices/not-communicating/download` +
      `?startTime=${startTime}&endTime=${endTime}&attributes=${attributes}` +
      `${this.timeZone}`,
      { responseType: 'arraybuffer', observe: 'response' }
    )
      .pipe(catchError(this.handleError));
  }

  downloadAllCommDevices(startTime, endTime, attributes): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `devices/communicating/download` +
      `?startTime=${startTime}&endTime=${endTime}&attributes=${attributes}` +
      `${this.timeZone}`,
      { responseType: 'arraybuffer', observe: 'response' }
    )
      .pipe(catchError(this.handleError));
  }

  // All api for usage report
  getUsageData(startTime, endTime): Observable<any> {
    return this.http.get(this.apiUrl + `analytics/usage/device/active?startTime=${startTime}&endTime=${endTime}`)
      .pipe(catchError(this.handleError));
  }

  getUsagePlanName(): Observable<any> {
    return this.http.get(this.apiUrl + `analytics/usage/device/plan`)
      .pipe(catchError(this.handleError));
  }

  // Log in/out activity report APIs
  getLogInOutActivity(userId, startTime, endTime, page): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `activity/loginActivity/user/${userId}?startTime=${startTime}&endTime=${endTime}` +
      `&page=${page}&limit=100`
    )
      .pipe(catchError(this.handleError));
  }

  downloadLogInOutActivity(userId, startTime, endTime): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `activity/loginActivity/download/user/${userId}?startTime=${startTime}&endTime=${endTime}`,
      { responseType: 'arraybuffer', observe: 'response' }
    )
      .pipe(catchError(this.handleError));
  }

  // Vehicle Message report APIs
  getVehicleMessageData(deviceId, startTime, endTime): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `analytics/device/message/count` +
      `?deviceId=${deviceId}&startDate=${startTime}&endDate=${endTime}`
    )
      .pipe(catchError(this.handleError));
  }

  // To get the total message count per day
  getDeviceTotalMessageCount(deviceId, startTime, endTime): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `analytics/device/total-message/count` +
      `?deviceId=${deviceId}&startDate=${startTime}&endDate=${endTime}`
    )
      .pipe(catchError(this.handleError));
  }

  // Message Report CSV download
  getMessageDataCSV(deviceId, start, end): Observable<any> {
    return this.http.get(
      this.apiUrl + `analytics/device/message/download` +
      `?deviceId=${deviceId}&startDate=${start}&endDate=${end}`,
      {
        responseType: 'arraybuffer', observe: 'response'
      }).pipe(catchError(this.handleError));
  }

  // Trip summary report csv download
  getTrips(deviceId, startTime, endTime, page): Observable<any> {
    return this.http.get(
      this.apiUrl + `trips/device/${deviceId}` +
      `?startTime=${startTime}&endTime=${endTime}&page=${page}&limit=100&sort=asc`
    )
      .pipe(catchError(this.handleError));
  }

  downloadTripSummary(deviceId, startTime, endTime): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `trips/device/${deviceId}/download?startTime=${startTime}&endTime=${endTime}&offset=${this.offsetInMinutes}`,
      { responseType: 'arraybuffer', observe: 'response' }
    )
      .pipe(catchError(this.handleError));
  }

  // Data Destination report APIs
  getMessagesPublished(deviceId?): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    let tempUrl = this.apiUrl + `analytics/messages/pushed`;
    if (deviceId) {
      tempUrl += `?deviceId=${deviceId}`;
    }
    return this.http.get(tempUrl, { headers })
      .pipe(catchError(this.handleError));
  }

  getAllProcessingTime(deviceId?, startTime?, endTime?): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    let tempUrl = this.apiUrl + `analytics/message/processing-time`;
    if (deviceId && !startTime) {
      tempUrl += `?deviceId=${deviceId}`;
    } else if (deviceId && startTime && endTime) {
      tempUrl += `?deviceId=${deviceId}&startTime=${startTime}&endTime=${endTime}`;
    }
    return this.http.get(tempUrl, { headers })
      .pipe(catchError(this.handleError));
  }

  getPublishedTime(deviceId?): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    let tempUrl = this.apiUrl + `analytics/device/processing`;
    if (deviceId) {
      tempUrl += `?deviceId=${deviceId}`;
    }
    return this.http.get(tempUrl, { headers })
      .pipe(catchError(this.handleError));
  }

  // Live ststus report API
  getLiveStatus(groupId?): Observable<any> {
    let tempUrl = this.apiUrl + `devices/live/stats`;
    if (groupId) {
      tempUrl += `?groupId=${groupId}`
    }
    return this.http.get(tempUrl)
      .pipe(catchError(this.handleError));
  }

  getStats(startTime, endTime): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `analytics/status/count?startTime=${startTime}&endTime=${endTime}`
    )
      .pipe(catchError(this.handleError));
  }

  getDeviceCounts(): Observable<any> {
    return this.http.get(this.apiUrl + `analytics/customer/message/count`)
      .pipe(catchError(this.handleError));
  }

  getFotaStats(start, end): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `campaigns/active/count?startDate=${start}&endDate=${end}`
    )
      .pipe(catchError(this.handleError));
  }

  getDevicesWithStatus(page, status, groupId?): Observable<any> {
    let tempUrl = this.apiUrl + `devices?status=${status}&page=${page}&limit=100`;
    if (groupId) {
      tempUrl += `&groups=${groupId}`
    }
    return this.http.get(
      tempUrl
    ).pipe(catchError(this.handleError));
  }

  // C & S Trigger Report APIs
  getAllStatusCount(type, customerId, start?, end?): Observable<any> {
    let tempUrl = `al/vehicles/provisioning/statusCount/requestType/${type}/customerId/${customerId}`;
    if (start && end) {
      tempUrl += `?startTime=${start}&endTime=${end}`;
    }
    return this.http.get(this.apiUrl + tempUrl).pipe(catchError(this.handleError));
  }

  getRequestCounts(type, status, customerId, start?, end?): Observable<any> {
    let tempUrl = `al/vehicles/provisioning/daysCount/requestType/${type}/customerId/${customerId}`;
    if (status !== 'all') {
      const char = tempUrl.includes('?') ? '&' : '?';
      tempUrl += `${char}status=${status}`;
    }
    if (start && end) {
      const char = tempUrl.includes('?') ? '&' : '?';
      tempUrl += `${char}startTime=${start}&endTime=${end}`;
    }
    return this.http.get(this.apiUrl + tempUrl).pipe(catchError(this.handleError));
  }

  getProvisioningErrorCounts(type, status, customerId, start?, end?): Observable<any> {
    let tempUrl = `al/vehicles/provisioning/errorCategory?requestType=${type}`;
    if (start && end) {
      tempUrl += `&startTime=${start}&endTime=${end}`;
    }
    if (customerId) {
      tempUrl += `&customerId=${customerId}`;
    }
    // if (status !== 'all') {
    //   tempUrl += `&status=${status}`;
    // }
    return this.http.get(this.apiUrl + tempUrl).pipe(catchError(this.handleError));
  }

  getAvgTimeTaken(type, status, customerId, start?, end?): Observable<any> {
    let tempUrl = `al/vehicles/provisioning/averageTimeTaken/requestType/${type}/customerId/${customerId}`;
    if (status !== 'all') {
      const char = tempUrl.includes('?') ? '&' : '?';
      tempUrl += `${char}status=${status}`;
    }
    if (start && end) {
      const char = tempUrl.includes('?') ? '&' : '?';
      tempUrl += `${char}startTime=${start}&endTime=${end}`;
    }
    return this.http.get(this.apiUrl + tempUrl).pipe(catchError(this.handleError));
  }

  getDevicesWithFilter(page, type, status, customerId, start?, end?): Observable<any> {
    let tempUrl = `al/vehicles/provisioning/requestType/${type}/customerId/${customerId}?page=${page}`;
    if (status !== 'all') {
      tempUrl += `&status=${status}`;
    }
    if (start && end) {
      const char = tempUrl.includes('?') ? '&' : '?';
      tempUrl += `${char}startTime=${start}&endTime=${end}`;
    }
    return this.http.get(this.apiUrl + tempUrl).pipe(catchError(this.handleError));
  }

  downloadCTriggerDevicesCSV(status, customerId, start?, end?): Observable<any> {
    let tempUrl = `al/vehicles/provisioning/download-c-trigger`;
    if (status !== 'all') {
      const char = tempUrl.includes('?') ? '&' : '?';
      tempUrl += `${char}status=${status}`;
    }
    if (customerId) {
      const char = tempUrl.includes('?') ? '&' : '?';
      tempUrl += `${char}customerId=${customerId}`;
    }
    if (start && end) {
      const char = tempUrl.includes('?') ? '&' : '?';
      tempUrl += `${char}startTime=${start}&endTime=${end}`;
    }
    return this.http.get(this.apiUrl + tempUrl, { responseType: 'arraybuffer', observe: 'response' })
    .pipe(catchError(this.handleError));
  }

  downloadSTriggerDevicesCSV(status, customerId?, start?, end?): Observable<any> {
    let tempUrl = `al/vehicles/provisioning/download-s-trigger`;
    if (status !== 'all') {
      const char = tempUrl.includes('?') ? '&' : '?';
      tempUrl += `${char}status=${status}`;
    }
    if (customerId) {
      const char = tempUrl.includes('?') ? '&' : '?';
      tempUrl += `${char}customerId=${customerId}`;
    }
    if (start && end) {
      const char = tempUrl.includes('?') ? '&' : '?';
      tempUrl += `${char}startTime=${start}&endTime=${end}`;
    }
    return this.http.get(this.apiUrl + tempUrl, { responseType: 'arraybuffer', observe: 'response' })
    .pipe(catchError(this.handleError));
  }

  // APIs for billing reports
  getProvisionBillingData(months, customerId?): Observable<any> {
    let tempUrl = `billing/provisioning/report?numberOfMonth=${months}`;
    if (customerId) {
      tempUrl += `&customerId=${customerId}`;
    }
    return this.http.get(this.apiUrl + tempUrl).pipe(catchError(this.handleError));
  }

  downloadMonthlyBill(month, year, customerId?): Observable<any> {
    let tempUrl = `billing/provisioning/report/download?year=${year}&month=${month}`;
    if (customerId) {
      tempUrl += `&customerId=${customerId}`;
    }
    return this.http.get(
      this.apiUrl + tempUrl,
      { responseType: 'arraybuffer', observe: 'response' }
    ).pipe(catchError(this.handleError));
  }

  // for packet history report
  downloadPacketHistoryCSV(deviceId, startTime, endTime, messageType?) {
    let tempUrl = `messages/device/${deviceId}/packet-history/download?startTime=${startTime}&endTime=${endTime}${this.timeZone}`;
    if (messageType) tempUrl += `&messageType=${messageType}`;
    return this.http.get(this.apiUrl + tempUrl,
    { responseType: 'arraybuffer', observe: 'response' })
    .pipe(catchError(this.handleError));
  }

  handleError(error: any | any) {
    return observableThrowError(
      error.error || error.json() || error || 'Server error'
    );
  }

  // Get BitBrew Usage Stats
  getUsageStats(customerId?: any): Observable<any> {
    let tempUrl = `analytics/device/usage/stats`
    if (customerId) {
      tempUrl += `?customerId=${customerId}`
    }
    return this.http.get(
      this.apiUrl + tempUrl)
      .pipe(catchError(this.handleError));
  }

  // Get BitBrew Usage Stats
  getUsageByMonthYear(year, month, page): Observable<any> {
    //  </div>https://api.dev.bitbrew.com/v1/analytics/device/usage/data/year/2022/month/3?page=0
    return this.http.get(
      this.apiUrl +
      `analytics/device/usage/data/year/${year}/month/${month}?page=${page}`)
      .pipe(catchError(this.handleError));
  }

  // Get BitBrew Usage Stats Count
  getUsageByMonthYearCount(year, month): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `analytics/device/usage/data/count/year/${year}/month/${month}`)
      .pipe(catchError(this.handleError));
  }

  //Download BitBrew Usage CSV file
  downloadDeviceCSV(year, month): Observable<any> {
    // https://api.dev.bitbrew.com/v1/analytics/download/device/usage/year/2022/month/3?offsetInMinutes=330
    let tempUrl = this.apiUrl + `analytics/download/device/usage/year/${year}/month/${month}?offsetInMinutes=${this.offsetInMinutes}`
    return this.http.get(tempUrl, { responseType: 'arraybuffer', observe: 'response' }).pipe(
      catchError(this.handleError));
  }

  // get total count of Vehicle/Device
  getTotalCountOfAsset(customerId?: any): Observable<any> {
    let tempUrl = 'devices/count/key/assetType'
    if (customerId !== undefined) {
      tempUrl += `/${customerId}`
    }
    return this.http.get(this.apiUrl + tempUrl).pipe(
      catchError(this.handleError));
  }
    // get Account status
    getAccountStatus(customerId): Observable<any> {
      //customerId = 133
      return this.http.get(this.apiUrl + `customers/${customerId}`).pipe(
        catchError(this.handleError));
    }

    // get total messages count
    getTotalMessagesCount(customerId?: any): Observable<any> {
      let tempUrl = 'destination/scheme/getMessageCount'
    if (customerId !== undefined) {
      tempUrl += `?customerId=${customerId}`
    }
      return this.http.get(this.apiUrl + tempUrl).pipe(
        catchError(this.handleError));
    }

    // Live ststus report API
  getAzugaLiveStatus(groupId?): Observable<any> {
    let tempUrl = this.apiUrl + `devices/live/stats/counts`;
    if (groupId) {
      tempUrl += `?groupId=${groupId}`
    }
    return this.http.get(tempUrl)
      .pipe(catchError(this.handleError));
  }

  getDevicesWithLiveStatusType(page, liveStatusType, groupId?): Observable<any> {
    if (liveStatusType === 14) {
      liveStatusType = "Communicating";
    } else if (liveStatusType === 15) {
      liveStatusType = "NotCommunicating";
    } else if (liveStatusType === 16) {
      liveStatusType = "Disconnected";
    } else if (liveStatusType === 3) {
      liveStatusType = "NeverCommunicating";
    }
    let tempUrl = this.apiUrl + `devices?liveStatusType=${liveStatusType}&page=${page}&limit=100`;
    if (groupId) {
      tempUrl += `&groups=${groupId}`
    }
    return this.http.get(
      tempUrl
    ).pipe(catchError(this.handleError));
  }

  getDisconnectedTrendData(startTime, endTime, type, value): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `analytics/devices/disconnected/trend` +
      `?startTime=${startTime}&endTime=${endTime}&type=${type}&value=${value}`
    )
      .pipe(catchError(this.handleError));
  }

  getAllDisconnectedDevices(startTime, endTime, groupId, page): Observable<any> {
    return this.http.get(
      this.apiUrl +
      `analytics/devices/disconnected/data` +
      `?startTime=${startTime}&endTime=${endTime}&groupName=${groupId}&page=${page}&limit=100`
    )
      .pipe(catchError(this.handleError));
  }
}
