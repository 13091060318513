import { Component, OnInit, NgZone, HostListener, OnChanges, Input, Output, EventEmitter, ElementRef, ViewChild, ChangeDetectorRef, ViewChildren, QueryList } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '@myproject/shared/shared.service';
import { environment } from '@myenv/environment';
import { LiveService } from '../live.service';
import { configMessage } from '@shared/config-message';
import * as FileSaver from 'file-saver';
import { DataService } from '@components/data/data.service';
import { trigger, state, style, animate, transition } from '@angular/animations';
import Chart from 'chart.js';
import { GraphicReportsService } from '@myproject/components/graphic-reports/graphic-reports.service';
import { DatePipe } from '@angular/common';
import { Angular5Csv } from 'angular5-csv/dist/Angular5-csv';
import * as moment from 'moment';
declare var $: any;
import { ObdPidViewComponent } from '@myproject/shared/obd-pid-view/obd-pid-view.component';

@Component({
  selector: 'app-trips',
  templateUrl: './trips.component.html',
  styleUrls: ['./trips.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        overflow: 'hidden',
        // height: '*',
        width: '100%'
      })),
      state('out', style({
        opacity: '1',
        overflow: 'auto',
        // height: '0px',
        width: '100%'
      })),
      transition('in => out', animate('400ms ease-in-out')),
      transition('out => in', animate('400ms ease-in-out'))
    ])
  ],
  providers: [ObdPidViewComponent]
})
export class TripsComponent implements OnInit {
  moduleName = 'TRIPS';
  subTitle = 'Trips';
  // for distance travelled
  public distanceMetrics = environment.distanceMetrics;
  distanceTravelled = 0;
  // Markers
  harsh_braking_color = '../../../../assets/img/trips/harsh-braking-color.png';
  harsh_braking_gray = '../../../../assets/img/trips/harsh-braking-gray.png';
  high_speed_color = '../../../../assets/img/trips/high_speed_color.png';
  high_speed_gray = '../../../../assets/img/trips/high_speed_gray.png';
  rapid_acceleration_color = '../../../../assets/img/trips/rapid_acceleration_color.png';
  rapid_acceleration_gray = '../../../../assets/img/trips/rapid_acceleration_gray.png';
  // for permissions
  permissionModules: any = [];
  tripAuthorities: any = [];

  @ViewChild('gmap', { static: true }) gmapElement: ElementRef;
  @ViewChild(ObdPidViewComponent, { static: true }) obdPidView!: ObdPidViewComponent;
  map: google.maps.Map;
  infoWindow: any = [];
  flightPath: any;
  markers: any = [];
  pageLoading = true;
  isLoading = false;
  public dateFormat = environment.isoDateTimeFormat;
  public msgDateFormat = environment.smallDateFormat;
  selecteddeviceId: string = null;
  selectedDevice: any = {};
  querySelectVehicle = null;
  vehicles: any = [];
  trips: any = [];
  // startTime: any;
  // endTime: any;
  tripStart: any;
  tripEnd: any;
  pickerStart: any;
  pickerEnd: any;
  deviceStartTime: any;
  deviceEndTime: any;
  selected = [];
  showEvents = false;
  disableNav = true;
  sort = 'desc';
  page = 0;
  selectedTrip: any = {};

  harshBraking = [];
  overSpeeding = [];
  harshAccelaration = [];
  hideCalendar = false;
  querySelectDate = false;
  selectedvehicleinfo: any = {};
  // devicestartTime: any;
  // deviceendTime: any;

  // for reports dropdown
  reportsMenu: any = [];
  reports: any = [];
  customLoader = false;
  staticReports = configMessage.staticReports;
  public AL_SystemReport = environment.AL_SystemReport;

  // for places filter
  filterplace = false;
  obd = {
    ts: null,
    te: null
  };
  obdLocation = 'tripMode';
  //store trips Data
  tripsData: any
  latencyData = {
    gsmStrength: '',
    eventTime: '',
    ingestionTimestamp: '',
    messageType: '',
    latitude: '',
    longitude: ''
  };
  selectedTrips: boolean = true
  // selectedNetwork: boolean = false
  // selectedStartDate: any;
  // selectedEnddate: any;
  noDevices = true;
  updateChrt = 0;
  pageInfo = {
    offset: 0,
    count: 0,
    limit: 6,
    pageSize: 0,
    lastPage: 0,
  };
  @ViewChild('infowindowData', { static: false }) infowindowData: ElementRef;
  // currentView = 0;
  graphContainerParams = null;
  actionDateChange = 0;
  // getScreenWidth: any;
  // getScreenHeight: any;

  // variable for map message view section start
  messageTypeArray = [];
  pageVehicleHistory = 0;
  dottedEvents: any = {
    // checked: false,
    tripChecked: false,
    messageChecked: false
  };
  initialDataLoad: Boolean = true;
  timeSeeker = {
    selected: false,
    ts: null,
    te: null,
    selectedVal: [],
    len: 0
  };
  selectedMessages = [];
  messageMarkers: any = {};
  isDrawerOpen = false;
  // vehicle: any = {
  //   deviceId: null,
  //   startTime: null,
  //   endTime: null
  // };
  origin: {};
  viewPage = false;
  show = true;
  liveHistoryKey: any;
  locationTotalCount: any;
  openDatePopup: Boolean = false;
  livehistorySearchheader: any = [];
  rows = [];
  historyRows: any = [];
  temp: any = [];
  liveHistorykeyString: String;
  enablePageVehicleHistory = true;
  liveHistoryAttribute = configMessage.liveHistoryAttribute;
  csvLiveHistPrefKeyArray = [];
  csvLiveHistPrefHeaderArray = [];
  pageCount: any;
  endPacket = false;
  toggleEffect = false;
  loadingIndicator = true;
  polyLineData: any = [];
  timer: any;
  currentPageCount: any = {
    start: 0,
    end: 0
  };
  cityCircle: any;
  multipleCircles: any = [];
  public resSizeenv = environment.resSize;
  readonly resSize = this.resSizeenv;
  prevResponse = 0;
  markerscluster = [];
  infowindow;
  locationVehicles: any;
  historyRowsEmpty: Boolean = false;
  alerts: any;
  usingEventTime = false;
  selectedFilter = '';
  liveSearch = '';
  liveKey = '';
  selectedVehicle: any = {};
  totalDistanceTravelled = 0;
  // message JSON popup
  messageJsonLoader = false;
  messageJsonRaw: any;
  messageJsonFormattedString: any;
  // message type side drawer
  messagesLoading = false;
  messages: any = [];
  places: any = [];
  line: any = [];
  searchModel: any = {};
  messageTypeColor = configMessage.messageTypeColor;
  tripViewToggle = true;
  networkViewToggle = true;
  accordion = {
    isHavingEnoughSpace: false,
    trip_view: true,
    isTripManualCollapse: false, // boolean to check accordion collapse manually
    msg_view: false,
    isMsgManualCollapse: false,
    pid_view: false,
    isPidManualCollapse: false,
  };
  constructor(
    private title: Title,
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private router: Router,
    private liveService: LiveService,
    private dataService: DataService,
    private ngzone: NgZone,
    private reportsService: GraphicReportsService,
    private cdr: ChangeDetectorRef,
    private eleRef: ElementRef
  ) { }

  ngOnInit() {
    window["trip"] = this
    this.title.setTitle('Trips' + environment.title_text);
    this.permissionModules = localStorage.getItem('permissionModules');
    const modules = JSON.parse(localStorage.getItem('accessRoleModules'));
    const tripRoleModule = modules.find(module => module.name === 'TRIPS');
    this.tripAuthorities = tripRoleModule && tripRoleModule.authorities ? tripRoleModule.authorities : [];
    // this.liveSelectedVehicle = JSON.parse(localStorage.getItem('liveSelectedVehicle'));
    const queryParams = this.route.snapshot.queryParams;
    if (queryParams.distance) this.distanceTravelled = queryParams.distance;
    this.mapInit();
    let localData = JSON.parse(localStorage.getItem("preferences"));
    // try {
    //   this.dottedEvents.tripChecked = localData["liveHistory"]["toggle"]["networkLatency"];
    //   this.dottedEvents.messageChecked = localData["liveHistory"]["toggle"]["networkLatency"];
    // } catch (e) {
    //   console.log(e);
    // }
    // this.getScreenWidth = window.innerWidth;
    // this.getScreenHeight = window.innerHeight;
    // this.setOBDHeight();
    // localData["liveHistory"]["toggle"]["tripsView"] = trips;
  }
  @HostListener('window:resize')
  onWindowResize() {
    const firstActiveSection = $('.left-sec')[0].style.display === 'block' ? $($('.left-sec')[0]) : $($('.left-sec')[1]);
    const activeViewHeight = firstActiveSection.height();
    const leftSection = $('#leftsctn').height();
    if (!this.accordion.isPidManualCollapse && (activeViewHeight > 100 || this.accordion.msg_view || this.accordion.trip_view)) {
      const checkCanViewDataView = (leftSection - activeViewHeight);
      this.accordion.isHavingEnoughSpace = checkCanViewDataView >= configMessage.minHeightReqDataView;
      this.accordion.pid_view = this.accordion.isHavingEnoughSpace;
    }
    const params = (this.accordion.isHavingEnoughSpace ? (this.tripViewToggle ? (this.accordion.trip_view ? ['trip_expand', this.accordion.pid_view ? 'data_expand' : 'data_collapse'] : ['trip_collapse', this.accordion.pid_view ? 'data_expand' : 'data_collapse']) : (this.accordion.msg_view ? ['msg_expand', this.accordion.pid_view ? 'data_expand' : 'data_collapse'] : ['msg_collapse', this.accordion.pid_view ? 'data_expand' : 'data_collapse'])) : (this.accordion.trip_view ? 1 : this.accordion.msg_view ? 2 : 3));
    this.sharedService.setOBDHeight(window.innerWidth, window.innerHeight, this.accordion.isHavingEnoughSpace, params, this.graphContainerParams.tabPos ? this.graphContainerParams.tabPos : 0);
  }

  controlAccordionActions(p1) {
    if (this.accordion.isHavingEnoughSpace) {
      if (p1 === 'trip') {
        this.accordion.trip_view = !this.accordion.trip_view;
        this.accordion.isTripManualCollapse = !this.accordion.trip_view;
      } else if (p1 === 'msg') {
        this.accordion.msg_view = !this.accordion.msg_view;
        this.accordion.isMsgManualCollapse = !this.accordion.msg_view;
      } else {
        this.accordion.pid_view = !this.accordion.pid_view;
        this.accordion.isPidManualCollapse = !this.accordion.pid_view;
        this.sharedService.set_OBD_PID_Height(this.graphContainerParams.tabPos);
      }
    } else {
      // when no space available either any one of the tabs shoule be shown to the user
      if (p1 === 'trip') {
        this.accordion.msg_view = false;
        if (this.accordion.trip_view) {
          this.accordion.trip_view = false;
          this.accordion.pid_view = true;
        } else {
          this.accordion.trip_view = true;
          this.accordion.pid_view = false;
        }
      } else if (p1 === 'msg') {
        this.accordion.trip_view = false;
        if (this.accordion.msg_view) {
          this.accordion.msg_view = false;
          this.accordion.pid_view = true;
        } else {
          this.accordion.msg_view = true;
          this.accordion.pid_view = false;
        }
      } else if (p1 === 'pidView') {
        if (this.accordion.pid_view) {
          // when pid view is already active and on second click
          if (this.tripViewToggle) {
            this.accordion.trip_view = true;
          } else {
            this.accordion.msg_view = true;
          }
          this.accordion.pid_view = false;
        } else {
          // when pid view is not active active and on click
          this.accordion.trip_view = false;
          this.accordion.msg_view = false;
          this.accordion.pid_view = true;
        }
      }
      this.sharedService.set_OBD_PID_Height(this.graphContainerParams.tabPos);
    }
  }

  onTimeChange(e) {
    this.resetTimeSeeker();
    this.actionDateChange += 1;
    const queryParams = this.route.snapshot.queryParams;
    if (!e && queryParams.startDate && queryParams.endDate) {
      this.pickerStart = queryParams.startDate;
      this.pickerEnd = queryParams.endDate;
      this.querySelectDate = true;
      // delete params
      const params = { ...queryParams };
      delete params.startDate;
      delete params.endDate;
      this.router.navigate([], { queryParams: params });
      // this.location.replaceState('trips');
    } else if (this.tripViewToggle) {
      this.pickerStart = e.startTime;
      this.pickerEnd = e.endTime;
      this.querySelectDate = false;
    }
    if (this.selecteddeviceId && this.tripViewToggle) {
      this.getTrips(this.sort);
    } else if (this.selecteddeviceId && !this.tripViewToggle) {
      // this.obd.ts = this.pickerStart;
      // this.obd.te = this.pickerEnd;
      this.initMapMessageView();
    }
  }

  changeVehicle(e) {
    this.resetTimeSeeker();
    this.resetAccordionParams();
    this.pickerStart = null;
    this.pickerEnd = null;
    this.obd.ts = null;
    this.obd.te = null;
    this.mapInit();
    const queryParams = this.route.snapshot.queryParams;
    if (queryParams.id && queryParams.startDate && queryParams.endDate) {
      this.selecteddeviceId = this.route.snapshot.queryParams.id;
      this.pickerStart = parseInt(queryParams.startDate, 10);
      this.pickerEnd = parseInt(queryParams.endDate, 10);
      this.obd.ts = this.pickerStart;
      this.obd.te = this.pickerEnd;
      this.getQueryVehicle(this.route.snapshot.queryParams.name);
      // delete params
      // const params = { ...queryParams };
      // delete params.id;
      // delete params.name;
      // this.router.navigate([], { queryParams: params });
      // this.location.replaceState('trips');
    } else {
      this.selecteddeviceId = e ? e.deviceId : null;
      this.selectedDevice = e;
      this.querySelectVehicle = null;
      const params = { ...queryParams };
      delete params.id;
      delete params.name;
      delete params.endDate;
      delete params.startDate;
      this.router.navigate([], { queryParams: params });
      this.getDeviceDetail();
    }
    if (e === null || e === undefined) {
      this.graphContainerParams = {
        noDevices: true
      };
    }
  }

  getQueryVehicle(name) {
    this.sharedService.onSearchVehicle('assetName', name).subscribe(res => {
      if (res.length) {
        this.selectedDevice = res[0];
        this.selecteddeviceId = res[0].deviceId;
        this.querySelectVehicle = res[0];
        this.initiateData();
        this.sharedService.getVehicleDetail(this.selecteddeviceId).subscribe(res => {
          this.selectedvehicleinfo = res;
          if (res && res.hasOwnProperty('groupName')) {
            this.selectedDevice['groupName'] = res.groupName;
          }
          //this.mapInit(); // changeVehicle //commented to fix NEX-3856
        }, error => {
          this.sharedService.getErrorMsg(error);
        });
      } else {
        this.selectedDevice = {};
        this.querySelectVehicle = null;
      }
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }

  getDeviceDetail() {
    this.sharedService.getRecentdeviceData(this.selecteddeviceId).subscribe((res: any) => {
      if (res.teTime || res.tsTime) {
        if (res.teTime) {
          this.pickerStart = res.teTime - (60 * 24 * 60 * 1000);
          this.pickerEnd = res.teTime;
        } else if (!res.teTime && res.tsTime) {
          this.pickerStart = res.tsTime - (60 * 24 * 60 * 1000);
          this.pickerEnd = res.tsTime;
        } else {
          let start = null;
          let end = null;
          const t = new Date();
          start = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 0, 0, 0).toUTCString());
          end = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59).toUTCString());
          this.pickerStart = start;
          this.pickerEnd = end;
        }
        if (this.pickerStart && this.pickerEnd) {
          this.deviceStartTime = this.pickerStart;
          this.deviceEndTime = this.pickerEnd;
          this.initiateData();
          this.sharedService.getVehicleDetail(this.selecteddeviceId).subscribe(res => {
            this.selectedvehicleinfo = res;
            if (res && res.hasOwnProperty('groupName')) {
              this.selectedDevice['groupName'] = res.groupName;
            }
            //this.mapInit(); // changeVehicle //commented to fix NEX-3856
          }, error => {
            this.sharedService.getErrorMsg(error);
          });
        }
      } else {
        this.sharedService.getRecentdeviceDataAlternateAPI(this.selecteddeviceId).subscribe((res: any) => {
          if (res.eventTimeEpoch) {
            this.pickerStart = res.eventTimeEpoch - (60 * 24 * 60 * 1000);
            this.pickerEnd = res.eventTimeEpoch;
          } else {
            let start = null;
            let end = null;
            const t = new Date();
            start = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 0, 0, 0).toUTCString());
            end = Date.parse(new Date(t.getFullYear(), t.getMonth(), t.getDate(), 23, 59, 59).toUTCString());
            this.pickerStart = start;
            this.pickerEnd = end;
          }
          if (this.pickerStart && this.pickerEnd) {
            this.deviceStartTime = this.pickerStart;
            this.deviceEndTime = this.pickerEnd;
            this.initiateData();
            this.sharedService.getVehicleDetail(this.selecteddeviceId).subscribe(res => {
              this.selectedvehicleinfo = res;
              if (res && res.hasOwnProperty('groupName')) {
                this.selectedDevice['groupName'] = res.groupName;
              }
              //this.mapInit(); // changeVehicle //commented to fix NEX-3856
            }, error => {
              this.sharedService.getErrorMsg(error);
            });
          }
        });
      }
    });
  }

  initiateData() {
    if (this.tripViewToggle) {
      this.getTrips(this.sort); // changeVehicle
    } else {
      this.getAllData();
    }
  }

  getTrips(sort) {
    if (this.selecteddeviceId !== null) {
      // this.obd.ts = null;
      // this.obd.te = null;
      // this.checkDateValidity();
      this.liveService.getTrips(this.selecteddeviceId, this.pickerEnd, this.pickerStart, sort).subscribe((res: any) => {
        if (res.length == 0) {
          this.selectedTrip = {};
          this.trips = [];
          this.showEvents = false;
          this.map.setZoom(4);
          this.clearMarkers();
          if (this.flightPath != undefined) { this.removePolyline(); }
          this.obd.ts = this.pickerStart;
          this.obd.te = this.pickerEnd;
        } else {
          this.trips = res;
          this.trips.map((item, index) => {
            item['index'] = index;
            if (item.hasOwnProperty('tsTime') && item.hasOwnProperty('teTime')) {
              item['tripDuration'] = this.getDuration(item.tsTime, item.teTime);
            } else {
              item['tripDuration'] = '';
            }
          });
          this.selected = [this.trips[0]];
          if (this.selected[0].hasOwnProperty('tsTime')) {
            this.obd.ts = this.selected[0].tsTime;
            // this.pickerEnd = this.selected[0].teTime;
            // this.pickerStart = this.pickerEnd - (1000 * 60 * 60 * 24);
          } else if (this.selected[0].hasOwnProperty('teTime')) {
            this.obd.te = this.selected[0].teTime;
            //this.pickerEnd = this.selected[0].teTime;
          }
          
          this.clearMarkers();
          this.selectTrip({ selected: this.selected });
          this.isLoading = false;
        }
        this.pageLoading = false;
      }, error => {
        this.pageLoading = false;
        this.sharedService.getErrorMsg(error);
      });
    } else {
      this.pageLoading = false;
    }
  }

  getDuration(ts, te) {
    let durationOfTrip = '';
    const [days, hours, minutes, seconds] = this.sharedService.getDurationBtwTwoEpoch(ts, te);
    if (ts && te) {
      if (hours < 10) { durationOfTrip += '0' + (hours).toString() + ':'; } else { durationOfTrip += (hours).toString() + ':'; }
      if (minutes < 10) { durationOfTrip += '0' + (minutes).toString() + ':'; } else { durationOfTrip += (minutes).toString() + ':'; }
      if (seconds < 10) { durationOfTrip += '0' + (seconds).toString(); } else { durationOfTrip += (seconds).toString(); }
      return durationOfTrip;
    }
    return '';
  }

  selectTrip(e) {
    this.selectedTrip = e.selected[0];
    const trip = e.selected[0];
    if (trip && Object.keys(trip).length) {
      let teTime = 0;
      if (trip.hardAcclCounts || trip.hardBreakingCounts || trip.highSpeedEventCounts) {
        this.showEvents = true;
      } else {
        this.showEvents = false;
      }
      if (!trip.teTime) {
        if (this.sort === 'asc') {
          if (trip.index === (this.trips.length - 1)) {
            // teTime = Date.parse(new Date().toUTCString());
            teTime = this.pickerEnd;
          } else {
            teTime = this.trips[trip.index + 1].tsTime;
          }
        } else if (this.sort === 'desc') {
          if (trip.index === 0) {
            teTime = this.pickerEnd;
          } else {
            teTime = this.trips[trip.index - 1].tsTime;
          }
        } else {
          teTime = this.pickerEnd;
        }
        this.obd.te = teTime;
      } else {
        teTime = trip.teTime;
        this.obd.te = teTime;
      }
      this.obd.ts = trip.tsTime;
      this.tripStart = trip.tsTime;
      this.tripEnd = teTime;
      if (this.dottedEvents.tripChecked) {
        this.switchToDots(this.selecteddeviceId, trip.tsTime, teTime);
      } else {
        this.getPolylineData(trip.tsTime, teTime);
      }
    }
  }

  sortTrips(e) {
    if (this.trips.length) {
      this.sort = e.newValue;
      this.getTrips(this.sort);
    }
  }

  // to get all Reports
  getallReports() {
    if (localStorage.getItem('reports')) {
      this.reportsMenu = [];
      let reports = JSON.parse(localStorage.getItem('reports'));
      reports.map(obj => {
        if (!obj.hidden) {
          this.reportsMenu.push(obj);
        }
        // this.vehicleDetail = {};
      });
      this.reports = this.reportsMenu;
    } else {
      this.customLoader = true;
      this.sharedService.getReports().subscribe(res => {
        res.map(obj => {
          if (!obj.hidden) {
            this.reportsMenu.push(obj);
          }
          // this.vehicleDetail = {};
        });
        this.staticReports = this.staticReports.filter(item => {
          if (item.reportType === 'system' && item.url.includes('trigger-report') && !this.AL_SystemReport) return false;
          if (item.reportType === 'device' && item.url.includes('trip-summary') && !this.tripAuthorities.includes('READ')) return false;
          if (item.reportType === 'billing' && !this.permissionModules.includes('BILLING')) return false;
          return true;
        });
        this.reportsMenu = [
          ...this.reportsMenu,
          ...this.staticReports
        ];
        localStorage.setItem('reports', JSON.stringify(this.reportsMenu));
        this.customLoader = false;
        this.reports = this.reportsMenu;
      }, error => {
        this.customLoader = false;
        this.sharedService.getErrorMsg(error);
      });
    }
  }

  filterReport(event) {
    const inputVal = event.target.value.toLowerCase();
    const val = inputVal.trim();
    const temp = this.reportsMenu.filter(function (d) {
      return d.name.toLowerCase().indexOf(val) !== -1 || !val;
    });
    this.reports = temp;
  }

  redirectToReports(report) {
    const vehicle = this.selectedDevice;
    try {
      localStorage.setItem('currentReport', JSON.stringify(report));
      localStorage.setItem('reportsVehicle', JSON.stringify(vehicle));
      if (report.type === 'VISUALIZATION') {
        this.router.navigate(['/reports/device/visualization']);
      } else if (report.downloadable && report.type !== 'AL_PLANT_MODE') {
        this.router.navigate(['/reports/device/custom']);
      } else if (report.type === 'AL_PLANT_MODE') {
        this.router.navigate(['/reports/device/alPlantMode']);
      } else if (['vehicle', 'device', 'system', 'billing'].includes(report.reportType) || report.name === 'Trip Summary') {
        this.router.navigate([report.url]);
      } else {
        this.router.navigate(['/reports/device/messages']);
      }
    } catch (e) {
      console.log(e.message);
    }
  }

  // To download trips data as a csv file
  downloadTripCSV() {
    this.liveService.downloadTripSummary(this.selecteddeviceId, this.pickerStart, this.pickerEnd).subscribe(res => {
      const contentDisposition = res.headers.get('Content-Disposition');
      let filename;
      if (contentDisposition == null) {
        const date = new Date();
        filename = 'Trip_Summary_' + date + '.csv';
      } else {
        filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
      }
      //Converting ArrayBuffer to String
      let arrbuf = new Uint8Array(res.body)
      //Converting the charcodes to string and replacing the string to Distance Travelled (KMS) 
      let text = (String.fromCharCode(...arrbuf)).replace("Distance Travelled,", "Distance Travelled (Kms),")
      // Converting the string to ArrayBuffer
      let uint8array = new TextEncoder().encode(text);
      const blob = new Blob([uint8array], {
        type: 'text/csv;charset=utf-8'
      });
      FileSaver.saveAs(blob, filename);
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }

  // togglePlacesFilter() {
  //   if (this.filterplace) {
  //     // this.page = 0;
  //     // this.pageInfo.offset = 0;
  //     this.clearFilter();
  //   } else {
  //     this.filterPlaces(this.startTime, this.endTime);
  //   }
  // }

  // navigateDates(dir) {
  //   if (dir === 'left') {
  //     this.startDt = new Date(this.startDt.setDate(this.startDt.getDate() - 1));
  //     this.disableNav = false;
  //   } else if (dir === 'right') {
  //     this.startDt = new Date(this.startDt.setDate(this.startDt.getDate() + 1));
  //   }
  //   this.getTrips('asc');
  // }

  // checkDateValidity() {
  //   if (
  //     this.startDt.getDate() === this.maxDate.getDate() &&
  //     this.startDt.getMonth() === this.maxDate.getMonth() &&
  //     this.startDt.getFullYear() === this.maxDate.getFullYear()
  //   ) {
  //     this.disableNav = true;
  //   } else {
  //     this.disableNav = false;
  //   }
  // }

  // Initialize map
  mapInit() {
    var mapOptionObject = {
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      zoom: 4,
      minZoom: 3,
      maxZoom: 21,
      mapTypeControl: true,
      mapTypeControlOptions: {
        // style : google.maps.MapTypeControlStyle.TOP_RIGHT,
        position: google.maps.ControlPosition.TOP_RIGHT
      },
      zoomControl: true,
      zoomControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
      },
      scaleControl: true,
      streetViewControl: true,
      streetViewControlOptions: {
        position: google.maps.ControlPosition.RIGHT_CENTER
      },
      fullscreenControl: false,
      center: {
        lat: this.selectedvehicleinfo.latitude ? this.selectedvehicleinfo.latitude : environment.lat,
        lng: this.selectedvehicleinfo.longitude ? this.selectedvehicleinfo.longitude : environment.lng
      },
      clickableIcons: false
    }
    this.map = new google.maps.Map(this.gmapElement.nativeElement, mapOptionObject);
  }

  // Receive Polyline data from API
  getPolylineData(startTime, endTime) {
    this.liveService.getTripsPolylineData(this.selecteddeviceId, startTime, endTime).subscribe(res => {
      // this.length = res.length;
      this.tripsData = res;
      if (res.length > 0) {
        this.clearMarkers();
        if (this.flightPath != undefined) { this.removePolyline(); }
        this.addPolyline(res);
        const imgStart = '../../assets/img/map-marker-start.png';
        const imgEnd = '../../assets/img/map-marker-end-red.png';
        for (var i = 0; i < res.length; i++) {
          if (res[i].messageType == "TRIP_END") {
            this.addMarker(res[i].latitude, res[i].longitude, imgEnd);
          }
          if (res[i].messageType == "TRIP_START") {
            this.addMarker(res[i].latitude, res[i].longitude, imgStart);
          }
        }
        if (this.showEvents) { this.plotEvents(res); }
      } else {
        this.map.setZoom(4);
      }
    }, error => {
      this.sharedService.getErrorMsg(error);
      this.map.setZoom(4);
      this.clearMarkers();
      this.removePolyline();
    });
  }

  addPolyline(data) {
    const flightPlanCoordinates = [];
    const bounds = new google.maps.LatLngBounds();

    data.forEach(item => {
      const latLngObj = {};
      if (item.latitude && item.longitude) {
        latLngObj['lat'] = item.latitude;
        latLngObj['lng'] = item.longitude;
        flightPlanCoordinates.push(latLngObj);
        // to extends the bounds
        bounds.extend(new google.maps.LatLng(item.latitude, item.longitude));
      }
    });

    this.flightPath = new google.maps.Polyline({
      path: flightPlanCoordinates,
      geodesic: true,
      strokeColor: '#891d56',
      strokeOpacity: 0.8,
      strokeWeight: 3
    });

    this.flightPath.setMap(this.map);
    this.map.fitBounds(bounds);
  }

  removePolyline() {
    if (this.flightPath != null) {
      this.flightPath.setMap(null);
    }
  }

  addMarker(lat, long, img, obj?) {
    const latlng = new google.maps.LatLng(lat, long);
    const marker = new google.maps.Marker({
      position: latlng,
      map: this.map,
      // label: eventDate,
      icon: img
    });
    this.setAnimation(marker);
    if (obj) {
      this.setInfoWindow(obj, marker);
    }
    this.markers.push(marker);
  }

  // Set Animation
  setAnimation(marker) {
    marker.setAnimation(google.maps.Animation.BOUNCE);
    setTimeout(function () { marker.setAnimation(null); }, 750);
  }

  clearMarkers() {
    for (let i = 0; i < this.markers.length; i++) {
      this.markers[i].setMap(null);
    }
    this.markers = [];
  }

  plotEvents(data) {
    this.harshAccelaration = [];
    this.harshBraking = [];
    this.overSpeeding = [];
    data.map(obj => {
      if (obj.messageType === 'HARD_BRAKING') {
        this.addMarker(obj.latitude, obj.longitude, this.harsh_braking_color, obj);
      } else if (obj.messageType === 'OVER_SPEEDING') {
        this.addMarker(obj.latitude, obj.longitude, this.high_speed_color, obj);
      } else if (obj.messageType === 'HARD_ACCELERATION') {
        this.addMarker(obj.latitude, obj.longitude, this.rapid_acceleration_color, obj);
      }
    });
  }

  // Set info Window for each marker
  setInfoWindow(obj, marker) {
    const global = this;
    if (obj.messageType === 'HARD_BRAKING') {
      this.harshBraking.push(marker);
      var brakeContent
      if(this.accordion.trip_view) {
        brakeContent = `
        <div id="brakeInfoWindow">
          <h5 style="border-bottom: 2px solid #c6425b">Hard Braking</h5>
          <p><span class="font-weight-bold">Initial Speed: </span><span>${this.sharedService.convertKPHtoMPH(obj.initialSpeed)} mph</span></p>
          <p><span class="font-weight-bold">Final Speed: </span><span>${this.sharedService.convertKPHtoMPH(obj.finalSpeed)} mph</span></p>
          <p><span class="font-weight-bold">Max Braking: </span><span>${this.sharedService.convertKPHtoMPH(obj.maxBraking)} mph</span></p>
       </div>`;
      } else {
        brakeContent = `
        <div id="brakeInfoWindow">
          <h5 style="border-bottom: 2px solid #c6425b">Hard Braking</h5>
          <p><span class="font-weight-bold">Initial Speed: </span><span>${this.sharedService.convertKPHtoMPH(obj.initialSpeed)} mph</span></p>
          <p><span class="font-weight-bold">Final Speed: </span><span>${this.sharedService.convertKPHtoMPH(obj.finalSpeed)} mph</span></p>
          <p><span class="font-weight-bold">Max Braking: </span><span>${this.sharedService.convertKPHtoMPH(obj.maxBraking)} mph</span></p>
          <p><span class="font-weight-bold">Event Time: </span><span>${obj.eventTime ? obj.eventTime : '--'}</span></p>
          <p><span class="font-weight-bold">Ingestion Time: </span><span>${obj.ingestionTimestamp ? obj.ingestionTimestamp: '--'}</span></p>
        </div>`;
      }
      var infoWindow = new google.maps.InfoWindow({
        content: brakeContent
      });
      global.infoWindow.push(infoWindow);

    } else if (obj.messageType === 'OVER_SPEEDING') {
      this.overSpeeding.push(marker);
      var speedContent; 
      if(this.accordion.trip_view) { 
        speedContent = `
        <div id="brakeInfoWindow">
          <h5 style="border-bottom: 2px solid #5ec5e2">Speeding</h5>
          <p><span class="font-weight-bold">Max Speed: </span><span>${this.sharedService.convertKPHtoMPH(obj.maxSpeed)} mph</span></p>
          <p><span class="font-weight-bold">Average Speed: </span><span>${this.sharedService.convertKPHtoMPH(obj.avgSpeed)} mph</span></p>
       </div>`; 
      } else {
        speedContent = `
        <div id="brakeInfoWindow">
          <h5 style="border-bottom: 2px solid #5ec5e2">Speeding</h5>
          <p><span class="font-weight-bold">Max Speed: </span><span>${this.sharedService.convertKPHtoMPH(obj.maxSpeed)} mph</span></p>
          <p><span class="font-weight-bold">Average Speed: </span><span>${this.sharedService.convertKPHtoMPH(obj.avgSpeed)} mph</span></p>
          <p><span class="font-weight-bold">Event Time: </span><span>${obj.eventTime ? obj.eventTime : '--'}</span></p>
          <p><span class="font-weight-bold">Ingestion Time: </span><span>${obj.ingestionTimestamp ? obj.ingestionTimestamp: '--' }</span></p>
        </div>`;
      }
    
      var infoWindow = new google.maps.InfoWindow({
        content: speedContent
      });
      global.infoWindow.push(infoWindow);

    } else if (obj.messageType === 'HARD_ACCELERATION') {
      this.harshAccelaration.push(marker);
      var acclContent;
      if(this.accordion.trip_view) {
        acclContent = `
        <div id="brakeInfoWindow">
          <h5 style="border-bottom: 2px solid #f6ab1f">Hard Acceleration</h5>
          <p><span class="font-weight-bold">Initial Speed: </span><span>${this.sharedService.convertKPHtoMPH(obj.initialSpeed)} mph</span></p>
          <p><span class="font-weight-bold">Final Speed: </span><span>${this.sharedService.convertKPHtoMPH(obj.finalSpeed)} mph</span></p>
          <p><span class="font-weight-bold">Max Acceleration: </span><span>${this.sharedService.convertKPHtoMPH(obj.maxAcceleration)} mph</span></p>
        </div>`;
       } else {
        acclContent = `
        <div id="brakeInfoWindow">
          <h5 style="border-bottom: 2px solid #f6ab1f">Hard Acceleration</h5>
          <p><span class="font-weight-bold">Initial Speed: </span><span>${this.sharedService.convertKPHtoMPH(obj.initialSpeed)} mph</span></p>
          <p><span class="font-weight-bold">Final Speed: </span><span>${this.sharedService.convertKPHtoMPH(obj.finalSpeed)} mph</span></p>
          <p><span class="font-weight-bold">Max Acceleration: </span><span>${this.sharedService.convertKPHtoMPH(obj.maxAcceleration)} mph</span></p>
          <p><span class="font-weight-bold">Event Time: </span><span>${obj.eventTime ? obj.eventTime: '--'}</span></p>
          <p><span class="font-weight-bold">Ingestion Time: </span><span>${obj.ingestionTimestamp ? obj.ingestionTimestamp : '--' }</span></p>
        </div>`;
      }
    
      var infoWindow = new google.maps.InfoWindow({
        content: acclContent
      });
      global.infoWindow.push(infoWindow);
    }
    var _this = this;
    google.maps.event.addListener(marker, 'mouseover', function () {
      if (global.infoWindow.length) {
        global.infoWindow.map(item => { item.close(); });
      }
      infoWindow.open(global.map, marker);
    });
    google.maps.event.addListener(marker, 'mouseout', function () {
      if (_this.dottedEvents.tripChecked) {
        infoWindow.close()
      }
    });
    google.maps.event.addListener(marker, 'mouseout', function () {
      if (!_this.dottedEvents.tripChecked) {
        infoWindow.close()
      }
    });
    google.maps.event.addListener(marker, 'click', function () {
      infoWindow.close();
    });
    google.maps.event.addListener(global.map, 'click', function () {
      infoWindow.close();
    });
  }

  goBackToLocationHistory() {
    this.updateToggle(this.dottedEvents.tripChecked, false);
    localStorage.setItem('liveSelectedVehicle', JSON.stringify(this.selectedDevice));
    this.router.navigate(['location-history'], {
      queryParams: {
        searchBy: '',
        value: '',
      }
    });
  }

  backToLive() {
    this.router.navigate(['/live']);
  }

  showReportsModal() {
    this.getallReports();
    document.getElementById('goToReportsBtn').click();
  }

  updateToggle(network, trips) {
    let localData = JSON.parse(localStorage.getItem("preferences"));
    localData["liveHistory"]["toggle"] = {};
    localData["liveHistory"]["toggle"]["networkLatency"] = network;
    localData["liveHistory"]["toggle"]["tripsView"] = trips;
    localStorage.setItem("preferences", JSON.stringify(localData));
    this.sharedService.updatePreference({ "liveHistory": { "toggle": localData["liveHistory"]["toggle"] } }).subscribe(data => {
      console.log("data", data);
    })
  }
  networkToggle(p1) {
    // let localData = JSON.parse(localStorage.getItem("preferences"));
    // localData["liveHistory"]["toggle"]={};
    // localData["liveHistory"]["toggle"]["networkLatency"] = !this.selectedNetwork;
    // localData["liveHistory"]["toggle"]["tripsView"] = true;
    // this.sharedService.updatePreference({ "liveHistory": {"toggle":localData["liveHistory"]["toggle"]}}).subscribe(data=>{
    //   console.log("data", data);
    // })
    this.mapInit();
    this.updateToggle(!this.dottedEvents.tripChecked, true);
    if (p1) {
      this.dottedEvents.tripChecked = p1;
      this.switchToDots(this.selecteddeviceId, this.obd.ts, this.obd.te);
    } else {
      this.dottedEvents.tripChecked = p1;
      this.clearMarkers();
      this.removePolyline();
      // for (var i = 0; i < this.flightPath.length; i++) {
      //   this.flightPath[i].setMap(null);
      // }
      this.selectTrip({ selected: [this.selectedTrip] });
    }
  }
  switchToDots(deviceId?, startTime?, endTime?) {
    // this.selecteddeviceId, this.startTime, this.endTime
    // if (!deviceId){
    //   deviceId = this.selecteddeviceId;
    //   startTime = this.startTime;
    //   endTime = this.endTime;
    // }
    // this.selectedStartDate = startTime;
    // this.selectedEnddate = endTime;
    this.liveService.getPolylineData(deviceId, startTime, endTime).subscribe(data => {
      this.liveService.getTripsPolylineData(this.selecteddeviceId, startTime, endTime).subscribe(res => {
        if (this.markers) {
          this.clearMarkers();
        }
        this.removePolyline();
        let locationData = data.locations;
        let graceArr = [];
        let uniqueChars = [];
        locationData.forEach((c) => {
          if (!uniqueChars.includes(c)) {
            uniqueChars.push(c);
          }
        });
        uniqueChars.forEach((ele, index) => {
          let tempObj = uniqueChars[index];
          res.forEach((resEle) => {
            if (ele.eventTime === resEle.eventTime && ele.latitude === resEle.latitude && ele.longitude === resEle.longitude && resEle.messageType != "GPS_MESSAGE") {
              Object.assign(tempObj, resEle);
            }
          })
          graceArr.push(tempObj)
        })
        locationData = graceArr;
        this.tripsData = locationData.filter(item => {
          if (item.ingestionTimestamp) {
            let eventTime = Date.parse(item.eventTime);
            let ingestionTimestamp = Date.parse(item.ingestionTimestamp);
            let timeDiff = ingestionTimestamp - eventTime;
            let result = Math.floor((timeDiff / 1000) / 60);
            if (result <= 3) {
              var iconUrl = {
                url: '../../assets/img/location-history-icons/green_circle.png',
                labelOrigin: { x: 50, y: -15 },
                scaledSize: new google.maps.Size(10, 10)
              }
            } else if (result > 3 && result <= 10) {
              var iconUrl = {
                url: '../../assets/img/location-history-icons/orange_circle.png',
                labelOrigin: { x: 50, y: -15 },
                scaledSize: new google.maps.Size(10, 10)
              }
            } else if (result > 10) {
              var iconUrl = {
                url: '../../assets/img/location-history-icons/red_circle.png',
                labelOrigin: { x: 50, y: -15 },
                scaledSize: new google.maps.Size(10, 10)
              }
            }
            if (item.messageType === 'HARD_BRAKING') {
              this.addMarker(item.latitude, item.longitude, this.harsh_braking_color, item);
            } else if (item.messageType === 'OVER_SPEEDING') {
              this.addMarker(item.latitude, item.longitude, this.high_speed_color, item);
            } else if (item.messageType === 'HARD_ACCELERATION') {
              this.addMarker(item.latitude, item.longitude, this.rapid_acceleration_color, item);
            }
            item.iconUrl = iconUrl;
            return item;
          }
        })
        this.tripsData.map(item => {
          this.addDots(item);
        })
        this.setDotsBounds();

      })
    })
  }
  // switchToDots() {
  //      if (this.markers) {
  //       this.clearMarkers();
  //     }

  //     this.removePolyline();
  //   this.tripsData = this.tripsData.map(item => {
  //          let eventTime = Date.parse(item.eventTime);
  //         // let ingestionTimestamp = Date.parse(item.ingestionTimestamp);
  //         let timeDiff = eventTime;
  //         let result = Math.floor((timeDiff / 1000) / 60);
  //         if (result <= 3) {
  //           var iconUrl = {
  //             url: '../../assets/img/location-history-icons/green_circle.png',
  //             labelOrigin: { x: 50, y: -15 },
  //             scaledSize: new google.maps.Size(10, 10)
  //           }
  //         } else if (result > 3 && result <= 10) {
  //           var iconUrl = {
  //             url: '../../assets/img/location-history-icons/orange_circle.png',
  //             labelOrigin: { x: 50, y: -15 },
  //             scaledSize: new google.maps.Size(10, 10)
  //           }
  //         } else if (result > 10) {
  //           var iconUrl = {
  //             url: '../../assets/img/location-history-icons/red_circle.png',
  //             labelOrigin: { x: 50, y: -15 },
  //             scaledSize: new google.maps.Size(10, 10)
  //           }
  //         }
  //         item.iconUrl = iconUrl;
  //         return item;

  //     })
  //     this.tripsData.map(item => {
  //       this.addDots(item);
  //     })
  //     this.setDotsBounds();

  // }
  checkInfoWindow(data) {
    return (data.ingestionTimestamp  && this.accordion.trip_view) || (data.ingestionTimestamp  && this.accordion.msg_view) || 
    this.dottedEvents.tripChecked || !this.dottedEvents.tripChecked
  }
  addDots(data) {
    const bounds = new google.maps.LatLngBounds();
    var latlng = new google.maps.LatLng(data.latitude, data.longitude);
    var marker = new google.maps.Marker({
      position: latlng,
      map: this.map,
      icon: data.iconUrl
    });
    this.markers.push(marker);
    let global = this;
   
      google.maps.event.addListener(marker, 'mouseover', function () {
        global.latencyData = data;
        this.infowindow = new google.maps.InfoWindow();
        this.infowindow.setContent(data.ingestionTimestamp && global.infowindowData.nativeElement);
        this.infowindow.open(global.map, marker);
      });
      google.maps.event.addListener(marker, 'mouseout', function () {
        this.infowindow.close();
      });
    
    // if(this.tripsData.length <= 1) {
    //   bounds.extend(latlng);
    //   this.map.fitBounds(bounds);
    // } else {
    //   this.map.setZoom(5);
    //   this.map.setCenter({ lat: environment.lat, lng: environment.lng });
    // }

  }
  setDotsBounds() {
    const bounds = new google.maps.LatLngBounds();
    for (let u in this.tripsData) {
      if (u) {
        var marker = new google.maps.Marker({
          position: new google.maps.LatLng(this.tripsData[u].latitude,
            this.tripsData[u].longitude),
        });
        bounds.extend(marker.getPosition());
      }
    }
    this.map.fitBounds(bounds);
  }
  onPIDClick(e) {
    this.graphContainerParams = e;
  }

  updatePointerEvents(e) {
    this.obdPidView.disablePointerEvents = e;
  }

  // map message view section
  initMapMessageView() {
    if (this.selectedDevice.architectureType) this.getMessageType(this.selectedDevice.architectureType);
    if ((this.querySelectVehicle && this.querySelectVehicle.lastCommunication) || (this.selectedDevice)) {
      this.selectedDevice.eventTime = this.querySelectVehicle && this.querySelectVehicle.hasOwnProperty('lastCommunication') ? this.querySelectVehicle.lastCommunication : this.selectedDevice.lastCommunication;
      if (!this.tripViewToggle) {  
      const endTime = Date.parse(this.selectedDevice.lastCommunication);
        const startTime = endTime - (1000 * 60 * 60 * 24);
        this.selectedDevice.startTime = startTime;
        this.selectedDevice.endTime = endTime;
        this.pickerStart = startTime;
        this.pickerEnd = endTime;
      }
      this.usingEventTime = true;
      this.getAllData();
    } else {
      this.historyRows = 0;
      this.historyRowsEmpty = true;
      this.locationTotalCount.count = 0;
      this.alerts = [];
      this.locationVehicles = 0;
      this.totalDistanceTravelled = 0;
      const endTime = Date.parse(new Date().toUTCString());
      const startTime = endTime - (1000 * 60 * 60 * 4);
      this.selectedDevice.startTime = startTime;
      this.selectedDevice.endTime = endTime;
      // this.mapInit();
    }
  }

  getMessageType(architectureType) {
    this.dataService.getMessageType(architectureType).subscribe({
      next: res => {
        this.messageTypeArray = res;
        // this.searchModel.messageType = this.messageTypeArray[0].messageType;
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  getAllData() {
    this.page = 0;
    this.pageVehicleHistory = 0;
    this.pageInfo.offset = 0;
    this.totalDistanceTravelled = 0;
    this.getPermissionsLiveHistory();
    // this.getAlerts(this.startTime, this.endTime);
    let start = this.pickerStart;
    let end = this.pickerEnd;
    this.obd.ts = this.pickerStart;
    this.obd.te = this.pickerEnd;

    if (this.selected.length && Object.keys(this.selected[0]).length && this.tripViewToggle) {
      this.obd.ts = this.pickerStart;
      this.obd.te = this.pickerEnd;  
    } else {
      this.obd.ts = this.pickerStart;
      this.obd.te = this.pickerEnd;
    }
    if (this.markers.length) { this.clearMarkers(); }
    if (this.dottedEvents.tripChecked) {
      this.plotDottedLine();
    } else {
      this.switchToDots(this.selecteddeviceId, this.obd.ts, this.obd.te);
    }
    // this.plotDottedLine();
    if (this.filterplace) {
      this.filterPlaces(start, end);
    } else {
      if (this.timeSeeker.selected) {
        this.getSingleVehicleView(this.selecteddeviceId, this.timeSeeker.ts, this.timeSeeker.te);
        this.getTestCount(this.timeSeeker.ts, this.timeSeeker.te);
      } else {
        this.getSingleVehicleView(this.selecteddeviceId, this.pickerStart, this.pickerEnd);
        this.getTestCount(this.pickerStart, this.pickerEnd);
      }
    }
    this.selectedMessages = [];
    this.clearAllMessageMarkers();
    if (this.isDrawerOpen) this.getAllMessagesWithCount();
  }

  // To get preferences and permissions
  getPermissionsLiveHistory() {
    this.liveHistoryKey = [];
    if (localStorage['liveHistoryPrefrence']) {
      const liveHistoryPrefrence = JSON.parse(localStorage.getItem('liveHistoryPrefrence'));
      this.livehistorySearchheader = liveHistoryPrefrence;
      this.liveHistoryKey = liveHistoryPrefrence.map(function (data) {
        return data.key;
      })
      if (this.liveHistoryKey.length) {
        this.liveHistorykeyString = this.liveHistoryKey.toString().concat(',').concat(this.liveHistoryAttribute);
      } else {
        this.liveHistorykeyString = this.liveHistoryAttribute;
      }
    } else {
      this.liveHistorykeyString = this.liveHistoryAttribute;
      this.livehistorySearchheader = '';
    }
  }

  /*Polyline and marker creation method starts*/
  getmapPolyline(startTime, endTime) {
    let req = null;
    if (this.timeSeeker.selected) {
      req = this.liveService.getPolylineData(this.selecteddeviceId, this.timeSeeker.ts, this.timeSeeker.te);
    } else {
      req = this.liveService.getPolylineData(this.selecteddeviceId, startTime, endTime);
    }
    req.subscribe(res => {
      if (this.markers.length) {
        this.clearMarkers();
      }
      if (res.events.length > 0) {
        this.plotEvents(res.events);
      }
      this.locationVehicles = res;
      this.totalDistanceTravelled = res.distanceTravelled;
      if (this.selectedDevice && this.selectedDevice.generation === 'V2X') {
        if (res.locations.length > 0) {
          var markerUrl = '../../assets/img/direction/v2x.png';
          if (res.locations[0] && res.locations[0].publishedBy === 'OBU Simulation') {
            markerUrl = '../../assets/img/marker_red.png';
          }
          var iconUrl = {
            url: markerUrl,
            labelOrigin: { x: 50, y: -15 }
          }
          res.locations[0].iconUrl = iconUrl;
          this.addMessageMarker(res.locations[0]);
          this.addBounds(res.locations);
        } else {
          this.map.setZoom(4);
        }
      } else {
        if (res.locations.length > 0) {
          if (res.locations[res.locations.length - 1]) {
            const iconUrl = {
              url: '../../assets/img/marker_start.png',
              labelOrigin: { x: 50, y: -15 }
            };
            res.locations[res.locations.length - 1].iconUrl = iconUrl;
            this.addMessageMarker(res.locations[res.locations.length - 1]);
          }
          if (res.locations[0]) {
            const heading = res.locations[0].heading;
            const packet = res.locations[0];
            let icon = '';
            if (packet.messageType) {
              if (packet.messageType === 'COMMUNICATION_LOST') {
                icon = '../../../../assets/img/direction/warning/';
              } else if (packet.messageType === 'TRIP_END' || packet.messageType === 'IGNITION_OFF') {
                icon = '../../../../assets/img/direction/red/';
              } else if (!packet.endPacket && packet.messageType !== 'TRIP_END' && packet.messageType !== 'IGNITION_OFF') {
                icon = '../../../../assets/img/direction/green/';
              } else {
                icon = '../../../../assets/img/direction/gray/';
              }
            } else {
              icon = '../../../../assets/img/direction/default/';
            }
            let iconName = `${icon}stop.png`;
            if (heading || heading == '0') {
              if (heading > 0 && heading <= 30) {
                iconName = `${icon}30.png`;
              } else if (heading > 30 && heading <= 60) {
                iconName = `${icon}60.png`;
              } else if (heading > 60 && heading <= 90) {
                iconName = `${icon}90.png`;
              } else if (heading > 90 && heading <= 120) {
                iconName = `${icon}120.png`;
              } else if (heading > 120 && heading <= 150) {
                iconName = `${icon}150.png`;
              } else if (heading > 150 && heading <= 180) {
                iconName = `${icon}180.png`;
              } else if (heading > 180 && heading <= 210) {
                iconName = `${icon}210.png`;
              } else if (heading > 210 && heading <= 240) {
                iconName = `${icon}240.png`;
              } else if (heading > 240 && heading <= 270) {
                iconName = `${icon}270.png`;
              } else if (heading > 270 && heading <= 300) {
                iconName = `${icon}300.png`;
              } else if (heading > 300 && heading <= 330) {
                iconName = `${icon}330.png`;
              } else if (heading > 330 && heading <= 360 || heading == '0') {
                iconName = `${icon}0.png`;
              }
            }
            const iconUrl = {
              url: iconName,
              labelOrigin: { x: 50, y: -15 }
            };
            res.locations[0].iconUrl = iconUrl;
            this.addMessageMarker(res.locations[0]);
          }
          this.addPolyline(res.locations);
        } else {
          this.map.setZoom(4);
        }
      }
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }

  addBounds(data) {
    let bounds = new google.maps.LatLngBounds();
    data.forEach(item => {
      if (item.latitude && item.longitude) {
        // to extends the bounds
        bounds.extend(new google.maps.LatLng(item.latitude, item.longitude));
      }
    });
    this.map.fitBounds(bounds);
  }

  filterPlaces(startTime, endTime) {
    this.loadingIndicator = true;
    this.filterplace = true;
    this.selectedFilter = 'placeFilter';
    this.liveService.getfilteredPlaces(
      this.selecteddeviceId, this.pageVehicleHistory, this.liveHistorykeyString, startTime, endTime, this.resSize
    ).subscribe(res => {
      this.ngzone.run(() => {
        if (res.length > 0) {
          if (this.pageVehicleHistory == 0) {
            this.historyRows = [];
          }
          this.historyRowsEmpty = false;
          this.historyRows = [...this.historyRows, ...res];
          this.selectedDevice.trips = this.historyRows;
          this.selectedDevice.trips = this.selectedDevice.trips.map(data => {
            if (data.vehicleSpeed && !Number.isInteger(data.vehicleSpeed)) {
              let newvehicleSpeed = data.vehicleSpeed;
              data.vehicleSpeed = parseFloat(newvehicleSpeed).toFixed(1);
            }
            var messageValue = configMessage.messageType[data.eventType];
            if (!messageValue) {
              messageValue = data.eventType;
            }
            data.activity = `${messageValue} (${data.placeName})`;
            let global = this;
            if (global.places.length) {
              global.places.forEach(placeData => {
                if (data.eventType == 'IN' && data.placeId == placeData.placeId) {
                  data.place = placeData;
                }
              });
              if (data.place) {
                global.plotCircle(data.place);
              }
            }
            return data;
          });
          // To set page Count
          this.setPageInfo();
          this.getPlcesFilterCount(startTime, endTime);
          this.pageCount = res.length;
          this.loadingIndicator = false;

        } else {
          this.currentPageCount = {
            start: 0,
            end: 0
          };
          this.historyRowsEmpty = true;
          this.historyRows = [];
          this.mapInit();
          this.getPlcesFilterCount(startTime, endTime);
          this.mapInit();
        }
      });
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }

  getPlcesFilterCount(startTime, endTime) {
    this.isLoading = true;
    this.liveService.getPlcesFilterCount(
      this.selecteddeviceId, startTime, endTime, this.pageVehicleHistory, this.resSize
    ).subscribe(res => {
      this.locationTotalCount = res;
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }

  setPageInfo() {
    this.pageInfo.count = this.historyRows.length;
    let pagecount = this.pageInfo.count / this.pageInfo.limit;
    this.pageInfo.lastPage = Math.floor(pagecount);
    let decimal = pagecount - this.pageInfo.lastPage;

    if (!Number.isInteger(decimal)) {
      this.pageInfo.lastPage = this.pageInfo.lastPage + 1;
    }
    if (this.pageVehicleHistory == 0) {
      if (this.historyRows.length) {
        this.currentPageCount.start = (this.pageInfo.offset * this.pageInfo.pageSize) + 1;
        if (this.historyRows.length <= this.pageInfo.limit) {
          this.currentPageCount.end = this.pageInfo.count;
        } else {
          this.currentPageCount.end = (this.pageInfo.offset * this.pageInfo.pageSize) + this.pageInfo.limit;
        }
      }
    }
  }

  plotCircle(place) {
    const location = { lat: place.circle.centre[0], lng: place.circle.centre[1] };
    this.cityCircle = new google.maps.Circle({
      strokeColor: place.color,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: place.color,
      fillOpacity: 0.35,
      map: this.map,
      center: location,
      radius: place.circle.radius,
      editable: false
    });
    let latlng = new google.maps.LatLng(location.lat, location.lng);
    let bounds = new google.maps.LatLngBounds();
    bounds.extend(latlng);
    this.multipleCircles.push(this.cityCircle);
    // this.map.fitBounds(this.cityCircle.getBounds());
  }

  // To get data for specific vehicle
  getSingleVehicleView(deviceId, startTime, endTime, messageType?) {
    try {
      this.enablePageVehicleHistory = true;
      this.loadingIndicator = true;
      this.viewPage = true;
      this.getPermissionsLiveHistory();
      if (this.pageVehicleHistory == 0) {
        this.historyRows = [];
      }
      if (deviceId) {
        if (this.timeSeeker.selected) {
          this.getMessages(this.selecteddeviceId, this.timeSeeker.ts, this.timeSeeker.te);
        } else {
          this.getMessages(deviceId, startTime, endTime, messageType);
        }
      } else {
        this.messagesReset();
      }
    } catch (e) {
      console.log(e.message);
    }
  }

  // To add a marker in the map
  addMessageMarker(data) {
    let localDate = new Date(data.eventTime);
    var datePipe = new DatePipe('en-US');
    let eventDate = datePipe.transform(data.eventTime, 'MMM d, h:mm:ss a');
    var latlng = new google.maps.LatLng(data.latitude, data.longitude);
    var marker = new google.maps.Marker({
      position: latlng,
      map: this.map,
      label: eventDate,
      icon: data.iconUrl.url
    });
    this.markers.push(marker);
  }
  plotDottedLine() {
    let req = null;
    if (this.timeSeeker.selected) {
      req = this.liveService.getPolylineData(this.selecteddeviceId, this.timeSeeker.ts, this.timeSeeker.te);
    } else {
      req = this.liveService.getPolylineData(this.selecteddeviceId, this.pickerStart, this.pickerEnd);
    }
    req.subscribe(res => {
      // this.locationVehicles = res;
      if (this.markers) {
        this.clearMarkers();
      }
      if (this.line.length) {
        this.clearPolyline();
      }
      this.removePolyline();
      this.totalDistanceTravelled = res.distanceTravelled;
      if (res.locations.length > 0) {
        this.locationVehicles = res.locations.filter(item => {
          if (item.ingestionTimestamp) {
            let eventTime = Date.parse(item.eventTime);
            let ingestionTimestamp = Date.parse(item.ingestionTimestamp);
            let timeDiff = ingestionTimestamp - eventTime;
            // let ms = 1000*Math.round(timeDiff/1000); // round to nearest second
            // let d = new Date(ms);
            // let result = d.getUTCMinutes();
            let result = Math.floor((timeDiff / 1000) / 60);
            if (result <= 3) {
              var iconUrl = {
                url: '../../assets/img/location-history-icons/green_circle.png',
                labelOrigin: { x: 50, y: -15 },
                scaledSize: new google.maps.Size(10, 10)
              }
            } else if (result > 3 && result <= 10) {
              var iconUrl = {
                url: '../../assets/img/location-history-icons/orange_circle.png',
                labelOrigin: { x: 50, y: -15 },
                scaledSize: new google.maps.Size(10, 10)
              }
            } else if (result > 10) {
              var iconUrl = {
                url: '../../assets/img/location-history-icons/red_circle.png',
                labelOrigin: { x: 50, y: -15 },
                scaledSize: new google.maps.Size(10, 10)
              }
            }
            item.iconUrl = iconUrl;
            return item;
          }
        })
        this.locationVehicles.map(item => {
          this.addDots(item);
        })
        this.setDotsBounds();
      }
      else {
        this.map.setZoom(4);
      }
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }
  getTestCount(startTime, endTime) {
    // if(!this.searchModel.messageType) {
    if (this.selecteddeviceId) {
      this.isLoading = true;
      this.liveService.getTestCount(
        this.selecteddeviceId, startTime, endTime, this.pageVehicleHistory, this.resSize, this.searchModel.messageType
      ).subscribe(res => {
        this.locationTotalCount = res;
      }, error => {
        this.sharedService.getErrorMsg(error);
      });
    }
    // }
  }
  clearAllMessageMarkers() {
    for (var k in this.messageMarkers) {
      if (this.messageMarkers[k] && this.messageMarkers[k].length) {
        this.clearMessageMarkers(k);
      }
    }
    this.messageMarkers = [];
  }
  clearPolyline() {
    for (var i = 0; i < this.line.length; i++) {
      this.line[i].setMap(null);
    }
    this.line = [];
  }
  getAllMessagesWithCount() {
    this.messagesLoading = true;
    const startTime = new Date(this.pickerStart);
    const endTime = new Date(this.pickerEnd);
    const start = `${startTime.getFullYear()}-${startTime.getMonth() + 1}-${startTime.getDate()}`;
    const end = `${endTime.getFullYear()}-${endTime.getMonth() + 1}-${endTime.getDate()}`;
    this.reportsService.getVehicleMessageData(this.selecteddeviceId, start, end).subscribe({
      next: res => {
        // find all the distinct messages
        if (res.length) {
          const list = [];
          res.forEach(item => {
            for (var k in item) {
              if (k !== 'date' && k !== 'NO_DATA') {
                const obj = { message: '', color: '', count: 0 };
                if (item[k]) {
                  obj.message = k;
                  obj.count = item[k];
                  if (this.messageTypeColor[k]) obj.color = this.messageTypeColor[k].colorOpaque;
                  else obj.color = this.messageTypeColor['UNKNOWN'].colorOpaque;
                  list.push(obj);
                }
              }
            }
          });
          this.messages = list;
        } else {
          this.messages = [];
          this.clearAllMessageMarkers();
        }
        this.messagesLoading = false;
      },
      error: error => {
        this.messagesLoading = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }
  clearMessageMarkers(messageType) {
    for (let i = 0; i < this.messageMarkers[messageType].length; i++) {
      this.messageMarkers[messageType][i].setMap(null);
    }
    this.messageMarkers = [];
  }
  getMessages(deviceId, startTime, endTime, messageType?) {
    this.liveService.getSingleVehicleMessage(
      deviceId, this.pageVehicleHistory, this.liveHistorykeyString, startTime, endTime, this.resSize, this.searchModel.messageType
    ).subscribe(res => {
      this.ngzone.run(() => {
        if (res.length > 0) {
          this.historyRowsEmpty = false;
          // if (this.timeSeeker.selected) {
          //   this.historyRows = res;
          // } else {
          //   this.historyRows = [...this.historyRows, ...res];
          // }
          if (this.pageVehicleHistory === 0) {
            this.historyRows = res;
          } else {
            this.historyRows = [...this.historyRows, ...res];
          }
          this.selectedDevice.trips = this.historyRows;
          this.selectedDevice.trips = this.selectedDevice.trips.map(data => {
            if (data.vehicleSpeed && !Number.isInteger(data.vehicleSpeed)) {
              let newvehicleSpeed = data.vehicleSpeed;
              data.vehicleSpeed = parseFloat(newvehicleSpeed).toFixed(1);
            }
            var messageValue = configMessage.messageType[data.messageType];
            if (!messageValue) {
              messageValue = data.messageType;
            }
            data.activity = messageValue;
            return data;
          });
          // To set page Count
          this.setPageInfo();
          this.prevResponse = res.length;
          this.pageCount = res.length;
          this.loadingIndicator = false;
        } else {
          this.messagesReset();
        }
        // if(this.searchModel.messageType) {
        //   this.locationTotalCount.count = 0;
        // }
      });
    }, error => {
      this.sharedService.getErrorMsg(error);
    });
  }
  messagesReset() {
    if (!this.pageVehicleHistory) {
      this.historyRowsEmpty = true;

      this.currentPageCount = {
        start: 0,
        end: 0
      };
    }
    this.pageCount = 0;
    this.historyRowsEmpty = true;
    this.historyRows = [];
    this.prevResponse = 0;
  }
  pageCallback(value) {
    this.pageInfo.offset = value.offset;
    this.currentPageCount.start = (value.offset * value.pageSize) + 1;
    const lastPage = Math.floor(this.historyRows.length / this.pageInfo.limit);
    if (lastPage == value.offset) {
      this.currentPageCount.end = value.count;
    } else {
      this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
    }
    // if (this.pageCount == 100) {
    if (this.pageInfo.lastPage == (this.pageInfo.offset + 1)) {
      if (this.prevResponse == 100) {
        this.pageVehicleHistory = ++this.pageVehicleHistory;
        // if (this.usingEventTime) {
        //   this.getSingleVehicleView(this.vehicle.deviceId, this.vehicle.startTime, this.vehicle.endTime);
        // } else {
        if (this.timeSeeker.selected) {
          this.getSingleVehicleView(this.selecteddeviceId, this.timeSeeker.ts, this.timeSeeker.te);
        } else {
          this.getSingleVehicleView(this.selecteddeviceId, this.pickerStart, this.pickerEnd);
        }
        // }
      }
    }
    // }
  }
  switchMapForLatency(p1) {
    this.dottedEvents.tripChecked = p1;
    this.mapInit();
    this.updateToggle(!this.dottedEvents.tripChecked, true);
    if (p1) {
      this.plotDottedLine();
    } else {
      this.switchToDots(this.selecteddeviceId, this.obd.ts, this.obd.te);
    }
  }
  switchMap(p1) {
    this.mapInit();
    this.dottedEvents.tripChecked = p1;
    this.updateToggle(this.dottedEvents.tripChecked, false);
    if (this.dottedEvents.tripChecked) {
      this.plotDottedLine();
    } else {
      this.getmapPolyline(this.pickerStart, this.pickerEnd);
    }
  }
  resetTimeSeeker() {
    this.timeSeeker = {
      selected: false,
      ts: null,
      te: null,
      selectedVal: [],
      len: 0
    };
  }
  resetAccordionParams() {
    this.tripViewToggle = true;
    this.accordion.isHavingEnoughSpace = false;
    this.accordion.trip_view = true;
    this.accordion.msg_view = false;
    this.accordion.pid_view = false;
    this.accordion.isMsgManualCollapse = false;
    this.accordion.isPidManualCollapse = false;
    this.accordion.isTripManualCollapse = false;
  }
  onTimeSelection(val) {
    this.pageVehicleHistory = 0;
    this.pageInfo.offset = 0;
    if (this.timeSeeker.selectedVal.includes(val.ts)) {
      const index = this.timeSeeker.selectedVal.indexOf(val.ts);
      this.timeSeeker.selectedVal.splice(index, 1);
      this.timeSeeker.selected = false;
      this.obd.ts = this.pickerStart;
      this.obd.te = this.pickerEnd;
      this.getMessages(this.selecteddeviceId, this.pickerStart, this.pickerEnd, this.searchModel.messageType);
      this.getTestCount(this.pickerStart, this.pickerEnd);
    } else if (this.timeSeeker.selectedVal.length < 1 || this.timeSeeker.selectedVal.length === 1) {
      this.timeSeeker.selectedVal = [];
      this.timeSeeker.selected = true;
      this.timeSeeker.ts = val.ts;
      this.timeSeeker.te = val.te;
      this.obd.ts = val.ts;
      this.obd.te = val.te;
      this.getMessages(this.selecteddeviceId, val.ts, val.te, this.searchModel.messageType);
      this.getTestCount(val.ts, val.te);
      this.timeSeeker.selectedVal.push(val.ts);
    }
    this.mapInit();
    this.getAllData();
  }
  tripViewSwitch(p1) {
    this.resetTimeSeeker();
    this.tripViewToggle = p1;
    if (p1) {
      this.accordion.msg_view = false;
      this.accordion.trip_view = true;
    } else {
      this.accordion.trip_view = false;
      this.accordion.msg_view = true;
      this.obd.ts = this.pickerStart;
      this.obd.te = this.pickerEnd;
    }
    this.initiateData();
  }
  getFilteredMessage() {
    this.page = 0;
    this.pageVehicleHistory = 0;
    this.pageInfo.offset = 0;
    if (!this.filterplace) {
      // this.getSingleVehicleView(this.vehicle.deviceId, this.startTime, this.endTime, this.searchModel.messageType);
      if (this.timeSeeker.selected) {
        this.getSingleVehicleView(this.selecteddeviceId, this.timeSeeker.ts, this.timeSeeker.te);
        this.getTestCount(this.timeSeeker.ts, this.timeSeeker.te);
      } else {
        this.getSingleVehicleView(this.selecteddeviceId, this.pickerStart, this.pickerEnd);
        this.getTestCount(this.pickerStart, this.pickerEnd);
      }
    }
    else {
      this.filterPlaces(this.pickerStart, this.pickerEnd);
    }
  }
  getMessageJSON(messageId) {
    this.messageJsonLoader = true;
    this.dataService.getMessage(messageId).subscribe((res: any) => {
      this.messageJsonLoader = false;
      this.messageJsonRaw = JSON.stringify(res, null, 4);
      this.messageJsonFormattedString = this.sharedService.syntaxHighlight([res]);
    }, error => {
      this.messageJsonLoader = false;
      this.sharedService.getErrorMsg(error);
    });
  }
  downloadMessageJSON() {
    const blob = new Blob([this.messageJsonRaw], {
      type: 'text/json;charset=utf-8'
    });
    FileSaver.saveAs(blob, 'message.json');
  }
  togglePlacesFilter() {
    this.totalDistanceTravelled = 0;
    if (this.filterplace) {
      this.page = 0;
      this.pageInfo.offset = 0;
      this.clearFilter();
    } else {
      // if (this.usingEventTime) {
      //   this.filterPlaces(this.vehicle.startTime, this.vehicle.endTime);
      // } else {
      this.filterPlaces(this.pickerStart, this.pickerEnd);
      // }
    }
  }
  clearFilter() {
    this.selectedFilter = '';
    this.filterplace = false;
    let start = null;
    let end = null;
    // if (this.usingEventTime) {
    //   start = this.vehicle.startTime;
    //   end = this.vehicle.endTime;
    // } else {
    start = this.pickerStart;
    end = this.pickerEnd;
    // }
    this.pageVehicleHistory = 0
    // this.getSingleVehicleView(this.vehicle.deviceId, start, end);
    if (this.timeSeeker.selected) {
      this.getSingleVehicleView(this.selecteddeviceId, this.timeSeeker.ts, this.timeSeeker.te);
      this.getTestCount(this.timeSeeker.ts, this.timeSeeker.te);
    } else {
      this.getSingleVehicleView(this.selecteddeviceId, this.pickerStart, this.pickerEnd);
      this.getTestCount(this.pickerStart, this.pickerEnd);
    }
    if (this.dottedEvents.checked) {
      this.plotDottedLine();
    } else {
      this.getmapPolyline(this.pickerStart, this.pickerEnd);
    }
    if (this.multipleCircles.length) {
      this.multipleCircles.forEach(item => {
        item.setMap(null);
      });
    }
  }
  downloadViewPage() {
    this.getHistoryCsvHeaderData();
    const data = this.historyRows.map(vehicle => {
      return this.csvLiveHistPrefKeyArray.map(csvHeader => {
        if (csvHeader === 'eventTime') {
          vehicle[csvHeader] = moment(vehicle[csvHeader]).format('DD MMM YYYY, hh:mm a');
        }
        return vehicle[csvHeader] || '';
      });
    });

    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalseparator: '.',
      showLabels: false,
      showTitle: false,
      useBom: false,
      headers: this.csvLiveHistPrefHeaderArray
    };
    const date = new Date();
    const filename = this.selectedDevice.assetName + '' + date + '';
    new Angular5Csv(data, filename, options);
  }
  // To download vehicle data as a csv file
  getHistoryCsvHeaderData() {
    // Getting the liveHistoryPrefrence and the default list of liveAttribute and creating a CSV Header Array after removing the duplicates
    // const defaultLiveHistoryArray = this.liveHistoryAttribute.split(',');
    const liveHistoryPreference = JSON.parse(localStorage.getItem('liveHistoryPrefrence'));
    if (localStorage['liveHistoryPrefrence']) {
      const liveHistoryPrefKeyArray = liveHistoryPreference.map(data => data.key);
      this.csvLiveHistPrefKeyArray = Array.from(new Set(['eventTime', ...liveHistoryPrefKeyArray]));
      // Creating our User Preferred Header Array with the display names to be shown on the downloaded file
      this.livehistorySearchheader.map(obj => {
        this.csvLiveHistPrefKeyArray.map(keys => {
          if (keys === obj.key) {
            const i = this.csvLiveHistPrefKeyArray.indexOf(keys);
            this.csvLiveHistPrefHeaderArray[i] = obj.displayName;
          }
        });
      });
    } else {
      this.csvLiveHistPrefKeyArray = ['eventTime'];
    }
    this.csvLiveHistPrefHeaderArray[0] = 'Time';
  }
}
