import { Router } from '@angular/router';
var AuthGuard = /** @class */ (function () {
    function AuthGuard(router) {
        this.router = router;
    }
    AuthGuard.prototype.canActivate = function (route, state) {
        if (localStorage.getItem('currentUser') || (localStorage.getItem('token'))) {
            // logged in so return true
            return true;
        }
        this.router.navigate(['login']);
        return false;
    };
    return AuthGuard;
}());
export { AuthGuard };
