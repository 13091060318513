import { Component, OnInit, NgZone, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@layouts/login/authentication.service';
import { SharedService } from '@myproject/shared/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@myenv/environment';

import { CookieService } from 'angular2-cookie/core';
import { enMessage } from '@shared/en-us-message';
import { DashboardService } from '@components/dashboard/dashboard.service';
import { configMessage } from '@shared/config-message';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  // localStorage = localStorage;
  // public tenantId = environment.tenantId;
  unoHeader = environment.unoHeader;
  public cmImageUrl = environment.cmImageUrl;
  currentUser: any = {};
  defaultReportUrl = localStorage;
  window = window;
  deviceId = '';
  url = '';
  accessRoleModules: any = {};
  permissionModules: any = [];
  notifications: any = [];
  dashboards: any = [];
  searchModel: any = {};
  userType: string;
  accountKey: string;
  responseMessage: any = {};
  impersonate: Boolean = false;
  isPermission = false;
  localImpersonate: string;
  defaultAdminUrl: string;
  defaultSupportAdminUrl: string;
  defaultOrdersUrl: string;
  @ViewChild('menuClose', { static: true }) menuClose: ElementRef;
  alertMessage: Boolean;
  unreadCount = 0;
  headerItems = [];
  outerHeader = [];
  dropdownHeader = [];
  randomNumber = Math.random();
  subTabHeader = false;
  menu_title = '';
  menu_subtitle = '';
  edgeimg = "../../../../assets/img/Edge_icon.svg";
  edgeToggle = false;
  profilePicRandomNumber: any = Math.random();

  constructor(
    private _cookieService: CookieService,
    public translate: TranslateService,
    private authenticationService: AuthenticationService,
    private ngzone: NgZone,
    public router: Router,
    private dashboardService: DashboardService,
    private sharedService: SharedService
  ) {
    authenticationService.changeEmitteds$.subscribe(data => {
      if (data === null) {
        this.router.navigate(['/login']);
      } else if (data !== null) {
        this.currentUser = data;
        this.getRoleAccessModule();
      }
    });
  }

  ngOnInit() {
    this.url = this.router.url;
    this.currentUser = this.authenticationService.getCurrentUser();
    if (this.currentUser !== null) {
      this.getRoleAccessModule();
    }
    // edge icon change on reload only
    if (this.url.includes('devices')) {
      this.edgeToggle = true;
      this.edgeimg = '../../../../assets/img/Edge_icon_green.svg';
    }
    this.setMenuTitle();
    const data = localStorage.getItem('currentUser');
    const x = JSON.parse(data);
    this.userType = x.userType;
    this.accountKey = x.accountKey;
    if (this._cookieService.get('user')) {
      this.impersonate = true;
    }
    const token = this.authenticationService.getHeaders();
    this.authenticationService.setHeaders(token);
    this.localImpersonate = localStorage.getItem('impersonate');
    this.getUserNotification();
    this.getUnreadNotificationCount();
  }

  getRoleAccessModule() {
    this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
    this.getDefaultAdminUrl();
    this.getDefaultSupportAdminUrl();
    this.getDefaultOrdersUrl();
    this.setHeaderItems();
  }

  // on page reload only
  setMenuTitle() {
    const routeParam = (this.url).split('/');
    const url = this.router.url;
    let menu_title = '';
    let menu_subtitle = '';
    if (routeParam.includes('admin') && (routeParam.includes('tags') || routeParam.includes('device') || routeParam.includes('groups'))) {
      menu_title = 'Edge';
      menu_subtitle = routeParam[2];
      this.subTabHeader = false;
      this.sharedService.setTitle(menu_title, menu_subtitle);
    } else if (routeParam.includes('admin')) {
      menu_title = 'Admin';
      menu_subtitle = routeParam[2] === 'developer' ? 'Api Credentials' : routeParam[2];
      this.subTabHeader = false;
      this.sharedService.setTitle(menu_title, menu_subtitle);
    } else if (url.includes('data') || url.includes('data-simulator') || url.includes('destinations') || url.includes('trips') || url.includes('location-history') || url.includes('location-history?searchBy=&value=')) {
      menu_title = 'Data';
      this.subTabHeader = true;
      menu_subtitle = (routeParam[2] === 'data-schemes' ? 'Data Schemes' : routeParam[1] === 'destinations' ? 'Destinations'
        : routeParam[1] === 'data' ? 'Messages' : routeParam[1] === 'data-simulator' ? 'Data Simulator' : routeParam[1]);
      this.sharedService.setTitle(menu_title, menu_subtitle);
    } else if (routeParam.includes('reports')) {
      menu_title = 'Reports';
      menu_subtitle = 'General';
      this.subTabHeader = false;
      this.sharedService.setTitle(menu_title, menu_subtitle);
    } else {
      this.subTabHeader = false;
      this.sharedService.setTitle('', '');
    }
  }
  changeEdgeIcon() {
    if (this.edgeToggle) {
      this.edgeToggle = false;
      this.edgeimg = '../../../../assets/img/Edge_icon.svg';
    } else {
      this.edgeToggle = true;
      this.edgeimg = '../../../../assets/img/Edge_icon_green.svg';
    }
  }
  // on click of nav bar button
  subNavHeader(p1) {
    if (p1 === 'DATAHUB') {
      this.subTabHeader = true;
    } else if (p1 === 'DASHBOARD') {
      this.router.navigate(['/dashboard/azuga']);
    } else {
      this.subTabHeader = false;
    } 
    // if (p1 !== 'DEVICE') {
    //   this.edgeToggle = false;
    //   this.edgeimg = '../../../../assets/img/Edge_icon.svg';
    // }
    // if (p1 === 'HISTORY') {
    //   const userPref = JSON.parse(localStorage.getItem('preferences'));
    //   if (userPref.liveHistory.toggle && userPref.liveHistory.toggle.tripsView) {
    //     this.router.navigate(['/trips'], {
    //       queryParams: {
    //         'liveSearch': '',
    //         'liveKey': ''
    //       }
    //     });
    //   } else {
    //     this.router.navigate(['/location-history']);
    //   }
    // }
  }

  setHeaderItems() {
    let header = [];
    header = this.unoHeader ? configMessage.unoHeaderItems : configMessage.headerItems;
    const userPref = JSON.parse(localStorage.getItem('preferences'));
    let dashboardAdded = false;
    let dataHubAdded = false;
    let connectionAdded = false;
    header.map(item => {
      if (this.permissionModules.includes(item) && (item !== 'LIVE' && item !== 'TRIPS' && item !== 'DATA' && item !== 'HISTORY' && item !== 'DATASIMULATOR' && item !== 'DESTINATION') && (item !== 'DEVICE' && item !== 'GROUP' && item !== 'TAGS') && (item !== 'DASHBOARD' && item !== 'SYSTEM-DASHBOARD' && item !== 'EVENTS-DASHBOARD' && item !== 'FLEET-DASHBOARD' && item !== 'INSURANCE-DASHBOARD' && item !== 'EV-DASHBOARD' && item !== 'AZUGA-DASHBOARD' && item !== 'OTA')) {
        const obj: any = {};
        obj.name = item === 'HISTORY' ? 'History' : `menu.${item}`;
        obj.permission = item;
        obj.icon = configMessage.headerIcons[item];
        if (item === 'ADMIN') {
          obj.route = localStorage.getItem('defaultAdminUrl');
        } else if (item === 'SUPPORT-ADMIN') {
          obj.route = localStorage.getItem('defaultSupportAdminUrl');
        } else if (item === 'ORDER') {
          obj.route = localStorage.getItem('defaultOrdersUrl');
        } else if (item === 'REPORT') {
          obj.route = localStorage.getItem('lastReportUrl');
        } else if (item === 'HISTORY') {
          // obj.route = (userPref.liveHistory.toggle && userPref.liveHistory.toggle.tripsView) ? '/trips' : '/location-history';
          obj.route = '/trips';
        } else {
          obj.route = configMessage.route[item];
        }
        this.headerItems.push(obj);
      }
      if (this.permissionModules.includes(item) && !dashboardAdded && (item === 'LIVE' || item === 'DASHBOARD' || item === 'SYSTEM-DASHBOARD' || item === 'EVENTS-DASHBOARD' || item === 'FLEET-DASHBOARD' || item === 'INSURANCE-DASHBOARD' || item === 'EV-DASHBOARD' || item === 'AZUGA-DASHBOARD')) {
        const obj: any = {};
        obj.permission = 'DASHBOARD';
        obj.route = item === 'LIVE' ? '/live' : item === 'DASHBOARD' ? '/dashboard/default' : item === 'SYSTEM-DASHBOARD' ? '/dashboard/system-dashboard' : item === 'EVENTS-DASHBOARD' ? '/dashboard/events-dashboard' : item === 'FLEET-DASHBOARD' ? '/dashboard/fleet-report' : item === 'INSURANCE-DASHBOARD' ? '/dashboard/insurance-report' : item === 'AZUGA-DASHBOARD' ? '/dashboard/azuga' : '/dashboard/ev';
        obj.name = 'Dashboard';
        obj.icon = configMessage.headerIcons['DASHBOARD'];
        dashboardAdded = true;
        this.headerItems.push(obj);
      } else if (this.permissionModules.includes(item) && !connectionAdded && (item === 'DEVICE' || item === 'GROUP' || item === 'TAGS' || item === 'OTA')) {
        const obj: any = {};
        obj.permission = 'CONNECTIONS';
        obj.route =  item === 'DEVICE' ? '/devices': item === 'GROUP' ? '/devices/groups' : item === 'TAGS' ? '/devices/tags' : '/ota';
        obj.icon = '../../../../assets/img/Edge_icon.svg';
        obj.name = 'Connections';
        obj.icon = configMessage.headerIcons['CONNECTIONS'];
        connectionAdded = true;
        this.headerItems.push(obj);
    } else if (this.permissionModules.includes(item) && !dataHubAdded && (item === 'TRIPS' || item === 'DATA' || item === 'DATASIMULATOR' || item === 'DESTINATION')) {
        const obj: any = {};
        obj.permission = 'DATAHUB';
        obj.route = item === 'TRIPS' ? '/trips' : item === 'DATA' ? '/data/messages' : item === 'DATASIMULATOR' ? '/data-simulator' : '/destinations';
        obj.name = 'Data Hub';
        obj.icon = configMessage.headerIcons['DATAHUB'];
        dataHubAdded = true;
        this.headerItems.push(obj);
      }
    });
    if (this.headerItems.length >= 5) {
      for (let i = 0; i < this.headerItems.length; i++) {
        if (i >= 8) {
          this.dropdownHeader.push(this.headerItems[i]);
        } else {
          this.outerHeader.push(this.headerItems[i]);
        }
      }
    } else {
      this.outerHeader = this.headerItems;
    }
    if (this.outerHeader.length > 0) {
      this.outerHeader = this.sortHeaders(this.outerHeader);
    }
  }

  sortHeaders(data) {
    let flag = false;
    let sortedArra = [];
    let storedObj;
    data.forEach(ele => {
      if (ele.permission === "OTA") {
        storedObj = ele;
        flag = true;
        sortedArra.push(storedObj)
      } else if (ele.permission === "DEVICE") {
        sortedArra.push(ele);
        sortedArra.push(storedObj)
        flag = false;
      } else {
        sortedArra.push(ele);
      }
    })
    return sortedArra;
  }

  logout() {
    const token = localStorage.getItem('token');
    // let baseToken = atob(token);
    localStorage.clear();
    sessionStorage.clear();
    this.authenticationService.logout(token).subscribe((res) => {
    });
    this.ngzone.run(() => {
      this.router.navigate(['/login']);
    });
  }

  redirectTo() {
    this.subTabHeader = false;
    const defaultUrl = localStorage.getItem('defaultUrl');
    this.router.navigate([defaultUrl]);
  }

  menuClick() {
    this.menuClose.nativeElement.click();
  }

  dashboardClick(data) {
    this.menuClose.nativeElement.click();
    localStorage.setItem('currentDashboard', JSON.stringify(data));
    this.router.navigate(['/dashboard'], {
      queryParams: {
        id: data.dashboardId
      }
    });
  }

  getDashboards() {
    if (localStorage.getItem('dashboards')) {
      this.dashboards = JSON.parse(localStorage.getItem('dashboards'));
    } else {
      this.dashboardService.getDashboards().subscribe(res => {
        this.dashboards = res;
        localStorage.setItem('dashboards', JSON.stringify(res));
      }, error => {
        this.sharedService.getErrorMsg(error);
      });
    }
  }

  tokenScenario(res) {
    this.authenticationService.getLoggedUser().subscribe((reponseInFo) => {
      if (res.status === 404) {
        const errorCode = res.errorCode;
        const errorMessage = enMessage[errorCode];
        this.responseMessage = { success: false, message: errorMessage };
        this.sharedService.setResponseMessage(this.responseMessage);
      } else {
        this.authenticationService.setLoggedInUser(reponseInFo);
        this.permissionModules = reponseInFo.modules.map(data => data.name);
        this.sharedService.setAccessRole(reponseInFo.modules, this.permissionModules);
        this.sharedService.setDefaultUrl(this.permissionModules);
        if (this.permissionModules.includes('REPORT')) {
          localStorage.setItem('lastReportUrl', '/reports/system/live-status');
        }
        window.location.reload();
      }
    }, (error) => {
      this.sharedService.getErrorMsg(error);
    });
  }

  stopImpersonate() {
    this.authenticationService.stopImpersonate().subscribe(res => {
      if (res.accessToken) {
        localStorage.clear();
        const base64Token = res.accessToken;
        this.authenticationService.setHeaders(base64Token);
        this._cookieService.remove('user');
        this.impersonate = false;

        this.tokenScenario(res);
        localStorage.setItem('impersonate', 'false');

      } else if (res.status === 404) {
        const errorCode = res.errorCode;
        const errorMessage = enMessage[errorCode];
        this.responseMessage = { success: false, message: errorMessage };
        this.sharedService.setResponseMessage(this.responseMessage);
      }
    }, (error) => {
      if (error.status === 404) {
        this.sharedService.getErrorMsg(error);
      } else {
        this.sharedService.getErrorMsg(error);
      }
    }
    );
  }

  getDefaultOrdersUrl() {
    if (this.permissionModules.includes('ORDER')) {
      this.defaultOrdersUrl = '/orders/pending';
      localStorage.setItem('defaultOrdersUrl', this.defaultOrdersUrl);
    }
  }

  getDefaultAdminUrl() {
    this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
    if (this.permissionModules.includes('ADMIN')) {
      if (this.permissionModules.includes('USER')) {
        this.defaultAdminUrl = '/admin/users';
        localStorage.setItem('defaultAdminUrl', this.defaultAdminUrl);
      } else if (this.permissionModules.includes('ROLE')) {
        this.defaultAdminUrl = '/admin/roles';
        localStorage.setItem('defaultAdminUrl', this.defaultAdminUrl);
      } else if (this.permissionModules.includes('PLACE')) {
        this.defaultAdminUrl = '/admin/place';
        localStorage.setItem('defaultAdminUrl', this.defaultAdminUrl);
      } else if (this.permissionModules.includes('ALERT')) {
        this.defaultAdminUrl = '/admin/alerts';
        localStorage.setItem('defaultAdminUrl', this.defaultAdminUrl);
      } else if (this.permissionModules.includes('HEADER')) {
        this.defaultAdminUrl = '/admin/headers';
        localStorage.setItem('defaultAdminUrl', this.defaultAdminUrl);
      } else if (this.permissionModules.includes('SETTING')) {
        this.defaultAdminUrl = '/admin/whitelabel';
        localStorage.setItem('defaultAdminUrl', this.defaultAdminUrl);
      } else if (this.permissionModules.includes('DEVELOPER')) {
        this.defaultAdminUrl = '/admin/developer';
        localStorage.setItem('defaultAdminUrl', this.defaultAdminUrl);
      } else if (this.permissionModules.includes('DESTINATION')) {
        this.defaultAdminUrl = '/admin/destination';
        localStorage.setItem('defaultAdminUrl', this.defaultAdminUrl);
      } else if (this.permissionModules.includes('RULE')) {
        this.defaultAdminUrl = '/admin/rules';
        localStorage.setItem('defaultAdminUrl', this.defaultAdminUrl);
      } else if (this.permissionModules.includes('AUDIT-TRIAL')) {
        this.defaultAdminUrl = '/admin/audit-trial';
        localStorage.setItem('defaultAdminUrl', this.defaultAdminUrl);
      } else if (this.permissionModules.includes('CACHE-ATTRIBUTES')) {
        this.defaultAdminUrl = '/admin/cache-attributes';
        localStorage.setItem('defaultAdminUrl', this.defaultAdminUrl);
      }
    }
  }

  getDefaultSupportAdminUrl() {
    this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
    if (this.permissionModules.includes('SUPPORT-ADMIN')) {
      if (this.permissionModules.includes('USER')) {
        this.defaultSupportAdminUrl = '/support-admin/users';
        localStorage.setItem('defaultSupportAdminUrl', this.defaultSupportAdminUrl);
      } else if (this.permissionModules.includes('ROLE')) {
        this.defaultSupportAdminUrl = '/support-admin/roles';
        localStorage.setItem('defaultSupportAdminUrl', this.defaultSupportAdminUrl);
      }
    }
  }

  getUserNotification() {
    this.authenticationService.getUserNotification().subscribe(res => {
      this.notifications = res;
      if (res) {
        this.alertMessage = true;
      }
    }, error => {
      this.alertMessage = false;
      this.sharedService.getErrorMsg(error);
    });
  }

  getUnreadNotificationCount() {
    this.authenticationService.getUnreadNotificationCount().subscribe(res => {
      this.unreadCount = res.count;
      localStorage.setItem('unreadNotification', JSON.stringify(res));
    });
  }

  readNotifications() {
    this.unreadCount = 0;
  }

  closeUserNotification(userNotificationId) {
    this.authenticationService.closeUserNotification(userNotificationId).subscribe(res => {
      this.alertMessage = false;
    }, error => {
      this.alertMessage = false;
      this.sharedService.getErrorMsg(error);
    });
  }
}
