import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { SharedService } from '@shared/shared.service';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from '@myenv/environment';
import { configMessage } from '@shared/config-message';

@Component({
  selector: 'app-reports-side-menu',
  templateUrl: './reports-side-menu.component.html',
  styleUrls: ['./reports-side-menu.component.scss']
})
export class ReportsSideMenuComponent implements OnInit {
  staticReports = configMessage.staticReports;
  @Output() selectReport = new EventEmitter<string>();
  public AL_SystemReport = environment.AL_SystemReport;
  modules: any;
  permissionModules: any = [];
  tripAuthorities: any = [];
  userAuthorities: any = [];
  searchModel: any = {};
  startTime: any;
  endTime: any;
  reportsMenu: any = [];
  columns: any = [];
  newReportsMenu: any = {};
  allReports = [];
  billingReport: number;
  deviceReport: number;
  vehicleReport: number;
  userReport: number;
  messagesReport: number;
  alPlantMode: number;
  customReport: number;
  visualizationReport: number;
  systemReport: number;
  multiVehicleReport: number;
  noReports = false;
  noData = false;
  noSearch = false;
  temp = [];
  localReport: any;
  currentReport: any = {};
  public report: any = {};
  reportsOverlay = true;
  public columMenu: any = [];
  visualizationMode = false;

  constructor(
    private sharedService: SharedService,
    private route: ActivatedRoute,
    private title: Title,
    public router: Router
  ) { }

  ngOnInit() {
    // for seperate visualizations category
    this.visualizationMode = (this.router.url.includes('/device/visualization')) ? true : false;
    // Getting reports list
    this.getallReports();
    // Getting values from localStorage
    this.localReport = JSON.parse(localStorage.getItem('currentReport'));
    this.modules = JSON.parse(localStorage.getItem('accessRoleModules'));
    this.permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
    // Getting TRIPS authorities
    const tripRoleModule = this.modules.find(module => module.name === 'TRIPS');
    this.tripAuthorities = tripRoleModule && tripRoleModule.authorities ? tripRoleModule.authorities : [];
    // Getting USER authorities
    const userRoleModule = this.modules.find(module => module.name === 'USER');
    this.userAuthorities = userRoleModule && userRoleModule.authorities ? userRoleModule.authorities : [];
    window["search"] = this;
  }

  // To get all reports
  getallReports() {
    if (localStorage.getItem('reports')) {
      this.newReportsMenu = {};
      const reports = JSON.parse(localStorage.getItem('reports'));
      // var newArr = reports.slice(0, -14);
      this.reportsMenu = reports;
      this.categorizeReports(reports);
      this.setReport();
      // console.log('rep from store:', reports.length);
    } else {
      this.sharedService.getReports().subscribe(res => {
        if (res.length > 0) {
          this.staticReports = this.staticReports.filter(item => {
            if (item.reportType === 'system' && item.url.includes('trigger-report') && !this.AL_SystemReport) return false;
            if (item.reportType === 'device' && item.url.includes('trip-summary') && !this.tripAuthorities.includes('READ')) return false;
            if (item.reportType === 'billing' && !this.permissionModules.includes('BILLING')) return false;
            if (!this.AL_SystemReport && (item.name === 'C-Triggers' || item.name === 'S-Triggers')) return false;
            return true;
          });
          this.reportsMenu = [
            ...res,
            ...this.staticReports
          ];
          this.columns = res;
          this.categorizeReports(res);
          localStorage.setItem('reports', JSON.stringify(this.reportsMenu));
          this.setReport();
          // console.log('rep from API:', this.reportsMenu.length);
        } else {
          localStorage.setItem('reports', JSON.stringify([]));
          this.noReports = true;
        }
      }, error => {
        this.sharedService.getErrorMsg(error);
      });
    }
  }

  categorizeReports(reports) {
    // seggregate reports not for static reports
    reports = reports.filter(item => {
      if (['system', 'user', 'device'].includes(item.reportType)) return false;
      return true;
    });
    // categorize reports received from API other than static reports
    if (!this.visualizationMode) {
      this.newReportsMenu.messages = [];
      reports.map(item => {
        if (
          item.type !== 'VISUALIZATION' &&
          !item.downloadable &&
          item.type !== 'MULTI_DEVICE_RECENT_MESSAGE_DOWNLOAD'
          && item.type !== 'AL_PLANT_MODE'
        ) {
          item.url = '/reports/device/messages';
          item.reportType = 'messages';
          this.newReportsMenu.messages.push(item);
        }
      });
      // console.log('msg rep', this.newReportsMenu.messages.length);
      this.newReportsMenu.custom = [];
      reports.map(item => {
        if (item.downloadable && item.type !== 'MULTI_DEVICE_RECENT_MESSAGE_DOWNLOAD' && item.type !== 'AL_PLANT_MODE') {
          item.url = '/reports/device/custom';
          item.reportType = 'custom';
          this.newReportsMenu.custom.push(item);
        }
      });
      // console.log('custom rep', this.newReportsMenu.custom.length);
      this.newReportsMenu.multiVehicleDownload = [];
      reports.map(item => {
        if (item.type === 'MULTI_DEVICE_RECENT_MESSAGE_DOWNLOAD') {
          item.url = '/reports/device/multiVehicleDownload';
          item.reportType = 'multiVehicleDownload';
          this.newReportsMenu.multiVehicleDownload.push(item);
        }
      });
      // console.log('multi rep', this.newReportsMenu.multiVehicleDownload.length);
      this.newReportsMenu.alPlantMode = [];
      reports.map(item => {
        if (item.type === 'AL_PLANT_MODE') {
          item.url = '/reports/device/alPlantMode';
          item.reportType = 'alPlantMode';
          this.newReportsMenu.alPlantMode.push(item);
        }
      });
      // console.log('al rep', this.newReportsMenu.alPlantMode.length);
    } else {
      this.newReportsMenu.visualization = [];
      reports.map(item => {
        if (item.type === 'VISUALIZATION') {
          item.url = '/reports/device/visualization';
          item.reportType = 'visualization';
          this.newReportsMenu.visualization.push(item);
        }
      });
      // console.log('vis rep', this.newReportsMenu.visualization.length);
    }
  }

  setReport() {
    this.temp = this.reportsMenu;
    let currentReport;
    const report = this.route.snapshot.queryParams.report;
    if (report) {
      this.reportsMenu.forEach(function (item) {
        if (item.reportId === report) {
          currentReport = item;
        }
      });
      this.columns = this.reportsMenu;
      localStorage.setItem('currentReport', JSON.stringify(currentReport));
      this.report.reportId = currentReport.reportId;
      this.currentReport = currentReport;
    } else if (JSON.parse(localStorage.getItem('currentReport'))) {
      const localReport = JSON.parse(localStorage.getItem('currentReport'));
      this.currentReport = localReport;
      this.report.reportId = localReport.reportId;
    } else {
      localStorage.setItem('currentReport', JSON.stringify({}));
    }
    if (!this.currentReport.name) {
      this.reportsOverlay = true;
    } else {
      this.reportsOverlay = false;
    }
    sessionStorage.setItem('reportName', this.currentReport.name);
    this.columMenu = this.currentReport.columns;
  }

  messageReport(report, reportUrl) {
    this.searchModel.columnVal = '';
    this.onColumnSearch();
    localStorage.setItem('currentReport', JSON.stringify(report));
    this.router.navigate([reportUrl]);
    this.selectReport.emit(report);
    if (!this.visualizationMode) localStorage.setItem('lastReportUrl', reportUrl);
    const localReport = JSON.parse(localStorage.getItem('currentReport'));
    this.currentReport = localReport;
  }

  setReportUrl(reportUrl, staticReportName?) {
    this.searchModel.columnVal = '';
    this.onColumnSearch();
    if (!this.visualizationMode) localStorage.setItem('lastReportUrl', reportUrl);
    let report = {};
    if (staticReportName === 'messageCount') report = { name: 'Messages', reportType: 'vehicle', url: '/reports/device/message-count' };
    if (staticReportName === 'packetHistory') report = { name: 'Packet History', reportType: 'vehicle', url: '/reports/device/packet-history' };
    if (staticReportName === 'tripSummary') report = { name: 'Trip Summary', reportType: 'vehicle', url: '/reports/device/trip-summary' };
    localStorage.setItem('currentReport', JSON.stringify(report));
  }

  onColumnSearch() {
    this.columns = this.reportsMenu;
    const inputVal = this.searchModel.columnVal.trim();
    const val = inputVal.toLowerCase();
    if (val === '') {
      this.allReports = [];
      this.noSearch = false;
      this.noData = false;
      return;
    } else {
      this.noSearch = true;
      const reports = this.newReportsMenu;
      let deviceReport = [];
      if (!this.visualizationMode) {
        deviceReport = [
          { name: 'Messages', reportType: 'vehicle', url: '/reports/device/message-count' },
          { name: 'Packet History', reportType: 'vehicle', url: '/reports/device/packet-history' },
          // { name: 'Device Detail', reportType: 'device', url: '/reports/device/device-report' },
        ];
        if (reports && reports.messages) deviceReport = [...deviceReport, ...reports.messages];
        if (reports && reports.custom) deviceReport = [...deviceReport, ...reports.custom];
        if (reports && reports.multiVehicleDownload) deviceReport = [...deviceReport, ...reports.multiVehicleDownload];
        if (reports && reports.alPlantMode) deviceReport = [...deviceReport, ...reports.alPlantMode];
        if (this.tripAuthorities.includes('READ')) {
          deviceReport.push({ name: 'Trip Summary', reportType: 'vehicle', url: '/reports/device/trip-summary' });
        }
      } else if (this.visualizationMode) {
        if (reports && reports.visualization) deviceReport = [...deviceReport, ...reports.visualization];
      }
      const userReport = [
        { name: 'Log-in Activity', reportType: 'user', url: '/reports/user/in-out-activity' }
      ];
      const systemReport = [
        { name: 'Message Published', reportType: 'system', url: '/reports/system/message-published' },
        { name: 'Live Status', reportType: 'system', url: '/reports/system/live-status' },
        { name: 'Provisioning', reportType: 'system', url: '/reports/system/provisioning' },
        { name: 'Software Version', reportType: 'system', url: '/reports/system/version-drilldown' },
        { name: 'Not Communicating', reportType: 'system', url: '/reports/system/not-communicating' },
        { name: 'Communicating', reportType: 'system', url: '/reports/system/communicating' },
        { name: 'Device Timeline', reportType: 'system', url: '/reports/system/device-timeline' },
        { name: 'Bitbrew Usage', reportType: 'system', url: '/reports/system/usage' },
        { name: 'Billing', reportType: 'system', url: '/reports/system/billing-report' },
        { name: 'Discovery', reportType: 'system', url: '/reports/system/discovery' },
        { name: 'Diagnostics', reportType: 'system', url: '/reports/system/diagnostics' },
        { name: 'Disonnected', reportType: 'system', url: '/reports/system/disconnected' }
      ];
      if (this.AL_SystemReport) {
        systemReport.push(
          { name: 'C-Triggers', reportType: 'system', url: '/reports/system/c-trigger-report' },
          { name: 'S-Triggers', reportType: 'system', url: '/reports/system/s-trigger-report' },
        );
      }
      // const billingReport = [];
      // if (this.permissionModules.includes('BILLING')) {
      //   billingReport.push(
      //     { name: 'Billing', reportType: 'billing', url: '/reports/billing/provision-billing' }
      //   );
      // }
      this.allReports = [
        ...deviceReport,
        ...userReport,
        ...systemReport,
        // ...billingReport
      ];
      if (this.allReports.length) {
        const temp = this.allReports.filter((item, index) => {
          if (item.name.toLowerCase().includes(val)) {
            return this.allReports[index];
          }
        });
        if (!temp.length) {
          this.noData = true;
        } else {
          this.noData = false;
        }
        this.allReports = temp;
        this.deviceReport = 0;
        this.userReport = 0;
        this.messagesReport = 0;
        this.alPlantMode = 0;
        this.customReport = 0;
        this.visualizationReport = 0;
        this.systemReport = 0;
        this.vehicleReport = 0;
        this.multiVehicleReport = 0;
        this.billingReport = 0;
        this.allReports.map(item => {
          if (item.reportType === 'device') {
            this.deviceReport++;
          } if (item.reportType === 'messages') {
            this.messagesReport++;
          } if (item.reportType === 'custom') {
            this.customReport++;
          } if (item.type === 'VISUALIZATION') {
            this.visualizationReport++;
          } if (item.reportType === 'vehicle') {
            this.vehicleReport++;
          } if (item.reportType === 'MULTI_DEVICE_RECENT_MESSAGE_DOWNLOAD') {
            this.multiVehicleReport++;
          } if (item.reportType === 'user') {
            this.userReport++;
          } if (item.reportType === 'system') {
            this.systemReport++;
          } if (item.reportType === 'alPlantMode') {
            this.alPlantMode++;
          } if (item.reportType === 'billing') {
            this.billingReport++;
          }
        });
      }
    }
  }
}
