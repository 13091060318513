import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import Chart from 'chart.js';
import { GraphicReportsService } from '../../graphic-reports.service';
import { SharedService } from '@myproject/shared/shared.service';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import { Title } from '@angular/platform-browser';
import { environment } from '@myenv/environment';
var VersionDrilldownReportComponent = /** @class */ (function () {
    function VersionDrilldownReportComponent(reportsService, title, sharedService) {
        this.reportsService = reportsService;
        this.title = title;
        this.sharedService = sharedService;
        this.page = 0;
        this.isLoading = false;
        this.firmwares = [];
        this.configs = [];
        this.devices = [];
        this.fwSelected = {};
        this.cfgSelected = {};
        this.backgroundColors = [];
        this.resetOffset = false;
        this.hideCalendar = false;
        this.blockCall = false;
        this.highestCount = 0;
        this.searchModel = {
            fwSearchVal: '',
            fwSearchedData: [],
            cfgSearchVal: '',
            cfgSearchedData: [],
        };
    }
    VersionDrilldownReportComponent.prototype.ngOnInit = function () {
        this.title.setTitle('Software Version' + environment.title_text);
    };
    VersionDrilldownReportComponent.prototype.onTimeChange = function (e) {
        this.startTime = e.startTime;
        this.endTime = e.endTime;
        this.page = 0;
        this.resetOffset = !this.resetOffset;
        this.searchModel = {
            fwSearchVal: '',
            fwSearchedData: [],
            cfgSearchVal: '',
            cfgSearchedData: [],
        };
        this.getFirmwareCount(e.startTime, e.endTime);
    };
    VersionDrilldownReportComponent.prototype.onPageChange = function (page) {
        this.page = 0;
        this.getFwConfigDevices(this.startTime, this.endTime, this.fwSelected.firmwareVersion, this.cfgSelected.configVersion, page);
    };
    VersionDrilldownReportComponent.prototype.refresh = function () {
        this.page = 0;
        this.resetOffset = !this.resetOffset;
        this.searchModel = {
            fwSearchVal: '',
            fwSearchedData: [],
            cfgSearchVal: '',
            cfgSearchedData: [],
        };
        this.getFirmwareCount(this.startTime, this.endTime);
    };
    VersionDrilldownReportComponent.prototype.onFWSearch = function () {
        var global = this;
        this.searchModel.fwSearchedData = this.firmwares.filter(function (item) {
            return item.firmwareVersion.toLowerCase().includes(global.searchModel.fwSearchVal.toLowerCase());
        });
    };
    VersionDrilldownReportComponent.prototype.onCFGSearch = function () {
        var global = this;
        this.searchModel.cfgSearchedData = this.configs.filter(function (item) {
            return item.configVersion.toLowerCase().includes(global.searchModel.cfgSearchVal.toLowerCase());
        });
    };
    VersionDrilldownReportComponent.prototype.setBackgroundColors = function (res) {
        var _this = this;
        res.map(function (item) {
            var r = Math.floor(Math.random() * 254);
            var g = Math.floor(Math.random() * 254);
            var b = Math.floor(Math.random() * 254);
            _this.backgroundColors.push('rgb(' + r + ',' + g + ',' + b + ')');
        });
    };
    VersionDrilldownReportComponent.prototype.getFirmwareCount = function (startTime, endTime) {
        var _this = this;
        this.isLoading = true;
        this.reportsService.getFirmwareCount(startTime, endTime).subscribe({
            next: function (res) {
                if (res.length) {
                    // console.log(res);
                    _this.firmwares = res;
                    _this.searchModel.fwSearchedData = res;
                    _this.fwSelected = res[0];
                    _this.setBackgroundColors(res);
                    _this.drawPieChart('pieChartFW', res);
                    _this.getConfigCount(startTime, endTime, res[0].firmwareVersion);
                }
                else {
                    _this.firmwares = [];
                    _this.searchModel.fwSearchedData = [];
                    _this.configs = [];
                    _this.devices = [];
                    _this.fwSelected = {};
                    _this.cfgSelected = {};
                    _this.drawPieChart('pieChartFW', []);
                    _this.drawPieChart('pieChartCFG', []);
                    _this.drawAreaGraph([]);
                    _this.isLoading = false;
                }
            },
            error: function (error) {
                _this.isLoading = false;
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    VersionDrilldownReportComponent.prototype.getConfigCount = function (startTime, endTime, firmware) {
        var _this = this;
        this.reportsService.getConfigCount(startTime, endTime, firmware).subscribe({
            next: function (res) {
                if (res.length) {
                    // console.log(res);
                    _this.configs = res;
                    _this.searchModel.cfgSearchedData = res;
                    _this.cfgSelected = res[0];
                    _this.drawPieChart('pieChartCFG', res);
                    _this.getFwConfigTrendData(startTime, endTime, firmware, res[0].configVersion);
                    _this.getFwConfigDevices(startTime, endTime, firmware, res[0].configVersion, _this.page);
                }
                else {
                    _this.configs = [];
                    _this.searchModel.cfgSearchedData = [];
                    _this.devices = [];
                    _this.cfgSelected = {};
                    _this.drawPieChart('pieChartCFG', []);
                    _this.drawAreaGraph([]);
                    _this.isLoading = false;
                }
            },
            error: function (error) {
                _this.isLoading = false;
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    VersionDrilldownReportComponent.prototype.getFwConfigTrendData = function (startTime, endTime, firmware, config) {
        var _this = this;
        this.reportsService.getFwConfigTrendData(startTime, endTime, firmware, config).subscribe({
            next: function (res) {
                if (res.length) {
                    if (_this.areaChart) {
                        _this.areaChart.destroy();
                    }
                    _this.drawAreaGraph(res);
                }
                else {
                    _this.drawAreaGraph([]);
                }
                _this.isLoading = false;
            },
            error: function (error) {
                _this.isLoading = false;
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    VersionDrilldownReportComponent.prototype.getFwConfigDevices = function (startTime, endTime, firmware, config, page) {
        var _this = this;
        this.reportsService.getFwConfigDevices(startTime, endTime, firmware, config, page).subscribe({
            next: function (res) {
                if (!res.length) {
                    _this.blockCall = true;
                }
                if (page === 0) {
                    _this.devices = res;
                }
                else {
                    _this.devices = tslib_1.__spread(_this.devices, res);
                }
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    VersionDrilldownReportComponent.prototype.downloadFwConfigDevices = function (attributes) {
        var _this = this;
        this.reportsService.downloadFwConfigDevices(this.startTime, this.endTime, this.fwSelected.firmwareVersion, this.cfgSelected.configVersion, attributes).subscribe({
            next: function (res) {
                var contentDisposition = res.headers.get('Content-Disposition');
                var filename;
                if (contentDisposition == null) {
                    var date = new Date();
                    filename = 'Device_' + date + '.zip';
                }
                else {
                    filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                }
                var blob = new Blob([res.body], {
                    type: 'application/zip'
                });
                FileSaver.saveAs(blob, filename);
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    VersionDrilldownReportComponent.prototype.selectFirmware = function (e) {
        this.page = 0;
        this.blockCall = false;
        this.resetOffset = !this.resetOffset;
        this.fwSelected = e;
        this.searchModel.cfgSearchVal = '';
        this.getConfigCount(this.startTime, this.endTime, this.fwSelected.firmwareVersion);
    };
    VersionDrilldownReportComponent.prototype.selectConfig = function (e) {
        this.page = 0;
        this.blockCall = false;
        this.resetOffset = !this.resetOffset;
        this.cfgSelected = e;
        this.getFwConfigTrendData(this.startTime, this.endTime, this.fwSelected.firmwareVersion, this.cfgSelected.configVersion);
        this.getFwConfigDevices(this.startTime, this.endTime, this.fwSelected.firmwareVersion, this.cfgSelected.configVersion, 0);
    };
    VersionDrilldownReportComponent.prototype.drawAreaGraph = function (data) {
        var _this = this;
        if (this.areaChart) {
            this.areaChart.destroy();
        }
        this.highestCount = 0;
        var labels = [];
        var counts = [];
        var label = '';
        if (data.length) {
            label = data[0].firmwareVersion + " > " + data[0].configVersion;
        }
        data.map(function (item) {
            var datePipe = new DatePipe('en-US');
            var eventDate = datePipe.transform(item.day, 'dd MMM');
            labels.push(eventDate);
            counts.push(item.count);
            if (item.count > _this.highestCount) {
                _this.highestCount = item.count;
            }
        });
        var ctx = document.getElementById('areaGraph');
        this.areaChart = new Chart(ctx, {
            type: 'line',
            responsive: true,
            data: {
                labels: labels,
                datasets: [{
                        label: label,
                        data: counts,
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                    }]
            },
            options: {
                plugins: {
                    filler: {
                        propagate: true
                    }
                },
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                            display: true,
                            ticks: {
                                beginAtZero: true
                            }
                        }],
                    yAxes: [{
                            display: true,
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 10,
                                stepSize: this.highestCount > 9 ? null : 1
                            }
                        }]
                }
            }
        });
    };
    VersionDrilldownReportComponent.prototype.drawPieChart = function (container, data) {
        if (container === 'pieChartFW' && this.fwChart) {
            this.fwChart.destroy();
        }
        else if (container === 'pieChartCFG' && this.configChart) {
            this.configChart.destroy();
        }
        var labels = [];
        var counts = [];
        var label = '';
        data.map(function (item) {
            if (item.firmwareVersion) {
                label = 'firmware';
                labels.push(item.firmwareVersion);
            }
            else if (item.configVersion) {
                label = 'config';
                labels.push(item.configVersion);
            }
            counts.push(item.count);
        });
        var ctx = document.getElementById(container);
        var myChart = new Chart(ctx, {
            type: 'pie',
            responsive: true,
            data: {
                labels: labels,
                datasets: [{
                        data: counts,
                        backgroundColor: this.backgroundColors,
                        borderColor: this.backgroundColors
                    }]
            },
            options: {
                legend: {
                    position: 'bottom',
                    display: false
                },
                scales: {
                    xAxes: [{
                            display: false,
                            ticks: {
                                beginAtZero: true
                            }
                        }],
                    yAxes: [{
                            display: false,
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                }
            }
        });
        if (label === 'firmware') {
            this.fwChart = myChart;
        }
        else {
            this.configChart = myChart;
        }
    };
    return VersionDrilldownReportComponent;
}());
export { VersionDrilldownReportComponent };
