import { OnInit, ElementRef } from '@angular/core';
// import { NgxSpinnerService } from 'ngx-spinner';
import { Title } from '@angular/platform-browser';
import { environment } from '@myenv/environment';
import { AdminService } from '@components/admin/admin.service';
var MarketplaceComponent = /** @class */ (function () {
    // tslint:disable-next-line:max-line-length
    function MarketplaceComponent(adminService, title) {
        this.adminService = adminService;
        this.title = title;
        // public tenantId = environment.tenantId;
        this.cmImageUrl = environment.cmImageUrl;
        this.dateFormat = environment.isoDateTimeFormat;
        this.responseMessage = {};
        this.roles = [];
        this.allModules = [];
        this.role = {};
        this.currentUser = {};
        this.modules = [];
        // tslint:disable-next-line:no-inferrable-types
        this.getRName = '';
        this.roleId = '';
        this.moduleName = 'ROLE';
        this.permissionAuthorities = {};
        this.permissionModules = [];
        this.isLoading = false;
        this.disable = false;
        this.loadingIndicator = true;
        this.searchModel = {};
        this.timeout = null;
        this.searchHeader = [
            { name: 'name', displayName: 'Role Name', type: 'STRING' }
        ];
        this.showModal = false;
        this.hideModal = false;
        this.currentPageCount = {
            start: 0,
            end: 0
        };
        this.pageInfo = {
            offset: 0,
            count: 0,
            limit: 10,
            pageSize: 0,
            lastPage: 0,
        };
        this.prevResponse = 0;
        this.selectedRole = null;
        this.popoverPlacement = 'right';
    }
    MarketplaceComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.searchModel = {
            searchId: 'name'
        };
        this.permissionModules = JSON.parse(localStorage.getItem('accessRoleModules'));
        this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
        this.permissionAuthorities = this.permissionModules.find(function (module) { return module.name === _this.moduleName; });
        this.title.setTitle('Marketplace' + environment.title_text);
        this.message = this.adminService.getSideMenu();
    };
    return MarketplaceComponent;
}());
export { MarketplaceComponent };
