<div class="btn-group btn-container" role="group" aria-label="Basic example">
  <button
    class="btn btn-custom"
    (click)="navigate('left')"
    tooltip="Previous"
    placement="top"
    >
    <i class="fa fa-angle-left" aria-hidden="true"></i>
  </button>
  <button
    class="btn btn-custom btn-date"
    (click)="togglePicker()"
    tooltip="Select Datetime"
    placement="top"
    >
    <i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;
    <span *ngIf="displayText==='Custom' || displayText==='Event Time' || navigating">
      <span *ngIf="timeSelection">
        {{displayDates?.start | date:'MMM d, h:mm a'}} -
        {{displayDates?.end | date:'MMM d, h:mm a'}}
      </span>
      <span *ngIf="!timeSelection">
        {{displayDates?.start | date:'MMM dd yyyy'}} -
        {{displayDates?.end | date:'MMM dd yyyy'}}
      </span>
    </span>
    <span *ngIf="displayText!=='Custom' && displayText!=='Event Time' && !navigating">
      {{displayText}}
    </span>
  </button>
  <button
    class="btn btn-custom"
    (click)="navigate('right')"
    tooltip="Next"
    placement="top"
    [disabled]="disableNavigation"
    >
    <i class="fa fa-angle-right" aria-hidden="true"></i>
  </button>
</div>

<!-- <button
  class="btn btn-outline-primary"
  (click)="togglePicker()"
  >
  <i class="fa fa-clock-o" aria-hidden="true"></i>&nbsp;
  <span *ngIf="displayText==='Custom' || displayText==='Event Time'">
    {{displayDates?.start | date:'MMM d, h:mm a'}} -
    {{displayDates?.end | date:'MMM d, h:mm a'}}
  </span>
  <span *ngIf="displayText!=='Custom' && displayText!=='Event Time'">
    {{displayText}}
  </span>
</button> -->

<div class="calendar-overlay" *ngIf="showPicker">
  <div class="calendar">
    <!-- <ngb-datepicker #dp
      [(ngModel)]="model"
      (navigate)="date = $event.next"
      [dayTemplate]="customDay"
      [displayMonths]="2"
      [outsideDays]="visible"
      (dateSelect)="onDateSelection($event)"
      >
    </ngb-datepicker>
    <ng-template #customDay
      let-date="date"
      let-currentMonth="currentMonth"
      let-selected="selected"
      let-disabled="disabled"
      let-focused="focused"
      >
      <span
        class="custom-day lol"
        [class.weekend]="isWeekend(date)"
        [class.focused]="focused"
        [class.bg-danger]="selected"
        [class.hidden]="date.month !== currentMonth"
        [class.text-muted]="disabled"
        [class.bg-primary]="hasTask(date)"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null"
        >
        {{ date.day }}
      </span>
    </ng-template> -->

    <ngb-datepicker
      [startDate]="{year: fromDate?.year, month: fromDate?.month}"
      [maxDate]="today" #dp
      (dateSelect)="onDateSelection($event)"
      (navigate)="navigationOnMonth($event)"
      [displayMonths]="2"
      [dayTemplate]="t"
      outsideDays="hidden"
      >
    </ngb-datepicker>
    <ng-template #t let-date let-focused="focused">
      <span
        class="custom-day {{getEventClass(date)}}"
        [class.focused]="focused"
        [class.range]="isRange(date)"
        [class.faded]="isHovered(date) || isInside(date)"
        (mouseenter)="hoveredDate = date"
        (mouseleave)="hoveredDate = null"
        >
        <!-- [class.messageEvent]="hasMessages(date)"
        [class.tripEvent]="hasTrips(date)" -->
        {{ date.day }}
        <img *ngIf="getRangeMinMax(date)" class="loading" src="../../../assets/img/loading.gif"/>
      </span>
    </ng-template>

    <div *ngIf="timeSelection" class="time-picker d-block text-left mt-3">
      <div class="from-time d-inline-block mr-4 pl-2">
        <span class="title text-dark-grey bold">From:&nbsp;
          <span class="bold text-blue-grey">
            {{fromDate?.day}}/{{fromDate?.month}}/{{fromDate?.year}}
          </span>
        </span>
        <ngb-timepicker [(ngModel)]="fromtime" [meridian]="meridian"></ngb-timepicker>
      </div>
      <div class="to-time d-inline-block ml-3">
        <span class="title text-dark-grey bold">To:&nbsp;
          <span *ngIf="toDate==null" class="bold text-blue-grey">
            {{fromDate?.day}}/{{fromDate?.month}}/{{fromDate?.year}}
          </span>
          <span *ngIf="toDate!=null" class="bold text-blue-grey">
            {{toDate?.day}}/{{toDate?.month}}/{{toDate?.year}}
          </span>
        </span>
        <ngb-timepicker [(ngModel)]="totime" [meridian]="meridian"></ngb-timepicker>
      </div>
    </div>
    <div class="d-block text-right" [class.mt-2]="!timeSelection">
      <small class="text-danger pr-4" *ngIf="invalidDateTime">{{errorText}}</small>
      <button class="btn btn-outline-secondary width100px mr-2" (click)="closePicker()">Cancel</button>
      <button class="btn btn-primary width100px" (click)="setCustomDate()">Apply</button>
    </div>
  </div>
  <span class="pre-sets"><b>Quick Selection</b></span>
  <div class="quick-links" [class.noTimeHeight]="!timeSelection">
    <ul>
      <li [class.selected]="displayText=='Custom'">
        Custom
      </li>
      <li
        [class.selected]="displayText=='Today'"
        *ngIf="!hideLinks.includes('today')"
        (click)="onTimeChange('today')"
        >Today
      </li>
      <li
        [class.selected]="displayText=='Last 15 Minutes'"
        *ngIf="!hideLinks.includes('15mins')"
        (click)="onTimeChange('15mins')"
        >Last 15 minutes
      </li>
      <li
        [class.selected]="displayText=='Last 30 Minutes'"
        *ngIf="!hideLinks.includes('30mins')"
        (click)="onTimeChange('30mins')"
        >Last 30 minutes
      </li>
      <li
        [class.selected]="displayText=='Last 1 Hour'"
        *ngIf="!hideLinks.includes('1hour')"
        (click)="onTimeChange('1hour')"
        >Last 1 hour
      </li>
      <li
        [class.selected]="displayText=='Last 4 Hours'"
        *ngIf="!hideLinks.includes('4hours')"
        (click)="onTimeChange('4hours')"
        >Last 4 hours
      </li>
      <li
        [class.selected]="displayText=='Last 12 Hours'"
        *ngIf="!hideLinks.includes('12hours')"
        (click)="onTimeChange('12hours')"
        >Last 12 hours
      </li>
      <li
        [class.selected]="displayText=='Last 24 Hours'"
        *ngIf="!hideLinks.includes('24hours')"
        (click)="onTimeChange('24hours')"
        >Last 24 hours
      </li>
      <li
        [class.selected]="displayText=='Last 7 Days'"
        *ngIf="!hideLinks.includes('7days')"
        (click)="onTimeChange('7days')"
        >Last 7 days
      </li>
      <li
        [class.selected]="displayText=='Last 30 Days'"
        *ngIf="!hideLinks.includes('30days')"
        (click)="onTimeChange('30days')"
        >Last 30 days
      </li>
      <li
        [class.selected]="displayText=='This Week'"
        *ngIf="!hideLinks.includes('week')"
        (click)="onTimeChange('week')"
        >This week
      </li>
      <li
        [class.selected]="displayText=='This Month'"
        *ngIf="!hideLinks.includes('month')"
        (click)="onTimeChange('month')"
        >This month
      </li>
      <li
        [class.selected]="displayText=='Today so Far'"
        *ngIf="!hideLinks.includes('todaysofar')"
        (click)="onTimeChange('todaysofar')"
        >Today so far
      </li>
      <li
        [class.selected]="displayText=='Week to Date'"
        *ngIf="!hideLinks.includes('weektodate')"
        (click)="onTimeChange('weektodate')"
        >Week to date
      </li>
      <li
        [class.selected]="displayText=='Month to Date'"
        *ngIf="!hideLinks.includes('monthtodate')"
        (click)="onTimeChange('monthtodate')"
        >Month to date
      </li>
      <li
        [class.selected]="displayText=='Last 90 days'"
        *ngIf="!hideLinks.includes('90days')"
        (click)="onTimeChange('90days')"
        >Last 90 days
      </li>
      <li
        [class.selected]="displayText=='Last 180 days'"
        *ngIf="!hideLinks.includes('180days')"
        (click)="onTimeChange('180days')"
        >Last 180 days
      </li>
      <li
        [class.selected]="displayText=='Last 365 days'"
        *ngIf="!hideLinks.includes('365days')"
        (click)="onTimeChange('365days')"
        >Last 365 days
      </li>
    </ul>
  </div>
</div>
