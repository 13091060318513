<div id="acdn">
  <ul>
    <li>
      <div class="column-search">
        <input type="text" name="userTypeVal" required [(ngModel)]="searchModel.columnVal"
          #columnVal="ngModel" (keyup)="onColumnSearch()" autofocus placeholder="Search"
        />
        <span class="cross-icon" *ngIf="searchModel.columnVal" (click)="searchModel.columnVal='';onColumnSearch()">
          <i class="fa fa-times" aria-hidden="true"></i>
        </span>
        <span><i class="fa fa-search img-responsive search-icon" aria-hidden="true"></i></span>
      </div>
    </li>
  </ul>
  <ul *ngIf="allReports.length" class="list-unstyled">
    <!-- <li *ngFor="let reports of allReports">
      <a
        *ngIf="reports.reportType=='vehicle'"
        (click)="setReportUrl(reports.url);"
        [routerLink]="reports.url">
        <i class="fa fa-bar-chart"></i> {{reports.name}}
      </a>
      <a
        *ngIf="reports.reportType=='messages'"
        (click)="messageReport(reports, reports.url);"
        [routerLink]="reports.url">
        <i class="fa fa-bar-chart"></i> {{reports.name}}
      </a>
      <a
        *ngIf="reports.reportType=='custom'"
        (click)="messageReport(reports, reports.url);"
        [routerLink]="reports.url">
        <i class="fa fa-bar-chart"></i> {{reports.name}}
      </a>
      <a
        *ngIf="reports.reportType=='alPlantMode'"
        (click)="messageReport(reports, reports.url);"
        [routerLink]="reports.url">
        <i class="fa fa-bar-chart"></i> {{reports.name}}
      </a>
      <a
        *ngIf="reports.type=='VISUALIZATION'"
        (click)="messageReport(reports, reports.url);"
        [routerLink]="reports.url">
        <i class="fa fa-bar-chart"></i> {{reports.name}}
      </a>
      <a
        *ngIf="reports.type=='MULTI_DEVICE_RECENT_MESSAGE_DOWNLOAD'"
        (click)="messageReport(reports, reports.url);"
        [routerLink]="reports.url">
        <i class="fa fa-bar-chart"></i> {{reports.name}}
      </a>
      <a
        *ngIf="reports.reportType=='device'"
        (click)="setReportUrl(reports.url);"
        [routerLink]="reports.url">
        <i class="fa fa-bar-chart"></i> {{reports.name}}
      </a>
    </li> -->

    <li *ngIf="deviceReport>0 ||  alPlantMode>0 || messagesReport>0 || customReport>0 || visualizationReport>0 || vehicleReport>0 || multiVehicleReport>0" class="top">
      <div>Data</div>
      <ul class="search-results">
        <li *ngFor="let reports of allReports">
          <a
            *ngIf="reports.reportType=='vehicle'"
            (click)="setReportUrl(reports.url);"
            [routerLink]="reports.url">
            <i class="fa fa-bar-chart"></i> {{reports.name}}
          </a>
          <a
            *ngIf="reports.reportType=='messages'"
            (click)="messageReport(reports, reports.url);"
            [routerLink]="reports.url">
            <i class="fa fa-bar-chart"></i> {{reports.name}}
          </a>
          <a
            *ngIf="reports.reportType=='custom'"
            (click)="messageReport(reports, reports.url);"
            [routerLink]="reports.url">
            <i class="fa fa-bar-chart"></i> {{reports.name}}
          </a>
          <a
            *ngIf="reports.reportType=='alPlantMode'"
            (click)="messageReport(reports, reports.url);"
            [routerLink]="reports.url">
            <i class="fa fa-bar-chart"></i> {{reports.name}}
          </a>
          <a
            *ngIf="reports.type=='VISUALIZATION'"
            (click)="messageReport(reports, reports.url);"
            [routerLink]="reports.url">
            <i class="fa fa-bar-chart"></i> {{reports.name}}
          </a>
          <a
            *ngIf="reports.type=='MULTI_DEVICE_RECENT_MESSAGE_DOWNLOAD'"
            (click)="messageReport(reports, reports.url);"
            [routerLink]="reports.url">
            <i class="fa fa-bar-chart"></i> {{reports.name}}
          </a>
          <a
            *ngIf="reports.reportType=='device'"
            (click)="setReportUrl(reports.url);"
            [routerLink]="reports.url">
            <i class="fa fa-bar-chart"></i> {{reports.name}}
          </a>
        </li>
      </ul>
    </li>
    <li *ngIf="userReport>0 && userAuthorities.includes('READ')" class="top">
      <div>User</div>
      <ul class="search-results">
        <li *ngFor="let reports of allReports">
          <a
            *ngIf="reports.reportType=='user'"
            (click)="setReportUrl(reports.url);"
            [routerLink]="reports.url">
            <i class="fa fa-bar-chart"></i> {{reports.name}}
          </a>
        </li>
      </ul>
    </li>
    <li *ngIf="systemReport>0" class="top">
      <div>System</div>
      <ul class="search-results">
        <li *ngFor="let reports of allReports">
          <a
            *ngIf="reports.reportType=='system'"
            (click)="setReportUrl(reports.url);"
            [routerLink]="reports.url">
            <i class="fa fa-bar-chart"></i> {{reports.name}}
          </a>
        </li>
      </ul>
    </li>
    <!-- <li *ngIf="billingReport>0" class="top">
      <div>Billing</div>
      <ul class="search-results">
        <li *ngFor="let reports of allReports">
          <a
            *ngIf="reports.reportType=='billing'"
            (click)="setReportUrl(reports.url);"
            [routerLink]="reports.url">
            <i class="fa fa-bar-chart"></i> {{reports.name}}
          </a>
        </li>
      </ul>
    </li> -->
  </ul>
  <ul *ngIf="noData" class="list-unstyled">
    <li class="top">
      <div>No Results found</div>
    </li>
  </ul>

  <div *ngIf="!noSearch">

    <!-- Vehicle Reports -->
    <!-- <ul>
      <li
        class="top"
        [ngClass]="{'active': router.url.includes('/reports/device')}">
        <div
          class="side-menu-heading"
          [ngClass]="{'active': router.url.includes('/reports/device'), 'collapsed': !router.url.includes('/reports/device')}"
          data-toggle="collapse" data-target="#vehicle" aria-expanded="false" aria-controls="system" role="button">
          <div [ngClass]="{'active': router.url.includes('/reports/device')}" class="caret"></div> Data
        </div>
        <ul
          [ngClass]="{'show': router.url.includes('/reports/device')}" class="collapse" id="vehicle" data-parent="#acdn">
          <li>
            <a (click)="setReportUrl('/reports/device/message-count');" routerLink="/reports/device/message-count" routerLinkActive="active">
              <i class="fa fa-bar-chart"></i> Messages
            </a>
          </li>
          <li *ngIf="tripAuthorities.includes('READ')">
            <a (click)="setReportUrl('/reports/device/trip-summary');" routerLink="/reports/device/trip-summary" routerLinkActive="active">
              <i class="fa fa-bar-chart"></i> Trip Summary
            </a>
          </li>
          <li>
            <a (click)="setReportUrl('/reports/device/device-report');" routerLink="/reports/device/device-report" routerLinkActive="active"
             class="d-none">
               <i class="fa fa-bar-chart"></i> Device Detail
            </a>
          </li>
          <li *ngIf="!visualizationMode">
            <a (click)="setReportUrl('/reports/device/packet-history', 'packetHistory');" routerLink="/reports/device/packet-history" routerLinkActive="active">
              <i class="fa fa-bar-chart"></i> Packet History
            </a>
          </li>
          <li *ngFor="let report of newReportsMenu.messages">
            <a
              *ngIf="report.type != 'VISUALIZATION' && report.type != 'AL_PLANT_MODE' && !report.downloadable"
              [ngClass]="{active: report.reportId==currentReport.reportId && router.url.includes('/reports/device/messages')}"
              (click)="messageReport(report, '/reports/device/messages');"
              >
              <i class="fa fa-bar-chart"></i> {{report.name}}
            </a>
          </li>
          <li *ngFor="let report of newReportsMenu.custom">
            <a
              *ngIf="report.downloadable"
              [ngClass]="{active: report.reportId==currentReport.reportId && router.url.includes('/reports/device/custom')}"
              (click)="messageReport(report, '/reports/device/custom');"
              >
              <i class="fa fa-bar-chart"></i> {{report.name}}
            </a>
          </li>
          <li *ngFor="let report of newReportsMenu.multiVehicleDownload">
            <a
              *ngIf="report.type=='MULTI_DEVICE_RECENT_MESSAGE_DOWNLOAD'"
              [ngClass]="{active: report.reportId==currentReport.reportId && router.url.includes('/reports/device/multiVehicleDownload')}"
              (click)="messageReport(report, '/reports/device/multiVehicleDownload');"
              >
              <i class="fa fa-bar-chart"></i> {{report.name}}
            </a>
          </li>
          <li *ngFor="let report of newReportsMenu.visualization">
            <a
              *ngIf="report.type=='VISUALIZATION'"
              [ngClass]="{active: report.reportId==currentReport.reportId && router.url.includes('/reports/device/visualization')}"
              (click)="messageReport(report, '/reports/device/visualization');"
              >
              <i class="fa fa-bar-chart"></i> {{report.name}}
            </a>
          </li>
          <li *ngFor="let report of newReportsMenu.alPlantMode">
            <a
              *ngIf="report.type=='AL_PLANT_MODE'"
              [ngClass]="{active: report.reportId==currentReport.reportId && router.url.includes('/reports/device/alPlantMode')}"
              (click)="messageReport(report, '/reports/device/alPlantMode');"
              >
              <i class="fa fa-bar-chart"></i> {{report.name}}
            </a>
          </li>
        </ul>
      </li>
    </ul> -->

    <!-- User Reports -->
    <!-- <ul *ngIf="userAuthorities.includes('READ')">
      <li class="top" [ngClass]="{'active': router.url.includes('/reports/user')}">
        <div class="side-menu-heading" [ngClass]="{'active': router.url.includes('/reports/user'), 'collapsed': !router.url.includes('/reports/user')}" data-toggle="collapse" data-target="#user" aria-expanded="false" aria-controls="user" role="button">
          <div [ngClass]="{'active': router.url.includes('/reports/user')}" class="caret"></div>User
        </div>
        <ul [ngClass]="{'show': router.url.includes('/reports/user')}" class="collapse" id="user" data-parent="#acdn">
          <li>
            <a (click)="setReportUrl('/reports/user/in-out-activity');" routerLink="/reports/user/in-out-activity" routerLinkActive="active">
              <i class="fa fa-bar-chart"></i> Log-in Activity
            </a>
          </li>
        </ul>
      </li>
    </ul> -->

    <!-- System Reports -->
    <ul class="mb-4">
      <li class="top" [ngClass]="{'active': router.url.includes('/reports/system')}">
        <div class="side-menu-heading" [ngClass]="{'active': router.url.includes('/reports/system'), 'collapsed': !router.url.includes('/reports/system')}" data-toggle="collapse" data-target="#system" aria-expanded="false" aria-controls="system" role="button">
          <div [ngClass]="{'active': router.url.includes('/reports/system')}" class="caret"></div> System
        </div>
        <ul [ngClass]="{'show': router.url.includes('/reports/system')}" class="collapse" id="system" data-parent="#acdn">
          <li>
            <a (click)="setReportUrl('/reports/system/live-status');" routerLink="/reports/system/live-status" routerLinkActive="active">
              <i class="fa fa-bar-chart"></i> Live Status
            </a>
          </li>
          <li class="d-none">
            <a (click)="setReportUrl('/reports/system/message-published');" routerLink="/reports/system/message-published" routerLinkActive="active">
              <i class="fa fa-bar-chart"></i> Messages Published
            </a>
          </li>
          <li>
          <li>
            <a (click)="setReportUrl('/reports/system/communicating');" routerLink="/reports/system/communicating" routerLinkActive="active">
              <i class="fa fa-bar-chart"></i> Communicating
            </a>
          </li>
          <li>
            <a (click)="setReportUrl('/reports/system/not-communicating');" routerLink="/reports/system/not-communicating" routerLinkActive="active">
              <i class="fa fa-bar-chart"></i> Not Communicating
            </a>
          </li>
          <li>
            <a (click)="setReportUrl('/reports/system/disconnected');" routerLink="/reports/system/disconnected" routerLinkActive="active">
              <i class="fa fa-bar-chart"></i> Disconnected
            </a>
          </li>
          <!-- <li>
            <a (click)="setReportUrl('/reports/system/version-drilldown');" routerLink="/reports/system/version-drilldown" routerLinkActive="active">
              <i class="fa fa-bar-chart"></i> Software Version
            </a>
          </li>
          <li>
            <a (click)="setReportUrl('/reports/system/provisioning');" routerLink="/reports/system/provisioning" routerLinkActive="active">
              <i class="fa fa-bar-chart"></i> Provisioning
            </a>
          </li> -->
          <!-- <li>
            <a (click)="setReportUrl('/reports/system/device-timeline');" routerLink="/reports/system/device-timeline" routerLinkActive="active">
              <i class="fa fa-bar-chart"></i> Device Timeline
            </a>
          </li> -->
            <li>
              <a (click)="setReportUrl('/reports/system/usage');" routerLink="/reports/system/usage" routerLinkActive="active">
                <i class="fa fa-bar-chart"></i> Bitbrew Usage
              </a>
            </li>
            <!-- <li>
              <a (click)="setReportUrl('/reports/system/billing-report');" routerLink="/reports/system/billing-report" routerLinkActive="active">
                <i class="fa fa-bar-chart"></i> Billing
              </a>
            </li> -->
          <!-- <li>
            <a (click)="setReportUrl('/reports/system/discovery');" routerLink="/reports/system/discovery" routerLinkActive="active">
              <i class="fa fa-bar-chart"></i> Discovery Report
            </a>
          </li> -->
          <!-- <li>
            <a (click)="setReportUrl('/reports/system/diagnostics');" routerLink="/reports/system/diagnostics" routerLinkActive="active">
              <i class="fa fa-bar-chart"></i> Diagnostics Report
            </a>
          </li> -->
          <li class="d-none">
            <a (click)="setReportUrl('/reports/system/usage');" routerLink="/reports/system/usage" routerLinkActive="active">
              <i class="fa fa-bar-chart"></i> Usage
            </a>
          </li>
          
          <!-- <li *ngIf="AL_SystemReport">
            <a (click)="setReportUrl('/reports/system/c-trigger-report');" routerLink="/reports/system/c-trigger-report" routerLinkActive="active">
              <i class="fa fa-bar-chart"></i> C-Triggers
            </a>
          </li>
          <li *ngIf="AL_SystemReport">
            <a (click)="setReportUrl('/reports/system/s-trigger-report');" routerLink="/reports/system/s-trigger-report" routerLinkActive="active">
              <i class="fa fa-bar-chart"></i> S-Triggers
            </a>
          </li> -->
        </ul>
      </li>
    </ul>

    <!-- Billing Reports -->
    <!-- <ul class="mb-4" *ngIf="permissionModules.includes('BILLING')">
      <li class="top" [ngClass]="{'active': router.url.includes('/reports/billing')}">
        <div class="side-menu-heading" [ngClass]="{'active': router.url.includes('/reports/billing'), 'collapsed': !router.url.includes('/reports/billing')}" data-toggle="collapse" data-target="#billing" aria-expanded="false" aria-controls="billing" role="button">
          <div [ngClass]="{'active': router.url.includes('/reports/billing')}" class="caret"></div> Billing
        </div>
        <ul [ngClass]="{'show': router.url.includes('/reports/billing')}" class="collapse" id="billing" data-parent="#acdn">
          <li>
            <a (click)="setReportUrl('/reports/billing/provision-billing');" routerLink="/reports/billing/provision-billing" routerLinkActive="active">
              <i class="fa fa-bar-chart"></i> Billing
            </a>
          </li>
        </ul>
      </li>
    </ul> -->
  </div>
</div>
