import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { SharedService } from '@myproject/shared/shared.service';
import { environment } from '@myenv/environment';
import { configMessage } from '@shared/config-message';

@Component({
  selector: 'app-reports-table',
  templateUrl: './reports-table.component.html',
  styleUrls: ['./reports-table.component.scss']
})
export class ReportsTableComponent implements OnInit, OnChanges {
  @Input() devicesList;
  @Input() displayTitle;
  @Input() changeOffset;
  @Input() blockCall: boolean;
  @Output() pageChangeEvent = new EventEmitter();
  @Output() downloadEvent = new EventEmitter();
  public dateFormat = environment.isoDateTimeFormat;
  dateAttributes = configMessage.dateAttributes;
  deviceStatus = configMessage.deviceStatus;
  deviceStatusClass = configMessage.deviceStatusClass;
  pageInfo: any = {
    offset: 0,
    count: 0,
    limit: 5,
    pageSize: 0,
    lastPage: 0,
  };
  page = 0;
  isLoading = false;
  pageLoading = true;
  currentPageCount: any = {
    start: 0,
    end: 0
  };
  headerItems: any[] = [];
  columns: any[] = [];
  searchModel: any = {};
  customHeaders: any[] = [];
  deviceStaticHeader = [
    'serialNumber',
    'imei',
    // 'simCcid',
    'firmwareVersion',
    'configVersion',
    // 'simCarrier1',
    // 'model',
    // 'deviceStatus',
    'lastCommunication'
  ];
  customSelectedHeader: any[] = [];
  columnPopup = false;
  selectCount = 0;
  allSelected = false;

  constructor(
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.getDeviceHeader();
  }

  ngOnChanges(changes) {
    if (changes.changeOffset) {
      if (changes.changeOffset.previousValue !== changes.changeOffset.currentValue) {
        this.pageInfo.offset = 0;
        this.page = 0;
      }
    }
    if (changes.devicesList) {
      if (changes.devicesList.previousValue !== undefined) {
        this.pageInfo.count = this.devicesList.length;
        const pagecount = this.pageInfo.count / this.pageInfo.limit;
        this.pageInfo.lastPage = Math.floor(pagecount);
        const decimal = pagecount - this.pageInfo.lastPage;

        if (!Number.isInteger(decimal)) {
          this.pageInfo.lastPage = this.pageInfo.lastPage + 1;
        }
        if (this.page == 0) {
          if (this.devicesList.length) {
            this.currentPageCount.start = (this.pageInfo.offset * this.pageInfo.pageSize) + 1;
            if (this.devicesList.length <= 5) {
              this.currentPageCount.end = this.pageInfo.count;
            } else {
              this.currentPageCount.end = (this.pageInfo.offset * this.pageInfo.pageSize) + this.pageInfo.limit;
            }
          }
        }
      }
    }
  }

  getDeviceHeader() {
    let headers = JSON.parse(localStorage.getItem('deviceHeaders'));
    if (headers) {
      this.headerItems = headers;
      this.columns = headers;
      this.isLoading = true;
      this.pageLoading = false;
    } else {
      this.sharedService.getDeviceHeader().subscribe({
        next: res => {
          localStorage.setItem('deviceHeaders', JSON.stringify(res));
          this.headerItems = res;
          this.columns = res;
          this.isLoading = true;
          this.pageLoading = false;
        },
        error: error => {
          this.pageLoading = false;
          this.sharedService.getErrorMsg(error);
        }
      });
    }
  }

  togglePopup() {
    if (this.searchModel && this.searchModel.columnVal) {
      this.searchModel.columnVal = '';
    }
    if (this.columnPopup) {
      this.cancelSelection();
    } else {
      this.checkCustomSelected();
      this.columnPopup = true;
    }
  }

  checkCustomSelected() {
    this.headerItems.map(item => {
      this.selectCount += this.deviceStaticHeader.includes(item.name) ? 1 : 0;
      if (this.customSelectedHeader.includes(item.name)) {
        item.checked = true;
        this.selectCount++;
      } else {
        item.checked = false;
      }
    });
    this.columns = this.headerItems;
  }

  selectItems(event, header, i) {
    if (!this.deviceStaticHeader.includes(header.name)) {
      if (event.target.checked) {
        this.selectCount++;
      } else {
        this.selectCount--;
      }
      this.headerItems.map(head => {
        if (head.name == header.name) {
          if (event.target.checked) {
            head.checked = true;
          } else {
            head.checked = false;
          }
        }
      });
      // this.headerItems[i].checked = event.target.checked;
    }
  }

  selectAllItems(event) {
    this.allSelected = event.target.checked;
    if (event.target.checked) {
      this.selectCount = this.headerItems.length;
    } else {
      this.selectCount = 0;
      this.headerItems.map(item => {
        this.selectCount += this.deviceStaticHeader.includes(item.name) ? 1 : 0;
      });
    }
    this.headerItems.map(head => {
      if (this.deviceStaticHeader.includes(head.name)) {
        head.checked = true;
      } else {
        head.checked = event.target.checked;
      }
    });
  }

  cancelSelection() {
    this.selectCount = 0;
    this.columnPopup = false;
  }

  updatePreference() {
    this.customHeaders = [];
    this.customSelectedHeader = [];
    this.selectCount = 0;
    this.headerItems.map(header => {
      if (
        header.checked &&
        !this.deviceStaticHeader.includes(header.name) &&
        !this.customSelectedHeader.includes(header.name)
      ) {
        const obj = {
          name: header.name,
          displayName: header.displayName,
          checked: header.checked,
          type: header.type
        };
        this.customHeaders.push(obj);
        this.customSelectedHeader.push(header.name);
      }
    });
    this.columnPopup = false;
  }

  downloadFile() {
    const attributes = [...this.deviceStaticHeader, ...this.customSelectedHeader];
    this.downloadEvent.emit(attributes);
  }

  pageCallback(value) {
    this.pageInfo.offset = value.offset;
    this.currentPageCount.start = (value.offset * value.pageSize) + 1;
    const lastPage = Math.floor(this.devicesList.length / 5);
    // this.pageInfo.lastPage = lastPage;
    if (lastPage == value.offset) {
      this.currentPageCount.end = value.count;
    } else {
      this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
    }
    if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
      if (!this.blockCall) {
        this.page = ++this.page;
        this.pageChangeEvent.emit(this.page);
      }
    }
  }

  onColumnSearch() {
    this.columns = this.headerItems;
    const inputVal = this.searchModel.columnVal.trim();
    const val = inputVal.toLowerCase();
    if (val === '') {
      this.columns = this.headerItems;
      return;
    } else {
      if (this.headerItems.length) {
        const temp = this.headerItems.filter((item, index) => {
          if (item.displayName.toLowerCase().includes(val)) {
            return this.headerItems[index];
          }
        });
        this.columns = temp;
      }
    }

  }
}
