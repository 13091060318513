import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnChanges } from '@angular/core';
import { Subject, concat, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { SharedService } from '../shared.service';
import { configMessage } from '@shared/config-message';
import * as moment from 'moment';
import { environment } from '@myenv/environment';
import { NgSelectComponent } from '@ng-select/ng-select';
var SearchSelectDeviceComponent = /** @class */ (function () {
    function SearchSelectDeviceComponent(sharedService) {
        this.sharedService = sharedService;
        this.deviceChangeEvent = new EventEmitter(); // Event raiser on device change
        this.isMulti = false; // for multselect device
        this.closeOnSelect = true; // closes the dropdown on select
        this.clearable = true; // for clearing selection and no device selected
        this.selectSearchCategory = false; // diplays the search type dropdown
        this.searchBy = 'assetName'; // accepts the search by parameter
        this.allowPaste = false; // allows copy paste selection
        this.devices = [];
        this.defaultDevices = [];
        this.deviceLoading = false;
        this.deviceInput$ = new Subject();
        this.selectedDevices = null;
        this.deviceStatus = configMessage.deviceStatus;
        this.deviceStatusClass = configMessage.deviceStatusClass;
        this.dateFormat = environment.smallDateFormat;
        this.pasted = false;
        this.categories = [
            { key: 'assetName', displayName: 'Name' },
            { key: 'serialNumber', displayName: 'DSN' },
            { key: 'vin', displayName: 'VIN' },
            { key: 'productSerialNumber', displayName: 'PSN' },
        ];
        this.attributes = 'deviceId,serialNumber,assetName,productSerialNumber,vin,lastCommunication,deviceStatus,architectureType,ecuMake,generation,simStatus,imei,firmwareVersion,configVersion,simCcid,lastKnownEvent,lastKnownStatus';
    }
    SearchSelectDeviceComponent.prototype.ngOnInit = function () {
        this.getAllDevices();
    };
    SearchSelectDeviceComponent.prototype.ngOnChanges = function (changes) {
        if (changes.querySelectDevice && changes.querySelectDevice.previousValue !== undefined) {
            if (changes.querySelectDevice.previousValue !== changes.querySelectDevice.currentValue) {
                if (changes.querySelectDevice.previousValue === null) {
                    this.selectedDevices = this.querySelectDevice;
                }
            }
        }
        // Empty Selection
        if (changes.emptySelection && changes.emptySelection.previousValue !== undefined) {
            if (changes.emptySelection.currentValue === 0) {
                this.selectedDevices = [];
            }
        }
    };
    SearchSelectDeviceComponent.prototype.trackByFn = function (item) {
        return item.deviceId;
    };
    SearchSelectDeviceComponent.prototype.getAllDevices = function () {
        var _this = this;
        this.sharedService.getAllDevices().subscribe({
            next: function (res) {
                _this.defaultDevices = res;
                // this.selectedDevices = res[0];
                // this.loadDevices();
                _this.selectDevice();
            },
            error: function (error) {
                _this.sharedService.getErrorMsg(error);
            }
        });
    };
    SearchSelectDeviceComponent.prototype.performDeviceOperations = function () {
        this.devices.map(function (device) {
            var commDate = Date.parse(new Date(device.lastCommunication).toUTCString());
            var date = moment(commDate).fromNow();
            device.lastCommunicationString = date;
        });
    };
    SearchSelectDeviceComponent.prototype.selectDevice = function () {
        if (!this.defaultselectdevice) {
            if (this.autoSelect) {
                this.selectedDevices = this.selectedDevices ? this.selectedDevices : this.defaultDevices[0];
            }
            else {
                this.selectedDevices = this.selectedDevices ? this.selectedDevices : null;
            }
        }
        else {
            if (this.selectedDevices) {
                this.sharedService.setdefaultselectvehicle(this.selectedDevices);
            }
            this.selectedDevices = this.sharedService.getdefaultselectvehicle() ? this.sharedService.getdefaultselectvehicle() : this.defaultDevices[0];
        }
        this.deviceChangeEvent.emit(this.selectedDevices);
        this.ngSelectComponent.searchTerm = '';
        this.deviceLoading = false;
        this.pasted = false;
    };
    SearchSelectDeviceComponent.prototype.validateInputString = function (term) {
        if (this.isMulti) {
            term = term.replace(/[^_\-@.,a-zA-Z0-9\s]/g, '').split(/[ ]+/).filter(function (v) { return v !== ''; }).join(',');
        }
        else {
            term = term.replace(/[^_\-@.a-zA-Z0-9\s]/g, '');
        }
        return term;
    };
    SearchSelectDeviceComponent.prototype.onPaste = function (ce) {
        if (ce.type === 'paste') {
            this.pasted = true;
        }
    };
    SearchSelectDeviceComponent.prototype.loadDevices = function () {
        var _this = this;
        this.device$ = concat(of(this.defaultDevices), // default items
        this.deviceInput$.pipe(distinctUntilChanged(), debounceTime(500), tap(function () { return _this.deviceLoading = true; }), switchMap(function (term) { return _this.sharedService.onSearchDevice(_this.searchBy, _this.validateInputString(term), null, null, null, null, _this.attributes).pipe(catchError(function (error) { return of(_this.sharedService.getErrorMsg(error), _this.defaultDevices); }), // default list on error
        tap(function () { return _this.deviceLoading = false; })); })));
        this.device$.subscribe({
            next: function (res) {
                if (_this.pasted && _this.allowPaste) {
                    _this.addDevices(res);
                }
                else {
                    _this.devices = res;
                    _this.deviceLoading = false;
                }
                _this.performDeviceOperations();
                _this.pasted = false;
            },
            error: function (error) {
                _this.deviceLoading = false;
                _this.pasted = false;
                _this.devices = _this.defaultDevices;
            }
        });
    };
    SearchSelectDeviceComponent.prototype.addDevices = function (devices) {
        if (this.selectedDevices !== null && this.selectedDevices.length) {
            this.devices = tslib_1.__spread(this.selectedDevices, devices);
        }
        else {
            this.devices = devices;
        }
        this.selectedDevices = this.devices;
        this.deviceChangeEvent.emit(this.selectedDevices);
        this.pasted = false;
        this.ngSelectComponent.searchTerm = '';
    };
    SearchSelectDeviceComponent.prototype.getLastKnownStatus = function (lastKnownStatus) {
        if (lastKnownStatus) {
            return lastKnownStatus;
        }
        else {
            return "Registered";
        }
    };
    return SearchSelectDeviceComponent;
}());
export { SearchSelectDeviceComponent };
