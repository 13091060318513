import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, OnChanges } from '@angular/core';
import { SharedService } from '@myproject/shared/shared.service';
import { environment } from '@myenv/environment';
import { configMessage } from '@shared/config-message';
var ReportsTableComponent = /** @class */ (function () {
    function ReportsTableComponent(sharedService) {
        this.sharedService = sharedService;
        this.pageChangeEvent = new EventEmitter();
        this.downloadEvent = new EventEmitter();
        this.dateFormat = environment.isoDateTimeFormat;
        this.dateAttributes = configMessage.dateAttributes;
        this.deviceStatus = configMessage.deviceStatus;
        this.deviceStatusClass = configMessage.deviceStatusClass;
        this.pageInfo = {
            offset: 0,
            count: 0,
            limit: 5,
            pageSize: 0,
            lastPage: 0,
        };
        this.page = 0;
        this.isLoading = false;
        this.pageLoading = true;
        this.currentPageCount = {
            start: 0,
            end: 0
        };
        this.headerItems = [];
        this.columns = [];
        this.searchModel = {};
        this.customHeaders = [];
        this.deviceStaticHeader = [
            'serialNumber',
            'imei',
            // 'simCcid',
            'firmwareVersion',
            'configVersion',
            // 'simCarrier1',
            // 'model',
            // 'deviceStatus',
            'lastCommunication'
        ];
        this.customSelectedHeader = [];
        this.columnPopup = false;
        this.selectCount = 0;
        this.allSelected = false;
    }
    ReportsTableComponent.prototype.ngOnInit = function () {
        this.getDeviceHeader();
    };
    ReportsTableComponent.prototype.ngOnChanges = function (changes) {
        if (changes.changeOffset) {
            if (changes.changeOffset.previousValue !== changes.changeOffset.currentValue) {
                this.pageInfo.offset = 0;
                this.page = 0;
            }
        }
        if (changes.devicesList) {
            if (changes.devicesList.previousValue !== undefined) {
                this.pageInfo.count = this.devicesList.length;
                var pagecount = this.pageInfo.count / this.pageInfo.limit;
                this.pageInfo.lastPage = Math.floor(pagecount);
                var decimal = pagecount - this.pageInfo.lastPage;
                if (!Number.isInteger(decimal)) {
                    this.pageInfo.lastPage = this.pageInfo.lastPage + 1;
                }
                if (this.page == 0) {
                    if (this.devicesList.length) {
                        this.currentPageCount.start = (this.pageInfo.offset * this.pageInfo.pageSize) + 1;
                        if (this.devicesList.length <= 5) {
                            this.currentPageCount.end = this.pageInfo.count;
                        }
                        else {
                            this.currentPageCount.end = (this.pageInfo.offset * this.pageInfo.pageSize) + this.pageInfo.limit;
                        }
                    }
                }
            }
        }
    };
    ReportsTableComponent.prototype.getDeviceHeader = function () {
        var _this = this;
        var headers = JSON.parse(localStorage.getItem('deviceHeaders'));
        if (headers) {
            this.headerItems = headers;
            this.columns = headers;
            this.isLoading = true;
            this.pageLoading = false;
        }
        else {
            this.sharedService.getDeviceHeader().subscribe({
                next: function (res) {
                    localStorage.setItem('deviceHeaders', JSON.stringify(res));
                    _this.headerItems = res;
                    _this.columns = res;
                    _this.isLoading = true;
                    _this.pageLoading = false;
                },
                error: function (error) {
                    _this.pageLoading = false;
                    _this.sharedService.getErrorMsg(error);
                }
            });
        }
    };
    ReportsTableComponent.prototype.togglePopup = function () {
        if (this.searchModel && this.searchModel.columnVal) {
            this.searchModel.columnVal = '';
        }
        if (this.columnPopup) {
            this.cancelSelection();
        }
        else {
            this.checkCustomSelected();
            this.columnPopup = true;
        }
    };
    ReportsTableComponent.prototype.checkCustomSelected = function () {
        var _this = this;
        this.headerItems.map(function (item) {
            _this.selectCount += _this.deviceStaticHeader.includes(item.name) ? 1 : 0;
            if (_this.customSelectedHeader.includes(item.name)) {
                item.checked = true;
                _this.selectCount++;
            }
            else {
                item.checked = false;
            }
        });
        this.columns = this.headerItems;
    };
    ReportsTableComponent.prototype.selectItems = function (event, header, i) {
        if (!this.deviceStaticHeader.includes(header.name)) {
            if (event.target.checked) {
                this.selectCount++;
            }
            else {
                this.selectCount--;
            }
            this.headerItems.map(function (head) {
                if (head.name == header.name) {
                    if (event.target.checked) {
                        head.checked = true;
                    }
                    else {
                        head.checked = false;
                    }
                }
            });
            // this.headerItems[i].checked = event.target.checked;
        }
    };
    ReportsTableComponent.prototype.selectAllItems = function (event) {
        var _this = this;
        this.allSelected = event.target.checked;
        if (event.target.checked) {
            this.selectCount = this.headerItems.length;
        }
        else {
            this.selectCount = 0;
            this.headerItems.map(function (item) {
                _this.selectCount += _this.deviceStaticHeader.includes(item.name) ? 1 : 0;
            });
        }
        this.headerItems.map(function (head) {
            if (_this.deviceStaticHeader.includes(head.name)) {
                head.checked = true;
            }
            else {
                head.checked = event.target.checked;
            }
        });
    };
    ReportsTableComponent.prototype.cancelSelection = function () {
        this.selectCount = 0;
        this.columnPopup = false;
    };
    ReportsTableComponent.prototype.updatePreference = function () {
        var _this = this;
        this.customHeaders = [];
        this.customSelectedHeader = [];
        this.selectCount = 0;
        this.headerItems.map(function (header) {
            if (header.checked &&
                !_this.deviceStaticHeader.includes(header.name) &&
                !_this.customSelectedHeader.includes(header.name)) {
                var obj = {
                    name: header.name,
                    displayName: header.displayName,
                    checked: header.checked,
                    type: header.type
                };
                _this.customHeaders.push(obj);
                _this.customSelectedHeader.push(header.name);
            }
        });
        this.columnPopup = false;
    };
    ReportsTableComponent.prototype.downloadFile = function () {
        var attributes = tslib_1.__spread(this.deviceStaticHeader, this.customSelectedHeader);
        this.downloadEvent.emit(attributes);
    };
    ReportsTableComponent.prototype.pageCallback = function (value) {
        this.pageInfo.offset = value.offset;
        this.currentPageCount.start = (value.offset * value.pageSize) + 1;
        var lastPage = Math.floor(this.devicesList.length / 5);
        // this.pageInfo.lastPage = lastPage;
        if (lastPage == value.offset) {
            this.currentPageCount.end = value.count;
        }
        else {
            this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
        }
        if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
            if (!this.blockCall) {
                this.page = ++this.page;
                this.pageChangeEvent.emit(this.page);
            }
        }
    };
    ReportsTableComponent.prototype.onColumnSearch = function () {
        var _this = this;
        this.columns = this.headerItems;
        var inputVal = this.searchModel.columnVal.trim();
        var val = inputVal.toLowerCase();
        if (val === '') {
            this.columns = this.headerItems;
            return;
        }
        else {
            if (this.headerItems.length) {
                var temp = this.headerItems.filter(function (item, index) {
                    if (item.displayName.toLowerCase().includes(val)) {
                        return _this.headerItems[index];
                    }
                });
                this.columns = temp;
            }
        }
    };
    return ReportsTableComponent;
}());
export { ReportsTableComponent };
