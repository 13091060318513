import * as tslib_1 from "tslib";
import { DatePipe } from "@angular/common";
import { OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { environment } from "@myenv/environment";
import { configMessage } from "@myproject/shared/config-message";
import Chart from "chart.js";
import { GraphicReportsService } from "../graphic-reports.service";
import * as FileSaver from 'file-saver';
var UsageComponent = /** @class */ (function () {
    function UsageComponent(title, reportsService, datePipe) {
        this.title = title;
        this.reportsService = reportsService;
        this.datePipe = datePipe;
        this.permissionModules = [];
        this.hideCalendar = false;
        this.highestCount = 0;
        this.chartLabels = [];
        this.stackedDataset = [];
        this.totalsDataset = [];
        this.showTotalChart = false;
        this.isSideMenuClose = false;
        this.messageTypeColor = configMessage.billingMessageTypeColor;
        this.deviceList = [];
        this.currentPageCount = {
            start: 0,
            end: 0,
            totalCount: 0
        };
        this.pageInfo = {
            offset: 0,
            count: 0,
            limit: 10,
            pageSize: 0,
            lastPage: 0,
        };
        this.prevResponse = 0;
        this.loadingIndicator = false;
        this.selectedDate = "";
        this.month = [{ "id": 1, "name": "January" }, { "id": 2, "name": "February" }, { "id": 3, "name": "March" }, { "id": 4, "name": "April" }, { "id": 5, "name": "May" }, { "id": 6, "name": "June" }, { "id": 7, "name": "July" }, { "id": 8, "name": "August" }, { "id": 9, "name": "September" }, { "id": 10, "name": "October" }, { "id": 11, "name": "November" }, { "id": 12, "name": "December" }];
        this.years = [];
        this.selectedMonth = 0;
        this.selectedYear = 0;
        this.page = 0;
        this.currentCustomer = {};
        this.assetType = 'device';
    }
    UsageComponent.prototype.ngOnInit = function () {
        window["deve"] = this;
        this.currentCustomer = JSON.parse(localStorage.getItem("currentUser"));
        this.title.setTitle("Bitbrew Usage" + environment.title_text);
        this.permissionModules = JSON.parse(localStorage.getItem("permissionModules"));
        var currentYear = new Date().getFullYear();
        for (var i = 0; i < 10; i++) {
            this.years.push(currentYear - i);
            this.years.push(currentYear + i);
        }
        this.years = tslib_1.__spread(new Set(this.years.sort()));
        this.selectedYear = currentYear;
        this.selectedMonth = new Date().getMonth() + 1;
        // this.selectedDate = this.datePipe.transform(this.selectedDate, 'yyyy-MM-dd');
        this.getUsageStats();
        this.getDeviceUsageByMonthYear(this.selectedYear, this.selectedMonth);
        this.getData(this.currentCustomer.customerId);
    };
    UsageComponent.prototype.getUsageStats = function () {
        var _this = this;
        this.reportsService.getUsageStats().subscribe(function (data) {
            _this.sortData(data);
        }, function (error) {
            console.log("error", error);
        });
    };
    UsageComponent.prototype.onChangeName = function ($event) {
        this.deviceList = [];
        this.page = 0;
        this.pageInfo.offset = 0;
        this.getDeviceUsageByMonthYear(this.selectedYear, this.selectedMonth);
        this.getDeviceUsageByMonthYearCount(this.selectedYear, this.selectedMonth);
    };
    UsageComponent.prototype.pageCallback = function (value) {
        this.pageInfo.offset = value.offset;
        this.currentPageCount.start = (value.offset * value.pageSize) + 1;
        var lastPage = Math.floor(this.deviceList.length / this.pageInfo.limit);
        if (lastPage === value.offset) {
            this.currentPageCount.end = value.count;
        }
        else {
            this.currentPageCount.end = (value.offset * value.pageSize) + value.limit;
        }
        if (this.pageInfo.lastPage === (this.pageInfo.offset + 1)) {
            // tslint:disable-next-line:no-unused-expression
            if (this.prevResponse === 100) {
                this.page = ++this.page;
                this.getDeviceUsageByMonthYear(this.selectedYear, this.selectedMonth);
            }
        }
    };
    UsageComponent.prototype.downloadUsage = function () {
        var _this = this;
        this.reportsService.downloadDeviceCSV(this.selectedYear, this.selectedMonth).subscribe(function (data) {
            console.log("data", data);
            var contentDisposition = data.headers.get('Content-Disposition');
            var filename;
            if (contentDisposition == null) {
                var date = new Date();
                // filename = 'Usage_' + date + '.csv';Bitbrew_Usage_Data_YYYY-MM-DD
                filename = 'Bitbrew_Usage_Data_' + _this.selectedYear + '-' + (_this.selectedMonth > 9 ? _this.selectedMonth : "0" + _this.selectedMonth) + "-1" + '.csv';
            }
            else {
                filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
            }
            var blob = new Blob([data.body], {
                type: 'text/csv;charset=utf-8'
            });
            FileSaver.saveAs(blob, filename);
        });
    };
    UsageComponent.prototype.getDeviceUsageByMonthYear = function (yr, mnth) {
        var _this = this;
        this.loadingIndicator = true;
        this.reportsService.getUsageByMonthYear(yr, mnth, this.page).subscribe(function (data) {
            _this.prevResponse = data.length;
            if (_this.page === 0) {
                _this.deviceList = data;
                //this.deviceList = this.deviceList.filter(device => device.deviceStatus !== 'enrolled');
            }
            else {
                _this.deviceList = tslib_1.__spread(_this.deviceList, data);
            }
            _this.deviceList.forEach(function (element) {
                if (element.deviceStatus == 'enrolled') {
                    element.deviceStatus = 'neverCommunicated';
                }
            });
            _this.loadingIndicator = false;
            _this.setPageInfo();
        }, function (error) {
            _this.loadingIndicator = false;
        });
    };
    UsageComponent.prototype.setPageInfo = function () {
        this.pageInfo.count = this.deviceList.length;
        var pagecount = this.pageInfo.count / this.pageInfo.limit;
        this.pageInfo.lastPage = Math.floor(pagecount);
        var decimal = pagecount - this.pageInfo.lastPage;
        if (!Number.isInteger(decimal)) {
            this.pageInfo.lastPage = this.pageInfo.lastPage + 1;
        }
        if (this.page == 0) {
            if (this.deviceList.length) {
                this.currentPageCount.start = (this.pageInfo.offset * this.pageInfo.pageSize) + 1;
                if (this.deviceList.length <= this.pageInfo.limit) {
                    this.currentPageCount.end = this.pageInfo.count;
                }
                else {
                    this.currentPageCount.end = (this.pageInfo.offset * this.pageInfo.pageSize) + this.pageInfo.limit;
                }
            }
        }
    };
    UsageComponent.prototype.getDeviceUsageByMonthYearCount = function (year, month) {
        var _this = this;
        this.reportsService.getUsageByMonthYearCount(year, month).subscribe(function (data) {
            _this.currentPageCount.totalCount = data.count;
        }, function (error) { });
    };
    UsageComponent.prototype.sortData = function (res) {
        var _this = this;
        var labels = [];
        var keys = [];
        var backgroundColors = [];
        var dataset = [];
        var totalCounts = [];
        // find all the distinct messages
        res.forEach(function (item) {
            item.neverCommunicated = item.enrolled;
            item.enrolled = item.active + item.inactive + item.enrolled;
            for (var k in item) {
                if (!keys.includes(k) && k !== "date" && k !== "NO_DATA") {
                    if (_this.messageTypeColor[_this.alterLabel(k)]) {
                        backgroundColors.push(_this.messageTypeColor[_this.alterLabel(k)].colorOpaque);
                    }
                    else {
                        backgroundColors.push(_this.messageTypeColor["UNKNOWN"].colorOpaque);
                    }
                    keys.push(k);
                }
            }
        });
        // Obtain date labels and total counts for each day
        res.map(function (item) {
            var datePipe = new DatePipe("en-US");
            // let eventDate = datePipe.transform(item.date, "EE, dd MMM");
            var eventDate = datePipe.transform(item.date, "MMM yyyy");
            labels.push(eventDate);
            var total = 0;
            for (var k in item) {
                if (k !== "date" && item[k]) {
                    total += item[k];
                }
            }
            totalCounts.push(total);
        });
        // get message count and configure dataset
        var highestCountsArray = [];
        var _loop_1 = function (i) {
            var obj = {};
            var counts = [];
            res.map(function (item) {
                if (item[keys[i]]) {
                    counts.push(item[keys[i]]);
                }
                else {
                    counts.push(0);
                }
            });
            obj.label = this_1.alterLabel(keys[i]);
            // obj.label = configMessage.messageType[keys[i]];
            obj.backgroundColor = backgroundColors[i];
            obj.data = counts;
            obj.borderWidth = 1;
            obj.xAxisID = "bar-x-axis1";
            dataset.push(obj);
            highestCountsArray.push(Math.max.apply(Math, tslib_1.__spread(counts)));
        };
        var this_1 = this;
        for (var i = 0; i < keys.length; i++) {
            _loop_1(i);
        }
        this.chartLabels = labels;
        this.stackedDataset = dataset;
        this.totalsDataset = [
            {
                label: "Total Messages",
                backgroundColor: "rgb(54, 162, 235, 1)",
                data: totalCounts,
            },
        ];
        // this.drawStackedBarGraph(this.chartLabels, this.totalsDataset);
        this.drawStackedBarGraph(this.chartLabels, this.stackedDataset);
    };
    UsageComponent.prototype.alterLabel = function (label) {
        if (label === "active") {
            return "Active";
        }
        else if (label === "inactive") {
            return "Inactive";
        }
        else if (label === "neverCommunicated") {
            return "Never Communicated";
        }
        else {
            return "Enrolled";
        }
    };
    UsageComponent.prototype.drawStackedBarGraph = function (labels, dataset) {
        var canvas = document.getElementById("stackedBarGraph");
        var ctx = canvas.getContext("2d");
        this.stackedChart = new Chart(ctx, {
            type: "bar",
            responsive: true,
            data: {
                labels: labels,
                datasets: dataset,
            },
            options: {
                legend: {
                    position: "bottom",
                    display: true,
                    onHover: function (event, legendItem) {
                        document.getElementById("canvas").style.cursor = "pointer";
                    },
                    onClick: function (e, legendItem) {
                        var index = legendItem.datasetIndex;
                        var ci = this.chart;
                        var alreadyHidden = ci.getDatasetMeta(index).hidden === null
                            ? false
                            : ci.getDatasetMeta(index).hidden;
                        var anyOthersAlreadyHidden = false;
                        var allOthersHidden = true;
                        // figure out the current state of the labels
                        ci.data.datasets.forEach(function (e, i) {
                            var meta = ci.getDatasetMeta(i);
                            if (i !== index) {
                                if (meta.hidden) {
                                    anyOthersAlreadyHidden = true;
                                }
                                else {
                                    allOthersHidden = false;
                                }
                            }
                        });
                        // if the label we clicked is already hidden
                        // then we now want to unhide (with any others already unhidden)
                        if (alreadyHidden) {
                            ci.getDatasetMeta(index).hidden = null;
                        }
                        else {
                            // otherwise, lets figure out how to toggle visibility based upon the current state
                            ci.data.datasets.forEach(function (e, i) {
                                var meta = ci.getDatasetMeta(i);
                                if (i !== index) {
                                    // handles logic when we click on visible hidden label and there is currently at least
                                    // one other label that is visible and at least one other label already hidden
                                    // (we want to keep those already hidden still hidden)
                                    if (anyOthersAlreadyHidden && !allOthersHidden) {
                                        meta.hidden = true;
                                    }
                                    else {
                                        // toggle visibility
                                        meta.hidden = meta.hidden === null ? !meta.hidden : null;
                                    }
                                }
                                else {
                                    meta.hidden = null;
                                }
                            });
                        }
                        ci.update();
                    },
                },
                scales: {
                    xAxes: [
                        {
                            display: true,
                            stacked: false,
                            id: "bar-x-axis1",
                            categoryPercentage: 0.5,
                            barPercentage: 1,
                            ticks: {
                                beginAtZero: true
                            },
                        },
                    ],
                    yAxes: [
                        {
                            display: true,
                            stacked: false,
                            ticks: {
                                suggestedMin: 0,
                                suggestedMax: 1,
                                beginAtZero: true
                                // stepSize: this.highestCount > 9 ? null : 1,
                            },
                        },
                    ],
                },
            },
        });
    };
    UsageComponent.prototype.getAccountStatus = function (customerID) {
        var _this = this;
        var assetType;
        var customerId;
        if (customerID != undefined && customerID != null) {
            customerId = customerID;
        }
        else {
            customerId = this.currentCustomer.customerId;
        }
        if (customerId != undefined || customerId != null) {
            this.reportsService.getAccountStatus(customerId).subscribe(function (data) {
                console.log("total vehicle/device count API", data);
                if (data.customerId && data.customerId != null) {
                    if (data.toDate && data.toDate != null) {
                        _this.convertLongToDate(data);
                    }
                    else {
                        _this.toDate = null;
                        _this.accountStatus = "Active";
                        _this.expiredDate = true;
                    }
                }
                else if (assetType === "device" && data[0].assetType === "device") {
                    _this.totalAssetCount = data[1].count;
                }
            }, function (error) {
                console.log("total vehicle/device count API", error);
            });
        }
    };
    UsageComponent.prototype.convertLongToDate = function (row) {
        var currentDate = new Date();
        var tempMin = currentDate.getTimezoneOffset();
        var offSet = tempMin * 60 * 1000 * -1;
        if (row.toDate && row.toDate != null) {
            var toDate = new Date(row.toDate);
            var tempToDate = toDate.getTime() + offSet;
            var todate = new Date(tempToDate);
            var todayDate = new Date();
            if (!(todate < todayDate)) {
                this.accountStatus = "Active";
                this.expiredDate = false;
            }
            else if (todate.getDate() === todayDate.getDate()) {
                this.accountStatus = "Active";
                this.expiredDate = false;
            }
            else {
                this.accountStatus = "Inactive";
                this.expiredDate = true;
            }
            this.toDate = tempToDate;
        }
    };
    UsageComponent.prototype.getTotalMessagesCount = function (customerId) {
        var _this = this;
        this.reportsService.getTotalMessagesCount(customerId).subscribe(function (data) {
            _this.totalMessagesCount = data.totalMessagesCount;
        }, function (error) {
            console.log("error", error);
        });
    };
    UsageComponent.prototype.getTotalCountOfAsset = function (assetType, customerId) {
        var _this = this;
        if (assetType === undefined) {
            this.assetType = "device";
        }
        else {
            this.assetType = assetType;
        }
        this.reportsService.getTotalCountOfAsset(customerId).subscribe(function (data) {
            data.forEach(function (element) {
                if (_this.assetType === "vehicle" && element.assetType === "vehicle") {
                    _this.totalAssetCount = element.count;
                }
                else if (_this.assetType === "device" && element.assetType === "device") {
                    _this.totalAssetCount = element.count;
                }
                else if (_this.assetType === "device" && element.assetType === "Others") {
                    _this.totalAssetCount = element.count;
                }
            });
        }, function (error) {
            console.log("total vehicle/device count API", error);
        });
    };
    UsageComponent.prototype.getData = function (customerId) {
        this.getTotalCountOfAsset("device", customerId);
        this.getAccountStatus(customerId);
        this.getTotalMessagesCount(customerId);
        this.getDeviceUsageByMonthYearCount(this.selectedYear, this.selectedMonth);
    };
    return UsageComponent;
}());
export { UsageComponent };
