import {Component, OnInit, NgZone} from '@angular/core';
import {AuthenticationService} from '@layouts/login/authentication.service';
import {Router} from '@angular/router';

import {configMessage} from '@shared/config-message';
import {Title} from '@angular/platform-browser';
import {SharedService} from '@shared/shared.service';
import {environment} from '@myenv/environment';
import {enMessage} from '@shared/en-us-message';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  // public tenantId = environment.tenantId;
  public footerText = environment.footerText;
  public cmImageUrl = environment.cmImageUrl;
  responseMessage: any = {};
  public forgot: any = {};
  disable = false;

  constructor(private sharedService: SharedService, private authenticationService: AuthenticationService, private ngzone: NgZone, private router: Router,  private title: Title) {
  }

  ngOnInit() {
    this.title.setTitle('Forgot password'+ environment.title_text);
  }

  /* Resetting the token in local storage
    Redirecting to login page */
  onClickForgotPassword(isValid: boolean) {
    if (isValid) {
      this.disable = true;

      this.authenticationService.ForgotPassword(this.forgot).subscribe((res) => {

        localStorage.setItem("resetToken", res.resetToken);
        this.ngzone.run(() => {
          this.disable = false;
          this.router.navigate(['/login'], {queryParams: {msg: 1}})
        });
      }, error => {
        this.sharedService.getErrorMsg(error);
        this.disable = false;
      });
    }
  }
}
