import { Injectable, NgZone } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SharedService } from '@shared/shared.service';

@Injectable()
export class PermissionGuard implements CanActivate {
  constructor(
    private sharedService: SharedService,
    private router: Router,
    private ngzone: NgZone
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let permissionModules = [];
    permissionModules = JSON.parse(localStorage.getItem('permissionModules'));
    const modulename = route.data.moduleName;
    localStorage.setItem('currentModule', modulename);
    return this.checkPermission(modulename, permissionModules).then(res => {
      if (res) {
        return true;
      } else {
        this.sharedService.setDefaultUrl(permissionModules);
        return false;
      }
    });
  }
  checkPermission(modulename, permissionModules): Promise<boolean> {
    if (permissionModules) {
      const checkmodule = permissionModules.includes(modulename);
      return Promise.resolve(checkmodule);
    } else {
      this.router.navigate(['login']);
    }
  }

}
