import * as tslib_1 from "tslib";
import { OnInit, NgZone, OnDestroy, ElementRef, AfterViewInit, } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgbTimepickerConfig } from '@ng-bootstrap/ng-bootstrap';
// ChangeDetectorRef,
import 'jquery';
import Chart from 'chart.js';
import { LiveService } from '@components/live/live.service';
import { Title } from '@angular/platform-browser';
import { environment } from '@myenv/environment';
import { configMessage } from '@shared/config-message';
import * as moment from 'moment';
//
import { SharedService } from '@shared/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
// import * as MarkerClusterer from '@google/markerclusterer';
import * as MarkerClustererPlus from '@google/markerclustererplus';
import { PlaceService } from '@components/places/places.service';
import { WorkerService } from './worker.service';
import { GroupsService } from '@myproject/components/device/groups/groups.service';
import { DashboardService } from '@components/dashboard/dashboard.service';
import { enMessage } from '@shared/en-us-message';
import { DatePipe } from '@angular/common';
import { DataService } from '@components/data/data.service';
import { ScoreService } from '../graphic-reports/score/score.service';
import * as FileSaver from 'file-saver';
var ProgressBar = require('progressbar.js');
var ProgressBar = require('progressbar.js');
var LiveComponent = /** @class */ (function () {
    function LiveComponent(router, route, sharedService, liveService, dashboardService, ngzone, title, workerService, placeService, dataService, groupsService, scoreService, config) {
        this.router = router;
        this.route = route;
        this.sharedService = sharedService;
        this.liveService = liveService;
        this.dashboardService = dashboardService;
        this.ngzone = ngzone;
        this.title = title;
        this.workerService = workerService;
        this.placeService = placeService;
        this.dataService = dataService;
        this.groupsService = groupsService;
        this.scoreService = scoreService;
        this.time = { hour: 1, minute: 59, second: 0 };
        this.distanceMetrics = environment.distanceMetrics;
        this.AL_SystemReport = environment.AL_SystemReport;
        this.noDevicesSelected = false;
        this.showModal = false;
        this.hideModal = false;
        this.disable = false;
        this.searchheader = [
            { displayName: 'Name', name: 'assetName' },
            { displayName: 'Serial Number', name: 'serialNumber' },
            { displayName: 'PSN', name: 'productSerialNumber' },
            { displayName: 'VIN', name: 'vin' },
            { displayName: 'Configuration', name: 'configVersion' },
            { displayName: 'Firmware', name: 'firmwareVersion' },
            { displayName: 'IMEI', name: 'imei' },
            { displayName: 'Message Type', name: 'messageType' },
            { displayName: 'SIM ICCID', name: 'simCcid' },
            { displayName: 'Tags', name: 'tags' }
            // { displayName: 'SIM Number', name: 'simNumber' },
        ];
        this.selectedSearchHeader = {
            displayName: 'Name',
            name: 'assetName'
        };
        this.staticReports = configMessage.staticReports;
        this.configMessage = configMessage;
        this.tripAuthorities = [];
        this.selected = [];
        this.reportsMenu = [];
        this.reports = [];
        this.reportsData = [];
        this.permissionModules = [];
        this.cmImageUrl = environment.cmImageUrl;
        this.speedMetrics = environment.speedMetrics;
        this.dateFormat = environment.smallDateFormat;
        this.refreshIcon = environment.refreshIcon;
        this.live_obd2_metrics_enabled = environment.live_obd2_metrics_enabled;
        this.responseMessage = {};
        this.lat = environment.lat;
        this.lng = environment.lng;
        this.zoom = 2;
        this.mapCenterPoint = {
            minLat: null,
            maxLat: null,
            minLong: null,
            maxLong: null,
        };
        this.liveVehicles = [];
        this.loadMore = false;
        this.vehicle = {};
        this.listPage = true;
        this.show = true;
        // loadAPI: Promise<any>;
        this.openRefreshPopup = false;
        this.detailBox = false;
        this.copied = false;
        this.messageLoader = false;
        this.copiedAssetName = false;
        this.customLoader = false;
        this.liveSearchHeader = [];
        this.isSnazzyInfoWindowOpened = false;
        this.vehicleView = false;
        this.headerHeight = environment.headerHeight;
        this.rowHeight = environment.rowHeight;
        this.pageLimit = environment.pageLimit;
        this.resSize = environment.resSize;
        this.page = 0;
        this.isLoading = false;
        this.loadingIndicator = true;
        this.rows = [];
        this.temp = [];
        this.enablePage = true;
        this.livePermissions = [];
        this.date = new Date();
        this.liveAttribute = configMessage.liveAttribute;
        this.timeout = null;
        this.csvLivePrefHeaderArray = [];
        this.csvLivePrefKeyArray = [];
        this.liveSearch = false;
        // NGX Datatable Variables
        this.infoWindowText = [];
        this.infoWindowTextCopy = [];
        // Filter Variables
        this.searchFilter = {};
        this.filter = false;
        this.speedfilter = '';
        this.searchKey = '';
        this.selectedFilter = '';
        this.searchValue = '';
        this.filterText = 'Status';
        this.favouriteText = {};
        // AutoRefresh Variables
        this.filterPopUpActive = false;
        this.searchModel = {};
        this.selectedFav = {};
        this.deviceList = {
            devices: []
        };
        this.querySelectDevice = null;
        this.toggleEffect = false;
        this.play = false;
        this.timer = false;
        // MarkerClusterer = MarkerClusterer;
        this.MarkerClustererPlus = MarkerClustererPlus;
        this.markers = [];
        this.vehicleTotalCount = { count: 0 };
        this.allfavouriteItems = [];
        this.favouriteListLoading = false;
        this.favEditingMode = false;
        this.tracking = false;
        this.trackedVehicle = {};
        this.subtractTime = 15;
        this.vehicleLocations = [];
        this.trackingTime = 3000;
        this.latestCoOrds = [];
        this.event = {};
        this.flightPlan = [];
        // Places Filter Variables
        this.places = [];
        this.placesListLoading = false;
        this.selectedPlace = 'Places';
        this.place = false;
        // Location Filter
        this.locatePlace = {};
        this.locateDeviceFilter = false;
        this.autoRefreshClick = false;
        this.newPlace = {
            circle: {
                newCenter: {
                    lat: null,
                    lng: null
                },
                radius: 100
            },
            color: '#e01111'
        };
        this.savedLocations = [];
        this.selectedSavedLocation = {};
        this.confirmDeleteAction = '';
        // Groups Filter Variables
        this.groups = [];
        this.groupsListLoading = false;
        this.openedNav = true;
        // showTable: Boolean = true;
        this.groupsMenu = [];
        this.selectedGroup = { name: 'Groups' };
        this.isGroupFilterOn = false;
        // Selected Vehicle Drawer Variables
        this.infoWindowSelectedVehicle = {};
        this.selectedVehicle = {};
        this.isDrawerOpen = false;
        this.dtcCodeCount = 0;
        this.snappedVehicle = {};
        this.drawerLoading = false;
        this.historyRows = [];
        this.EVMessageData = [];
        this.BSMessageData = [];
        this.vPacketData = [];
        this.liveHistoryAttribute = configMessage.liveHistoryAttribute;
        this.batteryEvent = {};
        this.batteryPotential = [];
        this.internalBattery = [];
        this.fuelLevel = [];
        this.healthFuelProgressBars = [];
        this.monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        // For sharing Track
        this.ctrl = new FormControl('', function (control) {
            var value = control.value;
            if (!value) {
                return { addTime: true };
            }
            if (value.hour > 8) {
                return { maxTime: true };
            }
            if (value.hour == 8 && value.minute > 0) {
                return { maxTime: true };
            }
            if (value.hour == 0 && value.minute == 0) {
                return { minTime: true };
            }
            return null;
        });
        // for device health widget in side drawer
        this.isHealthTabActive = false;
        this.deviceEvents = [];
        this.drivingBehaviour = {};
        this.progressBars = [];
        this.dailyScore = {};
        this.showLiveDashboard = false;
        this.initialObdsLoad = false;
        this.currentMonthName = this.monthNames[new Date().getMonth()] + " " + new Date().getFullYear();
    }
    LiveComponent.prototype.ngOnInit = function () {
        window["live"] = this;
        this.title.setTitle('Live' + environment.title_text);
        this.permissionModules = localStorage.getItem('permissionModules');
        var modules = JSON.parse(localStorage.getItem('accessRoleModules'));
        var tripRoleModule = modules.find(function (module) { return module.name === 'TRIPS'; });
        this.tripAuthorities = tripRoleModule && tripRoleModule.authorities ? tripRoleModule.authorities : [];
        this.workerService.postMessage({ action: 'init', apiUrl: environment.apiUrl });
        this.getPermissionsLive();
        this.searchKey = 'assetName';
        sessionStorage.clear();
        this.selectInput();
        this.searchFilter.value = 50;
        var queryParams = this.route.snapshot.queryParams;
        if (queryParams.searchBy && queryParams.value) {
            this.searchVehicle(queryParams.searchBy, queryParams.value);
        }
        this.checkAutoRefreshPrefrence();
        // if (this.permissionModules.includes('PLACE')) {
        //   this.getAllPlace(this.page);
        // }
        // this.getGroups();
        // this.getAllfavourites();
    };
    LiveComponent.prototype.changeValue = function (item) {
        this.selectedSearchHeader = item;
        this.searchKey = item.name;
    };
    LiveComponent.prototype.checkAutoRefreshPrefrence = function () {
        this.isLoading = false;
        var autoRefresh = JSON.parse(localStorage.getItem('preferences')).live.autoRefresh;
        if (autoRefresh && !autoRefresh.enabled || autoRefresh == null) {
            this.searchModel.enabled = false;
            this.getAllVehicleMessage(this.page);
        }
        else {
            this.autoRefresh();
        }
        var livePrefrences = JSON.parse(localStorage.getItem('preferences'));
        this.prefrences = livePrefrences.live;
    };
    LiveComponent.prototype.ngAfterViewInit = function () {
        this.mapInit();
    };
    LiveComponent.prototype.searchVehicle = function (searchBy, value) {
        var _this = this;
        this.inputOne.nativeElement.value = value;
        this.searchKey = searchBy;
        this.searchheader.map(function (item) {
            if (item.name === searchBy) {
                _this.selectedSearchHeader = item;
            }
        });
        this.inputValidator({ target: { value: value } });
    };
    // To get preferences and permissions
    LiveComponent.prototype.getPermissionsLive = function () {
        this.liveKey = [];
        this.livekeyString = this.liveAttribute;
    };
    // =======================================================================
    // WORKER FUNCTIONS WORKSPACE START
    LiveComponent.prototype.stopWorker = function () {
        var workerMessage = { action: 'autorefreshOFF' };
        this.workerService.postMessage(workerMessage);
    };
    // WORKER FUNCTIONS WORKSPACE END
    // ========================================================================
    // To get total campaign count
    LiveComponent.prototype.getVehicleCount = function () {
        var _this = this;
        this.liveService.getVehicleCount().subscribe({
            next: function (res) {
                _this.vehicleTotalCount = res;
            },
            error: function (err) {
                _this.sharedService.getErrorMsg(err);
            }
        });
    };
    LiveComponent.prototype.getGroups = function () {
        var _this = this;
        if (!this.groups.length) {
            this.groupsListLoading = true;
            this.groupsService.getAssignedGroups().subscribe({
                next: function (res) {
                    _this.groups = res;
                    _this.groupsMenu = _this.groups;
                    _this.groupsListLoading = false;
                },
                error: function (err) {
                    _this.sharedService.getErrorMsg(err);
                }
            });
        }
    };
    LiveComponent.prototype.loadPage = function (limit) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (this.enablePage === true) {
                            this.page = ++this.page;
                        }
                        this.getPermissionsLive();
                        this.page = 0;
                        return [4 /*yield*/, this.liveService.getAllVehicleMessage(this.page, this.pageLimit, this.livekeyString).subscribe({
                                next: function (results) {
                                    if (results.length === 0) {
                                        _this.enablePage = false;
                                    }
                                    results = results.map(function (data) {
                                        data.isSnazzyInfoWindowOpened = false;
                                        return data;
                                    });
                                    var rows = tslib_1.__spread(_this.rows, results);
                                    _this.temp = rows;
                                    _this.rows = rows;
                                    _this.liveVehicles = _this.liveVehicles.map(function (data) {
                                        if (data.vehicleSpeed && !Number.isInteger(data.vehicleSpeed)) {
                                            var newvehicleSpeed = data.vehicleSpeed;
                                            data.vehicleSpeed = newvehicleSpeed.toFixed(1);
                                        }
                                    });
                                    _this.liveVehicles = _this.rows;
                                    _this.pageCount = results.length;
                                },
                                error: function (err) {
                                    _this.sharedService.getErrorMsg(err);
                                }
                            })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    LiveComponent.prototype.selectInput = function () {
        var inputElem = this.inputOne.nativeElement;
        inputElem.select();
    };
    LiveComponent.prototype.inputValidator = function (event) {
        if (!this.openedNav) {
            this.openNav();
        }
        //const term = event.target.value.replace(/[^_\-.,a-zA-Z0-9\s]/g, '');
        //this.searchValue = term.split(/[ ]+/).filter(function (v) {
        //  return v !== ''
        //).join(',').trim();
        this.updateFilter(true);
    };
    // To get the search input value
    LiveComponent.prototype.updateFilter = function (isValid) {
        var _this = this;
        clearTimeout(this.timeout);
        if (this.tracking) {
            this.resetTracking();
        }
        if (this.isDrawerOpen) {
            this.explicitCloseDrawer();
        }
        if (this.route.snapshot.queryParams['searchBy']) {
            var snapshot = this.route.snapshot;
            var params = tslib_1.__assign({}, snapshot.queryParams);
            delete params.searchBy;
            delete params.value;
            this.router.navigate([], { queryParams: params });
        }
        if (isValid) {
            if (this.searchValue === '') {
                this.liveSearch = false;
                if (this.selectedFilter || this.searchFilter) {
                    if (this.timer === false || this.play === true) {
                        // tslint:disable-next-line: max-line-length
                        this.liveService.searchVehicle(this.selectedFilter, this.searchValue, this.searchKey, this.selectedGroup.id, this.searchFilter, this.newPlace)
                            .subscribe(function (res) {
                            _this.filter = true;
                            _this.liveVehicles = res;
                            _this.loadMore = false;
                            _this.mapFilter(res);
                            _this.vehicleTotalCount.count = res.length;
                            _this.isLoading = true;
                            _this.page = 0;
                            // this.getAllVehicleMessage(this.page);
                        }, function (error) {
                            _this.isLoading = true;
                            _this.sharedService.getErrorMsg(error);
                        });
                    }
                    else {
                        this.isLoading = true;
                        this.workerSubscription.unsubscribe();
                        this.timerFunction(this.vehicleTypeValue);
                        // this.checkForCount();
                    }
                }
                else {
                    if (this.timer === false || this.play === true) {
                        this.clearMarkers();
                        this.newmarkerCluster.clearMarkers();
                        this.page = 0;
                        this.isLoading = true;
                        this.getAllVehicleMessage(this.page);
                    }
                    else {
                        this.isLoading = false;
                        this.workerSubscription.unsubscribe();
                        this.timerFunction(this.vehicleTypeValue);
                    }
                }
            }
            else {
                this.loadMore = true;
                this.liveSearch = true;
                this.vehicleSearch(this.searchValue);
            }
        }
    };
    LiveComponent.prototype.clearSearch = function () {
        this.inputOne.nativeElement.value = '';
        this.liveSearch = false;
        this.updateFilter(true);
    };
    // To get the searched item from API
    LiveComponent.prototype.vehicleSearch = function (inputVal) {
        if (this.isDrawerOpen) {
            this.explicitCloseDrawer();
        }
        var global = this;
        global.timeout = setTimeout(function () {
            if (global.timer === false || global.play === true) {
                // tslint:disable-next-line: max-line-length
                global.liveService.searchVehicle(global.selectedFilter, inputVal, global.searchKey, global.selectedGroup.id, global.searchFilter, global.newPlace)
                    .subscribe(function (res) {
                    if (res.length) {
                        global.liveVehicles = res;
                        global.rows = res;
                        global.loadingIndicator = false;
                        global.loadMore = false;
                    }
                    else {
                        global.liveVehicles = [];
                        global.rows = [];
                    }
                    global.isLoading = true;
                    global.mapFilter(res);
                    global.searchValue = inputVal;
                    global.vehicleTotalCount['count'] = res.length;
                    global.loadingIndicator = false;
                }, function (error) {
                    global.sharedService.getErrorMsg(error);
                });
            }
            else {
                global.workerSubscription.unsubscribe();
                global.timerFunction(global.vehicleTypeValue);
            }
        }, 1500);
    };
    // To display the Filter popup
    LiveComponent.prototype.openFilterPopUp = function () {
        this.filterPopUpActive = !this.filterPopUpActive;
    };
    LiveComponent.prototype.mapFilter = function (res) {
        var _this = this;
        if (res.length) {
            this.trackActivity(res);
            this.ngzone.run(function () {
                if (_this.markers.length) {
                    _this.clearMarkers();
                }
                if (_this.infoWindow) {
                    _this.infoWindow.close();
                }
                if (_this.place || _this.newPlace.circle.newCenter.lat != null) {
                    _this.newmarkerCluster.clearMarkers();
                    _this.clearMarkers();
                    _this.liveVehicles.forEach(function (data) {
                        _this.addMarker(data);
                    });
                    if (_this.place) {
                        _this.map.fitBounds(_this.cityCircle.getBounds());
                    }
                    else {
                        _this.map.fitBounds(_this.locateCityCircle.getBounds());
                    }
                }
                else {
                    if (_this.liveVehicles.length > 1) {
                        if (_this.newmarkerCluster) {
                            _this.newmarkerCluster.clearMarkers();
                        }
                        // this.map.setZoom(4);
                        _this.addCluster(_this.liveVehicles);
                    }
                    else if (_this.liveVehicles.length < 1) {
                        _this.newmarkerCluster.clearMarkers();
                        _this.clearMarkers();
                        _this.map.setZoom(3);
                        // this.setMapCenter();
                    }
                    else {
                        if (_this.newmarkerCluster) {
                            _this.newmarkerCluster.clearMarkers();
                        }
                        _this.liveVehicles.forEach(function (data) {
                            _this.addMarker(data);
                        });
                    }
                }
            });
        }
    };
    // To get the vehicles based on a selected Filter
    LiveComponent.prototype.getFilter = function (filterItem) {
        var _this = this;
        if (this.tracking) {
            this.resetTracking();
        }
        if (this.isDrawerOpen) {
            this.explicitCloseDrawer();
        }
        if (filterItem === 'RUNNING') {
            this.filterText = 'Running';
        }
        if (filterItem === 'STOPPED') {
            this.filterText = 'Stopped';
        }
        if (filterItem === 'DISCONNECTED') {
            this.filterText = 'Disconnected';
        }
        if (filterItem === 'NOT_COMMUNICATING') {
            this.filterText = 'Not-Communicating';
        }
        if (filterItem === 'configVersion') {
            this.filterText = 'Configuration';
        }
        if (filterItem === 'firmwareVersion') {
            this.filterText = 'Firmware';
        }
        this.selectedFilter = filterItem;
        this.openFilterPopUp();
        this.speedfilter = '';
        if (this.place) {
            this.searchFilter.placeKey = 'places';
            this.searchFilter.placeValue = this.selectedPlace;
        }
        this.searchFilter.value = '';
        this.searchFilter.key = '';
        if (this.timer === false || this.play === true) {
            // tslint:disable-next-line: max-line-length
            this.liveService.searchVehicle(this.selectedFilter, this.searchValue, this.searchKey, this.selectedGroup.id, this.searchFilter, this.newPlace)
                .subscribe(function (res) {
                _this.filter = true;
                _this.liveVehicles = res;
                _this.mapFilter(res);
                _this.vehicleTotalCount.count = res.length;
            }, function (error) {
                _this.sharedService.getErrorMsg(error);
                _this.filterText = 'Status';
            });
        }
        else {
            this.workerSubscription.unsubscribe();
            this.timerFunction(this.vehicleTypeValue);
        }
    };
    // To get the vehicles based on speed filter
    LiveComponent.prototype.getSpeedFilter = function (filterItem, e) {
        var _this = this;
        e.stopPropagation();
        if (this.isDrawerOpen) {
            this.explicitCloseDrawer();
        }
        if (this.tracking) {
            this.resetTracking();
        }
        if (filterItem === 'getSpeed') {
            this.filterText = "Speed > " + this.searchFilter.value;
            if (!this.searchFilter.value) {
                filterItem = '';
            }
            else {
                filterItem = 'getSpeed';
            }
        }
        this.speedfilter = filterItem;
        this.openFilterPopUp();
        this.searchFilter.key = 'vehicleSpeed';
        this.selectedFilter = '';
        if (this.timer === false || this.play === true) {
            // tslint:disable-next-line: max-line-length
            this.liveService.searchVehicle(this.selectedFilter, this.searchValue, this.searchKey, this.selectedGroup.id, this.searchFilter, this.newPlace)
                .subscribe(function (res) {
                _this.filter = true;
                _this.liveVehicles = res;
                _this.mapFilter(res);
                _this.vehicleTotalCount.count = res.length;
            }, function (error) {
                _this.sharedService.getErrorMsg(error);
                _this.filterText = 'Status';
            });
        }
        else {
            this.workerSubscription.unsubscribe();
            this.timerFunction(this.vehicleTypeValue);
        }
    };
    // To clear all filters (To get all vehicles)
    LiveComponent.prototype.clearFilter = function (e) {
        e.stopPropagation();
        this.filterText = 'Status';
        this.filter = false;
        this.filterPopUpActive = false;
        this.selectedFilter = '';
        this.speedfilter = '';
        if (this.isDrawerOpen) {
            this.explicitCloseDrawer();
        }
        if (this.tracking) {
            this.resetTracking();
        }
        if (this.place) {
            this.searchFilter.placeKey = 'places';
            this.searchFilter.placeValue = this.selectedPlace;
        }
        else {
            this.searchFilter.key = '';
        }
        this.updateFilter(true);
    };
    LiveComponent.prototype.clearPlacesFilter = function (e) {
        e.stopPropagation();
        this.selectedPlace = 'Places';
        this.place = false;
        this.searchFilter = {
            value: 50
        };
        if (this.cityCircle) {
            this.cityCircle.setMap(null);
        }
        if (this.tracking) {
            this.resetTracking();
        }
        if (this.isDrawerOpen) {
            this.explicitCloseDrawer();
        }
        this.updateFilter(true);
        this.vehicleGroupFilterkey = null;
    };
    // To filter vehicles in a perticular place
    LiveComponent.prototype.placesFilter = function (data) {
        var _this = this;
        this.vehicleGroupFilterkey = data.placeName;
        if (this.tracking) {
            this.resetTracking();
        }
        if (this.isDrawerOpen) {
            this.explicitCloseDrawer();
        }
        this.selectedPlace = data.placeName;
        this.searchFilter.placeKey = 'places';
        this.searchFilter.placeValue = data.placeName;
        if (this.timer === false || this.play === true) {
            // tslint:disable-next-line: max-line-length
            this.liveService.searchVehicle(this.selectedFilter, this.searchValue, this.searchKey, this.selectedGroup.id, this.searchFilter, this.newPlace)
                .subscribe(function (res) {
                _this.place = true;
                _this.liveVehicles = res;
                _this.plotCircle(data);
                _this.mapFilter(res);
                _this.vehicleTotalCount.count = res.length;
            }, function (error) {
                _this.selectedPlace = 'Places';
                _this.sharedService.getErrorMsg(error);
            });
        }
        else {
            this.place = true;
            this.plotCircle(data);
            this.workerSubscription.unsubscribe();
            this.timerFunction(this.vehicleTypeValue);
        }
    };
    LiveComponent.prototype.plotCircle = function (place) {
        if (this.cityCircle) {
            this.cityCircle.setMap(null);
        }
        var location = { lat: place.circle.centre[0], lng: place.circle.centre[1] };
        this.cityCircle = new google.maps.Circle({
            strokeColor: place.color,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: place.color,
            fillOpacity: 0.35,
            map: this.map,
            center: location,
            radius: place.circle.radius,
            editable: false
        });
        var latlng = new google.maps.LatLng(location.lat, location.lng);
        var bounds = new google.maps.LatLngBounds();
        bounds.extend(latlng);
        this.map.fitBounds(this.cityCircle.getBounds());
    };
    LiveComponent.prototype.groupsFilter = function (data) {
        var _this = this;
        if (this.tracking) {
            this.resetTracking();
        }
        if (this.isDrawerOpen) {
            this.explicitCloseDrawer();
        }
        this.selectedGroup.name = data.name;
        this.selectedGroup.id = data.groupId;
        if (this.timer === false || this.play === true) {
            // tslint:disable-next-line: max-line-length
            this.liveService.searchVehicle(this.selectedFilter, this.searchValue, this.searchKey, this.selectedGroup.id, this.searchFilter, this.newPlace)
                .subscribe(function (res) {
                _this.isGroupFilterOn = true;
                _this.liveVehicles = res;
                _this.mapFilter(res);
                _this.vehicleTotalCount.count = res.length;
            }, function (error) {
                _this.selectedGroup.name = 'Groups';
                _this.sharedService.getErrorMsg(error);
            });
        }
        else {
            this.isGroupFilterOn = true;
            this.workerSubscription.unsubscribe();
            this.timerFunction(this.vehicleTypeValue);
        }
    };
    LiveComponent.prototype.clearGroupsFilter = function (e) {
        e.stopPropagation();
        this.selectedGroup.name = 'Groups';
        delete this.selectedGroup.id;
        this.isGroupFilterOn = false;
        if (this.tracking) {
            this.resetTracking();
        }
        if (this.isDrawerOpen) {
            this.explicitCloseDrawer();
        }
        this.updateFilter(true);
    };
    // to format the data when the liveVehicles[] change
    LiveComponent.prototype.formatData = function (res) {
        var _this = this;
        if (this.page == 0) {
            this.liveVehicles = [];
        }
        if (res.length < this.pageLimit || res.length === 0) {
            this.loadMore = false;
        }
        else {
            this.loadMore = true;
        }
        this.ngzone.run(function () {
            if (_this.infoWindow) {
                _this.infoWindow.close();
            }
        });
        this.liveVehicles = tslib_1.__spread(this.liveVehicles, res);
        this.trackActivity(res);
        this.loadingIndicator = false;
        if (this.place) {
            this.newmarkerCluster.clearMarkers();
            this.clearMarkers();
            this.liveVehicles.forEach(function (data) {
                _this.addMarker(data);
            });
            this.map.fitBounds(this.cityCircle.getBounds());
        }
        else {
            if (this.newmarkerCluster) {
                this.newmarkerCluster.clearMarkers();
            }
            this.clearMarkers();
            if (this.newPlace.circle.newCenter.lat == null) {
                // this.map.setZoom(this.zoom);
            }
            if (this.liveVehicles.length)
                this.addCluster(this.liveVehicles);
        }
        if (this.searchValue !== '' ||
            this.selectedFilter !== '' ||
            this.speedfilter !== '' ||
            this.place ||
            this.isGroupFilterOn ||
            this.newPlace.circle.newCenter.lat != null) {
            this.vehicleTotalCount.count = this.liveVehicles.length;
        }
        else {
            this.getVehicleCount();
        }
        this.setMapCenter();
    };
    // To track and set Activity(stopTime, vehicleSpeed, Message) of liveVehicles
    LiveComponent.prototype.trackActivity = function (res) {
        var _this = this;
        if (res.length) {
            this.mapCenterPoint.minLat = res[0].latitude;
            this.mapCenterPoint.maxLat = res[0].latitude;
            this.mapCenterPoint.minLong = res[0].longitude;
            this.mapCenterPoint.maxLong = res[0].longitude;
        }
        res.map(function (data) {
            if (data.vehicleSpeed && !Number.isInteger(data.vehicleSpeed)) {
                var newvehicleSpeed = data.vehicleSpeed;
                data.vehicleSpeed = parseFloat(newvehicleSpeed.toFixed(1));
            }
            // while loop traversing finding min max lat long values to get boundaries for map
            if (data.latitude && data.latitude < _this.mapCenterPoint.minLat) {
                _this.mapCenterPoint.minLat = data.latitude;
            }
            else if (data.latitude && data.latitude > _this.mapCenterPoint.maxLat) {
                _this.mapCenterPoint.maxLat = data.latitude;
            }
            if (data.longitude && data.longitude < _this.mapCenterPoint.minLong) {
                _this.mapCenterPoint.minLong = data.longitude;
            }
            else if (data.longitude && data.longitude > _this.mapCenterPoint.maxLong) {
                _this.mapCenterPoint.maxLong = data.longitude;
            }
            _this.addMarkerIcon(data);
            data.isSnazzyInfoWindowOpened = false;
            if (_this.place) {
                if (configMessage.messageType[data.messageType]) {
                    data.activity = configMessage.messageType[data.messageType] + '(' + data.placeName + ')';
                }
                else {
                    data.activity = data.messageType;
                }
            }
            else {
                if (configMessage.messageType[data.messageType]) {
                    data.activity = configMessage.messageType[data.messageType];
                    if ((data.messageType === 'PLACE_IN_MESSAGE' || data.messageType === 'PLACE_OUT_MESSAGE') && data.placeName) {
                        data.activity += ' ' + data.placeName;
                    }
                }
                else {
                    data.activity = data.messageType;
                }
            }
        });
        var stopTime = this.liveVehicles.map(function (data) {
            _this.date = new Date(data.eventTime);
            var millisecond = Date.parse(_this.date.toUTCString());
            var date = moment(millisecond).fromNow();
            var currentTime = new Date();
            var currentTimeMS = Date.parse(currentTime.toUTCString());
            data.endPacket = currentTimeMS - millisecond > 600000;
            return date;
        });
        var obj = {};
        for (var i = 0; i < stopTime.length; i++) {
            var split = stopTime[i].split(':');
            obj[split[0]] = split[1];
        }
        this.calculateStopTime = stopTime;
        this.temp = this.liveVehicles;
        this.addStopTime(this.liveVehicles, this.calculateStopTime);
        this.rows = this.liveVehicles;
        this.setMapCenter();
    };
    LiveComponent.prototype.setMapCenter = function () {
        if (this.mapCenterPoint.maxLat && this.mapCenterPoint.minLat) {
            this.map.setCenter(new google.maps.LatLng(((this.mapCenterPoint.maxLat + this.mapCenterPoint.minLat) / 2.0), ((this.mapCenterPoint.maxLong + this.mapCenterPoint.minLong) / 2.0)));
            this.fitMapBound();
        }
        else {
            this.map.setCenter(new google.maps.LatLng(0, 0));
            this.map.setZoom(2);
        }
    };
    LiveComponent.prototype.fitMapBound = function () {
        this.map.fitBounds(new google.maps.LatLngBounds(
        //bottom left
        new google.maps.LatLng(this.mapCenterPoint.minLat, this.mapCenterPoint.minLong), 
        //top right
        new google.maps.LatLng(this.mapCenterPoint.maxLat, this.mapCenterPoint.maxLong)));
    };
    // To get all the vehicle data on that page and to invoke cluster methods and to calculate stop time
    LiveComponent.prototype.getAllVehicleMessage = function (page, loadMore) {
        var _this = this;
        this.loadingIndicator = true;
        if (!loadMore) {
            this.isLoading = false;
        }
        this.listPage = true;
        this.liveService.getAllVehicleMessage(page, this.pageLimit, this.livekeyString).subscribe(function (res) {
            _this.isLoading = true;
            _this.formatData(res);
            // this.setMapCenter();
            _this.pageCount = res.length;
        }, function (error) {
            _this.isLoading = true;
            _this.sharedService.getErrorMsg(error);
        });
    };
    LiveComponent.prototype.addMarkerIcon = function (data) {
        var iconName = '';
        if (data.messageType) {
            if (data.messageType === 'COMMUNICATION_LOST') {
                iconName = '../../../assets/img/direction/warning/';
            }
            else if (data.messageType === 'TRIP_END' || data.messageType === 'IGNITION_OFF') {
                iconName = '../../../assets/img/direction/red/';
            }
            else if (!data.endPacket && data.messageType !== 'TRIP_END' && data.messageType !== 'IGNITION_OFF') {
                iconName = '../../../assets/img/direction/green/';
            }
            else {
                iconName = '../../../assets/img/direction/gray/';
            }
        }
        else {
            iconName = '../../../../assets/img/direction/default/';
        }
        this.setIcon(data, iconName);
    };
    LiveComponent.prototype.setIcon = function (data, icon) {
        var heading = data.heading;
        var publishedBy = data.publishedBy;
        var generation = data.generation;
        var iconName = icon + "stop.png";
        if (heading || heading == '0') {
            if (heading > 0 && heading <= 30) {
                iconName = icon + "30.png";
            }
            else if (heading > 30 && heading <= 60) {
                iconName = icon + "60.png";
            }
            else if (heading > 60 && heading <= 90) {
                iconName = icon + "90.png";
            }
            else if (heading > 90 && heading <= 120) {
                iconName = icon + "120.png";
            }
            else if (heading > 120 && heading <= 150) {
                iconName = icon + "150.png";
            }
            else if (heading > 150 && heading <= 180) {
                iconName = icon + "180.png";
            }
            else if (heading > 180 && heading <= 210) {
                iconName = icon + "210.png";
            }
            else if (heading > 210 && heading <= 240) {
                iconName = icon + "240.png";
            }
            else if (heading > 240 && heading <= 270) {
                iconName = icon + "270.png";
            }
            else if (heading > 270 && heading <= 300) {
                iconName = icon + "300.png";
            }
            else if (heading > 300 && heading <= 330) {
                iconName = icon + "330.png";
            }
            else if (heading > 330 && heading <= 360 || heading == '0') {
                iconName = icon + "0.png";
            }
        }
        if (publishedBy && publishedBy === 'RSU Simulation') {
            iconName = '../../assets/img/direction/v2x.png';
        }
        else if (publishedBy && publishedBy === 'OBU Simulation') {
            iconName = '../../assets/img/marker_red.png';
        }
        else if ((generation === 'EBIKE') || (publishedBy && publishedBy === 'EBIKE Simulation')) {
            iconName = '../../assets/img/direction/ebike.png';
        }
        else if ((generation === 'OBD_DASHCAM') || (publishedBy && publishedBy === 'OBD_DASHCAM Simulation')) {
            iconName = '../../assets/img/direction/obd_dashcam.png';
        }
        var iconUrl = {
            url: iconName,
            labelOrigin: { x: 50, y: -15 }
        };
        data.iconUrl = iconUrl;
        return data;
    };
    // For loading more vehicles on clicking Load more button
    LiveComponent.prototype.loadMoreVehicles = function () {
        this.page = ++this.page;
        if (this.timer) {
            this.workerSubscription.unsubscribe();
            if (!this.play) {
                this.autorefreshPlayPause();
            }
            this.getAllVehicleMessage(this.page, true);
        }
        else {
            this.getAllVehicleMessage(this.page, true);
        }
    };
    LiveComponent.prototype.addStopTime = function (rows, stopTime) {
        rows.map(function (item, index) {
            return item['calculateStopTime'] = stopTime[index];
        });
        return rows;
    };
    LiveComponent.prototype.refresh = function () {
        this.loadMore = false;
        // if (!this.place) {
        //   this.map.setZoom(this.zoom);
        // }
        this.liveVehicles = [];
        if (this.tracking) {
            this.resetTracking();
        }
        if (this.isDrawerOpen) {
            this.explicitCloseDrawer();
        }
        this.page = 0;
        if (this.timer) {
            if (this.searchValue !== '' ||
                this.selectedFilter !== '' ||
                this.speedfilter !== '' ||
                this.place ||
                this.isGroupFilterOn ||
                this.newPlace.circle.newCenter.lat != null) {
                this.workerSubscription.unsubscribe();
                this.timerFunction(this.vehicleTypeValue);
            }
        }
        else {
            if (this.searchValue !== '' ||
                this.selectedFilter !== '' ||
                this.speedfilter !== '' ||
                this.place ||
                this.isGroupFilterOn ||
                this.newPlace.circle.newCenter.lat != null) {
                this.loadingIndicator = true;
                this.vehicleSearch(this.searchValue);
            }
            else {
                this.getAllVehicleMessage(this.page);
                this.getVehicleCount();
            }
        }
    };
    // Important; DO NOT REMOVE THIS COMMENT
    // getAllVehicle() {
    //   this.infoWindow.close();
    //   this.newmarkerCluster.clearMarkers();
    //   this.clearMarkers();
    //   this.map.setZoom(4);
    //   this.addCluster(this.liveVehicles);
    // }
    LiveComponent.prototype.getVehicleInfoWindowView = function (vehicle, tooltip) {
        var _this = this;
        // tslint:disable-next-line:no-unused-expression
        if (vehicle.latitude, vehicle.longitude) {
            this.lat = vehicle.latitude;
            this.lng = vehicle.longitude;
        }
        else {
            vehicle.latitude = 0;
            vehicle.longitude = 0;
        }
        this.ngzone.run(function () {
            _this.clearMarkers();
            if (_this.vehicleView == false && vehicle.latitude == 0 && vehicle.longitude == 0) {
                _this.clearMarkers();
                _this.map.setZoom(14);
                // this.getAllVehicle();
            }
            else {
                _this.addMarker(vehicle, tooltip);
            }
            // this.changeDetectorRef.detectChanges();
        });
    };
    LiveComponent.prototype.onActivate = function (event) {
        // if(!this.route.snapshot.queryParams.assetName) {
        if (event.type == 'click' && event.cellIndex != -1) {
            // this.toggleExpandRow(event.row);
            if (this.timer) {
                if (!this.play) {
                    this.autorefreshPlayPause();
                }
                // Do something when you click on row cell other than checkbox.
                // this.getVehicleInfoWindowView(event.row);
            }
            if (this.showLiveDashboard) {
                this.selectedVehicle = undefined;
            }
            this.infoWindowSelectedVehicle = event.row;
            this.toggleDrawer(event.row);
            // Do something when you click on row cell other than checkbox.
            this.getVehicleInfoWindowView(event.row, true);
        }
        // }
    };
    // To get preferences and permissions
    LiveComponent.prototype.getPermissionsLiveHistory = function () {
        this.liveHistoryKey = [];
        var liveHistoryPrefrence = JSON.parse(localStorage.getItem('liveHistoryPrefrence'));
        if (liveHistoryPrefrence) {
            // this.livehistorySearchheader = liveHistoryPrefrence;
            this.liveHistoryKey = liveHistoryPrefrence.map(function (data) {
                return data.key;
            });
            if (this.liveHistoryKey.length) {
                this.liveHistorykeyString = this.liveHistoryKey.toString().concat(',').concat(this.liveHistoryAttribute);
            }
            else {
                this.liveHistorykeyString = this.liveHistoryAttribute;
            }
        }
        else {
            this.liveHistorykeyString = this.liveHistoryAttribute;
        }
    };
    LiveComponent.prototype.getSingleVehicleView = function (deviceId, startTime, endTime) {
        var _this = this;
        try {
            this.getPermissionsLiveHistory();
            if (deviceId) {
                this.liveService.getSingleVehicleMessage(deviceId, 0, this.liveHistorykeyString, startTime, endTime, 10).subscribe(function (res) {
                    _this.ngzone.run(function () {
                        _this.historyRows = res.slice(0, 10);
                        if (_this.historyRows.length > 0) {
                            var labels = [];
                            var y1Axis = [];
                            var y2Axis = [];
                            res.map(function (data) {
                                if (data.vehicleSpeed && !Number.isInteger(data.vehicleSpeed)) {
                                    var newvehicleSpeed = data.vehicleSpeed;
                                    data.vehicleSpeed = parseFloat(newvehicleSpeed).toFixed(1);
                                }
                                var messageValue = configMessage.messageType[data.messageType];
                                if (!messageValue) {
                                    messageValue = data.messageType;
                                }
                                data.activity = messageValue;
                            });
                        }
                    });
                }, function (error) {
                    _this.sharedService.getErrorMsg(error);
                });
            }
        }
        catch (e) {
            console.log(e.message);
        }
    };
    LiveComponent.prototype.getEVMessage = function (deviceId, startTime, endTime) {
        var _this = this;
        try {
            var liveHistorykeyString = 'batteryKwhIn,batteryKwhOut,eventTime';
            if (deviceId) {
                this.liveService.getSingleVehicleMessage(deviceId, 0, liveHistorykeyString, startTime, endTime, 50, 'EV_PERIODIC').subscribe(function (res) {
                    _this.EVMessageData = res;
                    if (res.length > 0) {
                        var labels_1 = [];
                        var y1Axis_1 = [];
                        var y2Axis_1 = [];
                        res.map(function (data) {
                            var datePipe = new DatePipe('en-US');
                            var eventDate = datePipe.transform(data.eventTime, 'dd MMM');
                            labels_1.push(eventDate);
                            y1Axis_1.push(data.batteryKwhIn);
                            y2Axis_1.push(data.batteryKwhOut);
                        });
                        _this.drawLineGraph(labels_1, y1Axis_1, y2Axis_1);
                    }
                }, function (error) {
                    _this.sharedService.getErrorMsg(error);
                });
            }
        }
        catch (e) {
            console.log(e.message);
        }
    };
    LiveComponent.prototype.getMessages = function (deviceId, startTime, endTime, liveHistorykeyString, messageType) {
        var _this = this;
        if (this.internalBatteryChart) {
            this.internalBatteryChart.destroy();
        }
        if (this.fuelLevelChart) {
            this.fuelLevelChart.destroy();
        }
        if (this.vehicleBatteryPotentialGraph) {
            this.vehicleBatteryPotentialGraph.destroy();
        }
        this.batteryPotential = [];
        this.internalBattery = [];
        this.fuelLevel = [];
        try {
            if (deviceId) {
                this.liveService.getSingleVehicleMessage(deviceId, 0, liveHistorykeyString, startTime, endTime, 50, messageType).subscribe(function (res) {
                    _this.BSMessageData = res;
                    if (res.length > 0) {
                        var labels_2 = [];
                        res.reverse();
                        res.map(function (data) {
                            var datePipe = new DatePipe('en-US');
                            var eventDate = datePipe.transform(data.eventTime, 'dd MMM');
                            labels_2.push(eventDate);
                            if (data.vehicleBatteryPotential) {
                                _this.batteryPotential.push(data.vehicleBatteryPotential);
                            }
                            if (data.internalBattery) {
                                _this.internalBattery.push(data.internalBattery);
                            }
                            if (data.fuelLevel) {
                                _this.fuelLevel.push(data.fuelLevel.toFixed(1));
                            }
                        });
                        if (_this.batteryPotential.length) {
                            _this.vehicleBatteryGraph(labels_2, _this.batteryPotential);
                        }
                        if (_this.internalBattery.length) {
                            _this.internalBatteryGraph(labels_2, _this.internalBattery);
                        }
                        if (_this.fuelLevel.length) {
                            _this.fuelLevelGraph(labels_2, _this.fuelLevel);
                        }
                    }
                }, function (error) {
                    _this.sharedService.getErrorMsg(error);
                });
            }
        }
        catch (e) {
            console.log(e.message);
        }
    };
    LiveComponent.prototype.getGPSMessage = function (deviceId) {
        var _this = this;
        try {
            if (deviceId) {
                var date = new Date();
                var endTime = date.toISOString().slice(0, 10);
                var lastDay = new Date(date.getTime() - (15 * 24 * 60 * 60 * 1000));
                var startTime = lastDay.toISOString().slice(0, 10);
                this.liveService.getvPacket(deviceId, startTime, endTime).subscribe(function (res) {
                    _this.vPacketData = res;
                    if (res.length > 0) {
                        var labels_3 = [];
                        var data1_1 = [];
                        var data2_1 = [];
                        res.map(function (data) {
                            var datePipe = new DatePipe('en-US');
                            var eventDate = datePipe.transform(data.bucket, 'dd MMM');
                            labels_3.push(eventDate);
                            data1_1.push(data.validFixCount);
                            data2_1.push(data.invalidFixCount);
                        });
                        // let chartData={
                        //   chartId: 'GPSChart',
                        //   chartType: 'bar',
                        //   label1: 'Valid Fix Count',
                        //   color1: '#007bff',
                        //   borderWidth: '1',
                        //   label2: 'Invalid Fix Count',
                        //   color2: 'dc3545',
                        //   display: 'false',
                        //   xStacked: 'true',
                        //   yStacked: 'true',
                        //   suggestedMin: '0',
                        //   suggestedMax: '10'
                        // };
                        // this.drawChart(chartData,labels, data1, data2);
                        _this.GPSGraph(labels_3, data1_1, data2_1);
                    }
                }, function (error) {
                    _this.sharedService.getErrorMsg(error);
                });
            }
        }
        catch (e) {
            console.log(e.message);
        }
    };
    LiveComponent.prototype.getBatteryEvent = function (deviceId) {
        var _this = this;
        try {
            if (deviceId) {
                this.liveService.getBatteryEvent(deviceId).subscribe(function (res) {
                    _this.batteryEvent = res;
                    var datePipe = new DatePipe('en-US');
                    if (_this.batteryEvent.mainBatteryConnectAt && _this.batteryEvent.mainBatteryDisConnectAt) {
                        var date = new Date().toUTCString();
                        var currentTime = Date.parse(date);
                        var reconnect = Date.parse(_this.batteryEvent.mainBatteryConnectAt);
                        var disconnect = Date.parse(_this.batteryEvent.mainBatteryDisConnectAt);
                        var reconnectDiff = currentTime - reconnect;
                        var disconnectDiff = currentTime - disconnect;
                        if (reconnectDiff > disconnectDiff) {
                            var eventDate = datePipe.transform(_this.batteryEvent.mainBatteryDisConnectAt, _this.dateFormat);
                            _this.batteryEvent['connect'] = "Disconnect " + eventDate;
                        }
                        else {
                            var eventDate = datePipe.transform(_this.batteryEvent.mainBatteryConnectAt, _this.dateFormat);
                            _this.batteryEvent['connect'] = "Reconnect " + eventDate;
                        }
                    }
                    else if (_this.batteryEvent.mainBatteryConnectAt) {
                        var eventDate = datePipe.transform(_this.batteryEvent.mainBatteryConnectAt, _this.dateFormat);
                        _this.batteryEvent['connect'] = "Reconnect " + eventDate;
                    }
                    else if (_this.batteryEvent.mainBatteryDisConnectAt) {
                        var eventDate = datePipe.transform(_this.batteryEvent.mainBatteryDisConnectAt, _this.dateFormat);
                        _this.batteryEvent['connect'] = "Disconnect " + eventDate;
                    }
                }, function (error) {
                    _this.sharedService.getErrorMsg(error);
                });
            }
        }
        catch (e) {
            console.log(e.message);
        }
    };
    LiveComponent.prototype.getVehicleTime = function (value) {
        try {
            this.vehicle = value;
            // localStorage.setItem('liveSelectedVehicle', JSON.stringify(value));
            localStorage.setItem('defaultselectvehicle', JSON.stringify(value));
            // const userPref = JSON.parse(localStorage.getItem('preferences'));
            // if (userPref.liveHistory.toggle && userPref.liveHistory.toggle.tripsView) {
            this.sharedService.goToHistory({
                queryParams: {
                    'liveSearch': this.searchValue,
                    'liveKey': this.searchKey
                }
            });
            // } else {
            // this.router.navigate(['/location-history'], {
            //   queryParams: {
            //     'liveSearch': this.searchValue,
            //     'liveKey': this.searchKey
            //   }
            // });
            // }
        }
        catch (e) {
            console.log(e.message);
        }
    };
    LiveComponent.prototype.redirectPage = function (page) {
        try {
            if (page == 'trips') {
                this.sharedService.goToHistory({
                    queryParams: {
                        'id': this.selectedVehicle.deviceId,
                        'name': this.selectedVehicle.assetName
                    }
                });
            }
        }
        catch (e) {
            console.log(e.message);
        }
    };
    LiveComponent.prototype.redirectToReports = function (report) {
        var vehicle = this.selectedVehicle;
        try {
            localStorage.setItem('currentReport', JSON.stringify(report));
            localStorage.setItem('reportsVehicle', JSON.stringify(vehicle));
            if (report.type === 'VISUALIZATION') {
                this.router.navigate(['/reports/device/visualization']);
            }
            else if (report.downloadable && report.type !== 'AL_PLANT_MODE') {
                this.router.navigate(['/reports/device/custom']);
            }
            else if (report.type === 'AL_PLANT_MODE') {
                this.router.navigate(['/reports/device/alPlantMode']);
            }
            else if (['vehicle', 'device', 'system', 'billing'].includes(report.reportType) || report.name === 'Trip Summary') {
                this.router.navigate([report.url]);
            }
            else {
                this.router.navigate(['/reports/device/messages']);
            }
        }
        catch (e) {
            console.log(e.message);
        }
    };
    LiveComponent.prototype.redirectToDevice = function (searchKey) {
        try {
            this.router.navigate(['/devices'], {
                queryParams: {
                    'searchBy': searchKey,
                    'value': this.vehicleDetail[searchKey],
                }
            });
        }
        catch (e) {
            console.log(e.message);
        }
    };
    LiveComponent.prototype.copyToClipboard = function () {
        var text = document.getElementById('text').innerText;
        var elem = document.createElement('textarea');
        document.body.appendChild(elem);
        elem.value = text;
        elem.select();
        document.execCommand('copy');
        document.body.removeChild(elem);
        this.copied = true;
        var global = this;
        setTimeout(function () {
            global.copied = false;
        }, 200);
    };
    LiveComponent.prototype.getMessage = function (messageId) {
        var _this = this;
        this.messageLoader = true;
        this.dataService.getMessage(messageId).subscribe(function (res) {
            _this.messageLoader = false;
            _this.messageFormattedString = _this.sharedService.syntaxHighlight([res]);
        }, function (error) {
            _this.messageLoader = false;
            _this.sharedService.getErrorMsg(error);
        });
    };
    LiveComponent.prototype.copyAssetName = function (name, index) {
        this.currentIndex = index;
        var elem = document.createElement('textarea');
        document.body.appendChild(elem);
        elem.value = name;
        elem.select();
        document.execCommand('copy');
        document.body.removeChild(elem);
        this.copiedAssetName = true;
        var global = this;
        setTimeout(function () {
            global.copiedAssetName = false;
        }, 1500);
    };
    // To download vehicle's table data in csv file
    LiveComponent.prototype.downloadCsv = function () {
        var _this = this;
        this.liveService.downloadLiveSelectedDevices(this.selectedFilter, this.searchValue, this.searchKey, this.selectedGroup.id, this.searchFilter, this.newPlace).subscribe({
            next: function (res) {
                var contentDisposition = res.headers.get('Content-Disposition');
                var filename;
                if (contentDisposition == null) {
                    var date = new Date();
                    filename = 'Live_Devices' + date + '.csv';
                }
                else {
                    filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                }
                var blob = new Blob([res.body], {
                    type: 'text/csv;charset=utf-8'
                });
                FileSaver.saveAs(blob, filename);
            },
            error: function (err) {
                _this.sharedService.getErrorMsg(err);
            }
        });
    };
    // download() {
    //   // Collecting data for the CSV file based on our csvLivePrefKeyArray Provided
    //   this.csvLivePrefHeaderArray[0] = 'Name';
    //   this.csvLivePrefHeaderArray[1] = 'Time';
    //   this.csvLivePrefKeyArray = Array.from(new Set(['assetName', 'eventTime']));
    //   const data = this.liveVehicles.map(vehicle => {
    //     return this.csvLivePrefKeyArray.map(csvHeader => {
    //       if (csvHeader === 'eventTime') {
    //         vehicle[csvHeader] = moment(vehicle[csvHeader]).format('MMM DD, hh:mm:ss a');
    //       }
    //       if (csvHeader === 'calculateStopTime') {
    //         vehicle[csvHeader] = 'Updated ' + vehicle[csvHeader];
    //       }
    //       return vehicle[csvHeader] || '';
    //     });
    //   });
    //   const options = {
    //     fieldSeparator: ',',
    //     quoteStrings: '"',
    //     decimalseparator: '.',
    //     showLabels: false,
    //     showTitle: false,
    //     useBom: false,
    //     headers: this.csvLivePrefHeaderArray
    //   };
    //   const date = new Date();
    //   const filename = 'Vehicles_' + date + '';
    //   new Angular5Csv(data, filename, options);
    // }
    // =========================================================================
    // AUTOREFRESH LOGIC STARTS HERE
    // To open Refresh tooltip box
    LiveComponent.prototype.openRefreshPicker = function () {
        this.openRefreshPopup = this.openRefreshPopup != true;
    };
    // It starts timer if enabled by user in preferences and vice versa.
    // Also invoked while loading component if autorefresh enabled in preferences
    LiveComponent.prototype.autoRefresh = function () {
        this.isLoading = false;
        var autoRefresh = JSON.parse(localStorage.getItem('preferences')).live.autoRefresh;
        if (autoRefresh) {
            if (autoRefresh.enabled === true) {
                if (autoRefresh.metrics === 'seconds' || autoRefresh.metrics === 'minutes') {
                    this.toggleEffect = true;
                    this.searchModel.enabled = true;
                    var timeValue = autoRefresh.value;
                    this.searchModel.value = timeValue;
                    this.isLoading = true;
                    this.setTimer(timeValue, autoRefresh.metrics);
                }
            }
            else {
                this.searchModel.value = 60;
                this.getAllVehicleMessage(0);
            }
        }
    };
    // Sets timer to minutes or seconds based on selection
    LiveComponent.prototype.setTimer = function (time, metrics) {
        this.openRefreshPopup = false;
        if (this.tracking) {
            this.resetTracking();
        }
        if (this.isDrawerOpen) {
            this.explicitCloseDrawer();
        }
        if (this.timer) {
            this.workerSubscription.unsubscribe();
        }
        if (this.newPlace.circle.newCenter.lat != null) {
            this.locateDevice('true');
        }
        if (metrics === 'seconds') {
            this.vehicleTypeValue = time * 1000;
            this.searchModel.enabled = true;
            this.searchModel.metrics = 'seconds';
            this.searchModel.value = time;
            this.timerFunction(this.vehicleTypeValue);
            this.updateAutorefresh();
        }
        else if (metrics === 'minutes') {
            this.vehicleTypeValue = time * 60000;
            this.searchModel.enabled = true;
            this.searchModel.metrics = 'minutes';
            this.searchModel.value = time;
            this.timerFunction(this.vehicleTypeValue);
            this.updateAutorefresh();
        }
    };
    // Play and pause autorefresh locally without API call
    LiveComponent.prototype.autorefreshPlayPause = function () {
        if (this.play === false) {
            this.toggleEffect = false;
            this.play = !this.play;
            this.workerSubscription.unsubscribe();
            this.stopWorker();
        }
        else {
            this.page = 0;
            if (this.tracking) {
                this.resetTracking();
            }
            if (this.isDrawerOpen) {
                this.explicitCloseDrawer();
            }
            this.play = !this.play;
            this.timerFunction(this.vehicleTypeValue);
        }
    };
    // Stops timer while updating autorefresh through API call
    LiveComponent.prototype.stopTimer = function () {
        this.openRefreshPopup = false;
        this.timer = false;
        this.play = true;
        this.searchModel.enabled = false;
        this.stopWorker();
        this.updateAutorefresh();
    };
    // Timer function to refresh
    LiveComponent.prototype.timerFunction = function (vehicleTypeValue) {
        var _this = this;
        // invoking worker to start autorefresh timer
        var token = localStorage.getItem('token');
        this.timer = true;
        this.play = false;
        var workerMessageNoSearch = {
            action: 'autorefreshON',
            subAction: 'none', page: this.page, pageLimit: this.pageLimit,
            liveKeyString: this.livekeyString,
            token: token, time: vehicleTypeValue
        };
        var workerMessageSearch = {
            action: 'autorefreshON',
            subAction: 'search',
            page: 0,
            pageLimit: this.pageLimit,
            liveKeyString: this.livekeyString,
            token: token,
            time: vehicleTypeValue,
            mType: this.selectedFilter,
            key: this.searchFilter['key'],
            value: this.searchFilter['value'],
            placeKey: this.searchFilter['placeKey'],
            placeValue: this.searchFilter['placeValue'],
            searchVal: this.searchValue,
            searchKey: this.searchKey,
            groupId: this.selectedGroup.id,
        };
        if (this.locateDeviceFilter)
            workerMessageSearch.newPlace = this.newPlace;
        if (this.searchValue !== '' ||
            this.selectedFilter !== '' ||
            this.speedfilter !== '' ||
            this.place ||
            this.isGroupFilterOn ||
            this.locateDeviceFilter
        // this.newPlace.circle.newCenter.lat != null
        ) {
            this.workerService.postMessage(workerMessageSearch);
        }
        else {
            this.workerService.postMessage(workerMessageNoSearch);
        }
        this.loadingIndicator = true;
        this.workerSubscription = this.workerService.workerSubject.subscribe(function (res) {
            if (res.error)
                _this.sharedService.getErrorMsg(res);
            _this.isLoading = true;
            _this.formatData(res);
        });
        this.isLoading = true;
    };
    LiveComponent.prototype.updateAutorefresh = function () {
        this.isLoading = false;
        var payload = { live: { autoRefresh: this.searchModel } };
        this.sharedService.updatePreference(payload).subscribe(function (res) {
        });
        var livePreference = JSON.parse(localStorage.getItem('preferences'));
        livePreference.live.autoRefresh = this.searchModel;
        localStorage.setItem('preferences', JSON.stringify(livePreference));
    };
    // AUTOREFRESH LOGIC ENDS HERE
    // ============================================================================
    // To open vehicle infowindow when the name in the cluster is clicked.
    LiveComponent.prototype.centerVehicle = function (event) {
        var val = event.toLowerCase();
        var temp = this.temp.filter(function (d) {
            return d.assetName.toLowerCase().indexOf(val) !== -1 || !val;
        });
        this.infoWindow.close();
        this.getVehicleInfoWindowView(temp[0]);
    };
    // to get all Reports
    LiveComponent.prototype.getallReports = function () {
        var _this = this;
        this.detailBox = false;
        if (localStorage.getItem('reports')) {
            this.reportsMenu = [];
            var reports = JSON.parse(localStorage.getItem('reports'));
            reports.map(function (obj) {
                if (!obj.hidden) {
                    _this.reportsMenu.push(obj);
                }
            });
            this.reports = this.reportsMenu;
            this.reportsData = this.reports;
        }
        else {
            this.customLoader = true;
            this.sharedService.getReports().subscribe(function (res) {
                res.map(function (obj) {
                    if (!obj.hidden) {
                        _this.reportsMenu.push(obj);
                    }
                });
                _this.staticReports = _this.staticReports.filter(function (item) {
                    if (item.reportType === 'system' && item.url.includes('trigger-report') && !_this.AL_SystemReport)
                        return false;
                    if (item.reportType === 'device' && item.url.includes('trip-summary') && !_this.tripAuthorities.includes('READ'))
                        return false;
                    if (item.reportType === 'billing' && !_this.permissionModules.includes('BILLING'))
                        return false;
                    return true;
                });
                _this.reportsMenu = tslib_1.__spread(_this.reportsMenu, _this.staticReports);
                localStorage.setItem('reports', JSON.stringify(_this.reportsMenu));
                _this.customLoader = false;
                _this.reports = _this.reportsMenu;
                _this.reportsData = _this.reports;
            }, function (error) {
                _this.customLoader = false;
                _this.sharedService.getErrorMsg(error);
            });
        }
    };
    LiveComponent.prototype.filterReport = function (event) {
        var inputVal = event.target.value.toLowerCase();
        var val = inputVal.trim();
        var temp = this.reportsMenu.filter(function (d) {
            return d.name.toLowerCase().indexOf(val) !== -1 || !val;
        });
        this.reportsData = temp;
        this.reports = temp;
    };
    LiveComponent.prototype.getRowClass = function (row) {
        row['detailRow'] = true;
        return 'row-color';
    };
    // Drawer functions start
    LiveComponent.prototype.toggleDrawer = function (vehicle) {
        if (this.selectedVehicle && this.selectedVehicle.deviceId) {
            if (this.selectedVehicle.deviceId === vehicle.deviceId) {
                this.isDrawerOpen = false;
                this.selectedVehicle = {};
                if (this.timer)
                    this.autorefreshPlayPause();
            }
            else {
                this.openDrawer(vehicle);
            }
        }
        else {
            this.openDrawer(vehicle);
        }
    };
    LiveComponent.prototype.openDrawer = function (vehicle) {
        if (this.tracking) {
            this.resetTracking();
        }
        var endTime = Date.parse(vehicle.eventTime);
        var startTime = endTime - (1000 * 60 * 60 * 4);
        this.drawerLoading = true;
        this.selectedVehicle = vehicle;
        this.vehicleDetail = {};
        this.snappedVehicle = {};
        if (this.isHealthTabActive)
            this.getDetailsForDeviceEvents();
        this.getSingleVehicleView(vehicle.deviceId, startTime, endTime);
        this.getDtcCodeCount(vehicle);
        this.getSnappedVehicle(vehicle);
        // this.getVehicleDetail();
        this.isDrawerOpen = true;
        this.getVehicleHealthAndFuelScores(vehicle);
    };
    LiveComponent.prototype.explicitCloseDrawer = function () {
        this.isDrawerOpen = false;
        this.selectedVehicle = {};
        this.snappedVehicle = {};
        this.vehicleDetail = {};
        if (this.timer)
            this.autorefreshPlayPause();
        this.destroyHelathFuelGraphs();
    };
    LiveComponent.prototype.getDtcCodeCount = function (vehicle) {
        var _this = this;
        this.dashboardService.getVehicleStatus(vehicle.deviceId, '20').subscribe(function (res) {
            if (res.count) {
                _this.dtcCodeCount = res.count;
            }
        }, function (error) {
            _this.drawerLoading = false;
            _this.sharedService.getErrorMsg(error);
        });
    };
    LiveComponent.prototype.getSnappedVehicle = function (vehicle) {
        var _this = this;
        var attributes = 'assetName,assetId,deviceId,gsmStrength,architectureType,' +
            'firmwareVersion,configVersion,tripNumber,odometer,vin,groupName,ignitionStatus,' +
            'vehicleBatteryPotential,internalBattery,fuelLevel,socGauge,distanceToEmpty,motorSpeedMcu,' +
            'motorTorqueMcu,engineOilPressure,ambientAirTemperature,engineOilTemperature,engineSpeed,' +
            'minimumVoltage,restingVoltage,avgInTripVoltage,timeToStart';
        this.liveService.getSnappedVehicle(vehicle.deviceId, attributes).subscribe(function (res) {
            _this.snappedVehicle = res;
            _this.totalTrips = _this.snappedVehicle.tripNumber;
            if (_this.snappedVehicle.gsmStrength) {
                if (_this.snappedVehicle.gsmStrength >= -70) {
                    _this.signalTooltip = "Excellent Signal (" + _this.snappedVehicle.gsmStrength + " dBm)";
                }
                else if (_this.snappedVehicle.gsmStrength >= -85 && _this.snappedVehicle.gsmStrength < -70) {
                    _this.signalTooltip = "Good Signal (" + _this.snappedVehicle.gsmStrength + " dBm)";
                }
                else if (_this.snappedVehicle.gsmStrength <= -86 && _this.snappedVehicle.gsmStrength >= -100) {
                    _this.signalTooltip = "Fair Signal (" + _this.snappedVehicle.gsmStrength + " dBm)";
                }
                else if (_this.snappedVehicle.gsmStrength < -100 && _this.snappedVehicle.gsmStrength > -110) {
                    _this.signalTooltip = "Poor Signal (" + _this.snappedVehicle.gsmStrength + " dBm)";
                }
                else if (_this.snappedVehicle.gsmStrength <= -110) {
                    _this.signalTooltip = "No Signal (" + _this.snappedVehicle.gsmStrength + " dBm)";
                }
            }
            else {
                _this.signalTooltip = '--';
            }
            var endTime = Date.parse(vehicle.eventTime);
            var startTime = endTime - (1000 * 60 * 60 * 4);
            var liveHistorykeyString = 'vehicleBatteryPotential,internalBattery,fuelLevel,eventTime';
            // this.snappedVehicle.architectureType = 'PDBS6 Non EEA'
            if (_this.snappedVehicle.architectureType === 'EV') {
                _this.getEVMessage(vehicle.deviceId, startTime, endTime);
            }
            else if (_this.snappedVehicle.architectureType === 'BS6_EDC') {
                var messageType = 'EDC_BS6_PERIODIC';
                _this.getMessages(vehicle.deviceId, startTime, endTime, liveHistorykeyString, messageType);
            }
            else if (_this.snappedVehicle.architectureType === 'BS6_EEA') {
                var messageType = 'EEA_BS6_PERIODIC';
                _this.getMessages(vehicle.deviceId, startTime, endTime, liveHistorykeyString, messageType);
            }
            else if (_this.snappedVehicle.architectureType === 'BS4_EDC') {
                var messageType = 'EDC_BS4_PERIODIC';
                _this.getMessages(vehicle.deviceId, startTime, endTime, liveHistorykeyString, messageType);
            }
            else if (_this.snappedVehicle.architectureType === 'BS4_EEA') {
                var messageType = 'EEA_BS4_PERIODIC';
                _this.getMessages(vehicle.deviceId, startTime, endTime, liveHistorykeyString, messageType);
            }
            if (_this.snappedVehicle.architectureType === 'BS6_EDC' ||
                _this.snappedVehicle.architectureType === 'BS6_EEA' ||
                _this.snappedVehicle.architectureType === 'BS4_EDC' ||
                _this.snappedVehicle.architectureType === 'BS4_EEA') {
                _this.getGPSMessage(vehicle.deviceId);
                _this.getBatteryEvent(vehicle.deviceId);
            }
            _this.drawerLoading = false;
        }, function (error) {
            _this.drawerLoading = false;
            _this.sharedService.getErrorMsg(error);
        });
    };
    LiveComponent.prototype.toggleDetailPopup = function () {
        this.detailBox = !this.detailBox;
        this.getVehicleDetail();
        // if (!this.vehicleDetail.deviceId) {
        // }
    };
    LiveComponent.prototype.getVehicleDetail = function () {
        var _this = this;
        this.liveService.getVehicleDetail(this.selectedVehicle.deviceId).subscribe(function (res) {
            _this.vehicleDetail = res[0];
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    LiveComponent.prototype.closeDetails = function () {
        this.detailBox = false;
    };
    // START OF VEHICLE TRACKING
    // To keep track of the selected Vehicle
    LiveComponent.prototype.trackVehicle = function (device) {
        var _this = this;
        this.showLiveDashboard = !this.showLiveDashboard;
        var global = this;
        global.tracking = !global.tracking;
        this.selectedVehicle = device;
        var currentVehicle = Object.assign({}, this.selectedVehicle);
        global.trackedVehicle = currentVehicle;
        if (this.timer) {
            if (!this.play) {
                global.autorefreshPlayPause();
            }
        }
        if (global.tracking) {
            // collapse table
            this.openNav();
            if (this.newmarkerCluster) {
                this.newmarkerCluster.clearMarkers();
            }
            var subtractMilliseconds = global.subtractTime * 60000;
            var date = new Date(global.trackedVehicle.eventTime);
            var milliseconds = Date.parse(date.toUTCString());
            var startTime = milliseconds - subtractMilliseconds;
            var endTime = milliseconds;
            global.liveService.getPolylineData(global.trackedVehicle.deviceId, startTime, endTime).subscribe(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                return tslib_1.__generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            global.vehicleLocations = tslib_1.__spread(res.locations);
                            global.vehicleLocations = global.vehicleLocations.slice().reverse();
                            return [4 /*yield*/, this.clearMarkers()];
                        case 1:
                            _a.sent();
                            return [4 /*yield*/, global.plotVehicle(res.locations)];
                        case 2:
                            _a.sent();
                            return [4 /*yield*/, this.keepTracking()];
                        case 3:
                            _a.sent();
                            this.trackingId = setInterval(function () {
                                global.keepTracking();
                            }, global.trackingTime);
                            return [2 /*return*/];
                    }
                });
            }); });
        }
        else {
            this.resetTracking();
        }
    };
    LiveComponent.prototype.keepTracking = function () {
        var _this = this;
        var global = this;
        global.liveService.getVehicleLocation(global.trackedVehicle.deviceId).subscribe(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(res.length != 0)) return [3 /*break*/, 5];
                        global.vehicleLocations = tslib_1.__spread(global.vehicleLocations, res);
                        global.latestCoOrds[0] = global.vehicleLocations[global.vehicleLocations.length - 2];
                        global.latestCoOrds[1] = global.vehicleLocations[global.vehicleLocations.length - 1];
                        global.trackedVehicle.latitude = res.latitude;
                        global.trackedVehicle.longitude = res.longitude;
                        global.vechileData = res;
                        // this.liveDashboardPids(res);
                        return [4 /*yield*/, global.plotVehicle(global.latestCoOrds)];
                    case 1:
                        // this.liveDashboardPids(res);
                        _a.sent();
                        return [4 /*yield*/, global.clearMarkers()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, global.addMarkerIcon(res)];
                    case 3:
                        _a.sent();
                        return [4 /*yield*/, global.addMarker(res)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5: return [2 /*return*/];
                }
            });
        }); });
        global.liveService.getVehicleEvents(global.trackedVehicle.deviceId).subscribe(function (res) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.liveDashboardPids(res);
                return [2 /*return*/];
            });
        }); });
    };
    LiveComponent.prototype.resetTracking = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, clearInterval(this.trackingId)];
                    case 1:
                        _a.sent();
                        this.tracking = false;
                        this.getVehicleInfoWindowView(this.trackedVehicle);
                        return [4 /*yield*/, this.removePolyline()];
                    case 2:
                        _a.sent();
                        return [4 /*yield*/, this.clearMarkers()];
                    case 3:
                        _a.sent();
                        this.trackedVehicle = {};
                        this.vehicleLocations = [];
                        this.latestCoOrds = [];
                        this.addCluster(this.liveVehicles);
                        this.showLiveDashboard = false;
                        if (!this.openedNav)
                            this.openNav();
                        if (this.timer) {
                            this.autorefreshPlayPause();
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    LiveComponent.prototype.plotVehicle = function (data) {
        var flightPlanCoordinates = [];
        var bounds = new google.maps.LatLngBounds();
        data.forEach(function (item) {
            var latLngObj = {};
            if (item !== undefined && item.latitude !== undefined && item.longitude !== undefined) {
                latLngObj['lat'] = item.latitude;
                latLngObj['lng'] = item.longitude;
                flightPlanCoordinates.push(latLngObj);
                // to extends the bounds
                bounds.extend(new google.maps.LatLng(item.latitude, item.longitude));
            }
        });
        this.flightPath = new google.maps.Polyline({
            path: flightPlanCoordinates,
            geodesic: true,
            strokeColor: '#891d56',
            strokeOpacity: 0.8,
            strokeWeight: 3
        });
        this.flightPath.setMap(this.map);
        this.flightPlan.push(this.flightPath);
    };
    LiveComponent.prototype.removePolyline = function () {
        this.flightPlan.map(function (line) {
            line.setMap(null);
        });
    };
    // END OF VEHICLE TRACKING
    // SIDE DRAWER EVENTS
    LiveComponent.prototype.getDetailsForDeviceEvents = function () {
        var _this = this;
        if (this.selectedVehicle.deviceId !== null) {
            var attributes = 'assetName,assetId,deviceId,vin,tripNumber,odometer,vehicleProtocolId,' +
                'firmwareVersion,configVersion,eventTime,latitude,longitude,minimumVoltage,' +
                'restingVoltage,avgInTripVoltage,timeToStart,messageType';
            this.dashboardService.getVehicleDetail(this.selectedVehicle.deviceId, attributes).subscribe(function (res) {
                _this.vehicleDetail = res;
                if (res.eventTime) {
                    var endTime = Date.parse(res.eventTime);
                    var startTime = endTime - 60 * 60 * 24 * 1000;
                    _this.getDeviceEvents();
                    _this.getVehicleCodeCount();
                    if (_this.permissionModules.includes('SCORE')) {
                        _this.getAllScores();
                        _this.getDrivingBehaviour(startTime, endTime);
                    }
                }
                else {
                    _this.deviceEvents = [];
                    _this.vehicleCount = { count: 0 };
                    _this.drivingBehaviour = {};
                    // this.destroyCharts();
                    if (_this.progressBars.length) {
                        _this.destroyAllProgressBar();
                    }
                    _this.createProgressBar();
                }
            }, function (error) {
                _this.sharedService.getErrorMsg(error);
            });
        }
    };
    LiveComponent.prototype.getDeviceEvents = function () {
        var _this = this;
        this.dashboardService.getDeviceEvents(this.selectedVehicle.deviceId, '20').subscribe(function (res) {
            _this.deviceEvents = res;
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    LiveComponent.prototype.getVehicleCodeCount = function () {
        var _this = this;
        this.dashboardService.getVehicleCount(this.selectedVehicle.deviceId, '20').subscribe(function (res) {
            _this.vehicleCount = res;
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    LiveComponent.prototype.getDrivingBehaviour = function (start, end) {
        var _this = this;
        var endDate = new Date();
        var endTime = endDate.getTime();
        var startTime = endTime - (60 * 60 * 24 * 30 * 1000);
        this.dashboardService.getDrivingBehaviour(this.selectedVehicle.deviceId, endTime, startTime)
            .subscribe(function (res) {
            _this.drivingBehaviour = res;
        }, function (error) {
            _this.sharedService.getErrorMsg(error);
        });
    };
    LiveComponent.prototype.getAllScores = function () {
        var _this = this;
        if (this.selectedVehicle.deviceId === null)
            return;
        // const endTime = Date.parse(new Date().toUTCString());
        // const startTime = endTime - (24 * 60 * 60 * 1000);
        var exposureProgressContainer = document.getElementById('exposureProgress');
        var behaviourProgressContainer = document.getElementById('behaviourProgress');
        var riskProgressContainer = document.getElementById('riskProgress');
        exposureProgressContainer.innerHTML = '';
        behaviourProgressContainer.innerHTML = '';
        riskProgressContainer.innerHTML = '';
        this.scoreService.getVehicleScores(this.selectedVehicle.deviceId).subscribe({
            next: function (res) {
                if (res.length) {
                    _this.dailyScore = res[0];
                    _this.exposureProgressBar = _this.scoreProgressBar(_this.dailyScore.exposure, exposureProgressContainer, 'Exposure', false);
                    _this.behaviourProgressBar = _this.scoreProgressBar(_this.dailyScore.behaviour, behaviourProgressContainer, 'Behaviour', false);
                    _this.riskProgressBar = _this.scoreProgressBar(_this.dailyScore.risk, riskProgressContainer, 'Risk', false);
                }
                else {
                    _this.createProgressBar();
                }
            },
            error: function (error) {
                _this.createProgressBar();
                // this.sharedService.getErrorMsg(error);
            }
        });
    };
    LiveComponent.prototype.createProgressBar = function () {
        if (this.progressBars.length) {
            this.destroyAllProgressBar();
        }
        var exposureProgressContainer = document.getElementById('exposureProgress');
        var behaviourProgressContainer = document.getElementById('behaviourProgress');
        var riskProgressContainer = document.getElementById('riskProgress');
        this.exposureProgressBar = this.scoreProgressBar(0, exposureProgressContainer, 'Exposure', false);
        this.behaviourProgressBar = this.scoreProgressBar(0, behaviourProgressContainer, 'Behaviour', false);
        this.riskProgressBar = this.scoreProgressBar(0, riskProgressContainer, 'Risk', false);
    };
    LiveComponent.prototype.scoreProgressBar = function (value, container, text, isHealthFuelGraph) {
        var bar = new ProgressBar.Circle(container, {
            // This has to be the same size as the maximum width to
            // prevent clipping
            strokeWidth: 7,
            trailWidth: 7,
            easing: 'easeOut',
            duration: 3000,
            text: {
                autoStyleContainer: false
            },
            from: { color: '#F4D03F', width: 1 },
            to: { color: '#229954', width: 4 },
            // Set default step function for all animate calls
            step: function (state, circle) {
                circle.path.setAttribute('stroke', state.color);
                var value = Math.round(circle.value() * 100);
                // circle.setText(value + '\n'+text);
                circle.setText(value);
                if (value > 85) {
                    circle.path.setAttribute('stroke', '#229954');
                }
                else if (value > 60 && value <= 85) {
                    circle.path.setAttribute('stroke', '#FFA500');
                }
                if (value > 30 && value <= 60) {
                    circle.path.setAttribute('stroke', '#FF0000');
                }
                if (value <= 30) {
                    circle.path.setAttribute('stroke', '#8B0000');
                }
                circle.path.setAttribute('stroke-linecap', 'round');
            }
        });
        if (!isHealthFuelGraph) {
            this.progressBars.push(bar);
        }
        else {
            this.healthFuelProgressBars.push(bar);
        }
        // bar.text.style.fontFamily = '"Raleway", Helvetica, sans-serif';
        bar.text.style.fontSize = '1rem';
        bar.text.style.textAlign = 'center';
        var progressValue = value / 100;
        bar.animate(progressValue);
    };
    LiveComponent.prototype.destroyAllProgressBar = function () {
        this.progressBars.forEach(function (bar) {
            if (bar) {
                bar.destroy();
            }
        });
        this.progressBars = [];
    };
    // GOOGLE MAPS START
    LiveComponent.prototype.mapInit = function () {
        var mapOptionObject = {
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoom: this.zoom,
            minZoom: this.zoom,
            maxZoom: 17,
            // styles: this.mapStyles.darkMode,
            mapTypeControl: true,
            mapTypeControlOptions: {
                position: google.maps.ControlPosition.TOP_RIGHT
            },
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_CENTER
            },
            scaleControl: true,
            streetViewControl: true,
            streetViewControlOptions: {
                position: google.maps.ControlPosition.RIGHT_CENTER
            },
            fullscreenControl: false,
            center: {
                lat: environment.lat,
                lng: environment.lng
            },
            clickableIcons: false
        };
        this.map = new google.maps.Map(this.gmapElement.nativeElement, mapOptionObject);
    };
    LiveComponent.prototype.addMarker = function (data, tooltip) {
        if (this.tracking) {
            data.assetName = '';
            var image = {
                url: data.iconUrl.url,
                size: new google.maps.Size(71, 71),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(14, 13),
            };
        }
        else {
            image = data.iconUrl.url;
        }
        var latlng = new google.maps.LatLng(data.latitude, data.longitude);
        var marker = new google.maps.Marker({
            position: latlng,
            map: this.map,
            label: data.assetName,
            icon: image
        });
        // add infowindow
        if (tooltip) {
            this.setMarkerInfoWindow(data, marker);
        }
        marker['eventTime'] = data.eventTime;
        marker['deviceId'] = data.deviceId;
        var route = this.router;
        this.markerClick(marker);
        var bounds = new google.maps.LatLngBounds();
        bounds.extend(latlng);
        this.map.fitBounds(bounds);
        this.markers.push(marker);
    };
    LiveComponent.prototype.setMarkerInfoWindow = function (d, marker) {
        var global = this;
        var prop = { color: '', badge: '' };
        if (d.messageType) {
            if (d.messageType === 'COMMUNICATION_LOST') {
                // prop.text = 'car';
                prop.color = '#f6b01d'; // Warning
                prop.badge = 'warning';
            }
            else if (d.messageType === 'TRIP_END' || d.messageType === 'IGNITION_OFF') {
                // prop.text = 'car';
                d.activity = 'Stopped';
                prop.color = '#dc3545'; // Red
                prop.badge = 'danger';
            }
            else if (!d.endPacket && d.messageType !== 'TRIP_END' && d.messageType !== 'IGNITION_OFF') {
                // prop.text = 'car';
                prop.color = '#469a25'; // Green
                prop.badge = 'success';
            }
            else {
                // prop.text = 'car';
                prop.color = '#555555'; // Dark Gray
                prop.badge = 'secondary';
            }
        }
        var datePipe = new DatePipe('en-US');
        var lastComm = datePipe.transform(d.eventTime, this.dateFormat);
        /*<a onclick="document.getElementById('shareTrackBtn').click();" style="margin-left: 5px;">
            <i class="fa fa-share-alt action-icons text-secondary" style="font-size: 14px;"></i>
          </a>*/
        var tooltipContent = "<div class=\"width100 pl-2 m-0 live-marker-infowindow\">\n      <p class=\"m-0\"><span class=\"iw-heading\" style=\"color: " + prop.color + "\">" + (d.assetName || '----') + "</span></p>\n      <p class=\"m-0\"><label class=\"m-0\">" + (d.vin || '----') + "</label></p>\n      <p class=\"m-0\"><span class=\"badge badge-" + prop.badge + " text-light p-1\">" + d.activity + "</span>\n      <p class=\"m-0\"><span>" + (lastComm || '----') + "</span></p>\n      <p class=\"m-0\">\n        <label>Go to <a href=\"javascript:void(0)\" onclick=\"document.getElementById('historyLink').click();\">History</a></label>\n\n        " + (!d.generation.includes('V2X') ? "\n        <a onclick=\"document.getElementById('trackVehicleBtn').click();\">\n          <i class=\"fa fa-eye action-icons text-secondary\" style=\"font-size: 14px;\"></i>\n        </a>" : '') + "\n        " + (this.permissionModules.includes('DEVICE') ?
            "<a onclick=\"document.getElementById('deviceLink').click();\">\n            <i class=\"fa fa-microchip action-icons text-secondary\" style=\"font-size: 14px;\"></i>\n          </a>"
            : null) + "\n        " + (this.permissionModules.includes('REPORT') ?
            "<a onclick=\"document.getElementById('reportsModalBtn').click();\">\n            <i class=\"fa fa-bar-chart action-icons text-secondary\" style=\"font-size: 14px;\"></i>\n          </a>"
            : null) + "\n      </p>\n    </div>";
        //   `<div class="row no-gutters align-items-center live-marker-infowindow">
        //   <div class="col-3 text-center">
        //     <i class="fa fa-${icon.text}" style="color: ${icon.color};"></i>
        //   </div>
        //   <div class="col-9 pl-2">
        //     <p class="m-0"><span class="iw-heading" style="color: ${icon.color}">${d.assetName || '----'}</span></p>
        //     <p class="m-0"><label>${d.vin || '----'}</label></p>
        //     <p class="m-0"><label>${d.serialNumber || '----'} ${d.productSerialNumber ? `(${d.productSerialNumber})` : ''}</label></p>
        //     <p class="m-0"><label>${lastComm || '----'}</label></p>
        //     <p class="m-0">
        //       <label>Go to <a href="javascript:void(0)" onclick="document.getElementById('historyLink').click();">History</a></label>
        //     </p>
        //   </div>
        // </div>`;
        // <label>or <a href="javascript:void(0)" onclick="document.getElementById('trackLink').click();">Track</a> device</label>
        var infoWindow = new google.maps.InfoWindow({
            content: tooltipContent
        });
        infoWindow['eventTime'] = d.eventTime;
        infoWindow['label'] = d.assetName;
        infoWindow['deviceId'] = d.deviceId;
        global.markerInfoWindow = infoWindow;
        infoWindow.open(global.map, marker);
    };
    LiveComponent.prototype.addCluster = function (liveVehicles) {
        var _this = this;
        var markerCluster = [];
        var latlng;
        liveVehicles.forEach(function (item) {
            var latLngObj = {};
            if (item.latitude && item.longitude) {
                var marker = new google.maps.Marker({
                    position: new google.maps.LatLng(item.latitude, item.longitude),
                    label: item.assetName,
                    title: item.assetName,
                    icon: item.iconUrl.url,
                });
                marker['eventTime'] = item.eventTime;
                marker['deviceId'] = item.deviceId;
                _this.markerClick(marker);
                markerCluster.push(marker);
            }
            latlng = new google.maps.LatLng(item.latitude, item.longitude);
        });
        if (markerCluster.length <= 1) {
            var bounds = new google.maps.LatLngBounds();
            bounds.extend(latlng);
            this.map.fitBounds(bounds);
        }
        // Addes marker clusterer to manage the markers.
        this.clusterHover(markerCluster);
    };
    LiveComponent.prototype.clusterHover = function (markers) {
        var options_markerclusterer = {
            gridSize: 50,
            maxZoom: 16,
            zoomOnClick: true,
            imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m'
        };
        var obj = this;
        var markerCluster = new this.MarkerClustererPlus(this.map, markers, options_markerclusterer);
        this.newmarkerCluster = markerCluster;
        obj.infoWindow = new google.maps.InfoWindow();
        google.maps.event.addListener(markerCluster, 'mouseover', function (cluster) {
            obj.infoWindowText = [];
            var markers = cluster.getMarkers();
            for (var i = 0; i < markers.length; i++) {
                obj.infoWindowText.push(markers[i].getTitle());
            }
            obj.infoWindowTextCopy = obj.infoWindowText;
            if (obj.clusterLink) {
                if (this.map.getZoom() <= markerCluster.getMaxZoom()) {
                    if (obj.markerInfoWindow) {
                        obj.markerInfoWindow.close();
                    }
                    obj.infoWindow.close();
                    obj.infoWindow.setContent(obj.clusterLink.nativeElement);
                    obj.infoWindow.setPosition(cluster.getCenter());
                    obj.infoWindow.open(this.map);
                }
            }
        });
        google.maps.event.addListener(this.map, 'click', function () {
            obj.infoWindow.close();
        });
        google.maps.event.addListener(markerCluster, 'click', function () {
            obj.infoWindow.close();
        });
    };
    LiveComponent.prototype.markerClick = function (marker) {
        var global = this;
        google.maps.event.addListener(marker, 'click', function () {
            global.goToLocationHistoryUsingEvent(marker);
        });
    };
    LiveComponent.prototype.goToLocationHistoryUsingEvent = function (marker) {
        var route = this.router;
        marker['assetName'] = marker['label'];
        var liveSelectedVehicle = {
            eventTime: marker.eventTime,
            assetName: marker.assetName,
            deviceId: marker.deviceId,
        };
        var device = this.liveVehicles.filter(function (e) { return e.deviceId === marker.deviceId; });
        this.sharedService.goToHistory({}, device);
    };
    LiveComponent.prototype.setMapOnAll = function (map) {
        for (var i = 0; i < this.markers.length; i++) {
            this.markers[i].setMap(map);
        }
    };
    LiveComponent.prototype.clearMarkers = function () {
        this.setMapOnAll(null);
    };
    // END OF GOOGLE MAPS
    // To get all place
    LiveComponent.prototype.getAllPlace = function (page) {
        var _this = this;
        if (!this.places.length) {
            this.placesListLoading = true;
            this.placeService.getAllPlace(this.page).subscribe(function (res) {
                _this.places = res;
                _this.temp = res;
                _this.placesListLoading = false;
            }, function (error) {
                _this.sharedService.getErrorMsg(error);
            });
        }
    };
    LiveComponent.prototype.addPlace = function () {
        this.router.navigate(['/admin/place'], {
            queryParams: {
                id: 1
            }
        });
    };
    LiveComponent.prototype.filterGroups = function (event) {
        var inputVal = event.target.value.toLowerCase();
        var val = inputVal.trim();
        var temp = this.groupsMenu.filter(function (d) {
            return d.name.toLowerCase().indexOf(val) !== -1 || !val;
        });
        this.groups = temp;
    };
    LiveComponent.prototype.openNav = function () {
        if (this.openedNav) {
            this.openedNav = false;
            document.getElementById('mySidebar').style.visibility = 'hidden';
            document.getElementById('tableContainer').style.display = 'none';
            document.getElementById('main').style.marginLeft = '0';
        }
        else {
            document.getElementById('mySidebar').style.visibility = 'visible';
            document.getElementById('tableContainer').style.display = 'block';
            document.getElementById('main').style.marginLeft = '27%';
            this.tableShow();
        }
    };
    LiveComponent.prototype.tableShow = function () {
        var global = this;
        setTimeout(function () {
            global.openedNav = true;
        }, 300);
    };
    // To get all favourites
    LiveComponent.prototype.getAllfavourites = function () {
        var _this = this;
        if (!this.allfavouriteItems.length) {
            this.favouriteListLoading = true;
            this.sharedService.getAllfavourites(1).subscribe({
                next: function (res) {
                    _this.allfavouriteItems = res;
                    _this.favouriteListLoading = false;
                },
                error: function (err) {
                    _this.sharedService.getErrorMsg(err);
                    _this.favouriteListLoading = false;
                }
            });
        }
    };
    // To get all favourites
    LiveComponent.prototype.deletefavourite = function () {
        var _this = this;
        this.sharedService.deletefavourite(this.selectedFavourite.id).subscribe({
            next: function (res) {
                _this.allfavouriteItems = [];
                _this.getAllfavourites();
                _this.getAllVehicleMessage(0);
                _this.hideModal = !_this.hideModal;
                _this.searchKey = 'assetName';
                _this.searchValue = '';
                _this.favouriteFilterKey = '';
                _this.responseMessage = {
                    success: true,
                    message: enMessage.favdeleteSuccess
                };
                _this.sharedService.setResponseMessage(_this.responseMessage);
            },
            error: function (err) {
                _this.hideModal = !_this.hideModal;
                _this.sharedService.getErrorMsg(err);
            }
        });
    };
    LiveComponent.prototype.togglePopover = function () {
        this.popoverElement.nativeElement.click();
    };
    LiveComponent.prototype.addfavourite = function (valid, form) {
        var _this = this;
        var payload = {
            name: this.favouriteText.favouriteValue,
            key: this.searchKey,
            value: this.searchValue,
            typeCode: 1
        };
        if (this.locateDeviceFilter) {
            payload.key = 'assetName';
            payload.value = this.liveVehicles.map(function (device) { return device.assetName; }).join();
        }
        if (this.favouriteText.favouriteValue && ((this.searchKey && this.searchValue) || this.locateDeviceFilter)) {
            this.disable = true;
            this.sharedService.addfavourite(payload).subscribe({
                next: function (res) {
                    // this.getAllfavourites();
                    _this.allfavouriteItems = [];
                    form.resetForm();
                    _this.togglePopover();
                    _this.disable = false;
                    _this.responseMessage = {
                        success: true,
                        message: enMessage.favAddSuccess
                    };
                    _this.sharedService.setResponseMessage(_this.responseMessage);
                },
                error: function (err) {
                    _this.hideModal = !_this.hideModal;
                    _this.disable = false;
                    _this.sharedService.getErrorMsg(err);
                }
            });
        }
    };
    LiveComponent.prototype.selectfavouriteFilter = function (data) {
        this.searchKey = data.key;
        this.searchValue = data.value;
        this.favouriteFilterKey = data.name;
        this.updateFilter('true');
    };
    LiveComponent.prototype.getSelectedFavourite = function (e, obj) {
        e.stopPropagation();
        this.confirmDeleteAction = 'favourite';
        this.selectedFavourite = obj;
        this.showModal = !this.showModal;
    };
    LiveComponent.prototype.getFavDetails = function (e, data) {
        var _this = this;
        if (data.key == 'serialNumber') {
            this.favEditingMode = true;
            e.stopPropagation();
            $('#editFavmodal').modal('show');
            this.selectedFavourite = data;
            this.sharedService.getFavDetails(data.name).subscribe({
                next: function (res) {
                    _this.selectedFav = res;
                    var selectedDevices = [];
                    res.serialNumber.map(function (item) {
                        var obj = {};
                        obj['serialNumber'] = item;
                        selectedDevices.push(obj);
                    });
                    _this.querySelectDevice = selectedDevices;
                    _this.noDevicesSelected = _this.querySelectDevice ? false : true;
                },
                error: function (err) {
                    _this.hideModal = !_this.hideModal;
                    _this.sharedService.getErrorMsg(err);
                }
            });
        }
    };
    LiveComponent.prototype.addDevices = function (e) {
        this.querySelectDevice = null;
        this.deviceList.devices = e;
        // e.map(device => {
        //   if (device.serialNumber) {
        //     this.deviceList.devices.push(device.serialNumber);
        //   } else {
        //     this.deviceList.devices.push(device)
        //   }
        // });
        if (this.deviceList.devices.length) {
            this.querySelectDevice = this.deviceList.devices;
        }
        this.noDevicesSelected = this.querySelectDevice ? false : true;
    };
    // To update fav
    LiveComponent.prototype.save = function (value) {
        var _this = this;
        if (this.querySelectDevice) {
            this.noDevicesSelected = false;
            this.disable = true;
            var payload = {};
            var serialNumbers_1 = [];
            if (this.querySelectDevice.length) {
                this.querySelectDevice.map(function (device) {
                    serialNumbers_1.push(device.serialNumber);
                });
            }
            payload['key'] = this.selectedFav.key;
            payload['value'] = serialNumbers_1.join();
            this.sharedService.updateFav(this.selectedFavourite.name, payload).subscribe(function (res) {
                _this.closeuploadDevice_model.nativeElement.click();
                _this.responseMessage = {
                    success: true,
                    message: enMessage.favUpdateSuccess
                };
                _this.sharedService.setResponseMessage(_this.responseMessage);
                _this.querySelectDevice = null;
                _this.disable = false;
                _this.allfavouriteItems = [];
                _this.getAllfavourites();
                _this.favEditingMode = false;
            }, function (error) {
                console.log(error);
                _this.querySelectDevice = null;
                _this.disable = false;
                _this.favEditingMode = false;
                _this.closeuploadDevice_model.nativeElement.click();
                _this.sharedService.getErrorMsg(error);
            });
        }
        else {
            this.noDevicesSelected = true;
            this.disable = false;
        }
    };
    LiveComponent.prototype.drawLineGraph = function (labels, data1, data2) {
        if (this.lineChart) {
            this.lineChart.destroy();
        }
        var ctx = document.getElementById('lineChartTotal');
        this.lineChart = new Chart(ctx, {
            type: 'line',
            responsive: true,
            data: {
                labels: labels,
                datasets: [
                    {
                        label: 'In',
                        data: data1,
                        borderColor: '#007bff',
                        fill: false,
                        borderWidth: 2,
                        pointRadius: 0
                    },
                    {
                        label: 'Out',
                        data: data2,
                        borderColor: '#dc3545',
                        fill: false,
                        borderWidth: 2,
                        pointRadius: 0
                    }
                ]
            },
            options: {
                title: {
                    display: true,
                    text: 'Battery kWh'
                },
                legend: {
                    display: true
                },
                scales: {
                    xAxes: [{
                            display: false,
                        }],
                    yAxes: [{
                            display: true
                        }]
                }
            }
        });
    };
    LiveComponent.prototype.vehicleBatteryGraph = function (labels, data1) {
        if (this.vehicleBatteryPotentialGraph) {
            this.vehicleBatteryPotentialGraph.destroy();
        }
        var ctx = document.getElementById('vehicleBatteryPotentialGraph');
        this.vehicleBatteryPotentialGraph = new Chart(ctx, {
            type: 'line',
            responsive: true,
            data: {
                labels: labels,
                datasets: [{
                        label: 'Total',
                        data: data1,
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        fill: true,
                        borderWidth: 2,
                        pointRadius: 0
                    }
                ]
            },
            options: {
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                            display: false,
                            ticks: {
                                beginAtZero: true
                            }
                        }],
                    yAxes: [{
                            display: false,
                        }]
                }
            }
        });
    };
    LiveComponent.prototype.internalBatteryGraph = function (labels, data1) {
        if (this.internalBatteryChart) {
            this.internalBatteryChart.destroy();
        }
        var ctx = document.getElementById('internalBatteryGraph');
        this.internalBatteryChart = new Chart(ctx, {
            type: 'line',
            responsive: true,
            data: {
                labels: labels,
                datasets: [{
                        label: 'Total',
                        data: data1,
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        fill: true,
                        borderWidth: 2,
                        pointRadius: 0
                    }
                ]
            },
            options: {
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                            display: false,
                            ticks: {
                                beginAtZero: true
                            }
                        }],
                    yAxes: [{
                            display: false,
                        }]
                }
            }
        });
    };
    LiveComponent.prototype.fuelLevelGraph = function (labels, data1) {
        if (this.fuelLevelChart) {
            this.fuelLevelChart.destroy();
        }
        var ctx = document.getElementById('fuelLevelGraph');
        this.fuelLevelChart = new Chart(ctx, {
            type: 'line',
            responsive: true,
            data: {
                labels: labels,
                datasets: [{
                        label: 'Total',
                        data: data1,
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        borderColor: 'rgba(54, 162, 235, 1)',
                        fill: true,
                        borderWidth: 2,
                        pointRadius: 0
                    }
                ]
            },
            options: {
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                            display: false,
                            ticks: {
                                beginAtZero: true
                            }
                        }],
                    yAxes: [{
                            display: false,
                        }]
                }
            }
        });
    };
    LiveComponent.prototype.GPSGraph = function (labels, data1, data2) {
        if (this.GPSChart) {
            this.GPSChart.destroy();
        }
        var ctx = document.getElementById('GPSChart');
        this.GPSChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: labels,
                datasets: [{
                        label: 'Valid Fix Count',
                        data: data1,
                        backgroundColor: '#007bff',
                        borderColor: '#007bff',
                        borderWidth: 1,
                    }, {
                        label: 'Invalid Fix Count',
                        data: data2,
                        backgroundColor: '#dc3545',
                        borderColor: '#dc3545',
                        borderWidth: 1,
                    }]
            },
            options: {
                legend: {
                    display: false
                },
                scales: {
                    xAxes: [{
                            stacked: true,
                            display: false
                        }],
                    yAxes: [{
                            stacked: true,
                            display: false,
                            suggestedMin: 0,
                            suggestedMax: 10
                        }]
                }
            }
        });
    };
    LiveComponent.prototype.validateFav = function (event) {
        this.favouriteText.favouriteValue = event.target.value.replace(/[^_\-@.a-zA-Z0-9\s]/g, '');
    };
    // locateDevice
    LiveComponent.prototype.searchMap = function () {
        var input = document.getElementById('pac-input');
        var searchBox = new google.maps.places.SearchBox(this.pacinput.nativeElement);
        var global = this;
        // Bias the SearchBox results towards current map's viewport.
        this.map.addListener('bounds_changed', function () {
            searchBox.setBounds(global.map.getBounds());
        });
        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        searchBox.addListener('places_changed', function () {
            var places = searchBox.getPlaces();
            if (places.length == 0) {
                return;
            }
            // For each place, get the icon, name and location.
            var bounds = new google.maps.LatLngBounds();
            places.forEach(function (place) {
                if (!place.geometry) {
                    console.log('Returned place contains no geometry');
                    return;
                }
                // global.locatePlace = place;
                global.locatePlace['name'] = place.name;
                // global.locatePlace['circle']['newCenter']['lat'] = place.geometry.location.lat();
                // global.locatePlace['circle']['newCenter']['lng'] = place.geometry.location.lng();
                global.newPlace.circle.newCenter.lat = place.geometry.location.lat();
                global.newPlace.circle.newCenter.lng = place.geometry.location.lng();
                // global.locateDeviceFilter = false;
            });
        });
    };
    LiveComponent.prototype.locateDevice = function (isValid, form) {
        var _this = this;
        if (isValid) {
            // this.newPlace = this.locatePlace
            // this.locatePlace.circle.newCenter.lat = this.newPlace.circle.newCenter.lat;
            // this.locatePlace.circle.newCenter.lng = this.newPlace.circle.newCenter.lng;
            this.locatePlace['newName'] = this.locatePlace.name;
            this.locateDeviceFilter = false;
            this.addRadius(Number(this.newPlace.circle.radius), this.newPlace.color, this.newPlace.circle.newCenter);
            if (this.timer === false || this.play === true) {
                this.liveService.searchVehicle(this.selectedFilter, this.searchValue, this.searchKey, this.selectedGroup.id, this.searchFilter, this.newPlace).subscribe(function (res) {
                    _this.filter = true;
                    _this.liveVehicles = res;
                    _this.loadMore = false;
                    _this.mapFilter(res);
                    _this.vehicleTotalCount.count = res.length;
                    _this.isLoading = true;
                    _this.page = 0;
                    _this.locateDeviceFilter = true;
                    if (!_this.autoRefreshClick) {
                        _this.locatePopover.nativeElement.click();
                        _this.autoRefreshClick = true;
                    }
                }, function (error) {
                    _this.isLoading = true;
                    _this.sharedService.getErrorMsg(error);
                });
            }
            else {
                this.filter = true;
                this.loadMore = false;
                this.isLoading = true;
                this.page = 0;
                this.locateDeviceFilter = true;
                if (!this.autoRefreshClick) {
                    this.locatePopover.nativeElement.click();
                    this.autoRefreshClick = true;
                }
                this.workerSubscription.unsubscribe();
                this.timerFunction(this.vehicleTypeValue);
            }
        }
    };
    LiveComponent.prototype.addRadius = function (radius, color, location) {
        if (this.locateCityCircle) {
            this.locateCityCircle.setMap(null);
        }
        this.locateCityCircle = new google.maps.Circle({
            strokeColor: color,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: color,
            fillOpacity: 0.35,
            map: this.map,
            center: location,
            radius: radius,
            editable: true
        });
        var global = this;
        this.locateCityCircle.addListener('radius_changed', function (e) {
            global.newPlace.circle.radius = global.locateCityCircle.getRadius();
            global.radiusSetBounds(location);
        });
        this.radiusSetBounds(location);
    };
    LiveComponent.prototype.radiusSetBounds = function (location) {
        var latlng = new google.maps.LatLng(location.lat, location.lng);
        var bounds = new google.maps.LatLngBounds();
        bounds.extend(latlng);
        this.map.fitBounds(this.locateCityCircle.getBounds());
    };
    LiveComponent.prototype.clearLocateFilter = function (e) {
        this.isLoading = true;
        this.newPlace = {
            circle: {
                newCenter: {
                    lat: null,
                    lng: null
                },
                radius: 100
            },
            color: '#e01111'
        };
        this.locateDeviceFilter = false;
        this.locatePlace = {};
        this.map.setZoom(4);
        this.setMapCenter();
        if (this.locateCityCircle) {
            this.locateCityCircle.setMap(null);
        }
        if (this.timer === false || this.play === true) {
            this.isLoading = false;
            this.updateFilter(true);
        }
        else {
            this.isLoading = false;
            this.workerSubscription.unsubscribe();
            this.timerFunction(this.vehicleTypeValue);
            // this.checkForCount();
        }
    };
    // checkForCount() {
    //   if (
    //     this.searchValue !== '' ||
    //     this.selectedFilter !== '' ||
    //     this.speedfilter !== '' ||
    //     this.place ||
    //     this.isGroupFilterOn ||
    //     this.newPlace.circle.newCenter.lat != null
    //   ) {
    //     false;
    //   } else {
    //     this.liveService.getVehicleCount().subscribe({
    //       next: (res: any) => {
    //         this.vehicleTotalCount = res;
    //       },
    //       error: (err: any) => {
    //         this.sharedService.getErrorMsg(err);
    //       }
    //     });
    //   }
    // }
    LiveComponent.prototype.getRadius = function (radius) {
        this.locateCityCircle.setRadius(Number(radius));
        this.map.fitBounds(this.locateCityCircle.getBounds());
    };
    LiveComponent.prototype.closeLocatePopover = function () {
        this.locatePopover.nativeElement.click();
    };
    // Locations Save and other operations start
    LiveComponent.prototype.getSavedLocations = function () {
        var _this = this;
        if (!this.savedLocations.length) {
            this.sharedService.getAllfavourites(2).subscribe({
                next: function (res) {
                    _this.savedLocations = res;
                },
                error: function (err) {
                    _this.sharedService.getErrorMsg(err);
                }
            });
        }
    };
    LiveComponent.prototype.locateSavedLocation = function (obj, locate) {
        this.locatePlace.newplaceName = obj.name;
        this.locatePlace.name = obj.name;
        this.newPlace.circle.radius = obj.radius;
        this.newPlace.circle.newCenter.lat = obj.latitude;
        this.newPlace.circle.newCenter.lng = obj.longitude;
        if (locate) {
            this.locateDevice(true);
        }
    };
    LiveComponent.prototype.saveLocation = function () {
        var _this = this;
        var payload = {
            name: this.locatePlace.name,
            latitude: this.newPlace.circle.newCenter.lat,
            longitude: this.newPlace.circle.newCenter.lng,
            radius: this.newPlace.circle.radius,
            typeCode: 2
        };
        this.sharedService.addfavourite(payload).subscribe({
            next: function (res) {
                _this.savedLocations = [];
                _this.getSavedLocations();
                _this.responseMessage = {
                    success: true,
                    message: enMessage.saveLocationSuccess
                };
                _this.sharedService.setResponseMessage(_this.responseMessage);
            },
            error: function (err) {
                _this.sharedService.getErrorMsg(err);
            }
        });
    };
    LiveComponent.prototype.confirmDeleteLocation = function (obj) {
        this.confirmDeleteAction = 'location';
        this.selectedSavedLocation = obj;
        this.showModal = !this.showModal;
    };
    LiveComponent.prototype.deleteLocation = function () {
        var _this = this;
        this.sharedService.deletefavourite(this.selectedSavedLocation.id).subscribe({
            next: function (res) {
                _this.savedLocations = [];
                _this.getSavedLocations();
                _this.hideModal = !_this.hideModal;
                _this.responseMessage = {
                    success: true,
                    message: enMessage.deleteLocationSuccess
                };
                _this.sharedService.setResponseMessage(_this.responseMessage);
            },
            error: function (err) {
                _this.hideModal = !_this.hideModal;
                _this.sharedService.getErrorMsg(err);
            }
        });
    };
    // Locations Save and other operations end
    // All the Operations performed after the operation is confirmed from the modal
    LiveComponent.prototype.onDeleteConfirm = function () {
        if (this.confirmDeleteAction === 'location') {
            this.deleteLocation();
        }
        else if (this.confirmDeleteAction === 'favourite') {
            this.deletefavourite();
        }
    };
    LiveComponent.prototype.trackShare = function (isValid, form) {
        var _this = this;
        if (isValid && this.ctrl.status == 'VALID') {
            var payloadTime = this.time.hour * 60 * 60 + this.time.minute * 60;
            var payload = {
                'emailId': this.searchModel.emailId,
                'expiresIn': payloadTime,
                'deviceId': this.selectedVehicle.deviceId,
                'assetName': this.selectedVehicle.assetName
            };
            this.liveService.trackVehicle(payload).subscribe({
                next: function (res) {
                    _this.time = { hour: 1, minute: 59, second: 0 };
                    form.resetForm();
                    _this.trackModel.nativeElement.click();
                    _this.responseMessage = {
                        success: true,
                        message: enMessage.locationShared
                    };
                    _this.sharedService.setResponseMessage(_this.responseMessage);
                    // this.selectedVehicle = {};
                },
                error: function (err) {
                    _this.sharedService.getErrorMsg(err);
                }
            });
        }
    };
    LiveComponent.prototype.goToDevice = function (row) {
        this.router.navigate(['/devices'], {
            queryParams: {
                searchBy: 'serialNumber',
                value: row.serialNumber
            }
        });
    };
    LiveComponent.prototype.showShareTrackingModal = function (row) {
        document.getElementById('shareTrackBtn').click();
        this.selectedVehicle = row;
        this.autofocus(this.shareTrack);
    };
    LiveComponent.prototype.showReportsModal = function (device) {
        this.selectedVehicle = device ? device : this.selectedVehicle;
        this.getallReports();
        document.getElementById('goToReportsBtn').click();
        this.autofocus(this.reportSearch);
        // setTimeout(() => {
        //   const inputElem = <HTMLInputElement>this.reportSearch.nativeElement;
        //   inputElem.select();
        // }, 500);
    };
    LiveComponent.prototype.autofocus = function (element) {
        setTimeout(function () {
            var inputElem = element.nativeElement;
            inputElem.select();
        }, 500);
    };
    LiveComponent.prototype.ngOnDestroy = function () {
        this.isLoading = true;
        clearInterval(this.trackingId);
        if (this.timer) {
            if (!this.play) {
                this.autorefreshPlayPause();
            }
            this.workerSubscription.unsubscribe();
        }
    };
    LiveComponent.prototype.pasteinputValidator = function (data_value) {
        this.searchValue = " ";
        this.updateFilter(true);
    };
    LiveComponent.prototype.getVehicleHealthAndFuelScores = function (vehicle) {
        var _this = this;
        this.destroyHelathFuelGraphs();
        if (vehicle && vehicle.vin) {
            this.liveService.getVehicleHealthAndFuelScores(vehicle.vin).subscribe({
                next: function (res) {
                    if (res) {
                        var healthScoreProgressContainer = document.getElementById('healthScoreProgress');
                        var fuelEconomyScoreProgressContainer = document.getElementById('fuelEconomyScoreProgress');
                        _this.healthScoreProgress = _this.scoreProgressBar(res.healthScore ? res.healthScore : 0, healthScoreProgressContainer, 'Vehicle Health Score', true);
                        _this.fuelEconomyScoreProgress = _this.scoreProgressBar(res.fuelEconomyScore ? res.fuelEconomyScore : 0, fuelEconomyScoreProgressContainer, 'Fuel Economy Score', true);
                    }
                    else {
                        _this.createHealtFuelProgressBar();
                    }
                },
                error: function (error) {
                    _this.createHealtFuelProgressBar();
                    // this.sharedService.getErrorMsg(error);
                }
            });
        }
        else {
            this.createHealtFuelProgressBar();
        }
    };
    LiveComponent.prototype.createHealtFuelProgressBar = function () {
        var healthScoreProgressContainer = document.getElementById('healthScoreProgress');
        var fuelEconomyScoreProgressContainer = document.getElementById('fuelEconomyScoreProgress');
        this.healthScoreProgress = this.scoreProgressBar(0, healthScoreProgressContainer, 'Vehicle Health Score', true);
        this.fuelEconomyScoreProgress = this.scoreProgressBar(0, fuelEconomyScoreProgressContainer, 'Fuel Economy Score', true);
    };
    LiveComponent.prototype.destroyHelathFuelGraphs = function () {
        this.healthFuelProgressBars.forEach(function (bar) {
            if (bar.svg) {
                bar.destroy();
            }
        });
        this.healthFuelProgressBars = [];
    };
    LiveComponent.prototype.dashboardTracking = function (row) {
        this.showLiveDashboard = !this.showLiveDashboard;
        this.trackVehicle(row);
    };
    LiveComponent.prototype.closeVechilePopUp = function () {
        // explicitCloseDrawer
        this.showLiveDashboard = false;
        this.tracking = false;
    };
    // track device data
    LiveComponent.prototype.liveDashboardPids = function (data) {
        if (data.hasOwnProperty("cog")) {
            var vechileData = [{ id: 1, name: "Ignition Status", value: data.ignitionStatus == 0 ? "OFF" : "ON", unit: "", icon: "../../../assets/img/pid_icons/1.png" },
                { id: 2, name: "Vechile Speed", value: data.obdSpeed, unit: "km/h", icon: "../../../assets/img/pid_icons/13.png" },
                { id: 3, name: "COG", value: data.cog, unit: "deg", icon: "../../../assets/img/pid_icons/cog.png" },
                { id: 4, name: "SOG", value: data.sog, unit: "km/h", icon: "../../../assets/img/pid_icons/12.png" },
                { id: 5, name: "Odometer", value: data.odometer, unit: "km", icon: "../../../assets/img/pid_icons/166.png" },
                { id: 6, name: "Altitude", value: data.altitude, unit: "m", icon: "../../../assets/img/pid_icons/altitude.png" },
                { id: 7, name: "Latitude", value: data.latitude.toFixed(5), unit: "DD", icon: "../../../assets/img/pid_icons/latLong.png" },
                { id: 8, name: "Longitude", value: data.longitude.toFixed(5), unit: "DD", icon: "../../../assets/img/pid_icons/latLong.png" }];
            this.obdsList = vechileData;
        }
        // let response = data;
        // let tempArr = [];
        // let orderArr = [13, 12, 17, 166, 5, 15, 1];
        // let orderArr1 = [{
        //   id: 1,
        //   value: 13
        // },
        // {
        //   id: 2,
        //   value: 12
        // },
        // {
        //   id: 3,
        //   value: 17
        // },
        // {
        //   id: 4,
        //   value: 166
        // },
        // {
        //   id: 5,
        //   value: 5
        // },
        // {
        //   id: 6,
        //   value: 15
        // },
        // {
        //   id: 7,
        //   value: 1
        // },
        // ];
        // let newObdsData = true;
        // const key = this.configMessage.allPids;
        // if (response.hasOwnProperty("obds")) {
        //   this.pastVechileData = response.obds;
        //   this.initialObdsLoad = true;
        // } else {
        //   newObdsData = false;
        // }
        // Object.keys(this.configMessage.allPids).forEach((ele) => {
        //   let finalObj = key[ele];
        //   let idx = orderArr.findIndex(x => x == finalObj.pid)
        //   if (idx > -1) {
        //     finalObj["idx"] = orderArr1[idx].id;
        //     finalObj["v"] = 0;
        //     finalObj["icon"] = `../../../assets/img/pid_icons/${finalObj.pid}.png`;
        //   }
        //   else {
        //     finalObj["v"] = 1;
        //     finalObj["idx"] = 8;
        //     finalObj["icon"] = `../../../assets/img/pid_icons/generic_pid.png`;
        //   }
        //   let obdVal = [];
        //   if (response.hasOwnProperty("obds")) {
        //     obdVal = response.obds.filter(obdEle => obdEle.pid == finalObj["pid"]);
        //   }
        //   // if (this.initialObdsLoad && !newObdsData) {
        //   //   obdVal = this.pastVechileData.obds.filter(obdEle => obdEle.pid == finalObj["pid"]);
        //   // }
        //   if (obdVal.length > 0) {
        //     // Object.assign(finalObj, { value: obdVal[0].values[0] });
        //     Object.assign(finalObj, { value: Number(obdVal[0].values[0]).toFixed() });
        //   } else {
        //     Object.assign(finalObj, { value: "-" });
        //   }
        //   tempArr.push(finalObj);
        // })
        // this.obdsList = [{
        //   pid: 0,
        //   icon: "../../../assets/img/pid_icons/1.png",
        //   description: 'Ignition status', unit: '', idx: 1, value: response.ignitionStatus === 0 ? "OFF" : "ON"
        // }, ...tempArr.sort((a, b) => a.description.toLowerCase().localeCompare(b.description.toLowerCase())).sort((a, b) => a.v - b.v).sort((a, b) => a.idx - b.idx)];
        if (data.hasOwnProperty("cog")) {
            if (data.cog < 45) {
                this.direction = "N";
            }
            else if (data.cog < 90) {
                this.direction = "NE";
            }
            else if (data.cog < 135) {
                this.direction = "E";
            }
            else if (data.cog < 180) {
                this.direction = "SE";
            }
            else if (data.cog < 225) {
                this.direction = "S";
            }
            else if (data.cog < 270) {
                this.direction = "SW";
            }
            else if (data.cog < 315) {
                this.direction = "W";
            }
            else if (data.cog < 360) {
                this.direction = "NW";
            }
        }
        else {
            // this.direction = "-"
        }
    };
    return LiveComponent;
}());
export { LiveComponent };
