import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js';
import { GraphicReportsService } from '../../graphic-reports.service';
import { SharedService } from '@myproject/shared/shared.service';
import { DatePipe } from '@angular/common';
import * as FileSaver from 'file-saver';
import { Title } from '@angular/platform-browser';
import { environment } from '@myenv/environment';
import { GroupsService } from '@myproject/components/device/groups/groups.service';
import { configMessage } from '@shared/config-message';

@Component({
  selector: 'app-not-communicating-report',
  templateUrl: './not-communicating-report.component.html',
  styleUrls: ['./not-communicating-report.component.scss']
})
export class NotCommunicatingReportComponent implements OnInit {
  page = 0;
  // isLoading = false;
  startTime: number;
  endTime: number;
  devices: any[] = [];
  pieChart: any;
  lineChart: any;
  selectedCategory = '';
  displayCategory = '';
  selectedType = '';
  resetOffset = false;
  hideCalendar = false;
  blockCall = false;
  highestCount = 0;
  colors: any = [];
  searchableData = [];
  searchedData = [];
  searchValue = '';
  searchPopup = false;
  isLoading = {
    firmwarePie: true,
    trendData: true,
    devices: true
  };

  groups: any = [];
  configMessage = configMessage;
  selectedDate: any = {};
  displayTitle: any = {};
  selectedGroupId = 'All';

  constructor(
    private reportsService: GraphicReportsService,
    private sharedService: SharedService,
    private title: Title,
    private groupsService: GroupsService
  ) { }

  ngOnInit() {
    this.title.setTitle('Not Communicating'+ environment.title_text);
    this.getGroups();
  }

  onTimeChange(e) {
    if (e) {
      this.startTime = e.startTime;
      this.endTime = e.endTime;
    }
    this.page = 0;
    this.resetOffset = !this.resetOffset;
    if (this.groups.length) {
      this.resetSearch();
      //this.getAllNotCommCount(e.startTime, e.endTime, this.selectedCategory);
      this.getNotCommTrendData(this.startTime, this.endTime, 'groupName', this.displayCategory, 'red');
      this.getAllNotCommDevices(this.startTime, this.endTime, 0);
    }
  }

  onPageChange(page) {
    this.page = page;
    this.getAllNotCommDevices(this.startTime, this.endTime, page);
  }

  onCategoryChange(key, display) {
    this.selectedCategory = key;
    this.displayCategory = display;
    this.blockCall = false;
    this.resetSearch();
    this.getAllNotCommCount(this.startTime, this.endTime, key);
  }

  onCategorySearch() {
    const global = this;
    this.searchedData =  this.searchableData.filter(function(item) {
      return item.category.toLowerCase().includes(global.searchValue.toLowerCase());
    });
  }

  showSearchPopup() {
    this.searchValue = '';
    this.searchedData = this.searchableData;
    this.searchPopup = true;
  }

  onSelectCategory(item: {category: '', color: '', name: '', groupId: ''}) {
    this.displayCategory = item.name;
    this.selectedType = item.category;
    this.searchPopup = false;
    this.searchValue = '';
    this.selectedGroupId = item.name;
    this.getNotCommTrendData(this.startTime, this.endTime, this.selectedCategory, item.name, "red");
    this.getAllNotCommDevices(this.startTime, this.endTime, 0);
  }

  resetSearch() {
    this.searchValue = '';
    this.searchableData = [];
    this.searchedData = [];
    this.searchPopup = false;
    this.isLoading.firmwarePie = true;
    this.isLoading.trendData = true;
  }

  refresh() {
    this.page = 0;
    this.resetOffset = !this.resetOffset;
    this.resetSearch();
    this.getAllNotCommCount(this.startTime, this.endTime, this.selectedCategory);
    this.getAllNotCommDevices(this.startTime, this.endTime, 0);
  }

  getAllNotCommCount(startTime, endTime, type) {
    this.isLoading.firmwarePie = true;
    this.reportsService.getAllNotCommCount(startTime, endTime, type).subscribe({
      next: res => {
        this.isLoading.firmwarePie = false;
        if (res.length) {
          res.map(item => {
            const r = Math.floor(Math.random() * 256);
            const g = Math.floor(Math.random() * 256);
            const b = Math.floor(Math.random() * 256);
            const color = 'rgb(' + r + ',' + g + ',' + b + ')';
            const obj = { category: item.type, color: color };
            this.searchableData.push(obj);
            this.colors.push(color);
          });
          this.selectedType = res[0].type;
          this.getNotCommTrendData(this.startTime, this.endTime, type, res[0].type, this.colors[0]);
          this.drawPieGraph(res);
        } else {
          this.drawPieGraph([]);
          this.drawLineGraph([], '');
          this.isLoading.trendData = false;
        }
      },
      error: error => {
        this.isLoading.firmwarePie = false;
        this.isLoading.trendData = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  getNotCommTrendData(startTime, endTime, type, value, color) {
    this.isLoading.trendData = true;
    this.reportsService.getNotCommTrendData(startTime, endTime, type, value).subscribe({
      next: res => {
        this.isLoading.trendData = false;
        if (res.length) {
          if (value === "All") {
            let sumCounts = this.sumCountsByDay(res);
            let combinedRes = [];
            let tempRes = {};
            tempRes["type"] = "All";
            tempRes["data"] = sumCounts;
            combinedRes.push(tempRes);
            this.drawLineGraph(combinedRes, color);
          } else {
            this.drawLineGraph(res, color);
          }
        } else {
          this.drawLineGraph([], '');
        }
      },
      error: error => {
        this.isLoading.trendData = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  getAllNotCommDevices(startTime, endTime, page) {
    this.isLoading.devices = true;
    this.isLoading.devices = false;

    this.selectedDate = new Date(endTime);
    this.selectedDate.setDate(this.selectedDate.getDate());
    this.selectedDate.setHours(0);
    this.selectedDate.setMinutes(0);
    this.selectedDate.setSeconds(0);
    this.selectedDate.setMilliseconds(0);
    const datePipe = new DatePipe('en-US');
    const eventDate = datePipe.transform(this.selectedDate, 'dd MMM yyyy');
    //this.displayTitle = "Not Communicating Devices as on " + eventDate;

    this.reportsService.getAllNotCommDevices(startTime, endTime, this.selectedGroupId, page).subscribe({
      next: res => {
        this.isLoading.devices = false;
        if (!res.length) { this.blockCall = true; }
        if (page === 0) {
          this.devices = res;
        } else {
          this.devices = [...this.devices, ...res];
        }
      },
      error: error => {
        this.isLoading.devices = false;
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  downloadAllNotCommDevices(attributes) {
    this.reportsService.downloadAllNotCommDevices(this.startTime, this.endTime, attributes).subscribe({
      next: res => {
        const contentDisposition = res.headers.get('Content-Disposition');
        let filename;
        if (contentDisposition == null) {
          const date = new Date();
          filename = 'Device_' + date + '.zip';
        } else {
          filename = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
        }
        const blob = new Blob([res.body], {
          type: 'application/zip'
        });
        FileSaver.saveAs(blob, filename);
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  drawPieGraph(data) {
    if (this.pieChart) {
      this.pieChart.destroy();
    }
    const global = this;
    const labels = [];
    const counts = [];
    data.map(item => {
      labels.push(item.type);
      counts.push(item.count);
    });
    var ctx = document.getElementById('pieChart');
    this.pieChart = new Chart(ctx, {
      type: 'pie',
      responsive: true,
      data: {
        labels: labels,
        datasets: [{
          data: counts,
          backgroundColor: global.colors,
          borderColor: global.colors
        }]
      },
      options: {
        legend: {
          position: 'bottom',
          display: false
        },
        scales: {
          xAxes: [{
            display: false,
            ticks: {
              beginAtZero: true
            }
          }],
          yAxes: [{
            display: false,
            stacked: true,
            ticks: {
              beginAtZero: true
            }
          }]
        },
        onClick: function (event, elem) {
          var elem = elem[0];
          if (!elem) return; // check and return if not clicked on bar/data
          console.log(elem);
          global.selectedType = elem._view.label;
          global.getNotCommTrendData(
            global.startTime, global.endTime, global.selectedCategory, elem._view.label, elem._view.backgroundColor
          );
        }
      }
    });
  }

  drawLineGraph(res, color) {
    if (this.lineChart) {
      this.lineChart.destroy();
    }
    this.highestCount = 0;
    const labels = [];
    const dataset = [];
    const highestCountsArray = [];
    if (res.length) {
      res[0].data.map(item => {
        const datePipe = new DatePipe('en-US');
        const eventDate = datePipe.transform(item.day + ((new Date).getTimezoneOffset() * 60 * 1000), 'dd MMM yyyy');
        labels.push(eventDate);
      });
      this.displayTitle = "Not Communicating Devices as on " + labels[labels.length - 1];
      res.map((item, index) => {
        const obj: any = {};
        const counts = [];
        item.data.map(innerItem => {
          counts.push(innerItem.count);
        });
        highestCountsArray.push(Math.max(...counts));
        obj.label = item.type;
        obj.data = counts;
        obj.backgroundColor = color;
        obj.borderColor = color;
        obj.fill = false;
        dataset.push(obj);
      });
      this.highestCount = Math.max(...highestCountsArray);
    }
    var ctx = document.getElementById('lineChart');
    this.lineChart = new Chart(ctx, {
      type: 'line',
      responsive: true,
      data: {
        labels: labels,
        datasets: dataset
      },
      options: {
        legend: {
          position: 'right',
          display: false
        },
        scales: {
          xAxes: [{
            display: true,
            ticks: {
              beginAtZero: true
            }
          }],
          yAxes: [{
            display: true,
            stacked: false,
            ticks: {
              suggestedMin: 0,
              suggestedMax: 10,
              stepSize: this.highestCount > 9 ? null : 1
            }
          }]
        },
        onClick: (event, elements) => {
          const points = this.lineChart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, false);
          console.log('Points:', points);  // Debug log
          
          if (points.length > 0) {
            const firstPoint = points[0];
            console.log('First Point:', firstPoint);  // Debug log
            
            const label = this.lineChart.data.labels[firstPoint._index];
            const value = this.lineChart.data.datasets[firstPoint._datasetIndex].data[firstPoint._index];

            // Convert the label to a Date object if necessary
            const date = new Date(label);
            this.displayTitle = "Not Communicating Devices as on " + label;
            this.getAllNotCommDevices(date.getTime() + (date.getTimezoneOffset() * -1 * 60 * 1000), date.getTime() + (date.getTimezoneOffset() * -1 * 60 * 1000), 0);
          }
        },
        onHover: (event, elements) => {
          const target = event.native ? event.native.target : event.currentTarget;
          if (elements.length > 0) {
            target.style.cursor = 'pointer';  // Only when hovering over a data point
          } else {
            target.style.cursor = 'default';  // Default cursor when not hovering over a data point
          }
        }
      }
    });
  }

  // To get the groups
  getGroups() {
    if (!this.groups.length) {
      let allGroupsOption = {"name": "All", "groupId":"All"};
      this.groupsService.getAssignedGroups().subscribe(res => {
        this.groups = res;
        this.groups.unshift(allGroupsOption);
        this.selectedCategory = "groupName";
        this.displayCategory = res[0].name;
        this.onTimeChange(null);
      }, error => {
        this.sharedService.getErrorMsg(error);
      });
    }
  }

  sumCountsByDay(dataSets: any[]) {
    const sumByDayMap = new Map<number, number>();
  
    dataSets.forEach(dataSet => {
      dataSet.data.forEach((item: { day: number; count: number }) => {
        const currentCount = sumByDayMap.get(item.day) || 0;
        sumByDayMap.set(item.day, currentCount + item.count);
      });
    });
  
    // Convert the map back to an array if needed
    return Array.from(sumByDayMap, ([day, count]) => ({ day, count }));
  }

}
