/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./side-menu.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "@angular/common";
import * as i4 from "@ngx-translate/core";
import * as i5 from "./side-menu.component";
var styles_SideMenuComponent = [i0.styles];
var RenderType_SideMenuComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SideMenuComponent, data: {} });
export { RenderType_SideMenuComponent as RenderType_SideMenuComponent };
function View_SideMenuComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 19, "li", [["class", "clearfix ripple side-menu-list-item"], ["routerLinkActive", "active"]], null, null, null, null, null)), i1.ɵdid(1, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i1.ElementRef, i1.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActiveOptions: [0, "routerLinkActiveOptions"], routerLinkActive: [1, "routerLinkActive"] }, null), i1.ɵqud(603979776, 1, { links: 1 }), i1.ɵqud(603979776, 2, { linksWithHrefs: 1 }), i1.ɵpod(4, { exact: 0 }), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵeld(6, 0, null, null, 12, "a", [["class", "pb-2 pt-2"], ["style", "cursor: pointer; display: block;"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 7).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(7, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(9, 0, null, null, 4, "span", [["class", "sideMenuIconBox"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵted(-1, null, ["\n            "])), (_l()(), i1.ɵeld(12, 0, null, null, 0, "i", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵted(-1, null, ["\n          "])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "span", [["class", "side-menu-text"]], null, null, null, null, null)), (_l()(), i1.ɵted(16, null, ["", ""])), i1.ɵpid(131072, i4.TranslatePipe, [i4.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n        "])), (_l()(), i1.ɵted(-1, null, ["\n      "]))], function (_ck, _v) { var currVal_0 = _ck(_v, 4, 0, true); var currVal_1 = "active"; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_4 = i1.ɵinlineInterpolate(1, "", _v.context.$implicit.route, ""); _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 7).target; var currVal_3 = i1.ɵnov(_v, 7).href; _ck(_v, 6, 0, currVal_2, currVal_3); var currVal_5 = i1.ɵinlineInterpolate(1, "fa fa-", _v.context.$implicit.img, " side-menu-icon"); _ck(_v, 12, 0, currVal_5); var currVal_6 = i1.ɵunv(_v, 16, 0, i1.ɵnov(_v, 17).transform(_v.context.$implicit.name)); _ck(_v, 16, 0, currVal_6); }); }
export function View_SideMenuComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "sidebar-object"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵeld(2, 0, null, null, 7, "div", [["class", "reportScrollSideMenu"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "ul", [["class", "list-recent reportsSidemenu"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n      "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SideMenuComponent_1)), i1.ɵdid(7, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n    "])), (_l()(), i1.ɵted(-1, null, ["\n  "])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sideData; _ck(_v, 7, 0, currVal_0); }, null); }
export function View_SideMenuComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-side-menu", [], null, null, null, View_SideMenuComponent_0, RenderType_SideMenuComponent)), i1.ɵdid(1, 114688, null, 0, i5.SideMenuComponent, [i4.TranslateService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SideMenuComponentNgFactory = i1.ɵccf("app-side-menu", i5.SideMenuComponent, View_SideMenuComponent_Host_0, { sideData: "sideData", report: "report", KeyUp: "KeyUp", imgSrc: "imgSrc" }, {}, []);
export { SideMenuComponentNgFactory as SideMenuComponentNgFactory };
