
import {throwError as observableThrowError, Observable, Subject} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Response} from '@angular/http';
import {HttpClient, HttpHeaders, HttpBackend} from '@angular/common/http';
import {Router} from '@angular/router';

import {environment} from '@myenv/environment';

@Injectable()
export class AuthenticationService {

  public apiUrl = environment.apiUrl;
  private emitChangeSourceUser = new Subject<any>();
  private httpClient: HttpClient;
  changeEmitteds$ = this.emitChangeSourceUser.asObservable();


  constructor(private http: HttpClient, private router: Router, private handler: HttpBackend) {
    this.httpClient = new HttpClient(handler);
  }

  login(data): Observable<any> {
    return this.http.post(this.apiUrl + 'tokens', data).pipe(
      catchError(this.handleError));
  }

  getMfaToken(token, payload) {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    return this.http.post(this.apiUrl + 'mfa/tokens', payload, { headers })
    .pipe(catchError(this.handleError));
  }

  resendVerficationCode(token): Observable<any> {
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json'
    });
    return this.http.post(this.apiUrl + 'mfa/tokens/resend', {}, { headers })
    .pipe(catchError(this.handleError));
  }

  getUserInfo(userId): Observable<any> {
    const _headers = new HttpHeaders();
    let token = this.getHeaders();
    const headers = _headers.append('Authorization', 'Bearer ' + token).append('Content-Type', 'application/json');
        // return this.http.get(this.apiUrl + 'users/loggedin', {headers}).pipe(
    return this.http.get(this.apiUrl + 'users/' + userId, {headers}).pipe(
      catchError(this.handleError))
  }

  impersonate(data): Observable<any> {
    return this.http.post(this.apiUrl + 'impersonate/user/' + data + '/login', data).pipe(
      catchError(this.handleError));
  }

  stopImpersonate(): Observable<any> {
    return this.http.post(this.apiUrl + 'impersonate/logout', {}).pipe(
      catchError(this.handleError));
  }

  getLoggedUser(): Observable<any> {
    return this.http.get(this.apiUrl + 'users/loggedin').pipe(
      catchError(this.handleError))
  }

  getUserNotification(): Observable<any> {
    const _headers = new HttpHeaders();
    let token = this.getHeaders();
    const headers = _headers.append('Authorization', 'Bearer ' + token).append('Content-Type', 'application/json');
    return this.http.get(this.apiUrl + 'notifications/user', {headers}).pipe(
      catchError(this.handleError))
  }

  getUnreadNotificationCount(): Observable<any> {
    const _headers = new HttpHeaders();
    let token = this.getHeaders();
    const headers = _headers.append('Authorization', 'Bearer ' + token).append('Content-Type', 'application/json');
    return this.http.get(this.apiUrl + 'activity/users/unread', {headers}).pipe(
      catchError(this.handleError))
  }

  getAllUserNotifications(): Observable<any> {
    const _headers = new HttpHeaders();
    const token = this.getHeaders();
    const headers = _headers.append('Authorization', 'Bearer ' + token).append('Content-Type', 'application/json');
    return this.http.get(this.apiUrl + 'activity/users', {headers}).pipe(
      catchError(this.handleError));
  }

  readAllUserNotifications(): Observable<any> {
    const _headers = new HttpHeaders();
    const token = this.getHeaders();
    const headers = _headers.append('Authorization', 'Bearer ' + token).append('Content-Type', 'application/json');
    return this.http.post(this.apiUrl + 'activity/users/read', {headers}).pipe(
      catchError(this.handleError));
  }

  closeUserNotification(userNotificationId) : Observable<any>{
    return this.http.patch(`${this.apiUrl}notifications/user/${userNotificationId}?display=true`, {}).pipe(
      catchError(this.handleError))
  }

  setHeaders(token) {
    return localStorage.setItem('token', token)
  }

  getHeaders() {
    return localStorage.getItem('token');
  }

  checkCredentials() {
    if (localStorage.getItem('currentUser') === null) {
      return this.router.navigate(['Login']);
    }
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  onClickRester(data): Observable<any> {
    return this.http.post(this.apiUrl + 'admin', data).pipe(
      catchError(this.handleError));
  }

  setLoggedInUser(data) {
    localStorage.setItem('currentUser', JSON.stringify(data));
    this.emitChangeSourceUser.next(data);
  }

  // livePrefrences(live) {
  //   // localStorage.setItem('livePrefrence', JSON.stringify(live));
  //   localStorage.setItem('livePrefrence', JSON.stringify([]));
  //   this.emitChangeSourceUser.next(live);
  // }

  userPreferences(): Observable<any> {
    const token = localStorage.getItem('token');
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
    });
    return this.http.get(this.apiUrl + 'users/preferences', {headers}).pipe(
      catchError(this.handleError));
  }

  liveHistoryPrefrences(liveHistory) {
    localStorage.setItem('liveHistoryPrefrence', JSON.stringify(liveHistory));
    this.emitChangeSourceUser.next(liveHistory);
  }

  ForgotPassword(data): Observable<any> {

    let body = new URLSearchParams();
    body.set('userName', data.userName);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.httpClient.post(this.apiUrl + 'password/reset', body.toString(), {headers}).pipe(
      catchError(this.handleError))
  }

  ResetPassword(data, resetToken): Observable<any> {
    let body = new URLSearchParams();
    body.set('newPassword', data.newPassword);
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });

    return this.httpClient.put(this.apiUrl + 'password/reset/' + resetToken, body.toString(), {headers}).pipe(
      catchError(this.handleError))
  }

  updatePassword(data): Observable<any> {
    return this.http.put(this.apiUrl + 'users/password', data).pipe(
      catchError(this.handleError))
  }

  logout(token): Observable<any> {
    localStorage.removeItem('currentUser');
    localStorage.clear();
    sessionStorage.clear();
    this.emitChangeSourceUser.next(null);
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
      // 'CUSTOM_X_LCM': 'true'
    });

    return this.http.delete(this.apiUrl + 'tokens/logout', {headers}).pipe(
      catchError(this.handleError));
  }

  handleError(error: any | any) {
    return observableThrowError(error.error || error.json() || error || 'Server error');
  }

}
