<!--BEGIN CONTENT-->
<!-- Top Action Row Start -->
<div class="container-fluid">
<app-dashboard-header></app-dashboard-header>
  <div class="row no-gutters action-row align-items-center">
    <div class="col-md-12">
      <div class="row">
        <!-- Live Page Search Starts -->
        <div class="col-md-5 p-0">
          <form #f="ngForm">
            <div class="input-group input-group-lg searchVeh liveSearch pl-2">
              <div class="select-container filter-search relative live-page" style="width: 40%">
                <ng-select [clearable]="false" [items]="searchheader" bindLabel="displayName" bindValue="name"
                  [multiple]="false" [closeOnSelect]="true" [(ngModel)]="searchKey" name="searchType"
                  #searchType="ngModel"
                  (change)='searchValue ? updateFilter(f.valid) : false; selectInput(); favouriteFilterKey = ""'
                  required [searchable]="true" [clearable]="false" placeholder="Select Type">
                </ng-select>
              </div>
              <div class="filter-search input-box">
                <input [ngClass]="{'hiddenelement':!listPage}" type="text" name="queryString"
                  (keyup)='inputValidator($event); favouriteFilterKey = ""'
                  (paste)="pasteinputValidator($event.clipboardData.getData('text/plain'))" [(ngModel)]="searchValue"
                  id="search" class="form-control search-border" placeholder="Search" #inputElement
                  autocomplete="off" />
                <i class="fa fa-search img-responsive search-icon cursor-pointer" aria-hidden="true"></i>
                <i *ngIf="searchValue || (locateDeviceFilter && liveVehicles.length)" tooltip="Add favorite"
                  placement="top" #popoverElement [popoverOnHover]="false" [popover]="myPopover"
                  class="fa fa-heart-o search-icon cursor-pointer">
                </i>
                <i *ngIf="!searchValue && !locateDeviceFilter"
                  class="fa fa-heart-o search-icon cursor-pointer text-disabled"></i>
              </div>
            </div>
          </form>
          <popover-content #myPopover title="Create a filter from your search" [closeOnClickOutside]="true">
            <form (ngSubmit)="addfavourite(sf.valid,sf)" #sf="ngForm" novalidate class="mb-2">
              <div class="row no-gutters">
                <div class="col-12">
                  <input placeholder="Add favorite" type="text" name="favouriteValue" required class="mb-0 favourinput"
                    [(ngModel)]="favouriteText.favouriteValue" (keyup)="validateFav($event)"
                    #favouriteValue="ngModel" />
                  <div [hidden]="favouriteValue.valid || (favouriteValue.pristine && !sf.submitted)">
                    <small class="text-danger">Please add filter name</small>
                  </div>
                </div>
              </div>
              <div class="row no-gutters mt-2">
                <div class="col-12 login-buttons text-right">
                  <button (click)="togglePopover()" type="button"
                    class="btn btn-outline-secondary small-screen-btn mr-2">
                    Cancel
                  </button>
                  <input type="submit" class="btn btn-success small-screen-btn" [disabled]="disable"
                    value="Set as favorite" />
                </div>
              </div>
            </form>
          </popover-content>
        </div>
        <!-- Live Page Search Ends -->
        <!-- filter and refresh buttons start -->
        <div class="col-md-2 tooltip-wrapper">
          <!-- Refresh starts here -->
          <div class="px-0 lineHeight d-inline-block" (clickOutside)="openRefreshPopup=false;">
            <button class="btn btn-custom" tooltip="Refresh" placement="top">
              <a (click)="refresh()" *ngIf="!timer"
                class="ripple searchFilterHeading tooltip-event refresh-button relative">
                <i class="fa fa-refresh" aria-hidden="true"></i>
              </a>
              <a class="active-filter tooltip-event ripple searchFilterHeading tooltip-event refresh-button refresh-filter relative"
                *ngIf="timer">
                <i class="fa fa-play refresh-button font-15" *ngIf="play" (click)="autorefreshPlayPause();">&nbsp;</i>
                <i class="fa fa-pause refresh-button font-15" *ngIf="!play" (click)="autorefreshPlayPause();">&nbsp;</i>
                <span (click)="openRefreshPicker()">
                  &nbsp;&nbsp;{{searchModel.value}}&nbsp;{{searchModel.metrics}}
                </span>
              </a>
              <span [ngClass]="{'active-filter': timer}" *ngIf="refreshIcon && !timer" (click)="openRefreshPicker()"
                class="tooltip-event downloadHeading refresh-button">
                Auto Refresh <i class="fa fa-angle-down"></i>
              </span>
            </button>
            <div class="arrow_box text-left" *ngIf="openRefreshPopup">
              <div class="mx-3 my-3">
                <table>
                  <tr>
                    <td class="px-3" [class.selected-box]="!timer" (click)="stopTimer(); isLoading = true" colspan="2">
                      <span>Off</span>
                    </td>
                    <!-- 
                    <td class="px-4"
                      [class.selected-box]="timer && searchModel.value==45 && searchModel.metrics=='seconds'"
                      (click)="setTimer(45,'seconds');">
                      <span>45 seconds</span>
                    </td>
                    -->
                  </tr>
                  <tr>
                    <td class="px-3"
                      [class.selected-box]="timer && searchModel.value==10 && searchModel.metrics=='seconds'"
                      (click)="setTimer(10,'seconds')">
                      <span>10 seconds</span>
                    </td>
                    <td class="px-4"
                      [class.selected-box]="timer && searchModel.value==1 && searchModel.metrics=='minutes'"
                      (click)="setTimer(1,'minutes');">
                      <span>1 minute</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-3"
                      [class.selected-box]="timer && searchModel.value==20 && searchModel.metrics=='seconds'"
                      (click)="setTimer(20,'seconds');">
                      <span>20 seconds</span>
                    </td>
                    <td class="px-4"
                      [class.selected-box]="timer && searchModel.value==3 && searchModel.metrics=='minutes'"
                      (click)="setTimer(3,'minutes');">
                      <span>3 minutes</span>
                    </td>
                  </tr>
                  <tr>
                    <td class="px-3"
                      [class.selected-box]="timer && searchModel.value==30 && searchModel.metrics=='seconds'"
                      (click)="setTimer(30,'seconds');">
                      <span>30 seconds</span>
                    </td>
                    <td class="px-4"
                      [class.selected-box]="timer && searchModel.value==5 && searchModel.metrics=='minutes'"
                      (click)="setTimer(5,'minutes');">
                      <span>5 minutes</span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <!-- Auto-refresh ends here -->
        </div>
        <!-- filter and refresh buttons end -->
        <!-- Filter badge display start -->
        <div class="col-md-2 mobile-text-center filter-badge-container">
          <label class="badge badge-secondary filter-badge-wrapper" *ngIf="selectedFilter">
            <i tooltip="Clear" placement="top" class="fa fa-times filter-badge cursor-pointer"
              (click)="clearFilter($event)">
            </i>
            <span class="filter-text">{{selectedFilter}}</span>
          </label>
          <label class="badge badge-secondary ml-2 filter-badge-wrapper"
            *ngIf="searchFilter.value && speedfilter == 'getSpeed'">
            <i tooltip="Clear" placement="top" class="fa fa-times filter-badge cursor-pointer"
              (click)="clearFilter($event)">
            </i>
            <span class="filter-text">{{searchFilter.value}}</span>
          </label>
          <label class="badge badge-secondary ml-2 filter-badge-wrapper" *ngIf="searchFilter.placeValue">
            <i tooltip="Clear" placement="top" class="fa fa-times filter-badge cursor-pointer"
              (click)="clearPlacesFilter($event)">
            </i>
            <span class="filter-text">{{searchFilter.placeValue}}</span>
          </label>
          <label class="badge badge-secondary ml-2 filter-badge-wrapper"
            *ngIf="selectedGroup.name && selectedGroup.name != 'Groups'">
            <i tooltip="Clear" placement="top" class="fa fa-times filter-badge cursor-pointer"
              (click)="clearGroupsFilter($event)">
            </i>
            <span class="filter-text">{{selectedGroup.name}}</span>
          </label>
          <label class="badge badge-secondary ml-2 filter-badge-wrapper" *ngIf="locateDeviceFilter">
            <i tooltip="Clear" placement="top" class="fa fa-times filter-badge cursor-pointer"
              (click)="clearLocateFilter($event)">
            </i>
            <span class="filter-text">{{locatePlace.newName}}</span>
          </label>
        </div>
        <!-- Filter badge display end -->
        <div class="col-md-3 text-right filter-icons pt-1 pr-1">
          <!-- filters start -->
          <div class="row no-gutters text-left pl-2 d-inline-block">
            <div class="filter-icons admin-columns width100">
              <!-- Locate device Popover start -->
              <div class="text-center d-inline">
                <popover-content #locateDevicePopover title="Select Address to Locate Device"
                  [closeOnClickOutside]="false">
                  <button class="btn btn-sm btn-transparent mb-2 active-filter" *ngIf="locateDeviceFilter"
                    (click)="clearLocateFilter($event)">
                    <i class="fa fa-times faNotBtn"></i> Clear existing locate filter.
                  </button>
                  <form #ld="ngForm" (ngSubmit)="autoRefreshClick=false; locateDevice(ld.valid,ld);" novalidate
                    class="mb-2">
                    <div class="row no-gutters">
                      <div class="col-12">
                        <div class="radius-input place-input">
                          <div class="pac-card" id="pac-card">
                            <input (keyup)="searchMap()" #pacinput id="pac-input" type="text" id="newplaceName"
                              class="form-control h5-email" name="newplaceName" [(ngModel)]="locatePlace.newplaceName"
                              #newplaceName="ngModel" required placeholder="Search for an address*">
                            <div id="infowindow-content">
                              <p id="place-name" class="title mb-0"></p>
                              <p class="mb-0" id="place-address"></p>
                            </div>
                            <div class="alert-box"
                              [hidden]="newplaceName.valid || (newplaceName.pristine && !ld.submitted)">
                              <small class="text-danger">Please enter address</small>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-12 col-md-12 col-sm-12 m-0">
                        <div class="radius-input mt-2">
                          <label class="control-label">Radius (in Meters) <span class="text-danger">*</span></label>
                          <input type="number" id="radius" class="form-control h5-email col-lg-12" [value]="100"
                            [min]="100" [max]="1000000000" oninput="this.value = Math.abs(this.value)"
                            autocomplete="off" tabindex="1" maxlength="10" name="radius"
                            [(ngModel)]="newPlace.circle.radius" #radius="ngModel" required />
                          <div [hidden]="radius.valid || (radius.pristine && !ld.submitted)">
                            <small class="text-danger">Please enter a valid radius</small>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row no-gutters mt-2">
                      <div class="col-12 login-buttons text-right">
                        <button (click)="closeLocatePopover()" type="button" class="btn btn-outline-secondary btn-sm">
                          Cancel
                        </button>
                        <button (click)="saveLocation()" type="button"
                          [disabled]="disable || newPlace.circle.newCenter.lat === null || !locatePlace.name || !locatePlace.newplaceName"
                          class="btn btn-outline-primary btn-sm mx-1">
                          Save Location
                        </button>
                        <button type="submit" class="btn btn-success btn-sm"
                          [disabled]="disable || newPlace.circle.newCenter.lat === null || !locatePlace.name || !locatePlace.newplaceName"
                          value="Locate Device">
                          Locate Device
                        </button>
                      </div>
                    </div>
                    <div class="row no-gutters mt-3" *ngIf="savedLocations.length">
                      <label class="m-0">Saved Locations</label>
                      <div class="col-12 saved-locations">
                        <div class="location" *ngFor="let item of savedLocations">
                          <span class="text" (click)="locateSavedLocation(item,true)">{{item.name}}</span>
                          <span class="actions pull-right">
                            <!-- <i class="fa fa-arrow-up" tooltip="Populate" (click)="locateSavedLocation(item)"></i> -->
                            <!-- <i class="fa fa-pencil-square-o" tooltip="Edit" (click)="locateSavedLocation(item,false)"></i> -->
                            <i class="fa fa-trash-o text-danger" tooltip="Delete"
                              (click)="confirmDeleteLocation(item)"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </form>
                </popover-content>
                <a (click)="autoRefreshClick=false; getSavedLocations();"
                  [ngClass]="{'active-filter': locateDeviceFilter}" [popover]="locateDevicePopover" #locatePopover
                  [popoverOnHover]="false" tooltip="Locate Device" placement="top"
                  class="ripple tooltip-event relative">
                  <i class="fa fa-map-marker" aria-hidden="true"></i>
                </a>
              </div>
              <!-- Locate device Popover end -->
              <!-- Favourites dropdown start -->
              <div class="dropdown show text-center d-inline px-0 mx-0">
                <a tooltip="favorites" placement="top"
                  class="filter-toggle downloadHeading dropdown-toggle relative tooltip-event" href="#" role="button"
                  id="dropdownMenufavourite" (click)="this.getAllfavourites();" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false" [ngClass]="{'active-filter': favouriteFilterKey}">
                  <i class="fa fa-heart"></i>
                </a>
                <div class="dropdown-menu status-menu fav-list" aria-labelledby="dropdownMenufavourite">
                  <p *ngIf="searchValue" class="dropdown-item mb-0 cursor-pointer" href="javascript:void(0)"
                    (click)="togglePopover()">
                    + Create a filter
                  </p>
                  <p class="text-center" *ngIf="favouriteListLoading">
                    <img src="../../../assets/img/darby_loader_small.gif" />
                  </p>
                  <p *ngIf="!favouriteListLoading && !allfavouriteItems.length"
                    class="dropdown-item mb-0 cursor-pointer">
                    No data found
                  </p>
                  <p [ngClass]="{'text-danger': data.name == favouriteFilterKey}" *ngFor="let data of allfavouriteItems"
                    class="dropdown-item mb-0 cursor-pointer" href="javascript:void(0)"
                    (click)="selectfavouriteFilter(data)">
                    {{data.name}}
                    <i tooltip="Delete" placement="top" class="fa fa-trash clear-filter pull-right cursor-pointer mt-1"
                      [ngClass]="{'text-danger': data.name == favouriteFilterKey}"
                      (click)="getSelectedFavourite($event, data)">
                    </i>
                    <i tooltip="Edit" placement="top" data-toggle="modal" *ngIf="data.key == 'serialNumber'"
                      data-target="#editFavmodal" (click)="getFavDetails($event, data)"
                      [ngClass]="{'text-danger': data.name == favouriteFilterKey}"
                      class="fa fa-pencil clear-filter pull-right cursor-pointer mr-1 mt-1">
                    </i>
                  </p>
                </div>
              </div>
              <!-- Favourites dropdown end -->
              <!-- Device status filter dropdown start -->
              <div class="dropdown show text-center d-inline px-0 mx-0">
                <a tooltip="Device Status" placement="top"
                  class="filter-toggle downloadHeading dropdown-toggle relative tooltip-event" href="#" role="button"
                  id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                  [ngClass]="{'active-filter': selectedFilter || speedfilter}">
                  <i class="fa fa-location-arrow"></i>
                </a>
                <div class="dropdown-menu status-menu" aria-labelledby="dropdownMenuLink">
                  <a class="dropdown-item" [ngClass]="{'text-danger': selectedFilter == 'RUNNING'}"
                    (click)="getFilter('RUNNING')">
                    <i class="fa fa-circle-thin"></i>
                    Running
                    <i tooltip="Clear" placement="top"
                      [ngClass]="{'text-danger': filterText == 'Running', 'd-none': filterText !== 'Running'}"
                      class="fa fa-times clear-filter pull-right" style="cursor:pointer" (click)="clearFilter($event)">
                    </i>
                  </a>
                  <a (click)="getSpeedFilter('getSpeed', $event)" class="dropdown-item pr-1"
                    [ngClass]="{'text-danger': speedfilter == 'getSpeed'}" data-toggle="collapse"
                    data-target="#input-speed">
                    <i class="fa fa-angle-double-right"></i>
                    Speeding
                    <form class="speed-input" (ngSubmit)="f.form.valid" #f="ngForm" novalidate>
                      <input type="number" (keyup)="getSpeedFilter('getSpeed', $event)" [(ngModel)]="searchFilter.value"
                        #value="ngModel" name="value" id="value" />
                      <span> mph</span>
                    </form>
                  </a>
                  <a class="dropdown-item" [ngClass]="{'text-danger': selectedFilter == 'STOPPED'}"
                    (click)="getFilter('STOPPED')">
                    <i class="fa fa-circle"></i>
                    Stopped
                    <i tooltip="Clear" placement="top"
                      [ngClass]="{'text-danger': filterText == 'Stopped', 'd-none': filterText !== 'Stopped'}"
                      class="fa fa-times clear-filter pull-right" style="cursor:pointer" (click)="clearFilter($event)">
                    </i>
                  </a>
                  <a class="dropdown-item" [ngClass]="{'text-danger': selectedFilter == 'DISCONNECTED'}"
                    (click)="getFilter('DISCONNECTED')">
                    <i class="fa fa-minus-square-o"></i>
                    Disconnected
                    <i tooltip="Clear" placement="top"
                      [ngClass]="{'text-danger': filterText == 'Disconnected', 'd-none': filterText !== 'Disconnected'}"
                      class="fa fa-times clear-filter pull-right" style="cursor:pointer" (click)="clearFilter($event)">
                    </i>
                  </a>
                  <a class="dropdown-item relative" [ngClass]="{'text-danger': selectedFilter == 'NOT_COMMUNICATING'}"
                    (click)="getFilter('NOT_COMMUNICATING')">
                    <i class="fa fa-times-circle-o"></i>
                    Not Communicating
                    <i tooltip="Clear" placement="top"
                      [ngClass]="{'text-danger': selectedFilter == 'NOT_COMMUNICATING', 'd-none': selectedFilter !== 'NOT_COMMUNICATING'}"
                      class="fa fa-times clear-filter pull-right not-communicating" (click)="clearFilter($event)">
                    </i>
                  </a>
                </div>
              </div>
              <!-- Device status filter dropdown end -->
              <!-- Places button and dropdown start -->
              <div *ngIf="permissionModules?.includes('PLACE');" class="dropdown d-inline text-center px-0">
                <div tooltip="Places Filter" placement="top" id="dropdownMenuButton4" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false"
                  class="downloadHeading places-drop-down d-inline tooltip-event relative" [class.active-filter]="place"
                  (click)="getAllPlace(0)">
                  <i class="fa fa-globe" [class.text-danger]="place"></i>
                </div>
                <div class="d-inline"></div>
                <div class="dropdown-menu groups-filter status-menu" aria-labelledby="dropdownMenuButton4">
                  <p class="text-center" *ngIf="placesListLoading">
                    <img src="../../../assets/img/darby_loader_small.gif" />
                  </p>
                  <p class="text-center" *ngIf="!placesListLoading && !places.length">No Places Found.</p>
                  <p [ngClass]="{'text-danger': data.placeName == vehicleGroupFilterkey}"
                    class="dropdown-item mb-0 cursor-pointer" (click)="placesFilter(data)" href="javascript:void(0)"
                    *ngFor="let data of places">
                    {{data.placeName}}
                    <i tooltip="Clear" placement="top"
                      [ngClass]="{'text-danger': data.placeName == vehicleGroupFilterkey, 'd-none': data.placeName !== vehicleGroupFilterkey}"
                      class="fa fa-times clear-filter pull-right" style="cursor:pointer" *ngIf="vehicleGroupFilterkey"
                      (click)="clearPlacesFilter($event)">
                    </i>
                  </p>
                  <div class="dropdown-divider"></div>
                  <p class="dropdown-item mb-0" routerLink="/admin/place">View Places</p>
                  <p class="dropdown-item mb-0" (click)="addPlace()">+ Create a place</p>
                </div>
              </div>
              <!-- Places button and dropdown end -->
              <!-- Groups button with dropdown start -->
              <div *ngIf="permissionModules?.includes('GROUP');" class="dropdown d-inline text-center px-0">
                <div tooltip="Groups Filter" placement="top" id="dropdownMenuButton2" data-toggle="dropdown"
                  aria-haspopup="true" aria-expanded="false" (click)="getGroups()"
                  class="downloadHeading places-drop-down d-inline tooltip-event relative"
                  [class.active-filter]="isGroupFilterOn">
                  <i class="fa fa-object-ungroup" [class.text-danger]="isGroupFilterOn"></i>
                </div>
                <div class="dropdown-menu status-menu groups-filter" aria-labelledby="dropdownMenuButton2">
                  <form #f="ngForm" *ngIf="!groupsListLoading && groups.length">
                    <input #reportSearch type="text" class="form-control p-2" placeholder="Search Group"
                      (click)="$event.stopPropagation();" (keyup)="filterGroups($event)" />
                  </form>
                  <p class="text-center" *ngIf="groupsListLoading">
                    <img src="../../../assets/img/darby_loader_small.gif" />
                  </p>
                  <p class="text-center" *ngIf="!groupsListLoading && !groups.length">No Groups Found.</p>
                  <p [ngClass]="{'text-danger': data.name == selectedGroup.name}"
                    class="dropdown-item mb-0 cursor-pointer" *ngFor="let data of groups" (click)="groupsFilter(data)">
                    {{data.name}}
                    <i tooltip="Clear" placement="top"
                      [ngClass]="{'text-danger': data.groupId == selectedGroup.id, 'd-none': data.groupId !== selectedGroup.id}"
                      class="fa fa-times clear-filter pull-right" style="cursor:pointer" *ngIf="selectedGroup.id"
                      (click)="clearGroupsFilter($event)">
                    </i>
                  </p>
                </div>
              </div>
              <!-- Groups button with dropdown end -->
            </div>
          </div>
          <!-- filters end -->
          <!-- Download CSV button start -->
          <div class="text-center d-inline">
            <a tooltip="Download" placement="top" style="cursor : pointer" (click)="downloadCsv()"
              class="ripple tooltip-event relative">
              <i class="fa fa-cloud-download" aria-hidden="true"></i>
            </a>
          </div>
          <!-- Download CSV button end -->
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Top Action Row End -->
<!-- Table and Map Row Start -->
<div class="row no-gutters live-page-height">
  <!-- Table Column Start -->
  <div class="scrollfix relative sidebar" id="mySidebar">
    <div *ngIf="!isLoading" class="text-center no-data-found">
      <img src="../../../assets/img/darby_loader_small.gif" />
    </div>
    <div *ngIf="isLoading" class="liveTable activity">
      <div *ngIf="!liveVehicles.length" class="text-center no-data-found">
        <img width="170" src="../../../assets/img/info_nodata_256.png" />
        <h4 class="mt-3">No data to show</h4>
        <p>To see data, go ahead and <a class="pointer" href="javascript:window.location.reload(true)">clear filters</a>
          or <a class="pointer" routerLink="/devices">create device</a>.</p>
      </div>
      <!-- Data Table Starts -->
      <div id="tableContainer">
        <ngx-datatable *ngIf="liveVehicles.length && openedNav" id="tableau" #myTable
          class="bootstrap alertTable liveNgxDataTable wrapcell wrapAddress active-table" [rows]="liveVehicles"
          [columns]="" [columnMode]="'force'" (activate)="onActivate($event)" [selected]="selected"
          [selectionType]="'single'" [headerHeight]="headerHeight" [loadingIndicator]="loadingIndicator"
          [rowClass]="getRowClass" [scrollbarH]="false" [rowHeight]="'auto'">
          <ngx-datatable-column [resizeable]="false" name="Activity" [sortable]="false" [width]="200">
            <ng-template let-row="row" let-rowIndex="rowIndex" ngx-datatable-cell-template>
              <!-- display assetname -->
              <a (click)="getVehicleTime(row)" class="d-inline ml-2" data-toggle="tooltip" data-placement="top"
                title="{{row.assetName}}">
                <i *ngIf="row.generation !== 'EBIKE' && row.publishedBy !== 'EBIKE Simulation'"
                  class="fa fa-car pr-1"></i>
                <i *ngIf="row.generation === 'EBIKE' || row.publishedBy === 'EBIKE Simulation'"
                  class="fa fa-motorcycle pr-1"></i>
                {{row.assetName}}
              </a>

              <!-- action icons start -->
              <!-- copy icon -->
              <i class="fa fa-clone action-icons text-secondary"
                (click)="copyAssetName(row.assetName, rowIndex); $event.stopPropagation()">
              </i>
              <span *ngIf="copiedAssetName && rowIndex == currentIndex" class="arrow_box">Copied!</span>
              <!-- go to reports link -->
              <span class="pull-right mr-2" style="display: contents;">
                <a *ngIf="permissionModules.includes('REPORT')">
                  <i class="fa fa-bar-chart pull-right action-icons text-secondary" tooltip="Go to reports"
                    placement="top" (click)="showReportsModal(row); $event.stopPropagation()">
                  </i>
                </a>
                <!-- go to device link -->
                <a *ngIf="permissionModules.includes('DEVICE');">
                  <i class="fa fa-microchip pull-right action-icons text-secondary" tooltip="Go to device"
                    placement="top" (click)="goToDevice(row); $event.stopPropagation()">
                  </i>
                </a>
                <!-- tracking icon -->
                <a>
                  <i class="fa fa-eye pull-right action-icons text-secondary" *ngIf="!(row.generation == 'V2X' || tracking)" tooltip="Track device"
                    placement="top" (click)="trackVehicle(row); $event.stopPropagation()">
                  </i>
                  <i class="fa fa-eye pull-right action-icons text-success"
                    [class.d-inline-block]="tracking && selectedVehicle.deviceId==row.deviceId"
                    *ngIf="tracking && selectedVehicle.deviceId==row.deviceId" tooltip="Tracking" placement="top"
                    (click)="closeVechilePopUp();$event.stopPropagation()">
                  </i>
                </a>
                <!-- share tracking link -->
                <!-- <a>
                  <i class="fa fa-share-alt pull-right action-icons text-secondary" tooltip="Share tracking"
                    placement="top" (click)="showShareTrackingModal(row); $event.stopPropagation()">
                  </i>
                </a> -->

                <!-- Live Vechile tracking -->
                <!-- <a>
                  <i class="fa fa-tachometer pull-right action-icons text-secondary" tooltip="live vechile tracking"
                    placement="top" (click)="dashboardTracking(row); $event.stopPropagation()">
                  </i>
                </a> -->

              </span>
              <!-- action icons end -->
              <!-- Activity badge start -->
              <div style="height: 25px; margin-top: 4px;">
                <div *ngIf="row.messageType=='DISCONNECT_MESSAGE'">
                  <span class="live-text-danger ml-2 mr-2">Disconnected</span>
                  <span>{{row.eventTime | date : dateFormat}}</span>
                  <span class="lastUpdated"> ({{row.calculateStopTime}})</span>
                </div>
                <div
                  *ngIf="row.messageType!='DISCONNECT_MESSAGE' && (row.messageType=='TRIP_END' || row.messageType=='IGNITION_OFF')">
                  <span class="live-text-danger ml-2 mr-2">Stopped</span>
                  <span>{{row.eventTime | date : dateFormat}}</span>
                  <span class="lastUpdated"> ({{row.calculateStopTime}})</span>
                </div>
                <div
                  *ngIf="row.messageType!='DISCONNECT_MESSAGE' && (row.endPacket && row.messageType !== 'TRIP_END' && row.messageType !== 'IGNITION_OFF')">
                  <span>
                    <span class="statusColor ml-2 mr-2" *ngIf="!row.activity.includes('Not Communicating')">
                      {{row.activity}}</span>
                    <span class="warning-message ml-2 mr-2" *ngIf="row.activity.includes('Not Communicating')">
                      {{row.activity}}</span>
                    <span>{{row.eventTime | date : dateFormat}}</span>
                    <span class="lastUpdated"> ({{row.calculateStopTime}})</span>
                  </span>
                </div>
                <div
                  *ngIf="row.messageType!='DISCONNECT_MESSAGE' && (!row.endPacket && row.messageType !== 'TRIP_END' && row.messageType !== 'IGNITION_OFF')">
                  <span class="success-message ml-2 mr-2">{{row.activity}}
                    <span *ngIf="row.vehicleSpeed">({{row.vehicleSpeed == 'null'?(' ') : row.vehicleSpeed}}
                      {{speedMetrics}})</span>
                  </span>
                  <span>{{row.eventTime | date : dateFormat}}</span>
                </div>
              </div>
              <!-- Activity badge end -->
            </ng-template>
          </ngx-datatable-column>
        </ngx-datatable>
      </div>
      <!-- Data Table Ends -->
      <!-- load more starts here -->
      <div class="text-center">
        <p class="load-more-btn" (click)="loadMoreVehicles()" *ngIf="loadMore && liveVehicles.length >= 100">
          Loadmore
        </p>
      </div>
      <div class="total-count" *ngIf="vehicleTotalCount && liveVehicles.length">
        Total Vehicles: {{vehicleTotalCount.count}}
      </div>
      <!-- load more ends here -->
      <!-- Side Drawer Starts -->
      <div class="sideDrawer" [ngClass]="{'side-drawer-open':isDrawerOpen}">
        <div class="row m-0 header">
          <h6>{{selectedVehicle.assetName}}</h6>
          <div (clickOutside)="closeDetails()">
            <div class="tooltip-address list-item" (click)="toggleDetailPopup()">
              <i class="fa fa-ellipsis-v details-ellipse close" aria-hidden="true"></i>
            </div>
            <!-- device detail tooltip -->
            <div class="tooltip-address-text" *ngIf="detailBox">
              <ul id="text" #copyText class="list-unstyled vehicle-detail-list mb-2">
                <li class="d-block width100 copy-text tooltip-wrapper">
                  <span *ngIf="!permissionModules?.includes('DEVICE');">
                    <span class="text-label">Name: </span>{{vehicleDetail?.assetName}}
                  </span>
                  <span *ngIf="permissionModules?.includes('DEVICE');">
                    <span class="text-label">Name: </span>
                    <span class="link-color cursor-pointer"
                      (click)="redirectToDevice('assetName')">{{vehicleDetail?.assetName}}
                    </span>
                  </span>
                  <i (click)="copyToClipboard()" class="fa fa-clone"></i>
                  <span *ngIf="copied" class="arrow_box">Copied!</span>
                </li>
                <li class="d-block width100">
                  <span *ngIf="!permissionModules?.includes('DEVICE');">
                    <span class="text-label">Serial Number: </span>{{vehicleDetail?.serialNumber}}
                  </span>
                  <span *ngIf="permissionModules?.includes('DEVICE');">
                    <span class="text-label">Serial Number: </span>
                    <span (click)="redirectToDevice('serialNumber')"
                      class="link-color cursor-pointer">{{vehicleDetail?.serialNumber}}
                    </span>
                  </span>
                </li>
                <li class="d-block width100">
                  <span class="text-label">Group: </span>{{vehicleDetail?.groupName}}
                </li>
                <li class="d-block width100">
                  <span class="text-label">Added on: </span>{{vehicleDetail?.createdAt | date : dateFormat}}
                </li>
                <li class="d-block width100">
                  <span class="text-label">Last Communication: </span>{{vehicleDetail?.lastCommunication | date :
                  dateFormat}}
                </li>
                <li class="d-block width100">
                  <span class="text-label">Added By: </span>{{vehicleDetail?.creatorName}}
                </li>
                <li class="d-block width100">
                  <span class="text-label">FW / Config : </span>
                  {{snappedVehicle.firmwareVersion}} / {{snappedVehicle.configVersion}}
                </li>
              </ul>
            </div>
          </div>
          <span class="close" (click)="explicitCloseDrawer()">x</span>
        </div>
        <div *ngIf="drawerLoading" class="text-center drawerLoader">
          <img src="../../../assets/img/darby_loader_small.gif" />
        </div>
        <div class="data-container" [ngClass]="{'d-none': drawerLoading}">
          <div class="mb-0">
            <!-- <ul class="list-group list-group-flush">
              <li class="list-group-item bold text-dark text-left">Summary</li>
            </ul> -->
            <ul class="nav nav-tabs detailed-tabs relative page-sub-heading" role="tablist">
              <li class="nav-item active">
                <a class="nav-link text-center active mt-1 ml-4" role="tab" data-toggle="tab" href="#summary"
                  (click)="isHealthTabActive=false;">
                  General
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link text-center mt-1 ml-4" role="tab" data-toggle="tab" href="#deviceHealth"
                  (click)="getDetailsForDeviceEvents(); isHealthTabActive=true;">
                  Device Health
                </a>
              </li>
            </ul>
            <div class="tab-content">
              <!-- general tab summary start -->
              <div role="tabpanel" class="tab-pane fade in active show relative" id="summary">
                <div class="row m-0 mt-2 px-2 summary-box">
                  <div class="col-4 pr-0">
                    <h4 [tooltip]="snappedVehicle.totalTrips" placement="top" *ngIf="snappedVehicle.totalTrips">
                      {{snappedVehicle.totalTrips | ellipses:10}}</h4>
                    <h4 *ngIf="!snappedVehicle.totalTrips">--</h4>
                    <p>TOTAL TRIPS</p>
                  </div>
                  <div class="col-4 pr-0">
                    <h4 [tooltip]="snappedVehicle.odometer" placement="top"
                      *ngIf="snappedVehicle.vehicleOdometer && snappedVehicle.vehicleOdometer != 0">
                      {{snappedVehicle.vehicleOdometer | ellipses:10 | distanceMetricspipe}}
                      <span class="odometer">{{distanceMetrics}}</span>
                    </h4>
                    <h4 *ngIf="!snappedVehicle.vehicleOdometer || snappedVehicle.vehicleOdometer == 0"
                      style="font-size: 13px;">Not Supported</h4>
                    <p>ODOMETER</p>
                  </div>
                  <div class="col-md-12 mobile-mb-3">
                    <div class="col-md-3 d-inline-block" id="healthScoreProgress"></div>
                    <div *ngIf="currentMonthName" class="col-md-9 scoresLabel pl-0">Vehicle Health Score ({{currentMonthName}})</div>
                    <div *ngIf="!currentMonthName" class="col-md-9 scoresLabel pl-0">Vehicle Health Score</div>
                  </div>
                  <div class="col-md-12 mobile-mb-3">
                    <div class="col-md-3 d-inline-block" id="fuelEconomyScoreProgress"></div>
                    <div *ngIf="currentMonthName" class="col-md-9 scoresLabel pl-0">Fuel Economy Score ({{currentMonthName}})</div>
                    <div *ngIf="!currentMonthName" class="col-md-9 scoresLabel pl-0">Fuel Economy Score</div>
                  </div>
                  <!-- <div class="col-2 pr-0">
                    <h4 [tooltip]="signalTooltip" placement="top" class="ml-1 break-word"
                      *ngIf="snappedVehicle?.gsmStrength"
                      [ngClass]="{
                        'signal-icon':snappedVehicle?.gsmStrength,
                        'excellent-signal':snappedVehicle?.gsmStrength >= -70,
                        'good-signal':snappedVehicle?.gsmStrength >= -85 && snappedVehicle?.gsmStrength < -70,
                        'fair-signal':snappedVehicle?.gsmStrength <= -86 && snappedVehicle?.gsmStrength >= -100,
                        'poor-signal': snappedVehicle?.gsmStrength < -100  && snappedVehicle?.gsmStrength > -110,
                        'no-signal':snappedVehicle?.gsmStrength <= -110
                      }"
                      >
                    </h4>
                    <h4 *ngIf="!snappedVehicle?.gsmStrength">--</h4>
                  </div> -->
                  <div class="col-2 pr-0">
                    <span *ngIf="snappedVehicle.ignitionStatus == '0'">
                      <i class="fa fa-circle text-danger" tooltip="Ignition Off"></i>
                    </span>
                    <span *ngIf="snappedVehicle.ignitionStatus == '1'">
                      <i class="fa fa-circle text-success" tooltip="Ignition On"></i>
                    </span>
                  </div>
                </div>
                <div class="row m-0 mt-2 px-2 summary-box">
                  <div class="col-4 text-right p-0">
                    <p><i class="fa fa-truck"></i> VIN:</p>
                  </div>
                  <div class="col-8">
                    <h4 [tooltip]="snappedVehicle.vin" *ngIf="snappedVehicle.vin">{{snappedVehicle.vin}}</h4>
                    <h4 *ngIf="!snappedVehicle.vin">--</h4>
                  </div>
                  <div class="col-4 text-right p-0">
                    <p><i class="fa fa-object-ungroup"></i> Group:</p>
                  </div>
                  <div class="col-8">
                    <h4 [tooltip]="snappedVehicle.groupName" *ngIf="snappedVehicle.groupName">
                      {{snappedVehicle.groupName}}</h4>
                    <h4 *ngIf="!snappedVehicle.groupName">--</h4>
                  </div>
                  <div class="col-4 text-right p-0">
                    <p><i class="fa fa-cube"></i> FW / Config:</p>
                  </div>
                  <div class="col-8">
                    <h4 [tooltip]="snappedVehicle.firmwareVersion + '/' + snappedVehicle.configVersion"
                      *ngIf="snappedVehicle.firmwareVersion || snappedVehicle.configVersion">
                      {{snappedVehicle.firmwareVersion}} / {{snappedVehicle.configVersion}}</h4>
                    <h4 *ngIf="!snappedVehicle.firmwareVersion && !snappedVehicle.configVersion">--</h4>
                  </div>
                </div>
                <div class="mb-0" *ngIf="historyRows.length">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item bold text-dark text-left">Recent Messages</li>
                  </ul>
                  <div class="row mx-0 pt-2 pb-3 pr-3 pl-4 bottom-border recent-activity-container history-data">
                    <ng-container *ngFor="let row of historyRows">
                      <div class="pb-2 width100" *ngIf="row.messageType=='TRIP_END' || row.messageType=='IGNITION_OFF'">
                        <span class="live-text-danger">Stopped</span>
                        <br>
                        <span>{{row.eventTime | date : dateFormat}}</span>
                      </div>
                      <div class="pb-2 width100"
                        *ngIf="row.messageType !== 'TRIP_END' && row.messageType !== 'IGNITION_OFF'">
                        <span>
                          <span data-toggle="modal" data-target="#destinationDetail"
                            (click)="getMessage(row.messageId);" class="success-message cursor-pointer"
                            *ngIf="!row.activity.includes('Not Communicating')"> {{row.activity}}
                            <span *ngIf="row.vehicleSpeed">({{row.vehicleSpeed == 'null'?(' ') : row.vehicleSpeed}}
                              {{speedMetrics}})</span>
                          </span>
                          <span data-toggle="modal" data-target="#destinationDetail"
                            (click)="getMessage(row.messageId);" class="warning-message cursor-pointer"
                            *ngIf="row.activity.includes('Not Communicating')"> {{row.activity}}</span>
                          <br>
                          <span>{{row.eventTime | date : dateFormat}}</span>
                        </span>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
              <!-- general tab summary end -->
              <!-- device events tab start -->
              <div role="tabpanel" class="tab-pane fade in relative" id="deviceHealth">
                <div class="widget-container pt-3 mb-0" style="box-shadow: none; border: none;">
                  <div class="recent-activity-container">
                    <div class="media events-box" data-toggle="modal" data-target="#deviceEvents_model">
                      <!-- Generator: Adobe Illustrator 19.2.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
                      <svg [ngClass]="{'devicedata': deviceEvents.length}" class="nodata align-self-center"
                        version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 40 40"
                        style="enable-background:new 0 0 40 40;" xml:space="preserve">
                        <path class="st0" d="M32.9,12.4h-1.3V7.6c0-1.9-1.6-3.5-3.5-3.5H11.9c-1.9,0-3.5,1.6-3.5,3.5v4.7H7.1c-1.9,0-3.5,1.6-3.5,3.5v15.5
                          c0,1.9,1.6,3.5,3.5,3.5h25.9c1.9,0,3.5-1.6,3.5-3.5V15.9C36.5,13.9,34.9,12.4,32.9,12.4z M31.6,25.4c0,1.1-0.9,1.9-1.9,1.9H10.3
                          c-1.1,0-1.9-0.9-1.9-1.9v-3.6c0-1.1,0.9-1.9,1.9-1.9h19.4c1.1,0,1.9,0.9,1.9,1.9V25.4z" />
                      </svg>
                      <div class="media-body">
                        <h6 class="mt-0 text-dark-grey">Device Events</h6>
                        <div *ngIf="deviceEvents.length">
                          <p *ngIf="deviceEvents.length >= 1">
                            <!-- <i class="fa fa-info-circle text-blue-grey" aria-hidden="true"></i>&nbsp; -->
                            <span
                              class="text-blue-grey">{{configMessage.messageType[deviceEvents[0].messageType]}}</span>&nbsp;
                            <span class="time text-light-grey">{{deviceEvents[0].eventTime | date : dateFormat}}</span>
                          </p>
                          <p *ngIf="deviceEvents.length >= 2">
                            <!-- <i class="fa fa-info-circle text-blue-grey" aria-hidden="true"></i>&nbsp; -->
                            <span
                              class="text-blue-grey">{{configMessage.messageType[deviceEvents[1].messageType]}}</span>&nbsp;
                            <span class="time text-light-grey">{{deviceEvents[1].eventTime | date : dateFormat}}</span>
                          </p>
                        </div>
                        <div *ngIf="!deviceEvents.length">
                          <p class="nodata-text">No data to show.</p>
                        </div>
                      </div>
                    </div>
                    <hr class="status-hr">
                    <div class="media" data-toggle="modal" data-target="#vehicleStatus_model">
                      <svg *ngIf="vehicleCount && vehicleCount.count == 0"
                        [ngClass]="{'devicedata': vehicleCount && vehicleCount.count == 0}"
                        class="nodata align-self-center" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 40 40"
                        style="enable-background:new 0 0 40 40;" xml:space="preserve">
                        <path class="st0"
                          d="M25.6,30.8c1.4-0.5,2.8-1.1,4.2-1.6c0.1,0,0.3-0.1,0.4-0.1c1,0,2.1,0,3.1,0c1.1,0,1.7-0.7,1.7-1.8v-3.5
                          c0-0.3,0.2-0.5,0.5-0.5l0.4,0c0.4,0,0.4-0.1,0.4-0.4l0-2c0-0.2-0.2-0.4-0.4-0.4h-0.5c-0.3,0-0.5-0.2-0.5-0.5l0-4.3
                          c0-0.6-0.2-1.1-0.7-1.4c-0.8-0.6-3.3-2.3-3.7-2.6c-0.3-0.2-0.6-0.3-0.9-0.2l-4.2,0c-0.4,0-0.7-0.3-0.7-0.7v-0.1
                          c0-0.4,0.3-0.7,0.7-0.7h2.8c0.4,0,0.7-0.3,0.7-0.7V8.7c0-0.4-0.3-0.7-0.7-0.7H17.7c-0.4,0-0.7,0.3-0.7,0.7v0.8
                          c0,0.4,0.3,0.7,0.7,0.7h3.1c0.4,0,0.7,0.3,0.7,0.7V11c0,0.4-0.3,0.7-0.7,0.7l-4.2,0c-0.8,0-1.5,0.2-2.1,0.7c-0.9,0.6-1.8,1.3-2.6,2
                          c-0.2,0.2-0.5,0.3-0.8,0.3c-0.8,0-1.7,0-2.5,0c-1,0-1.7,0.7-1.7,1.7l0,2.9c0,0.2-0.2,0.5-0.5,0.5H6c-0.5,0-0.9,0.4-0.9,0.9v4.6
                          c0,0.5,0.4,0.9,0.9,0.9h0.4c0.2,0,0.5,0.2,0.5,0.5c0,0.7,0,1.9,0,2.6c0,0.7,0,1.9,1.2,1.9h17.1C25.3,30.9,25.5,30.9,25.6,30.8" />
                        <path class="st0" d="M1.1,17.6c-0.3,0-0.6,0.3-0.6,0.6v9.2c0,0.3,0.3,0.6,0.6,0.6h1.7c0.3,0,0.6-0.3,0.6-0.6v-9.2
                          c0-0.3-0.3-0.6-0.6-0.6L1.1,17.6L1.1,17.6z" />
                        <path class="st0" d="M38.9,17.6H38c-0.3,0-0.6,0.3-0.6,0.6V27c0,0.3,0.3,0.6,0.6,0.6h0.8c0.3,0,0.6-0.3,0.6-0.6v-8.7
                          C39.5,17.9,39.2,17.6,38.9,17.6" />
                      </svg>
                      <svg *ngIf="vehicleCount && vehicleCount.count != 0"
                        [ngClass]="{'vehicledata': vehicleCount && vehicleCount.count != 0}"
                        class="nodata align-self-center" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 40 40"
                        style="enable-background:new 0 0 40 40;" xml:space="preserve">
                        <path class="st0"
                          d="M25.6,30.8c1.4-0.5,2.8-1.1,4.2-1.6c0.1,0,0.3-0.1,0.4-0.1c1,0,2.1,0,3.1,0c1.1,0,1.7-0.7,1.7-1.8v-3.5
                          c0-0.3,0.2-0.5,0.5-0.5l0.4,0c0.4,0,0.4-0.1,0.4-0.4l0-2c0-0.2-0.2-0.4-0.4-0.4h-0.5c-0.3,0-0.5-0.2-0.5-0.5l0-4.3
                          c0-0.6-0.2-1.1-0.7-1.4c-0.8-0.6-3.3-2.3-3.7-2.6c-0.3-0.2-0.6-0.3-0.9-0.2l-4.2,0c-0.4,0-0.7-0.3-0.7-0.7v-0.1
                          c0-0.4,0.3-0.7,0.7-0.7h2.8c0.4,0,0.7-0.3,0.7-0.7V8.7c0-0.4-0.3-0.7-0.7-0.7H17.7c-0.4,0-0.7,0.3-0.7,0.7v0.8
                          c0,0.4,0.3,0.7,0.7,0.7h3.1c0.4,0,0.7,0.3,0.7,0.7V11c0,0.4-0.3,0.7-0.7,0.7l-4.2,0c-0.8,0-1.5,0.2-2.1,0.7c-0.9,0.6-1.8,1.3-2.6,2
                          c-0.2,0.2-0.5,0.3-0.8,0.3c-0.8,0-1.7,0-2.5,0c-1,0-1.7,0.7-1.7,1.7l0,2.9c0,0.2-0.2,0.5-0.5,0.5H6c-0.5,0-0.9,0.4-0.9,0.9v4.6
                          c0,0.5,0.4,0.9,0.9,0.9h0.4c0.2,0,0.5,0.2,0.5,0.5c0,0.7,0,1.9,0,2.6c0,0.7,0,1.9,1.2,1.9h17.1C25.3,30.9,25.5,30.9,25.6,30.8" />
                        <path class="st0" d="M1.1,17.6c-0.3,0-0.6,0.3-0.6,0.6v9.2c0,0.3,0.3,0.6,0.6,0.6h1.7c0.3,0,0.6-0.3,0.6-0.6v-9.2
                          c0-0.3-0.3-0.6-0.6-0.6L1.1,17.6L1.1,17.6z" />
                        <path class="st0" d="M38.9,17.6H38c-0.3,0-0.6,0.3-0.6,0.6V27c0,0.3,0.3,0.6,0.6,0.6h0.8c0.3,0,0.6-0.3,0.6-0.6v-8.7
                          C39.5,17.9,39.2,17.6,38.9,17.6" />
                      </svg>
                      <div class="media-body">
                        <h6 class="mt-0 text-dark-grey">Vehicle Status</h6>
                        <p *ngIf="vehicleCount">
                          <span class="link-color">{{vehicleCount.count}}</span>
                          <span class="text-blue-grey"> Diagnostic Trouble Code(s)</span>
                        </p>
                      </div>
                    </div>
                    <hr class="status-hr">
                    <!-- driving behaviour -->
                    <div class="driving-behaviour-container" *ngIf="permissionModules.includes('SCORE')">
                      <div class="driving-behaviour-table row no-gutters">
                        <div class="col-md-4" tooltip="Hard Braking">
                          <div class="card relative">
                            <svg class="nodata harsh align-self-center mr-2" version="1.1" id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                              y="0px" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve">
                              <path class="st0"
                                d="M20,0C9,0,0,9,0,20s9,20,20,20s20-9,20-20S31,0,20,0z M20.1,33.1L8.8,13.5h22.6L20.1,33.1z" />
                            </svg>
                            <span *ngIf="drivingBehaviour.HARD_BRAKING>=0" class="link-color">
                              {{drivingBehaviour.HARD_BRAKING}}
                            </span>
                            <span *ngIf="!drivingBehaviour.HARD_BRAKING && drivingBehaviour.HARD_BRAKING!=0"
                              class="text-blue-grey">--</span>
                          </div>
                          <div class="media">
                            <svg class="nodata harsh align-self-center mr-2" version="1.1" id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                              y="0px" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve">
                              <path class="st0"
                                d="M20,0C9,0,0,9,0,20s9,20,20,20s20-9,20-20S31,0,20,0z M20.1,33.1L8.8,13.5h22.6L20.1,33.1z" />
                            </svg>
                            <div class="media-body">
                              <p class="cursor-pointer font-14">
                                <span *ngIf="drivingBehaviour.HARD_BRAKING>=0" class="link-color">
                                  {{drivingBehaviour.HARD_BRAKING}}
                                </span>
                                <span *ngIf="!drivingBehaviour.HARD_BRAKING && drivingBehaviour.HARD_BRAKING!=0"
                                  class="text-blue-grey">--</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4" tooltip="Speeding">
                          <div class="card relative">
                            <svg class="nodata speeding align-self-center mr-2" version="1.1" id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                              y="0px" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve">
                              <path class="st0" d="M20,0C9,0,0,9,0,20s9,20,20,20s20-9,20-20S31,0,20,0z M25.6,28.8c-1.5,0-2.9-0.4-4.1-1.1l-9.6-4.5h5.6L6.8,18
                                h7.8l-9.4-5h20c0.1,0,0.3,0,0.4,0c4.4,0,7.9,3.6,7.9,7.9C33.5,25.2,30,28.8,25.6,28.8z" />
                            </svg>
                            <span *ngIf="drivingBehaviour.OVER_SPEEDING>=0" class="link-color">
                              {{drivingBehaviour.OVER_SPEEDING}}
                            </span>
                            <span *ngIf="!drivingBehaviour.OVER_SPEEDING && drivingBehaviour.OVER_SPEEDING!=0"
                              class="text-blue-grey">--</span>
                          </div>
                          <div class="media">
                            <svg class="nodata speeding align-self-center mr-2" version="1.1" id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                              y="0px" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve">
                              <path class="st0" d="M20,0C9,0,0,9,0,20s9,20,20,20s20-9,20-20S31,0,20,0z M25.6,28.8c-1.5,0-2.9-0.4-4.1-1.1l-9.6-4.5h5.6L6.8,18
                                h7.8l-9.4-5h20c0.1,0,0.3,0,0.4,0c4.4,0,7.9,3.6,7.9,7.9C33.5,25.2,30,28.8,25.6,28.8z" />
                            </svg>
                            <div class="media-body">
                              <p class="cursor-pointer font-14">
                                <span *ngIf="drivingBehaviour.OVER_SPEEDING>=0" class="link-color">
                                  {{drivingBehaviour.OVER_SPEEDING}}
                                </span>
                                <span *ngIf="!drivingBehaviour.OVER_SPEEDING && drivingBehaviour.OVER_SPEEDING!=0"
                                  class="text-blue-grey">--</span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4" tooltip="Hard Acceleration">
                          <div class="card relative">
                            <svg class="nodata rapid align-self-center mr-3" version="1.1" id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                              y="0px" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve">
                              <g>
                                <circle class="st0" cx="24.6" cy="20.1" r="2.9" />
                                <path class="st0"
                                  d="M20,0C9,0,0,9,0,20s9,20,20,20s20-9,20-20S31,0,20,0z M24.6,28.1H7.9v-1.4h12.3c-2.1-1.5-3.5-3.9-3.5-6.6
                                c0-1.1,0.2-2.1,0.6-3.1h-10v-1.3H18c0.6-0.8,1.3-1.5,2.1-2.1H12v-1.4h12.6c4.4,0,7.9,3.7,7.9,8S29,28.1,24.6,28.1z" />
                              </g>
                            </svg>
                            <span *ngIf="drivingBehaviour.HARD_ACCELERATION>=0"
                              class="link-color">{{drivingBehaviour.HARD_ACCELERATION}}</span>
                            <span *ngIf="!drivingBehaviour.HARD_ACCELERATION && drivingBehaviour.HARD_ACCELERATION!=0"
                              class="text-blue-grey">--
                            </span>
                          </div>
                          <div class="media">
                            <svg class="nodata rapid align-self-center mr-3" version="1.1" id="Layer_1"
                              xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                              y="0px" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve">
                              <g>
                                <circle class="st0" cx="24.6" cy="20.1" r="2.9" />
                                <path class="st0"
                                  d="M20,0C9,0,0,9,0,20s9,20,20,20s20-9,20-20S31,0,20,0z M24.6,28.1H7.9v-1.4h12.3c-2.1-1.5-3.5-3.9-3.5-6.6
                              c0-1.1,0.2-2.1,0.6-3.1h-10v-1.3H18c0.6-0.8,1.3-1.5,2.1-2.1H12v-1.4h12.6c4.4,0,7.9,3.7,7.9,8S29,28.1,24.6,28.1z" />
                              </g>
                            </svg>
                            <div class="media-body">
                              <p class="cursor-pointer font-14">
                                <span *ngIf="drivingBehaviour.HARD_ACCELERATION>=0"
                                  class="link-color">{{drivingBehaviour.HARD_ACCELERATION}}</span>
                                <span
                                  *ngIf="!drivingBehaviour.HARD_ACCELERATION && drivingBehaviour.HARD_ACCELERATION!=0"
                                  class="text-blue-grey">--
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr class="status-hr">
                      <div class="driving-behaviour-table row no-gutters text-center">
                        <div class="col-md-4 mobile-mb-3">
                          <div id="exposureProgress">
                          </div>
                          <p class="text-blue-grey font-14">Exposure</p>
                        </div>
                        <div class="col-md-4 mobile-mb-3">
                          <div id="behaviourProgress">
                          </div>
                          <p class="text-blue-grey font-14 ">Behaviour</p>
                        </div>
                        <div class="col-md-4 mobile-mb-3">
                          <div id="riskProgress"></div>
                          <p class="text-blue-grey font-14">Risk</p>
                        </div>
                        <div class="pull-right score-widget-footer-label">
                          Average scores for last 30 days
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- device events tab end -->
            </div>
          </div>
          <hr class="m-0">
          <!-- <div class="mb-0">
            <ul class="list-group list-group-flush">
              <li class="list-group-item bold text-dark text-left">Action</li>
            </ul>
            <div class="row mx-0 pt-3 pb-2 px-2">
              <div class="col-6 pr-0">
                <div class="list-item link-color" *ngIf="!tracking" (click)="trackVehicle()"><i class="fa fa-eye"></i>
                  Track
                </div>
                <div *ngIf="tracking" class="text-success"><i class="fa fa-eye"></i> Tracking</div>
              </div>
              <div class="col-6 pr-0">
                <div class="dropdown" (click)="getallReports();"><i class="fa fa-bar-chart link-color"></i>
                  <a class="dropdown-toggle link-color" href="#" role="button" id="dropdownMenuButton3" data-toggle="dropdown"
                     aria-haspopup="true" aria-expanded="false">
                    Reports
                  </a>
                  <div class="dropdown-menu reports" aria-labelledby="dropdownMenuButton3">
                    <div *ngIf="customLoader" class="text-center tooltip-address-text">
                      <img src="../../../assets/img/darby_loader_small.gif"/>
                    </div>
                    <form #f="ngForm">
                      <input #reportSearch type="text" class="form-control p-2" placeholder="Search Report"
                             (click)="$event.stopPropagation();" (keyup)="filterReport($event)">
                    </form>
                    <span (click)="redirectToReports(data)" *ngFor="let data of reportsData" class="dropdown-item"
                          href="#">
                      <i class="fa fa-bar-chart report-icon"></i> {{data.name}}
                    </span>
                    <span class="dropdown-item text-center" *ngIf="!reportsData.length" href="#">
                      <b>No reports found</b>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mx-0 pb-3 px-2 pt-2">
              <div class="col-6 pr-0">
                <div class="list-item link-color" *ngIf="permissionModules?.includes('TRIPS');" (click)="redirectPage('trips')">
                  <i class="fa fa-map-o"></i> Trips
                </div>
              </div>
              <div class="col-6 pr-0">
                <div class="list-item link-color" data-toggle="modal" data-target="#sharetracking"><i class="fa fa-share-alt"></i>
                  Share
                </div>
              </div>
            </div>
          </div> -->
          <div class="mb-0 d-none"
            [ngClass]="{'d-block': snappedVehicle.architectureType == 'BS6_EEA' || snappedVehicle.architectureType == 'BS6_EDC' || snappedVehicle.architectureType == 'BS4_EDC' || snappedVehicle.architectureType == 'BS4_EEA'}">
            <ul class="list-group list-group-flush">
              <li class="list-group-item bold text-dark text-left">Health</li>
            </ul>
            <div class="row mx-0 py-2 summary-box">
              <div class="col-6">
                <p>Main Battery</p>
                <h4 class="d-flex align-items-center"
                  *ngIf="snappedVehicle.vehicleBatteryPotential || snappedVehicle.vehicleBatteryPotential==0"><i
                    class="fa fa-battery-quarter position-static mr-1"></i> {{snappedVehicle.vehicleBatteryPotential}}
                  <span class="odometer"> v</span>
                </h4>
                <h4 *ngIf="!snappedVehicle.vehicleBatteryPotential && snappedVehicle.vehicleBatteryPotential!=0">--</h4>
                <p class="mb-0">
                  <small>
                    <ng-container *ngIf="batteryEvent?.connect">{{batteryEvent.connect}}</ng-container>&nbsp;
                  </small>
                </p>
                <div [ngClass]="{'d-none': !batteryPotential.length}">
                  <div class="graph-container">
                    <canvas id="vehicleBatteryPotentialGraph" height="200" role="img"></canvas>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <p>Internal Battery</p>
                <h4 class="d-flex align-items-center"
                  *ngIf="snappedVehicle.internalBattery || snappedVehicle.internalBattery==0"><i
                    class="fa fa-battery-quarter position-static mr-1"></i> {{snappedVehicle.internalBattery}} <span
                    class="odometer"> v</span></h4>
                <h4 *ngIf="!snappedVehicle.internalBattery && snappedVehicle.internalBattery!=0">--</h4>
                <ng-container>
                  <p class="mb-0">
                    <small>
                      <ng-container *ngIf="batteryEvent?.LOW_INTERNAL_BATTERY_START">
                        Low: {{batteryEvent.LOW_INTERNAL_BATTERY_START | date: dateFormat}}
                      </ng-container>&nbsp;
                    </small>
                  </p>
                </ng-container>
                <div [ngClass]="{'d-none': !internalBattery.length}">
                  <div class="graph-container">
                    <canvas id="internalBatteryGraph" height="200" role="img"></canvas>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mx-0 py-2 summary-box">
              <div class="col-6 mt-3">
                <p>Fuel Level</p>
                <h4 class="d-flex align-items-center" *ngIf="snappedVehicle.fuelLevel || snappedVehicle.fuelLevel==0">
                  <i class="fa fa-tint position-static mr-1"></i>&nbsp;
                  <span *ngIf="fuelLevel.length">{{fuelLevel[fuelLevel.length - 1]}}</span>
                  <span class="odometer">%</span>
                </h4>
                <h4 *ngIf="!snappedVehicle.fuelLevel && snappedVehicle.fuelLevel!=0">--</h4>
                <div [ngClass]="{'d-none': !fuelLevel.length}">
                  <div class="graph-container">
                    <canvas id="fuelLevelGraph" height="200" role="img"></canvas>
                  </div>
                </div>
              </div>
              <div class="col-6 mt-3">
                <p>GPS</p>
                <h4 [ngClass]="{'d-none': !vPacketData.length}" class="mt-4"></h4>
                <div [ngClass]="{'d-none': !vPacketData.length}">
                  <div class="graph-container">
                    <canvas id="GPSChart" height="215" role="img"></canvas>
                  </div>
                </div>
                <div *ngIf="!vPacketData.length">
                  <p class="nodata-text">No data to show.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-0" [ngClass]="{'d-none': snappedVehicle.architectureType != 'EV'}">
            <ul class="list-group list-group-flush">
              <li class="list-group-item bold text-dark text-left">Health</li>
            </ul>
            <div class="row mx-0 py-3 pr-3 pl-4 summary-box no-gutters">
              <div class="col-6">
                <h4 *ngIf="snappedVehicle.socGauge || snappedVehicle.socGauge==0">
                  <i class="fa fa-battery-0 static-position" *ngIf="snappedVehicle.socGauge == 0"></i>
                  <i class="fa fa-battery-quarter static-position"
                    *ngIf="snappedVehicle.socGauge > 0 && snappedVehicle.socGauge <= 20"></i>
                  <i class="fa fa-battery-half static-position"
                    *ngIf="snappedVehicle.socGauge > 20 && snappedVehicle.socGauge <= 40"></i>
                  <i class="fa fa-battery-three-quarters static-position"
                    *ngIf="snappedVehicle.socGauge > 40 && snappedVehicle.socGauge <= 70"></i>
                  <i class="fa fa-battery-full static-position"
                    *ngIf="snappedVehicle.socGauge >70 && snappedVehicle.socGauge <= 1000"></i>
                  {{snappedVehicle.socGauge}} <span class="odometer">%</span>
                </h4>
                <h4 *ngIf="!snappedVehicle.socGauge && snappedVehicle.socGauge!=0">--</h4>
                <p>SOC Gauge</p>
              </div>
              <div class="col-6">
                <h4 *ngIf="snappedVehicle.distanceToEmpty || snappedVehicle.distanceToEmpty==0">
                  {{snappedVehicle.distanceToEmpty | distanceMetricspipe}} <span
                    class="odometer">{{distanceMetrics}}</span></h4>
                <h4 *ngIf="!snappedVehicle.distanceToEmpty && snappedVehicle.distanceToEmpty!=0">-- <span
                    class="odometer">{{distanceMetrics}}</span></h4>
                <p>Distance To Empty</p>
              </div>
              <div class="col-6 mt-2">
                <h4 *ngIf="snappedVehicle.motorSpeedMcu || snappedVehicle.motorSpeedMcu==0">
                  {{snappedVehicle.motorSpeedMcu}} <span class="odometer">rpm</span></h4>
                <h4 *ngIf="!snappedVehicle.motorSpeedMcu && snappedVehicle.motorSpeedMcu!=0">-- <span
                    class="odometer">rpm</span></h4>
                <p>Motor Speed</p>
              </div>
              <div class="col-6 mt-2">
                <h4 *ngIf="snappedVehicle.motorTorqueMcu || snappedVehicle.motorTorqueMcu==0">
                  {{snappedVehicle.motorTorqueMcu}} <span class="odometer">Nm</span></h4>
                <h4 *ngIf="!snappedVehicle.motorTorqueMcu && snappedVehicle.motorTorqueMcu!=0">-- <span
                    class="odometer">Nm</span></h4>
                <p>Motor Torque</p>
              </div>
              <div class="col-12 mt-3" [ngClass]="{'d-none': !EVMessageData.length}">
                <div class="graph-container">
                  <canvas id="lineChartTotal" height="200" role="img"></canvas>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-0" [ngClass]="{'d-none': snappedVehicle.architectureType != 'PDBS6 Non EEA'}">
            <ul class="list-group list-group-flush">
              <li class="list-group-item bold text-dark text-left">Health</li>
            </ul>
            <div class="row mx-0 py-3 pr-3 pl-4 summary-box no-gutters">
              <div class="col-6">
                <h4 *ngIf="snappedVehicle.engineOilPressure || snappedVehicle.engineOilPressure==0">
                  {{snappedVehicle.engineOilPressure}}
                  <!--<span class="odometer">{{distanceMetrics}}</span>-->
                </h4>
                <h4 *ngIf="!snappedVehicle.engineOilPressure && snappedVehicle.engineOilPressure!=0">--</h4>
                <p>Engine Oil Pressure</p>
              </div>
              <div class="col-6">
                <h4 *ngIf="snappedVehicle.ambientAirTemperature || snappedVehicle.ambientAirTemperature==0">
                  {{snappedVehicle.ambientAirTemperature}} <span class="odometer">&#8451;</span></h4>
                <h4 *ngIf="!snappedVehicle.ambientAirTemperature && snappedVehicle.ambientAirTemperature!=0">-- <span
                    class="odometer">&#8451;</span></h4>
                <p>Ambient Air Temp</p>
              </div>
              <div class="col-6 mt-2">
                <h4 *ngIf="snappedVehicle.engineOilTemperature || snappedVehicle.engineOilTemperature==0">
                  {{snappedVehicle.engineOilTemperature}} <span class="odometer">&#8451;</span></h4>
                <h4 *ngIf="!snappedVehicle.engineOilTemperature && snappedVehicle.engineOilTemperature!=0">-- <span
                    class="odometer">&#8451;</span></h4>
                <p>Engine Oil Temp</p>
              </div>
              <div class="col-6 mt-2">
                <h4 *ngIf="snappedVehicle.engineSpeed || snappedVehicle.engineSpeed==0">{{snappedVehicle.engineSpeed}}
                  <span class="odometer">km/h</span>
                </h4>
                <h4 *ngIf="!snappedVehicle.engineSpeed && snappedVehicle.engineSpeed!=0">-- <span
                    class="odometer">km/h</span></h4>
                <p>Engine Speed</p>
              </div>
            </div>
          </div>
          <div class="mb-0" *ngIf="snappedVehicle.architectureType=='OBD2'">
            <ul class="list-group list-group-flush">
              <li class="list-group-item bold text-dark text-left">Health</li>
            </ul>
            <div class="row mx-0 mt-3 pr-3 pl-4  recent-activity-container bottom-border">
              <div class="media">
                <svg [ngClass]="{'vehicledata': dtcCodeCount != 0}" class="nodata align-self-center" version="1.1"
                  id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                  y="0px" viewBox="0 0 40 40" style="enable-background:new 0 0 40 40;" xml:space="preserve">
                  <path class="st0"
                    d="M25.6,30.8c1.4-0.5,2.8-1.1,4.2-1.6c0.1,0,0.3-0.1,0.4-0.1c1,0,2.1,0,3.1,0c1.1,0,1.7-0.7,1.7-1.8v-3.5
                    c0-0.3,0.2-0.5,0.5-0.5l0.4,0c0.4,0,0.4-0.1,0.4-0.4l0-2c0-0.2-0.2-0.4-0.4-0.4h-0.5c-0.3,0-0.5-0.2-0.5-0.5l0-4.3
                    c0-0.6-0.2-1.1-0.7-1.4c-0.8-0.6-3.3-2.3-3.7-2.6c-0.3-0.2-0.6-0.3-0.9-0.2l-4.2,0c-0.4,0-0.7-0.3-0.7-0.7v-0.1
                    c0-0.4,0.3-0.7,0.7-0.7h2.8c0.4,0,0.7-0.3,0.7-0.7V8.7c0-0.4-0.3-0.7-0.7-0.7H17.7c-0.4,0-0.7,0.3-0.7,0.7v0.8
                    c0,0.4,0.3,0.7,0.7,0.7h3.1c0.4,0,0.7,0.3,0.7,0.7V11c0,0.4-0.3,0.7-0.7,0.7l-4.2,0c-0.8,0-1.5,0.2-2.1,0.7c-0.9,0.6-1.8,1.3-2.6,2
                    c-0.2,0.2-0.5,0.3-0.8,0.3c-0.8,0-1.7,0-2.5,0c-1,0-1.7,0.7-1.7,1.7l0,2.9c0,0.2-0.2,0.5-0.5,0.5H6c-0.5,0-0.9,0.4-0.9,0.9v4.6
                    c0,0.5,0.4,0.9,0.9,0.9h0.4c0.2,0,0.5,0.2,0.5,0.5c0,0.7,0,1.9,0,2.6c0,0.7,0,1.9,1.2,1.9h17.1C25.3,30.9,25.5,30.9,25.6,30.8" />
                  <path class="st0" d="M1.1,17.6c-0.3,0-0.6,0.3-0.6,0.6v9.2c0,0.3,0.3,0.6,0.6,0.6h1.7c0.3,0,0.6-0.3,0.6-0.6v-9.2
                    c0-0.3-0.3-0.6-0.6-0.6L1.1,17.6L1.1,17.6z" />
                  <path class="st0" d="M38.9,17.6H38c-0.3,0-0.6,0.3-0.6,0.6V27c0,0.3,0.3,0.6,0.6,0.6h0.8c0.3,0,0.6-0.3,0.6-0.6v-8.7
                    C39.5,17.9,39.2,17.6,38.9,17.6" />
                </svg>
                <div class="media-body" data-toggle="modal" data-target="#vehicleStatus_model">
                  <h6 class="mt-0">Vehicle Status</h6>
                  <p>{{dtcCodeCount}} Diagnostic Trouble Code(s)</p>
                </div>
              </div>
              <hr>
              <div class="media mt-3">
                <svg
                  [ngClass]="{'goodBattery': snappedVehicle.minimumVoltage >= 12, 'attentionBattery': snappedVehicle.minimumVoltage >= 11.5 && snappedVehicle.minimumVoltage <= 12, 'cautionBattery': snappedVehicle.minimumVoltage <= 11.5 }"
                  class="nodata align-self-center" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 40 40"
                  style="enable-background:new 0 0 40 40;" xml:space="preserve">
                  <path class="st0" d="M34.2,8.7h-8V6.5c0-0.6,0.4-1.1,1-1.1h6c0.6,0,1,0.5,1,1.1V8.7z" />
                  <path class="st0" d="M15.4,8.7h-8V6.5c0-0.6,0.4-1.1,1-1.1h6c0.6,0,1,0.5,1,1.1V8.7z" />
                  <path class="st0" d="M35.4,10.3H6c-1.2,0-2.3,1-2.3,2.3V31c0,1.3,1,2.3,2.3,2.3h29.4c1.2,0,2.3-1,2.3-2.3V12.6
                      C37.7,11.4,36.7,10.3,35.4,10.3z M35.4,31H6V12.6h29.4V31z" />
                  <path class="st0" d="M8.3,14.9v13.8h24.9V14.9H8.3z M18,22.8h-2.8v2.8h-1.6v-2.8h-2.8v-1.6h2.8v-2.9h1.6v2.9H18V22.8z M30.8,22.8
                      h-2.3h-1.3h-2.3v-1.6h2.3h1.3h2.3V22.8z" />
                </svg>
                <div class="media-body">
                  <h6 class="mt-0">Battery Metrics</h6>
                  <ul class="list-unstyled">
                    <li>Minimum(V):&emsp;&emsp;&ensp;&nbsp;
                      <span *ngIf="snappedVehicle.minimumVoltage">{{snappedVehicle.minimumVoltage}}</span>
                      <span *ngIf="!snappedVehicle.minimumVoltage"><strong>--</strong></span>
                    </li>
                    <li>Resting(V):&emsp;&emsp;&emsp;&ensp;&nbsp;
                      <span *ngIf="snappedVehicle.restingVoltage">{{snappedVehicle.restingVoltage}}</span>
                      <span *ngIf="!snappedVehicle.restingVoltage"><strong>--</strong></span>
                    </li>
                    <li>Avg In Trip(V):&emsp;&ensp;&ensp;&nbsp;
                      <span *ngIf="snappedVehicle.avgInTripVoltage">{{snappedVehicle.avgInTripVoltage}}</span>
                      <span *ngIf="!snappedVehicle.avgInTripVoltage"><strong>--</strong></span>
                    </li>
                    <li>Time to Start(ms):&ensp;
                      <span *ngIf="snappedVehicle.timeToStart">{{snappedVehicle.timeToStart}}</span>
                      <span *ngIf="!snappedVehicle.timeToStart"><strong>--</strong></span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    </div>
    <!-- Side Drawer Ends -->
  </div>






  <!-- Table Column End -->
  <!-- Map Column Start -->
  <div id="main" class="p-0 main-padding" style="width:100%">
    <section [ngClass]="{'map-section':showLiveDashboard}" id="live_content" class="bg slice-sm sct-color-1">
      <div class="pt-3 collapse-btn">
        <span *ngIf="openedNav" tooltip="Collapse Side Panel" placement="right" (click)="openNav()"
          class="openbtn badge badge-secondary">
          <i class="fa fa-angle-double-left font-14" aria-hidden="true"></i>
        </span>
        <span *ngIf="!openedNav" tooltip="Expand Side Panel" placement="right" (click)="openNav()"
          class="openbtn badge badge-secondary">
          <i class="fa fa-angle-double-right font-14" aria-hidden="true"></i>
        </span>
      </div>
      <!-- Vehicle Tracking Indicator Start -->
      <div *ngIf="tracking" class="vehicle-track">
        Tracking {{trackedVehicle.assetName}} &nbsp;
        <a (click)="resetTracking()">Stop</a>
      </div>
      <!-- Vehicle Tracking Indicator End -->
      <div class="p-0 dirMap snazzy-popup-wrapper" id="map_canvas">
        <div class="outer-wrapper">
          <div class="map-wrapper">
            <ng-container>
              <div #gmap class="map-size"></div>
            </ng-container>
            <div #clusterLink>
              <a style="padding-bottom: 10px; color: #178bff; display: block; cursor: pointer" class="clusterLink"
                *ngFor="let data of infoWindowText">
                <span (click)="centerVehicle(data)">{{data}}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- style="width:25%;float: right;" -->
    <section style="width: 30%; float: right;">
      <!-- Live Vechile Data -->
      <div class="sideDrawer" [ngClass]="{'side-drawer-open-tracking' :showLiveDashboard}" style="left: 70%; right:0%;">
        <div class="row m-0 header">
          <h6>{{trackedVehicle?trackedVehicle.assetName:""}}</h6>
          <!-- <div (clickOutside)="closeDetails()">
          </div> -->
          <span class="close" (click)="resetTracking()">x</span>
        </div>
        <div class="col-md-12 directionSections row">
          <div class="col-md-8 nopadding">
            <div class="row">
              <div class="col-md-5 nopadding">
                <span class="" style="float: right;"><i class="fa fa-truck" aria-hidden="true"></i> VIN&nbsp;:</span>
              </div>
              <div class="col-md-7 nopadding ovf">
                <span [tooltip]="vechileData ? vechileData.vin : ''">&nbsp;<b>{{ vechileData ? vechileData.vin : "" }}</b></span>
              </div>
              <div class="col-md-5 nopadding">
                <span class="" style="float: right;"><i class="fa fa-object-ungroup"></i> Group&nbsp;:</span>
              </div>
              <div class="col-md-7 nopadding ovf">
                <span [tooltip]="vechileData ? vechileData.groupName : ''">&nbsp;<b>{{ vechileData ? vechileData.groupName : "" }}</b></span>
              </div>
            </div>
          </div>
          <div class="col-md-4 " style="text-align: center;justify-content: center;">
            <div class="">
              Direction
            </div>
            <div class="directionBody">
              {{ direction ? direction : "" }}
            </div>
          </div>
        </div>
        <div class="data-container">
          <div class="row" style="margin-top: 10px;margin-bottom: 80px;">
            <div class="col-md-6  liveIcon obdsHover" *ngFor="let obds of obdsList">
              <div class="">
                <div style="width: 100%;display: flex;text-align: center;height: 40px;">
                  <div style="width: 50%;">
                    <div>
                      <img class="align-self-start" width="60px" style="border-radius: 40px;" [src]="obds.icon">
                    </div>
                  </div>
                  <div style="width: 50%;font-size: 18px; color: #000;font-weight:bold;">
                    {{obds.value}}
                  </div>
                </div>
                <div
                  style="width: 100%;height: 100%;text-align: center;color: #000;font-size: 11px;position: relative; display: flex;top: 22px;  ">
                  <div style="width: 50%;padding-bottom: 16px;">
                    <p style="font-size: 11px;line-height: 1.5;text-align: center;text-overflow: ellipsis;">
                      {{obds.name}}
                    </p>
                  </div>
                  <div style="width: 50%;font-size: 12px;font-weight: 600;">
                    <p style="line-height: 1.5;text-overflow: ellipsis;">
                      {{obds.unit}}
                    </p>
                  </div>
                </div>
              </div>

              <!-- <div class="">
                <div style="width: 100%;display: flex;text-align: center;height: 40px;">
                  <div style="width: 60%;">
                    <div>
                      <img class="align-self-start" width="60px" style="border-radius: 40px;" [src]="obds.icon">
                    </div>
                  </div>
                  <div style="width: 40%;font-size: 24px; color: #000;font-weight:bold;">
                    {{obds.value}}
                  </div>
                </div>
                <div
                  style="width: 100%;height: 100%;text-align: center;color: #000;font-size: 11px;position: relative; display: flex;top: 22px;  ">
                  <div style="width: 60%;padding-bottom: 16px;">
                    <p style="font-size: 11px;line-height: 1.5;text-align: center;text-overflow: ellipsis;width: 79px;">
                      {{obds.description}}
                    </p>
                  </div>
                  <div style="width: 40%;font-size: 14px;font-weight: 600;">
                    <p style="line-height: 1.5;text-overflow: ellipsis;">
                      {{obds.unit}}
                    </p>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- Live Vechile Data end -->
    </section>
  </div>
  <!-- Map Column End -->
</div>
<!-- Table and Map Row End -->
<!-- END OF CONTENT -->
<!-- BEGIN MODAL DELETE -->
<app-confirm-delete-modal [title]="'Delete favorite'" [showModal]="showModal" [hideModal]="hideModal"
  [disableBtn]="disable" [displayText]="'Yes, Delete it!'" (confirmEvent)="onDeleteConfirm()">
</app-confirm-delete-modal>
<!--END MODAL DELETE-->
<!-- ADD DEVICE MODAL -->
<div data-keyboard="false" data-backdrop="static" id="editFavmodal" class="modal effect-flip-horizontal fade">
  <div class="modal-dialog" role="document">
    <div class="modal-content bd-0 bg-transparent overflow-hidden">
      <div class="modal-body p-0">
        <div class="row no-gutters">
          <div class="col-12">
            <span #closeuploadDevice_model class="pull-right closeBtn" data-dismiss="modal"
              (click)="querySelectDevice = null; favEditingMode = false;">
              <i class="fa fa-times" aria-hidden="true"></i>
            </span>
            <div class="modal-box p-30 edit-fav-model">
              <div class="reply-header">
                <h5>Add Devices</h5>
              </div>
              <div class="login-content user-modal mt-20">
                <form action="javascript:void(0);" (ngSubmit)="save(updatef)" #updatef="ngForm" method="POST"
                  class="margin-bottom-0">
                  <div class="row mb-1 mt-10">
                    <div class="col-lg-12 sm-form-design camp-add-device pl-0">
                      <app-search-select-device *ngIf="favEditingMode" isMulti="true" [searchBy]="'serialNumber'"
                        [autoSelect]="true" [querySelectDevice]="querySelectDevice" [closeOnSelect]="false"
                        (deviceChangeEvent)="addDevices($event)"></app-search-select-device>
                      <small class="text-danger" *ngIf="noDevicesSelected">
                        Please select atleast one device.
                      </small>
                    </div>
                  </div>
                  <div class="row pt-3">
                    <div class="col-lg-6"></div>
                    <div class="col-lg-6 pl-0 text-right sm-form-design">
                      <div class="login-buttons width100 text-right">
                        <button class="btn btn-outline-secondary small-screen-btn mr-2" data-dismiss="modal"
                          (click)="querySelectDevice = null; favEditingMode = false;">
                          Cancel
                        </button>
                        <button type="submit" class="btn btn-success small-screen-btn pull-right" [disabled]="disable">
                          Add Devices
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- ADD DEVICE MODAL END -->
<!--start Popup destinationDetail-->
<div id="destinationDetail" data-keyboard="false" data-backdrop="static" class="modal effect-flip-horizontal fade">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content bd-0 bg-transparent overflow-hidden">
      <div class="modal-body p-0">
        <div class="row no-gutters">
          <div class="col-12">
            <span class="pull-right closeBtn" data-dismiss="modal">
              <i class="fa fa-times" aria-hidden="true"></i>
            </span>
            <div class="modal-box p-3 mt-4">
              <div class="reply-header">
                <h5>Destination Detail</h5>
              </div>
              <div *ngIf="messageLoader" class="text-center mt-5 pt-5">
                <img src="../../../assets/img/darby_loader_small.gif" />
              </div>
              <div *ngIf="!messageLoader" class="scrollable-modal-body">
                <pre class="bench-device-data mt-4" [innerHTML]="messageFormattedString"> </pre>
              </div>
              <div class="row pt-3">
                <div class="col-lg-6"></div>
                <div class="col-lg-6 sm-form-design">
                  <div class="login-buttons width100 text-right">
                    <button class="btn btn-outline-secondary small-screen-btn mr-2" data-dismiss="modal">Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--end Popup destinationDetail-->
<!--start Popup share track-->
<div id="sharetracking" data-keyboard="false" data-backdrop="static" class="modal fade">
  <div class="modal-dialog" role="document">
    <div class="modal-content bd-0 bg-transparent overflow-hidden">
      <div class="modal-body p-0">
        <div class="row no-gutters">
          <div class="col-12">
            <span #trackModel class="pull-right closeBtn" data-dismiss="modal">
              <i class="fa fa-times" aria-hidden="true"></i>
            </span>
            <div class="modal-box p-3 mt-4">
              <div class="reply-header">
                <h5>Share Vehicle Location</h5>
              </div>
              <form (ngSubmit)="tf.form.valid && trackShare(tf.valid,tf)" #tf="ngForm" novalidate class="mb-2">
                <div class="row">
                  <div class="col-lg-12 sm-form-design">
                    <p class="mb-0 pt-3 info-text">
                      <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>&nbsp;
                      <em>You are about to share a vehicle's location information to users who do not have an account in
                        BitBrew.</em>
                    </p>
                  </div>
                  <div class="col-sm-12 sm-form-design mt-2">
                    <textarea #shareTrackTextArea id="emailId" class="form-control h5-email email-add"
                      placeholder="Please enter Email" value="" autocomplete="off" tabindex="1" rows="3"
                      pattern="^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$" name="emailId"
                      [(ngModel)]="searchModel.emailId" #emailId="ngModel" required>
                  </textarea>
                    <!--pattern="^([\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})(,[\w+-.%]+@[\w.-]+\.[A-Za-z]{2,4})*$"-->
                    <label class="control-label">Email (Enter email address seperated by commas)</label>
                    <div [hidden]="emailId.valid || (emailId.pristine && !tf.submitted)">
                      <small *ngIf="emailId.errors?.required" class="text-danger">Please enter Email</small>
                      <!--<small class="text-danger">Please enter Email</small>-->
                      <small *ngIf="emailId.errors?.pattern" class="text-danger">Please enter valid emailId</small>
                    </div>
                  </div>
                  <div class="d-flex col-12 align-items-center">
                    <p class="mr-3">Share location for <label>(HH:MM)</label>: </p>
                    <div>
                      <div class="form-group">
                        <ngb-timepicker [(ngModel)]="time" [formControl]="ctrl" required></ngb-timepicker>
                        <!--<div *ngIf="ctrl.valid" class="small form-text text-success">Great choice</div>-->
                        <div *ngIf="ctrl.errors" class="small form-text text-danger">
                          <div *ngIf="ctrl.errors['addTime']">Please add sharing time.</div>
                          <div *ngIf="ctrl.errors['maxTime']">Oh no, you can choose maximum 8 hours!</div>
                          <div *ngIf="ctrl.errors['minTime']">Please select some time!</div>
                          <!--<div *ngIf="ctrl.errors['tooEarly']">It's a bit too early</div>-->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6"></div>
                  <div class="col-lg-6 sm-form-design">
                    <div class="login-buttons width100 text-right">
                      <button class="btn btn-outline-secondary small-screen-btn mr-2"
                        data-dismiss="modal">Close</button>
                      <button type="submit" class="btn btn-primary small-screen-btn">
                        Share
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!--end Popup share track-->
<!-- start popup go to reports -->
<div id="goToReportsModal" data-keyboard="false" data-backdrop="static" class="modal fade">
  <div class="modal-dialog" role="document">
    <div class="modal-content bd-0 bg-transparent overflow-hidden">
      <div class="modal-body p-0">
        <div class="row no-gutters">
          <div class="col-12">
            <span #closeReportsModal class="pull-right closeBtn" data-dismiss="modal">
              <i class="fa fa-times" aria-hidden="true"></i>
            </span>
            <div class="modal-box p-3 mt-4">
              <div class="reply-header">
                <h5>Go to Reports</h5>
              </div>
              <div class="row no-gutters mt-3">
                <div class="col-sm-12">
                  <div class="card">
                    <ul class="list-group list-group-flush" style="max-height: 400px; overflow: auto;">
                      <li class="list-group-item">
                        <form #reportSearchForm="ngForm">
                          <input #reportSearch autofocus type="text" class="form-control p-2"
                            placeholder="Search Report" (click)="$event.stopPropagation();"
                            (keyup)="filterReport($event)" />
                        </form>
                      </li>
                      <li class="list-group-item text-left cursor-pointer" *ngFor="let data of reportsData"
                        (click)="redirectToReports(data)">
                        <i class="fa fa-bar-chart report-icon"></i> {{data.name}}
                      </li>
                      <li *ngIf="customLoader" class="text-center">
                        <img src="../../../assets/img/darby_loader_small.gif" />
                      </li>
                      <li class="list-group-item text-center" *ngIf="!reportsData.length && !customLoader">
                        <b>No reports found</b>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- end popup go to reports -->
<!-- Hidden Buttons For Infowindow -->
<button id="historyLink" type="button" hidden (click)="goToLocationHistoryUsingEvent(markerInfoWindow)">Go to
  History</button>
<!-- <button id="trackLink" type="button" hidden (click)="trackVehicle()">Track</button> -->
<!-- explicit button for share track -->
<button id="shareTrackBtn" type="button" hidden data-toggle="modal" data-target="#sharetracking"></button>
<button id="goToReportsBtn" type="button" hidden data-toggle="modal" data-target="#goToReportsModal"></button>
<!-- trigger buttons to call internal functions through infowindow -->
<button id="reportsModalBtn" type="button" hidden (click)="showReportsModal()"></button>
<button id="trackVehicleBtn" type="button" hidden (click)="trackVehicle(infoWindowSelectedVehicle)"></button>
<button id="deviceLink" type="button" hidden (click)="goToDevice(infoWindowSelectedVehicle)"></button>