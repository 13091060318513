import { Component, OnInit, Output, EventEmitter, Input, OnChanges, ElementRef, ViewChild } from '@angular/core';
import { Observable, Subject, concat, of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { SharedService } from '../shared.service';
import { configMessage } from '@shared/config-message';
import * as moment from 'moment';
import { environment } from '@myenv/environment';
import { NgSelectComponent } from '@ng-select/ng-select';
import { DeviceService } from '@myproject/components/device/device.service';

@Component({
  selector: 'app-search-select-device',
  templateUrl: './search-select-device.component.html',
  styleUrls: ['./search-select-device.component.scss']
})

export class SearchSelectDeviceComponent implements OnInit, OnChanges {
  @ViewChild(NgSelectComponent, { static: false }) ngSelectComponent: NgSelectComponent;
  @Output() deviceChangeEvent = new EventEmitter(); // Event raiser on device change
  @Input() autoSelect: boolean; // Selects device automatically on page load
  @Input() querySelectDevice: any; // selects feeded device(s) on page load overwrites autoselect
  @Input() isMulti = false; // for multselect device
  @Input() closeOnSelect = true; // closes the dropdown on select
  @Input() emptySelection: number; // takes in the length of array and removes all selection if 0
  @Input() clearable = true; // for clearing selection and no device selected
  @Input() selectSearchCategory = false; // diplays the search type dropdown
  @Input() searchBy = 'assetName'; // accepts the search by parameter
  @Input() allowPaste = false; // allows copy paste selection
  @Input() defaultselectdevice: boolean;
  device$: Observable<any[]>;
  devices = [];
  defaultDevices: any = [];
  deviceLoading = false;
  deviceInput$ = new Subject<string>();
  selectedDevices = <any>null;
  deviceStatus = configMessage.deviceStatus;
  deviceStatusClass = configMessage.deviceStatusClass;
  public dateFormat = environment.smallDateFormat;
  pasted = false;
  categories = [
    { key: 'assetName', displayName: 'Name' },
    { key: 'serialNumber', displayName: 'DSN' },
    { key: 'vin', displayName: 'VIN' },
    { key: 'productSerialNumber', displayName: 'PSN' },
  ];
  attributes = 'deviceId,serialNumber,assetName,productSerialNumber,vin,lastCommunication,deviceStatus,architectureType,ecuMake,generation,simStatus,imei,firmwareVersion,configVersion,simCcid,lastKnownEvent,lastKnownStatus';

  constructor(
    private sharedService: SharedService
  ) { }

  ngOnInit() {
    this.getAllDevices();
  }

  ngOnChanges(changes) {
    if (changes.querySelectDevice && changes.querySelectDevice.previousValue !== undefined) {
      if (changes.querySelectDevice.previousValue !== changes.querySelectDevice.currentValue) {
        if (changes.querySelectDevice.previousValue === null) {
          this.selectedDevices = this.querySelectDevice;
        }
      }
    }
    // Empty Selection
    if (changes.emptySelection && changes.emptySelection.previousValue !== undefined) {
      if (changes.emptySelection.currentValue === 0) {
        this.selectedDevices = [];
      }
    }
  }

  trackByFn(item) {
    return item.deviceId;
  }

  getAllDevices() {
    this.sharedService.getAllDevices().subscribe({
      next: res => {
        this.defaultDevices = res;
        // this.selectedDevices = res[0];
        // this.loadDevices();
        this.selectDevice();
      },
      error: error => {
        this.sharedService.getErrorMsg(error);
      }
    });
  }

  performDeviceOperations() {
    this.devices.map(device => {
      const commDate = Date.parse(new Date(device.lastCommunication).toUTCString());
      const date = moment(commDate).fromNow();
      device.lastCommunicationString = date;
    });
  }

  selectDevice() {
    if (!this.defaultselectdevice) {
      if (this.autoSelect) {
        this.selectedDevices = this.selectedDevices ? this.selectedDevices : this.defaultDevices[0];
      } else {
        this.selectedDevices = this.selectedDevices ? this.selectedDevices : null;
      }
    } else {
      if (this.selectedDevices) {
        this.sharedService.setdefaultselectvehicle(this.selectedDevices);
      }
      this.selectedDevices = this.sharedService.getdefaultselectvehicle() ? this.sharedService.getdefaultselectvehicle() : this.defaultDevices[0];
    }

    this.deviceChangeEvent.emit(this.selectedDevices);
    this.ngSelectComponent.searchTerm = '';
    this.deviceLoading = false;
    this.pasted = false;
  }

  validateInputString(term): string {
    if (this.isMulti) {
      term = term.replace(/[^_\-@.,a-zA-Z0-9\s]/g, '').split(/[ ]+/).filter(function (v) { return v !== ''; }).join(',');
    } else {
      term = term.replace(/[^_\-@.a-zA-Z0-9\s]/g, '');
    }
    return term;
  }

  onPaste(ce) {
    if (ce.type === 'paste') {
      this.pasted = true;
    }
  }

  loadDevices() {
    this.device$ = concat(
      of(this.defaultDevices), // default items
      this.deviceInput$.pipe(
        distinctUntilChanged(),
        debounceTime(500),
        tap(() => this.deviceLoading = true),
        switchMap(term => this.sharedService.onSearchDevice(
          this.searchBy, this.validateInputString(term), null, null, null, null, this.attributes
        ).pipe(
          catchError((error) => of(this.sharedService.getErrorMsg(error), this.defaultDevices)), // default list on error
          tap(() => this.deviceLoading = false)
        ))
      )
    );
    this.device$.subscribe({
      next: (res: any[]) => {
        if (this.pasted && this.allowPaste) {
          this.addDevices(res);
        } else {
          this.devices = res;
          this.deviceLoading = false;
        }
        this.performDeviceOperations();
        this.pasted = false;
      },
      error: error => {
        this.deviceLoading = false;
        this.pasted = false;
        this.devices = this.defaultDevices;
      }
    });
  }

  addDevices(devices) {
    if (this.selectedDevices !== null && this.selectedDevices.length) {
      this.devices = [...this.selectedDevices, ...devices];
    } else {
      this.devices = devices;
    }
    this.selectedDevices = this.devices;
    this.deviceChangeEvent.emit(this.selectedDevices);
    this.pasted = false;
    this.ngSelectComponent.searchTerm = '';
  }

  getLastKnownStatus(lastKnownStatus) {
    if (lastKnownStatus) {
      return lastKnownStatus;
    } else {
      return "Registered";
    }
  }
}
