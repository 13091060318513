import { throwError as observableThrowError } from 'rxjs';
import { catchError, share } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { environment } from '@myenv/environment';
var DeviceService = /** @class */ (function () {
    function DeviceService(http, handler) {
        this.http = http;
        this.handler = handler;
        this.apiUrl = environment.apiUrl;
        this.d = new Date();
        this.offsetInMinutes = this.d.getTimezoneOffset() * -1;
        this.timeZone = "?dateFormat=" + environment.isoDateTimeFormat + "&offsetInMinutes=" + this.offsetInMinutes;
        this.httpClient = new HttpClient(handler);
    }
    DeviceService.prototype.updateXlFile = function (data) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.httpClient.put(this.apiUrl + 'devices/upload', data, { headers: headers }).pipe(catchError(this.handleError));
    };
    DeviceService.prototype.uploadXlFile = function (data) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.httpClient.post(this.apiUrl + "devices/upload", data, { headers: headers }).pipe(catchError(this.handleError));
    };
    DeviceService.prototype.bulkuploadFile = function (data) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.httpClient.post(this.apiUrl + "devices/bulkUpload", data, { headers: headers }).pipe(catchError(this.handleError));
    };
    DeviceService.prototype.getDeletedDevice = function (page) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
        });
        return this.http.get(this.apiUrl + 'devices/deleted?page=' + page, { headers: headers }).pipe(catchError(this.handleError));
    };
    DeviceService.prototype.getDeviceUploadLogs = function () {
        return this.http.get(this.apiUrl + 'devices/log').pipe(catchError(this.handleError));
    };
    DeviceService.prototype.getDeviceStatus = function (deviceId) {
        return this.http.get(this.apiUrl + 'devices/status/deviceId/' + deviceId).pipe(catchError(this.handleError));
    };
    DeviceService.prototype.getVehicleDetail = function (serialNumber) {
        return this.http.get(this.apiUrl + 'vehicles/serialNumber/' + serialNumber).pipe(catchError(this.handleError));
    };
    DeviceService.prototype.getDeviceDetail = function (deviceId) {
        return this.http.get(this.apiUrl + 'devices/' + deviceId)
            .pipe(share()).pipe(catchError(this.handleError));
    };
    DeviceService.prototype.getSimInfo = function (simCcid) {
        return this.http.get(this.apiUrl + ("carrier/iccid/" + simCcid + "/siminfo")).pipe(catchError(this.handleError));
    };
    DeviceService.prototype.getVehicleCount = function (key, value, data, group, isAssociatedDevice) {
        var tempUrl = this.apiUrl + "devices/count?page=0";
        if (isAssociatedDevice === undefined) {
            isAssociatedDevice = false;
        }
        if (value !== '' && value) {
            tempUrl += "&key=" + key + "&value=" + value + "&isAssociatedDevice=" + isAssociatedDevice;
        }
        if (data) {
            tempUrl += "&status=" + data;
        }
        if (group) {
            tempUrl += "&groups=" + group;
        }
        return this.http.get(tempUrl).pipe(catchError(this.handleError));
        // return this.http.get(this.apiUrl + 'devices/count').pipe(
        //   catchError(this.handleError));
    };
    DeviceService.prototype.downloadUploadLog = function (logId) {
        return this.http.get(this.apiUrl + 'devices/log/' + logId + '/csvDownload' + this.timeZone, {
            responseType: 'arraybuffer',
            observe: 'response'
        }).pipe(catchError(this.handleError));
    };
    DeviceService.prototype.updateDevice = function (payload, device) {
        return this.http.put(this.apiUrl + 'devices/' + device.deviceId, payload).pipe(catchError(this.handleError));
    };
    DeviceService.prototype.deleteDevice = function (deviceId) {
        return this.http.delete(this.apiUrl + 'devices/' + deviceId).pipe(catchError(this.handleError));
    };
    DeviceService.prototype.downloadDeviceCSV = function (searchData, deviceStatusKey, groups, selectedAttr, isAssociatedDevice) {
        var tempUrl = this.apiUrl + 'devices/download' + this.timeZone + '&page=0';
        // if (searchData.deviceTypeVal || deviceStatusKey) {
        if (deviceStatusKey) {
            tempUrl += '&status=' + deviceStatusKey;
        }
        if (searchData.deviceTypeVal) {
            tempUrl += '&key=' + searchData.deviceId + '&value=' + searchData.deviceTypeVal + '&isAssociatedDevice=' + isAssociatedDevice;
        }
        if (groups) {
            tempUrl += '&groups=' + groups;
        }
        if (selectedAttr) {
            tempUrl += '&attributes=' + selectedAttr;
        }
        // }
        return this.http.get(tempUrl, { responseType: 'arraybuffer', observe: 'response' }).pipe(catchError(this.handleError));
    };
    DeviceService.prototype.getDeviceMetrics = function (data) {
        return this.http.get(this.apiUrl + ("analytics/serialNumber/message/count?serialNumber=" + data.serialNumber + "&startDate=\n      " + data.startTime + "&endDate=" + data.endTime)).pipe(catchError(this.handleError));
    };
    DeviceService.prototype.getCarrier = function (carrierName, data) {
        return this.http.post(this.apiUrl + ("carrier/" + carrierName + "/GET_SIM_STATUS"), data).pipe(catchError(this.handleError));
    };
    DeviceService.prototype.getCarrierFeatures = function (carrierName) {
        return this.http.get(this.apiUrl + ("carrier/" + carrierName + "/features")).pipe(catchError(this.handleError));
    };
    DeviceService.prototype.getAllDevices = function (page, data, key, value) {
        var tempUrl = "devices?page=" + page;
        if (data.prop) {
            tempUrl = tempUrl + ("&sortingKey=" + data.prop + "&sort=" + data.dir.toUpperCase());
        }
        if (key && value) {
            tempUrl = tempUrl + ("&key=" + key + "&value=" + value);
        }
        return this.http.get(this.apiUrl + tempUrl).pipe(catchError(this.handleError));
    };
    DeviceService.prototype.downloadActivityCSV = function (deviceId, filter) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
        });
        var tempUrl = "activity/device/download/deviceId/" + deviceId + this.timeZone;
        if (filter)
            tempUrl += "&filter=" + filter;
        return this.http.get(this.apiUrl + tempUrl, { responseType: 'arraybuffer', observe: 'response', headers: headers })
            .pipe(catchError(this.handleError));
    };
    DeviceService.prototype.addDevice = function (device) {
        // the complete device creation url
        var url = this.apiUrl + "devices";
        return this.http.post(url, device).pipe(catchError(this.handleError));
    };
    DeviceService.prototype.getDeviceCampaigns = function (page, limit, serialNumber, exact, filterLabel) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        var tempUrl = this.apiUrl + ("campaigns/serialNumber/" + serialNumber + "?page=" + page + "&limit=" + limit + "&partialSearch=" + exact);
        if (filterLabel) {
            tempUrl += "&label=" + filterLabel;
        }
        return this.httpClient.get(tempUrl, { headers: headers })
            .pipe(catchError(this.handleError));
    };
    DeviceService.prototype.downloadKYC = function (serialNumber) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.http.get(this.apiUrl + ("al/vehicles/provisioning/kyc/dsn/" + serialNumber + "/download"), { headers: headers, responseType: 'arraybuffer' }).pipe(catchError(this.handleError));
    };
    DeviceService.prototype.getQueryDevices = function (term, page) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
        });
        return this.http.get(this.apiUrl + ("devices/advance/search?query=" + term + "&page=" + page + "&limit=100"), { headers: headers }).pipe(catchError(this.handleError));
    };
    DeviceService.prototype.getQueryDevicesCount = function (term) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
        });
        return this.http.get(this.apiUrl + ("devices/advance/search/count?query=" + term), { headers: headers }).pipe(catchError(this.handleError));
    };
    DeviceService.prototype.getHistoricalUsageData = function (simCcid, startTime, endTime) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
        });
        return this.http.get(this.apiUrl + ("carrier/sim/usages/history/" + simCcid + "/details?startTime=" + startTime + "&endTime=" + endTime), { headers: headers })
            .pipe(catchError(this.handleError));
    };
    DeviceService.prototype.getAdvancedFilters = function () {
        return this.http.get(this.apiUrl + "devices/filter").pipe(catchError(this.handleError));
    };
    DeviceService.prototype.createAdvancedFilter = function (payload) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.httpClient.post(this.apiUrl + "devices/filter", payload, { headers: headers })
            .pipe(catchError(this.handleError));
    };
    DeviceService.prototype.downloadQueryDevices = function (term, attributes) {
        return this.http.get(this.apiUrl + ("devices/advance/search/download?query=" + term + "&attributes=" + attributes), { responseType: 'arraybuffer', observe: 'response' }).pipe(catchError(this.handleError));
    };
    DeviceService.prototype.getLoginActivity = function (sessionId) {
        return this.http.get(this.apiUrl + ("activity/loginActivity/session/" + sessionId))
            .pipe(catchError(this.handleError));
    };
    DeviceService.prototype.getConnectivityCarriers = function () {
        return this.http.get(this.apiUrl + "carrier/connectivity")
            .pipe(catchError(this.handleError));
    };
    // for tags
    DeviceService.prototype.getAllTags = function () {
        return this.http.get(this.apiUrl + "devices/tags")
            .pipe(catchError(this.handleError));
    };
    DeviceService.prototype.createTag = function (tagName) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token
        });
        return this.httpClient.post(this.apiUrl + ("devices/tag?tagName=" + tagName), {}, { headers: headers })
            .pipe(catchError(this.handleError));
    };
    DeviceService.prototype.updateTag = function (tagId, tagName, type) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.patch(this.apiUrl + ("devices/tag/" + tagId + "?tagName=" + tagName + "&type=" + type), {}, { headers: headers }).pipe(catchError(this.handleError));
    };
    DeviceService.prototype.deleteTag = function (tagId) {
        var token = localStorage.getItem('token');
        var headers = new HttpHeaders({
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        });
        return this.http.delete(this.apiUrl + ("devices/tag/remove/" + tagId), { headers: headers }).pipe(catchError(this.handleError));
    };
    DeviceService.prototype.handleError = function (error) {
        return observableThrowError(error.error || error.json() || error || 'Server error');
    };
    DeviceService.prototype.getConfig = function (serialNumber, configType) {
        return this.http.get(this.apiUrl + 'twins/config?serialNumber=' + serialNumber + '&configType=' + configType).pipe(catchError(this.handleError));
    };
    return DeviceService;
}());
export { DeviceService };
